import { ReactNode, useEffect, useState } from "react";

import styles from "./ModalWrapper.module.css";

import { useModalContext } from "@/context/ModalContext";
import ModalAuthHeader from "../AuthenticatedHeader/ModalAuthHeader";

type Props = {
  children: ReactNode;
  backgroundColor?: string;
  title: string;
  subTitle?: string;
  noHeader?: boolean;
  noLine?: boolean;
  onClose?: () => void;
  onScroll?: (e: React.UIEvent<HTMLElement>) => void;
};

export default function ModalWrapper(props: Props) {
  const { emptyModal, modalComponent, setModalOpen } = useModalContext();

  return (
    <dialog
      onMouseDown={() => {
        emptyModal();
      }}
      className={styles.main}
    >
      <div onMouseDown={(e) => e.stopPropagation()} className={styles.parent}>
        <main
          style={
            props.backgroundColor
              ? { backgroundColor: props.backgroundColor }
              : {}
          }
          id="dialog"
          className={
            modalComponent.length > 1 ? styles.wrapperSide : styles.wrapper
          }
          onMouseDown={(e) => e.stopPropagation()}
        >
          {!props.noHeader && (
            <ModalAuthHeader
              noLine={props.noLine}
              title={props.title}
              subTitle={props.subTitle}
              downArrow={modalComponent.length < 2 ? true : false}
              onBack={() => {
                setModalOpen(false);
                props.onClose && props.onClose();
              }}
            />
          )}
          <div
            onScroll={(e) => props.onScroll && props.onScroll(e)}
            className={styles.childrenWrapper}
          >
            {props.children}
          </div>
        </main>
      </div>
    </dialog>
  );
}
