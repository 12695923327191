export default function ForwardArrow() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0001 9.99855C17.9946 9.85558 17.9352 9.68995 17.8376 9.58605L14.6376 6.18605C14.4204 5.99151 14.0047 5.91104 13.7626 6.13604C13.5235 6.35792 13.5313 6.78838 13.7688 7.01104L16.0188 9.39865L2.6 9.39864C2.26876 9.39864 2 9.6674 2 9.99864C2 10.3299 2.26876 10.5986 2.6 10.5986L16.0188 10.5986L13.7688 12.9862C13.5641 13.1917 13.5258 13.637 13.7626 13.8612C13.9985 14.0855 14.4297 14.0136 14.6376 13.8112L17.8376 10.4112C17.9469 10.2948 18.0001 10.1581 18.0001 9.99875L18.0001 9.99855Z"
        fill="#E6E1DF"
      />
    </svg>
  );
}
