import { useEffect, useState } from "react";
import {
  SummaryRowValue,
  SummaryTableRow,
} from "@dimensional-engineering/dimensional-models";

import styles from "./SummaryTable.module.css";

type ValueProps = {
  index: number;
  natureColor: string | null;
  value: SummaryRowValue;
  row: SummaryTableRow;
  isLast: boolean;
};

export default function SummaryTableValue(props: ValueProps) {
  const [span, setSpan] = useState<string>("");

  useEffect(() => {
    if (props.isLast) {
      setSpan("");
    } else {
      if (
        props.row.displayStyle &&
        props.row.displayStyle === "commaSeparated"
      ) {
        setSpan(",");
      } else {
        setSpan("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: "flex" }}>
      {props.index === 0 && (
        <div
          style={
            props.natureColor ? { backgroundColor: props.natureColor } : {}
          }
          className={styles.natureCircle}
        ></div>
      )}
      <p
        className={
          props.value.isHighlighted
            ? `${styles.summaryTableValueHighlighted} ${
                props.row.title === "Top strengths" ? styles.topStrengths : ""
              }`
            : `${styles.summaryTableValue} ${
                props.row.title === "Top strengths" ? styles.topStrengths : ""
              }`
        }
      >
        {props.row.values ? props.value.text : "?"}
      </p>
      <span
        className={
          span === "/"
            ? styles.summaryTableValue
            : styles.summaryTableValueHighlighted
        }
      >
        {span}
      </span>
    </div>
  );
}
