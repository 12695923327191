/* eslint-disable @next/next/no-img-element */
import { useSelector } from "react-redux";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";

import styles from "./SmallProfileImage.module.css";

import { useModalContext } from "@/context/ModalContext";
import { RootState } from "@/redux/store";
import { getConnectionProfileColor } from "../utils";
import ProfileModalHandler from "@/components/authenticatedProfile/Abstracts/ProfileModal/ProfileModalHandler";

type Props = {
  publicProfile: PublicProfile | null;
  imageUrl: string;
  noLink?: boolean;
  verySmal?: boolean;
};

export default function ComparedSmallProfileImage(props: Props) {
  const { setModalComponent } = useModalContext();
  const userState = useSelector((state: RootState) => state.user);
  const connectionColor = getConnectionProfileColor(
    userState.publicProfileData?.primaryNature?.nature,
    props.publicProfile?.primaryNature?.nature
  );

  if (props.noLink) {
    return (
      <div
        className={
          props.verySmal
            ? styles.verySmallImageContatiner
            : styles.smallImageContainer
        }
        style={{ borderRadius: "50%", border: `3px solid ${connectionColor}` }}
      >
        <img
          alt=""
          src={props.imageUrl}
          className={props.verySmal ? styles.verySmallImage : styles.smallImage}
        />
      </div>
    );
  }
  return (
    <div
      onClick={() =>
        setModalComponent(
          <ProfileModalHandler
            uid={props.publicProfile?.ownerUUID}
            elementName="profileModal"
          />
        )
      }
      className={
        props.verySmal
          ? styles.verySmallImageContatiner
          : styles.smallImageContainer
      }
      style={{ borderRadius: "50%", border: `3px solid ${connectionColor}` }}
    >
      <img
        alt=""
        src={props.imageUrl}
        className={props.verySmal ? styles.verySmallImage : styles.smallImage}
      />
    </div>
  );
}
