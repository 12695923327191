import React from "react";

import styles from "./CircularScoreBar.module.css";

import { manipulateScorebarValue } from "../utils";

export default function CircularScoreBar(props: {
  score: number | null | undefined;
  doubleQuestionMark?: boolean;
}) {
  const radius = 37.99; // Adjust as needed
  const circumference = 2 * Math.PI * radius;
  const openPercentage = props.score
    ? 100 - manipulateScorebarValue(props.score)
    : 100;

  const openLength = (circumference * openPercentage) / 100;
  const closedLength = circumference - openLength;

  const strokeDashoffset = closedLength;

  if (props.doubleQuestionMark) {
    return (
      <div className={styles.main}>
        <div className={styles.mainCircle}></div>
        <svg className={styles.inner} width={radius * 2} height={radius * 2}>
          <circle
            cx={radius}
            cy={radius}
            r={radius - 5}
            fill="transparent"
            strokeWidth={6.5}
            stroke="#FFFFDE"
            strokeDasharray={`${closedLength} ${openLength}`}
            strokeDashoffset={strokeDashoffset}
            style={
              props.score !== null && props.score
                ? { strokeLinecap: "round" }
                : {}
            }
          />
        </svg>
        <p
          className={
            props.score !== null && props.score
              ? props.score < 10
                ? styles.scoreLow
                : styles.score
              : styles.scoreunknownDoubleLarge
          }
        >
          {props.score !== null && props.score !== undefined
            ? `${props.score.toFixed(0)}`
            : "??"}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.mainCircle}></div>
      <svg className={styles.inner} width={radius * 2} height={radius * 2}>
        <circle
          cx={radius}
          cy={radius}
          r={radius - 5}
          fill="transparent"
          strokeWidth={6.5}
          stroke="#FFFFDE"
          strokeDasharray={`${closedLength} ${openLength}`}
          strokeDashoffset={strokeDashoffset}
          style={
            props.score !== null && props.score
              ? { strokeLinecap: "round" }
              : {}
          }
        />
      </svg>
      <p
        className={
          props.score !== null && props.score
            ? props.score < 10
              ? styles.scoreLow
              : styles.score
            : styles.scoreunknownLarge
        }
      >
        {props.score !== null && props.score !== undefined
          ? `${props.score.toFixed(0)}`
          : "?"}
      </p>
    </div>
  );
}

export function CircularScoreBarThin(props: {
  score: number | null | undefined;
}) {
  const radius = 37; // Adjust as needed
  const circumference = 2 * Math.PI * radius;
  const openPercentage = props.score
    ? 100 - manipulateScorebarValue(props.score)
    : 100;

  const openLength = (circumference * openPercentage) / 100;
  const closedLength = circumference - openLength;

  const strokeDashoffset = closedLength;

  return (
    <div className={styles.main}>
      <div style={{ borderWidth: "5px" }} className={styles.mainCircle}></div>
      <svg className={styles.inner} width={radius * 2} height={radius * 2}>
        <circle
          cx={radius}
          cy={radius}
          r={radius - 5}
          fill="transparent"
          strokeWidth={5}
          stroke="#FFFFDE"
          strokeDasharray={`${closedLength} ${openLength}`}
          strokeDashoffset={strokeDashoffset}
          style={
            props.score !== null && props.score
              ? { strokeLinecap: "round" }
              : {}
          }
        />
      </svg>
      <p
        className={
          props.score !== null && props.score
            ? props.score < 10
              ? styles.scoreLow
              : styles.score
            : styles.scoreunknownLarge
        }
      >
        {props.score !== null && props.score !== undefined
          ? `${props.score.toFixed(0)}`
          : "??"}
      </p>
    </div>
  );
}

export function CircularScoreBarSmallWithIcon(props: {
  score: number | null | undefined;
  icon: JSX.Element;
  inactive: boolean;
}) {
  const radius = 29; // Adjust as needed
  const circumference = 2 * Math.PI * radius;
  const openPercentage = props.score
    ? 100 - manipulateScorebarValue(props.score)
    : 100;

  const openLength = (circumference * openPercentage) / 100;
  const closedLength = circumference - openLength;

  const strokeDashoffset = closedLength;

  return (
    <div
      style={props.inactive ? { opacity: "0.4" } : {}}
      className={styles.main}
    >
      <div className={styles.mainCircleSmall}></div>
      <svg
        className={styles.innerSmalll}
        width={radius * 2}
        height={radius * 2}
      >
        <circle
          cx={radius}
          cy={radius}
          r={radius - 5}
          fill="transparent"
          strokeWidth={4.5}
          stroke="#FFFFDE"
          strokeDasharray={`${closedLength} ${openLength}`}
          strokeDashoffset={strokeDashoffset}
          style={props.score !== null ? { strokeLinecap: "round" } : {}}
        />
      </svg>
      <div className={styles.iconDiv}>{props.icon}</div>
      <p className={styles.scoreWithIcon}>
        {props.score !== null && props.score !== undefined
          ? `${props.score.toFixed(0)}`
          : "??"}
      </p>
    </div>
  );
}
