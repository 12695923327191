import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { db, firebaseStorage } from "@/_firebase/firebaseConfig";
import { getDefaultProfilePhoto } from "@/context/utils";
import { ExtendedScale, FriendsPublicProfile } from "@/models/sharedModels";
import {
  FriendRequest,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";

export async function sendFriendRequest(
  senderPublicProfile: PublicProfile,
  receiverPublicProfile: PublicProfile,
  isClose: boolean,
  isDeep: boolean,
  requestBody?: string
) {
  const sendRequest = callFirebaseFunctions("sendFriendRequest");
  return sendRequest({
    sender: senderPublicProfile,
    receiver: receiverPublicProfile,
    status: "sent",
    isClose: isClose,
    isDeep: isDeep,
    createdOn: new Date().getMilliseconds(),
    requestBody: requestBody,
  });
}

export function sortConnections(
  sortType: string,
  a: FriendsPublicProfile | undefined,
  b: FriendsPublicProfile | undefined,
  closeFriendList: string[],
  deepFriendList: string[]
) {
  if (a && b) {
    if (sortType === "a-z") {
      if (a.name && b.name) {
        if (a.name.toLowerCase() < b.name.toLocaleLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      } else if (a.userName && b.userName) {
        if (a.userName < b.userName) {
          return -1;
        }
        if (a.userName > b.userName) {
          return 1;
        }
        return 0;
      } else {
        return 0;
      }
    } else if (sortType === "type") {
      const aIsDeepFriend = deepFriendList.includes(a.ownerUUID);
      const bIsDeepFriend = deepFriendList.includes(b.ownerUUID);

      const aIsCloseFriend = closeFriendList.includes(a.ownerUUID);
      const bIsCloseFriend = closeFriendList.includes(b.ownerUUID);

      if (aIsDeepFriend && !bIsDeepFriend) {
        return -1;
      } else if (!aIsDeepFriend && bIsDeepFriend) {
        return 1;
      } else if (aIsCloseFriend && !bIsCloseFriend) {
        return -1;
      } else if (!aIsCloseFriend && bIsCloseFriend) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (a.grantedOn && b.grantedOn) {
        return a?.grantedOn - b?.grantedOn;
      }
    }
  }
  return 0;
}

export function acceptFriendRequest(req: FriendRequest) {
  const acceptFunc = callFirebaseFunctions("acceptFriendRequest");
  return acceptFunc(req);
}

export async function declineFriendRequest(req: FriendRequest) {
  let docId: string = "";

  const q = query(
    collection(db, "friendRequests"),
    where("receiver.ownerUUID", "==", req.receiver.ownerUUID),
    where("sender.ownerUUID", "==", req.sender.ownerUUID),
    where("status", "==", "sent")
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    docId = doc.id;
  });

  if (docId !== "") {
    return deleteDoc(doc(db, "friendRequests", docId));
  }
}

export function sortPeerRatingScales(a: ExtendedScale, b: ExtendedScale) {
  let dimensionOrder = {
    personality: 1,
    "personality-II": 2,
    values: 3,
    "communication-style": 5,
    interests: 8,
    "love-style": 4,
    "love-attitudes": 6,
    "attachment-style": 7,
    "conflict-style": 11,
    strengths: 10,
    "sex-attitudes": 12,
    "political-ideology": 13,
    "secondary-traits": 14,
    lifestyle: 9,
  };

  if (!a.dimensionSlug && b.dimensionSlug) {
    return -1;
  } else if (a.dimensionSlug && !b.dimensionSlug) {
    return 1;
  } else {
    return (
      dimensionOrder[a.dimensionSlug as keyof typeof dimensionOrder] -
      dimensionOrder[b.dimensionSlug as keyof typeof dimensionOrder]
    );
  }
}

export async function handlePeerAnswer(
  rating: number | null,
  userId: string,
  friendUid: string,
  scaleSlug: string
) {
  const ref = doc(
    db,
    "members",
    userId,
    "peerRatings",
    `${friendUid}-${scaleSlug}`
  );

  return await setDoc(ref, {
    createdOn: Date.now(),
    friendUid: friendUid,
    rating: rating,
    scaleSlug: scaleSlug,
  });
}

export function handlePermissionStatus(
  friendStatus:
    | "connected"
    | "close"
    | "deep"
    | "sent"
    | "unconnected"
    | "received"
    | null,
  permission: "connected" | "isClose" | "isDeep" | "private"
) {
  if (permission === "isClose") {
    if (
      friendStatus === "connected" ||
      friendStatus === "received" ||
      friendStatus === "sent" ||
      friendStatus === "unconnected"
    ) {
      return "isClose";
    } else {
      return null;
    }
  } else if (permission === "isDeep") {
    if (
      friendStatus === "close" ||
      friendStatus === "connected" ||
      friendStatus === "received" ||
      friendStatus === "sent" ||
      friendStatus === "unconnected"
    ) {
      return "isDeep";
    } else {
      return null;
    }
  } else {
    if (
      friendStatus === "received" ||
      friendStatus === "sent" ||
      friendStatus === "unconnected"
    ) {
      return "connected";
    } else {
      return null;
    }
  }
}

export async function getUserProfilePhoto(uid: string) {
  let imageUrl = "";
  await getDownloadURL(
    ref(
      firebaseStorage,
      `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${uid}/thumbnail.jpg`
    )
  )
    .then((url) => {
      imageUrl = url;
    })
    .catch(async () => {
      await getDefaultProfilePhoto(uid).then((value) => {
        imageUrl = value;
      });
    });

  return imageUrl;
}
