import { db } from "@/_firebase/firebaseConfig";
import {
  AgeRange,
  Gender,
  Nature,
  PollOption,
  PollResponse,
} from "@dimensional-engineering/dimensional-models";
import { doc, setDoc } from "firebase/firestore";

import { ResultBreakDownPolls } from "./UserPollResponseModal/PollMostLikelyToChoose/PollMostLikelyToChoose";

export async function submitPollResponse(
  pollSlug: string,
  uid: string | undefined,
  selectedOption?: PollOption | null | undefined
) {
  if (uid) {
    const ref = doc(db, `/members/${uid}/pollResponses/${pollSlug}`);
    const payload: PollResponse = selectedOption
      ? {
          pollSlug: pollSlug,
          selectedOption: selectedOption,
        }
      : {
          pollSlug: pollSlug,
          selectedOption: null,
        };
    return await setDoc(ref, payload);
  }
}

export function getMostLikelyGenderForOptions(
  liveData: ResultBreakDownPolls,
  pollOptions: PollOption[] | null | undefined
): {
  options: Record<number, Gender | null>;
  maleTotalCount: null | number;
  femaleTotalCount: null | number;
  otherTotalCount: null | number;
} {
  const options: Record<number, Gender | null> = {};

  let maleTotalCount: number | null = null;
  let femaleTotalCount: number | null = null;
  let otherTotalCount: number | null = null;

  //getting total vote count
  liveData?.genderResultGroups?.forEach((group) => {
    if (group.gender === Gender.male) {
      group.pollOptions.forEach((option) => {
        if (maleTotalCount) {
          if (option.voteCount) {
            maleTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            maleTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.gender === Gender.female) {
      group.pollOptions.forEach((option) => {
        if (femaleTotalCount) {
          if (option.voteCount) {
            femaleTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            femaleTotalCount = option.voteCount;
          }
        }
      });
    } else {
      group.pollOptions.forEach((option) => {
        if (otherTotalCount) {
          if (option.voteCount) {
            otherTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            otherTotalCount = option.voteCount;
          }
        }
      });
    }
  });

  let maleOptionsVotePercentage: { id: number; percentage: number }[] = [];
  let femaleOptionsVotePercentage: { id: number; percentage: number }[] = [];
  let otherOptionsVotePercentage: { id: number; percentage: number }[] = [];

  //getting the percentage of votes for each gender for each option
  liveData?.genderResultGroups?.forEach((group) => {
    if (group.gender === Gender.male) {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (maleTotalCount && maleTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / maleTotalCount) * 100;
          maleOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.gender === Gender.female) {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (femaleTotalCount && femaleTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / femaleTotalCount) * 100;
          femaleOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (otherTotalCount && otherTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / otherTotalCount) * 100;
          otherOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    }
  });

  pollOptions?.forEach((option) => {
    const foundMalePercentage =
      maleOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundFemalePercentage =
      femaleOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundOtherPercentage =
      otherOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;

    const maxPercentage = Math.max(
      foundMalePercentage,
      foundFemalePercentage,
      foundOtherPercentage
    );

    if (maxPercentage === 0) {
      options[option.id] = null;
    } else {
      let maxCategory: Gender;
      if (maxPercentage === foundMalePercentage) {
        maxCategory = Gender.male;
      } else if (maxPercentage === foundFemalePercentage) {
        maxCategory = Gender.female;
      } else {
        maxCategory = Gender.other;
      }
      options[option.id] = maxCategory;
    }
  });

  ///TODO:
  ///If total number in group is not at least 10, disregard the group

  return { options, maleTotalCount, femaleTotalCount, otherTotalCount };
}

export function getMostLikelyNatureForOptions(
  liveData: ResultBreakDownPolls,
  pollOptions: PollOption[] | null | undefined
): {
  options: Record<number, Nature | null>;
  orderAndStructureTotalCount: null | number;
  selfEnhancementTotalCount: null | number;
  energyAndExcitementTotalCount: null | number;
  warmthAndAgreeablenessTotalCount: null | number;
  opennessAndIntellectTotalCount: null | number;
  chaosTotalCount: null | number;
  tranquilityTotalCount: null | number;
} {
  const options: Record<number, Nature | null> = {};

  let orderAndStructureTotalCount: number | null = null;
  let selfEnhancementTotalCount: number | null = null;
  let energyAndExcitementTotalCount: number | null = null;
  let warmthAndAgreeablenessTotalCount: number | null = null;
  let opennessAndIntellectTotalCount: number | null = null;
  let chaosTotalCount: number | null = null;
  let tranquilityTotalCount: number | null = null;

  //getting total vote count
  liveData?.natureResultGroups?.forEach((group) => {
    if (group.nature === Nature.orderAndStructure) {
      group.pollOptions.forEach((option) => {
        if (orderAndStructureTotalCount) {
          if (option.voteCount) {
            orderAndStructureTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            orderAndStructureTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.nature === Nature.selfEnhancement) {
      group.pollOptions.forEach((option) => {
        if (selfEnhancementTotalCount) {
          if (option.voteCount) {
            selfEnhancementTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            selfEnhancementTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.nature === Nature.energyAndExcitement) {
      group.pollOptions.forEach((option) => {
        if (energyAndExcitementTotalCount) {
          if (option.voteCount) {
            energyAndExcitementTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            energyAndExcitementTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.nature === Nature.warmthAndAgreeableness) {
      group.pollOptions.forEach((option) => {
        if (warmthAndAgreeablenessTotalCount) {
          if (option.voteCount) {
            warmthAndAgreeablenessTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            warmthAndAgreeablenessTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.nature === Nature.opennessAndIntellect) {
      group.pollOptions.forEach((option) => {
        if (opennessAndIntellectTotalCount) {
          if (option.voteCount) {
            opennessAndIntellectTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            opennessAndIntellectTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.nature === Nature.chaos) {
      group.pollOptions.forEach((option) => {
        if (chaosTotalCount) {
          if (option.voteCount) {
            chaosTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            chaosTotalCount = option.voteCount;
          }
        }
      });
    } else {
      group.pollOptions.forEach((option) => {
        if (tranquilityTotalCount) {
          if (option.voteCount) {
            tranquilityTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            tranquilityTotalCount = option.voteCount;
          }
        }
      });
    }
  });

  let orderAndStructureOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let selfEnhancementOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let energyAndExcitementOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let warmthAndAgreeablenessOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let opennessAndIntellectOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let chaosOptionsVotePercentage: { id: number; percentage: number }[] = [];
  let tranquilityOptionsVotePercentage: { id: number; percentage: number }[] =
    [];

  //getting the percentage of votes for each nature for each option
  liveData?.natureResultGroups?.forEach((group) => {
    if (group.nature === Nature.orderAndStructure) {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (
          orderAndStructureTotalCount &&
          orderAndStructureTotalCount > 9 &&
          option.voteCount
        ) {
          votePercentage =
            (option.voteCount / orderAndStructureTotalCount) * 100;
          orderAndStructureOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.nature === Nature.selfEnhancement) {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (
          selfEnhancementTotalCount &&
          selfEnhancementTotalCount > 9 &&
          option.voteCount
        ) {
          votePercentage = (option.voteCount / selfEnhancementTotalCount) * 100;
          selfEnhancementOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.nature === Nature.energyAndExcitement) {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (
          energyAndExcitementTotalCount &&
          energyAndExcitementTotalCount > 9 &&
          option.voteCount
        ) {
          votePercentage =
            (option.voteCount / energyAndExcitementTotalCount) * 100;
          energyAndExcitementOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.nature === Nature.warmthAndAgreeableness) {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (
          warmthAndAgreeablenessTotalCount &&
          warmthAndAgreeablenessTotalCount > 9 &&
          option.voteCount
        ) {
          votePercentage =
            (option.voteCount / warmthAndAgreeablenessTotalCount) * 100;
          warmthAndAgreeablenessOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.nature === Nature.opennessAndIntellect) {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (
          opennessAndIntellectTotalCount &&
          opennessAndIntellectTotalCount > 9 &&
          option.voteCount
        ) {
          votePercentage =
            (option.voteCount / opennessAndIntellectTotalCount) * 100;
          opennessAndIntellectOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.nature === Nature.chaos) {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (chaosTotalCount && chaosTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / chaosTotalCount) * 100;
          chaosOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (
          tranquilityTotalCount &&
          tranquilityTotalCount > 9 &&
          option.voteCount
        ) {
          votePercentage = (option.voteCount / tranquilityTotalCount) * 100;
          tranquilityOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    }
  });

  pollOptions?.forEach((option) => {
    const foundorderAndStructurePercentage =
      orderAndStructureOptionsVotePercentage.find((g) => g.id === option.id)
        ?.percentage ?? 0;
    const foundselfEnhancementPercentage =
      selfEnhancementOptionsVotePercentage.find((g) => g.id === option.id)
        ?.percentage ?? 0;
    const foundenergyAndExcitementPercentage =
      energyAndExcitementOptionsVotePercentage.find((g) => g.id === option.id)
        ?.percentage ?? 0;
    const foundwarmthAndAgreeablenessPercentage =
      warmthAndAgreeablenessOptionsVotePercentage.find(
        (g) => g.id === option.id
      )?.percentage ?? 0;
    const foundopennessAndIntellectPercentage =
      opennessAndIntellectOptionsVotePercentage.find((g) => g.id === option.id)
        ?.percentage ?? 0;
    const foundchaosPercentage =
      chaosOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundtranquilityPercentage =
      tranquilityOptionsVotePercentage.find((g) => g.id === option.id)
        ?.percentage ?? 0;

    const maxPercentage = Math.max(
      foundorderAndStructurePercentage,
      foundselfEnhancementPercentage,
      foundenergyAndExcitementPercentage,
      foundwarmthAndAgreeablenessPercentage,
      foundopennessAndIntellectPercentage,
      foundchaosPercentage,
      foundtranquilityPercentage
    );

    if (maxPercentage === 0) {
      options[option.id] = null;
    } else {
      let maxCategory: Nature;
      if (maxPercentage === foundorderAndStructurePercentage) {
        maxCategory = Nature.orderAndStructure;
      } else if (maxPercentage === foundselfEnhancementPercentage) {
        maxCategory = Nature.selfEnhancement;
      } else if (maxPercentage === foundenergyAndExcitementPercentage) {
        maxCategory = Nature.energyAndExcitement;
      } else if (maxPercentage === foundwarmthAndAgreeablenessPercentage) {
        maxCategory = Nature.warmthAndAgreeableness;
      } else if (maxPercentage === foundopennessAndIntellectPercentage) {
        maxCategory = Nature.opennessAndIntellect;
      } else if (maxPercentage === foundchaosPercentage) {
        maxCategory = Nature.chaos;
      } else {
        maxCategory = Nature.tranquility;
      }
      options[option.id] = maxCategory;
    }
  });

  return {
    options,
    orderAndStructureTotalCount,
    selfEnhancementTotalCount,
    energyAndExcitementTotalCount,
    warmthAndAgreeablenessTotalCount,
    opennessAndIntellectTotalCount,
    chaosTotalCount,
    tranquilityTotalCount,
  };
}

export function getMostLikelyAgeForOptions(
  liveData: ResultBreakDownPolls,
  pollOptions: PollOption[] | null | undefined
): {
  options: Record<number, AgeRange | null>;
  underTwentyTotalCount: null | number;
  twentyToTwentyFiveTotalCount: null | number;
  twentySixToThirtyTotalCount: null | number;
  thirtyOneToThirtyFiveTotalCount: null | number;
  thirtySixPlusTotalCount: null | number;
} {
  const options: Record<number, AgeRange | null> = {};
  // <20
  const underTwentyAgeRange: AgeRange = { minAge: null, maxAge: 19 };
  // 20-25
  const twentyToTwentyFiveAgeRange: AgeRange = { minAge: 20, maxAge: 25 };
  // 26-30
  const twentySixToThirtyAgeRange: AgeRange = { minAge: 26, maxAge: 30 };
  // 4. 31-35
  const thirtyOneToThirtyFiveAgeRange: AgeRange = { minAge: 31, maxAge: 35 };
  // 5. 36+
  const thirtySixPlusAgeRange: AgeRange = { minAge: 36, maxAge: null };

  let underTwentyTotalCount: number | null = null;
  let twentyToTwentyFiveTotalCount: number | null = null;
  let twentySixToThirtyTotalCount: number | null = null;
  let thirtyOneToThirtyFiveTotalCount: number | null = null;
  let thirtySixPlusTotalCount: number | null = null;

  //getting total vote count
  liveData?.ageResultGroups?.forEach((group) => {
    if (group.ageRange.maxAge === 19) {
      group.pollOptions.forEach((option) => {
        if (underTwentyTotalCount) {
          if (option.voteCount) {
            underTwentyTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            underTwentyTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.ageRange.maxAge === 25) {
      group.pollOptions.forEach((option) => {
        if (twentyToTwentyFiveTotalCount) {
          if (option.voteCount) {
            twentyToTwentyFiveTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            twentyToTwentyFiveTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.ageRange.maxAge === 30) {
      group.pollOptions.forEach((option) => {
        if (twentySixToThirtyTotalCount) {
          if (option.voteCount) {
            twentySixToThirtyTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            twentySixToThirtyTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.ageRange.maxAge === 35) {
      group.pollOptions.forEach((option) => {
        if (thirtyOneToThirtyFiveTotalCount) {
          if (option.voteCount) {
            thirtyOneToThirtyFiveTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            thirtyOneToThirtyFiveTotalCount = option.voteCount;
          }
        }
      });
    } else {
      group.pollOptions.forEach((option) => {
        if (thirtySixPlusTotalCount) {
          if (option.voteCount) {
            thirtySixPlusTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            thirtySixPlusTotalCount = option.voteCount;
          }
        }
      });
    }
  });

  let underTwentyOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let twentyToTwentyFiveOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let twentySixToThirtyOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let thirtyOneToThirtyFiveOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let thirtySixPlusOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];

  //getting the percentage of votes for each age grou[] for each option
  liveData?.ageResultGroups?.forEach((group) => {
    if (group.ageRange.maxAge === 19) {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (
          underTwentyTotalCount &&
          underTwentyTotalCount > 9 &&
          option.voteCount
        ) {
          votePercentage = (option.voteCount / underTwentyTotalCount) * 100;
          underTwentyOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.ageRange.maxAge === 25) {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (
          twentyToTwentyFiveTotalCount &&
          twentyToTwentyFiveTotalCount > 9 &&
          option.voteCount
        ) {
          votePercentage =
            (option.voteCount / twentyToTwentyFiveTotalCount) * 100;
          twentyToTwentyFiveOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.ageRange.maxAge === 30) {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (
          twentySixToThirtyTotalCount &&
          twentySixToThirtyTotalCount > 9 &&
          option.voteCount
        ) {
          votePercentage =
            (option.voteCount / twentySixToThirtyTotalCount) * 100;
          twentySixToThirtyOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.ageRange.maxAge === 35) {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (
          thirtyOneToThirtyFiveTotalCount &&
          thirtyOneToThirtyFiveTotalCount > 9 &&
          option.voteCount
        ) {
          votePercentage =
            (option.voteCount / thirtyOneToThirtyFiveTotalCount) * 100;
          thirtyOneToThirtyFiveOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (
          thirtySixPlusTotalCount &&
          thirtySixPlusTotalCount > 9 &&
          option.voteCount
        ) {
          votePercentage = (option.voteCount / thirtySixPlusTotalCount) * 100;
          thirtySixPlusOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    }
  });

  pollOptions?.forEach((option) => {
    const foundunderTwentyPercentage =
      underTwentyOptionsVotePercentage.find((g) => g.id === option.id)
        ?.percentage ?? 0;
    const foundtwentyToTwentyFivePercentage =
      twentyToTwentyFiveOptionsVotePercentage.find((g) => g.id === option.id)
        ?.percentage ?? 0;
    const foundentwentySixToThirtyPercentage =
      twentySixToThirtyOptionsVotePercentage.find((g) => g.id === option.id)
        ?.percentage ?? 0;
    const foundthirtyOneToThirtyFivePercentage =
      thirtyOneToThirtyFiveOptionsVotePercentage.find((g) => g.id === option.id)
        ?.percentage ?? 0;
    const foundthirtySixPlusPercentage =
      thirtySixPlusOptionsVotePercentage.find((g) => g.id === option.id)
        ?.percentage ?? 0;

    const maxPercentage = Math.max(
      foundunderTwentyPercentage,
      foundtwentyToTwentyFivePercentage,
      foundentwentySixToThirtyPercentage,
      foundthirtyOneToThirtyFivePercentage,
      foundthirtySixPlusPercentage
    );

    if (maxPercentage === 0) {
      options[option.id] = null;
    } else {
      let maxCategory: AgeRange;
      if (maxPercentage === foundunderTwentyPercentage) {
        maxCategory = underTwentyAgeRange;
      } else if (maxPercentage === foundtwentyToTwentyFivePercentage) {
        maxCategory = twentyToTwentyFiveAgeRange;
      } else if (maxPercentage === foundentwentySixToThirtyPercentage) {
        maxCategory = twentySixToThirtyAgeRange;
      } else if (maxPercentage === foundthirtyOneToThirtyFivePercentage) {
        maxCategory = thirtyOneToThirtyFiveAgeRange;
      } else {
        maxCategory = thirtySixPlusAgeRange;
      }
      options[option.id] = maxCategory;
    }
  });

  return {
    options,
    underTwentyTotalCount,
    twentyToTwentyFiveTotalCount,
    twentySixToThirtyTotalCount,
    thirtyOneToThirtyFiveTotalCount,
    thirtySixPlusTotalCount,
  };
}

export function getMostLikelyMBTIForOptions(
  liveData: ResultBreakDownPolls,
  pollOptions: PollOption[] | null | undefined
): {
  options: Record<number, string | null>;
  ENFJTotalCount: number | null;
  ENFPTotalCount: number | null;
  ENTJTotalCount: number | null;
  ENTPTotalCount: number | null;
  ESFJTotalCount: number | null;
  ESFPTotalCount: number | null;
  ESTJTotalCount: number | null;
  ESTPTotalCount: number | null;
  INFJTotalCount: number | null;
  INFPTotalCount: number | null;
  INTJTotalCount: number | null;
  INTPTotalCount: number | null;
  ISFJTotalCount: number | null;
  ISFPTotalCount: number | null;
  ISTJTotalCount: number | null;
  ISTPotalCount: number | null;
} {
  const options: Record<number, string | null> = {};

  let ENFJTotalCount: number | null = null;
  let ENFPTotalCount: number | null = null;
  let ENTJTotalCount: number | null = null;
  let ENTPTotalCount: number | null = null;
  let ESFJTotalCount: number | null = null;
  let ESFPTotalCount: number | null = null;
  let ESTJTotalCount: number | null = null;
  let ESTPTotalCount: number | null = null;
  let INFJTotalCount: number | null = null;
  let INFPTotalCount: number | null = null;
  let INTJTotalCount: number | null = null;
  let INTPTotalCount: number | null = null;
  let ISFJTotalCount: number | null = null;
  let ISFPTotalCount: number | null = null;
  let ISTJTotalCount: number | null = null;
  let ISTPotalCount: number | null = null;

  //getting total vote count
  liveData?.archetypeResultGroups?.forEach((group) => {
    if (group.archetypeSlug === "mbti-enfj") {
      group.pollOptions.forEach((option) => {
        if (ENFJTotalCount) {
          if (option.voteCount) {
            ENFJTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            ENFJTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.archetypeSlug === "mbti-enfp") {
      group.pollOptions.forEach((option) => {
        if (ENFPTotalCount) {
          if (option.voteCount) {
            ENFPTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            ENFPTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.archetypeSlug === "mbti-entj") {
      group.pollOptions.forEach((option) => {
        if (ENTJTotalCount) {
          if (option.voteCount) {
            ENTJTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            ENTJTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.archetypeSlug === "mbti-entp") {
      group.pollOptions.forEach((option) => {
        if (ENTPTotalCount) {
          if (option.voteCount) {
            ENTPTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            ENTPTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.archetypeSlug === "mbti-esfj") {
      group.pollOptions.forEach((option) => {
        if (ESFJTotalCount) {
          if (option.voteCount) {
            ESFJTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            ESFJTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.archetypeSlug === "mbti-esfp") {
      group.pollOptions.forEach((option) => {
        if (ESFPTotalCount) {
          if (option.voteCount) {
            ESFPTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            ESFPTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.archetypeSlug === "mbti-estj") {
      group.pollOptions.forEach((option) => {
        if (ESTJTotalCount) {
          if (option.voteCount) {
            ESTJTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            ESTJTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.archetypeSlug === "mbti-estp") {
      group.pollOptions.forEach((option) => {
        if (ESTPTotalCount) {
          if (option.voteCount) {
            ESTPTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            ESTPTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.archetypeSlug === "mbti-infj") {
      group.pollOptions.forEach((option) => {
        if (INFJTotalCount) {
          if (option.voteCount) {
            INFJTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            INFJTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.archetypeSlug === "mbti-infp") {
      group.pollOptions.forEach((option) => {
        if (INFPTotalCount) {
          if (option.voteCount) {
            INFPTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            INFPTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.archetypeSlug === "mbti-intj") {
      group.pollOptions.forEach((option) => {
        if (INTJTotalCount) {
          if (option.voteCount) {
            INTJTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            INTJTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.archetypeSlug === "mbti-intp") {
      group.pollOptions.forEach((option) => {
        if (INTPTotalCount) {
          if (option.voteCount) {
            INTPTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            INTPTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.archetypeSlug === "mbti-isfj") {
      group.pollOptions.forEach((option) => {
        if (ISFJTotalCount) {
          if (option.voteCount) {
            ISFJTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            ISFJTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.archetypeSlug === "mbti-isfp") {
      group.pollOptions.forEach((option) => {
        if (ISFPTotalCount) {
          if (option.voteCount) {
            ISFPTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            ISFPTotalCount = option.voteCount;
          }
        }
      });
    } else if (group.archetypeSlug === "mbti-istj") {
      group.pollOptions.forEach((option) => {
        if (ISTJTotalCount) {
          if (option.voteCount) {
            ISTJTotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            ISTJTotalCount = option.voteCount;
          }
        }
      });
    } else {
      group.pollOptions.forEach((option) => {
        if (ISTPotalCount) {
          if (option.voteCount) {
            ISTPotalCount += option.voteCount;
          }
        } else {
          if (option.voteCount) {
            ISTPotalCount = option.voteCount;
          }
        }
      });
    }
  });

  let ENFJOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let ENFPOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let ENTJOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let ENTPOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let ESFJOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let ESFPOptionsVotePercentage: { id: number; percentage: number }[] = [];
  let ESTJOptionsVotePercentage: { id: number; percentage: number }[] = [];
  let ESTPOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let INFJOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let INFPOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let INTJOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let INTPOptionsVotePercentage: {
    id: number;
    percentage: number;
  }[] = [];
  let ISFJOptionsVotePercentage: { id: number; percentage: number }[] = [];
  let ISFPOptionsVotePercentage: { id: number; percentage: number }[] = [];
  let ISTJOptionsVotePercentage: { id: number; percentage: number }[] = [];
  let ISTPOptionsVotePercentage: { id: number; percentage: number }[] = [];

  //getting the percentage of votes for each arcehtype for each option
  liveData?.archetypeResultGroups?.forEach((group) => {
    if (group.archetypeSlug === "mbti-enfj") {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (ENFJTotalCount && ENFJTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / ENFJTotalCount) * 100;
          ENFJOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.archetypeSlug === "mbti-enfp") {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (ENFPTotalCount && ENFPTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / ENFPTotalCount) * 100;
          ENFPOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.archetypeSlug === "mbti-entj") {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (ENTJTotalCount && ENTJTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / ENTJTotalCount) * 100;
          ENTJOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.archetypeSlug === "mbti-entp") {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (ENTPTotalCount && ENTPTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / ENTPTotalCount) * 100;
          ENTPOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.archetypeSlug === "mbti-esfj") {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (ESFJTotalCount && ESFJTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / ESFJTotalCount) * 100;
          ESFJOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.archetypeSlug === "mbti-esfp") {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (ESFPTotalCount && ESFPTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / ESFPTotalCount) * 100;
          ESFPOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.archetypeSlug === "mbti-estj") {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (ESTJTotalCount && ESTJTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / ESTJTotalCount) * 100;
          ESTJOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.archetypeSlug === "mbti-estp") {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (ESTPTotalCount && ESTPTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / ESTPTotalCount) * 100;
          ESTPOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.archetypeSlug === "mbti-infj") {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (INFJTotalCount && INFJTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / INFJTotalCount) * 100;
          INFJOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.archetypeSlug === "mbti-infp") {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (INFPTotalCount && INFPTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / INFPTotalCount) * 100;
          INFPOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.archetypeSlug === "mbti-intj") {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (INTJTotalCount && INTJTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / INTJTotalCount) * 100;
          INTJOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.archetypeSlug === "mbti-intp") {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (INTPTotalCount && INTPTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / INTPTotalCount) * 100;
          INTPOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.archetypeSlug === "mbti-isfj") {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (ISFJTotalCount && ISFJTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / ISFJTotalCount) * 100;
          ISFJOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.archetypeSlug === "mbti-isfp") {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (ISFPTotalCount && ISFPTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / ISFPTotalCount) * 100;
          ISFPOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else if (group.archetypeSlug === "mbti-istj") {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (ISTJTotalCount && ISTJTotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / ISTJTotalCount) * 100;
          ISTJOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    } else {
      group.pollOptions.forEach((option) => {
        const id = option.id;
        let votePercentage: number | null = null;
        if (ISTPotalCount && ISTPotalCount > 9 && option.voteCount) {
          votePercentage = (option.voteCount / ISTPotalCount) * 100;
          ISTPOptionsVotePercentage.push({
            id: id,
            percentage: votePercentage,
          });
        }
      });
    }
  });

  pollOptions?.forEach((option) => {
    const foundENFJPercentage =
      ENFJOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundENFPPercentage =
      ENFPOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundENTJPercentage =
      ENTJOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundENTPPercentage =
      ENTPOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundESFJPercentage =
      ESFJOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundESFPPercentage =
      ESFPOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundESTJPercentage =
      ESTJOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundESTPPercentage =
      ESTPOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundINFJPercentage =
      INFJOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundINFPercentage =
      INFPOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundINTJPercentage =
      INTJOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundINTPPercentage =
      INTPOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundISFJPercentage =
      ISFJOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundISFPPercentage =
      ISFPOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundISTJPercentage =
      ISTJOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;
    const foundISTPPercentage =
      ISTPOptionsVotePercentage.find((g) => g.id === option.id)?.percentage ??
      0;

    const maxPercentage = Math.max(
      foundENFJPercentage,
      foundENFPPercentage,
      foundENTJPercentage,
      foundENTPPercentage,
      foundESFJPercentage,
      foundESFPPercentage,
      foundESTJPercentage,
      foundESTPPercentage,
      foundINFJPercentage,
      foundINFPercentage,
      foundINTJPercentage,
      foundINTPPercentage,
      foundISFJPercentage,
      foundISFPPercentage,
      foundISTJPercentage,
      foundISTPPercentage
    );

    if (maxPercentage === 0) {
      options[option.id] = null;
    } else {
      let maxCategory: string;
      if (maxPercentage === foundENFJPercentage) {
        maxCategory = "mbti-enfj";
      } else if (maxPercentage === foundENFPPercentage) {
        maxCategory = "mbti-enfp";
      } else if (maxPercentage === foundENTJPercentage) {
        maxCategory = "mbti-entj";
      } else if (maxPercentage === foundENTPPercentage) {
        maxCategory = "mbti-entp";
      } else if (maxPercentage === foundESFJPercentage) {
        maxCategory = "mbti-esfj";
      } else if (maxPercentage === foundESFPPercentage) {
        maxCategory = "mbti-esfp";
      } else if (maxPercentage === foundESTJPercentage) {
        maxCategory = "mbti-estj";
      } else if (maxPercentage === foundESTPPercentage) {
        maxCategory = "mbti-estp";
      } else if (maxPercentage === foundINFJPercentage) {
        maxCategory = "mbti-infj";
      } else if (maxPercentage === foundINFPercentage) {
        maxCategory = "mbti-infp";
      } else if (maxPercentage === foundINTJPercentage) {
        maxCategory = "mbti-intj";
      } else if (maxPercentage === foundINTPPercentage) {
        maxCategory = "mbti-intp";
      } else if (maxPercentage === foundISFJPercentage) {
        maxCategory = "mbti-isfj";
      } else if (maxPercentage === foundISFPPercentage) {
        maxCategory = "mbti-isfp";
      } else if (maxPercentage === foundISTJPercentage) {
        maxCategory = "mbti-istp";
      } else {
        maxCategory = Nature.tranquility;
      }
      options[option.id] = maxCategory;
    }
  });

  return {
    options,
    ENFJTotalCount,
    ENFPTotalCount,
    ENTJTotalCount,
    ENTPTotalCount,
    ESFJTotalCount,
    ESFPTotalCount,
    ESTJTotalCount,
    ESTPTotalCount,
    INFJTotalCount,
    INFPTotalCount,
    INTJTotalCount,
    INTPTotalCount,
    ISFJTotalCount,
    ISFPTotalCount,
    ISTJTotalCount,
    ISTPotalCount,
  };
}

export function getMostLikelyResults(
  liveData: ResultBreakDownPolls,
  pollOptions: PollOption[] | null | undefined
): {
  genderResults: {
    options: Record<number, Gender | null>;
    maleTotalCount: null | number;
    femaleTotalCount: null | number;
    otherTotalCount: null | number;
  };
  natureResults: {
    options: Record<number, Nature | null>;
    orderAndStructureTotalCount: null | number;
    selfEnhancementTotalCount: null | number;
    energyAndExcitementTotalCount: null | number;
    warmthAndAgreeablenessTotalCount: null | number;
    opennessAndIntellectTotalCount: null | number;
    chaosTotalCount: null | number;
    tranquilityTotalCount: null | number;
  };
  ageResults: {
    options: Record<number, AgeRange | null>;
    underTwentyTotalCount: null | number;
    twentyToTwentyFiveTotalCount: null | number;
    twentySixToThirtyTotalCount: null | number;
    thirtyOneToThirtyFiveTotalCount: null | number;
    thirtySixPlusTotalCount: null | number;
  };
  mbtiResults: {
    options: Record<number, string | null>;
    ENFJTotalCount: number | null;
    ENFPTotalCount: number | null;
    ENTJTotalCount: number | null;
    ENTPTotalCount: number | null;
    ESFJTotalCount: number | null;
    ESFPTotalCount: number | null;
    ESTJTotalCount: number | null;
    ESTPTotalCount: number | null;
    INFJTotalCount: number | null;
    INFPTotalCount: number | null;
    INTJTotalCount: number | null;
    INTPTotalCount: number | null;
    ISFJTotalCount: number | null;
    ISFPTotalCount: number | null;
    ISTJTotalCount: number | null;
    ISTPotalCount: number | null;
  };
} {
  const genderResults = getMostLikelyGenderForOptions(liveData, pollOptions);
  const natureResults = getMostLikelyNatureForOptions(liveData, pollOptions);
  const ageResults = getMostLikelyAgeForOptions(liveData, pollOptions);
  const mbtiResults = getMostLikelyMBTIForOptions(liveData, pollOptions);

  return {
    genderResults,
    natureResults,
    ageResults,
    mbtiResults,
  };
}

export const pollOptionIndexMap = {
  0: "A.",
  1: "B.",
  2: "C.",
  3: "D.",
  4: "E.",
  5: "F.",
  6: "G.",
};
