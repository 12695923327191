export default function CompatibilityRankingListIcon(props: {
  inactive?: boolean;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={props.inactive ? { opacity: "0.4" } : {}}
    >
      <rect
        y="1.77783"
        width="7.11111"
        height="2.96296"
        rx="0.592593"
        fill="#6AB4EA"
      />
      <rect
        y="6.51855"
        width="7.11111"
        height="2.96296"
        rx="0.592593"
        fill="#5DAF4D"
      />
      <rect
        y="11.2593"
        width="7.11111"
        height="2.96296"
        rx="0.592593"
        fill="#E0CC65"
      />
      <rect
        x="8.89062"
        y="1.77783"
        width="7.11111"
        height="2.96296"
        rx="0.592593"
        fill="#EF453C"
      />
      <rect
        x="8.89062"
        y="6.51855"
        width="7.11111"
        height="2.96296"
        rx="0.592593"
        fill="#A069CE"
      />
      <rect
        x="8.89062"
        y="11.2593"
        width="7.11111"
        height="2.96296"
        rx="0.592593"
        fill="#F37A1C"
      />
    </svg>
  );
}
