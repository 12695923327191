export const natureData = [
  {
    slug: "opennessAndIntellect",
    name: "Openness & Curiosity",
    shortName: "Openness",
    color: "#5DAF4D",
    secondaryColor: "#1ACC6C",
    description:
      "Represented by green, Openness & Curiosity is the nature of abstract ideas,  independent thought, and the pursuit of truth and understanding.",
    shortDescription:
      "Abstract ideas, independent thought, and the pursuit of truth and understanding.",
  },
  {
    slug: "warmthAndAgreeableness",
    name: "Warmth & Agreeableness",
    shortName: "Warmth",
    color: "#E0CC65",
    secondaryColor: "#FDF65B",
    description:
      "Represented by yellow, Warmth & Agreeableness is the nature of openheartedness, trust, and compassion. ",
    shortDescription: "Openheartedness, trust, and compassion.",
  },
  {
    slug: "chaos",
    name: "Passion & Sensitivity",
    shortName: "Passion",
    color: "#EF453C",
    secondaryColor: "#F62626",
    description:
      "Represented by red, Passion & Sensitivity is the nature of deep feeling, impulsiveness, and sensitivity.",
    shortDescription: "Deep feeling, impulsiveness, and sensitivity.",
  },
  {
    slug: "energyAndExcitement",
    name: "Energy & Excitement",
    shortName: "Excitement",
    color: "#F37A1C",
    secondaryColor: "#FF994C",
    description:
      "Represented by orange, Energy & Excitement is the nature of adventure, experiences, and a zest for life.",
    shortDescription: "Adventure, experiences, and a zest for life.",
  },
  {
    slug: "tranquility",
    name: "Tranquility",
    shortName: "Tranquility",
    color: "#6AB4EA",
    secondaryColor: "#4275EE",
    description:
      "Represented by blue, Tranquility is the nature of inner peace, forgiveness, and moderation.",
    shortDescription: "Inner peace, forgiveness, and moderation.",
  },
  {
    slug: "selfEnhancement",
    name: "Ambition & Self-Enhancement",
    shortName: "Ambition",
    color: "#FFA0B9",
    secondaryColor: "#ED85C2",
    description:
      "Represented by pink, Ambition & Self-Enhancement is the nature of achievement, assertiveness, pleasure, and the pursuit of excellence.",
    shortDescription:
      "Achievement, assertiveness, pleasure, and the pursuit of excellence.",
  },
  {
    slug: "orderAndStructure",
    name: "Order & Responsibility",
    shortName: "Order",
    color: "#A069CE",
    secondaryColor: "#9026FF",
    description:
      "Represented by purple, Order & Responsibility is the nature of planning, security, duty, and controlling chaos in the environment.",
    shortDescription:
      "Planning, security, duty, and controlling chaos in the environment.",
  },
];

export const natureMap = {
  opennessAndIntellect: {
    slug: "opennessAndIntellect",
    name: "Openness & Curiosity",
    color: "#5DAF4D",
    secondaryColor: "#1ACC6C",
    description:
      "Represented by green, Openness & Curiosity is the nature of abstract ideas,  independent thought, and the pursuit of truth and understanding.",
    shortDescription:
      "Abstract ideas, independent thought, and the pursuit of truth and understanding.",
  },
  warmthAndAgreeableness: {
    slug: "warmthAndAgreeableness",
    name: "Warmth & Agreeableness",
    color: "#E0CC65",
    secondaryColor: "#FDF65B",
    description:
      "Represented by yellow, Warmth & Agreeableness is the nature of openheartedness, trust, and compassion. ",
    shortDescription: "Openheartedness, trust, and compassion.",
  },
  chaos: {
    slug: "chaos",
    name: "Passion & Sensitivity",
    color: "#EF453C",
    secondaryColor: "#F62626",
    description:
      "Represented by red, Passion & Sensitivity is the nature of deep feeling, impulsiveness, and sensitivity.",
    shortDescription: "Deep feeling, impulsiveness, and sensitivity.",
  },
  energyAndExcitement: {
    slug: "energyAndExcitement",
    name: "Energy & Excitement",
    color: "#F37A1C",
    secondaryColor: "#FF994C",
    description:
      "Represented by orange, Energy & Excitement is the nature of adventure, experiences, and a zest for life.",
    shortDescription: "Adventure, experiences, and a zest for life.",
  },
  tranquility: {
    slug: "tranquility",
    name: "Tranquility",
    color: "#6AB4EA",
    secondaryColor: "#4275EE",
    description:
      "Represented by blue, Tranquility is the nature of inner peace, forgiveness, and moderation.",
    shortDescription: "Inner peace, forgiveness, and moderation.",
  },
  selfEnhancement: {
    slug: "selfEnhancement",
    name: "Ambition & Self-Enhancement",
    color: "#FFA0B9",
    secondaryColor: "#ED85C2",
    description:
      "Represented by pink, Ambition & Self-Enhancement is the nature of achievement, assertiveness, pleasure, and the pursuit of excellence.",
    shortDescription:
      "Achievement, assertiveness, pleasure, and the pursuit of excellence.",
  },
  orderAndStructure: {
    slug: "orderAndStructure",
    name: "Order & Responsibility",
    color: "#A069CE",
    secondaryColor: "#9026FF",
    description:
      "Represented by purple, Order & Responsibility is the nature of planning, security, duty, and controlling chaos in the environment.",
    shortDescription:
      "Planning, security, duty, and controlling chaos in the environment.",
  },
};
