import {
  PublicProfile,
  SummaryTable as SummaryTableType,
} from "@dimensional-engineering/dimensional-models";

import styles from "./SummaryTable.module.css";

import SumamryTableRow from "./SummaryTableRow";
import { useModalContext } from "@/context/ModalContext";
import NatureModal from "@/components/authenticatedProfile/NatureModal/NatureModal";
import NatureModalConnection from "@/components/authenticatedProfile/NatureModal/NatureModalConnection";

type SummaryTableProps = {
  data: SummaryTableType | null;
  natureColor: string | null;
  notOwn?: boolean;
  auth?: boolean;
  publicProfile?: PublicProfile | null;
  imageUrl?: string;
};

export default function SummaryTable(props: SummaryTableProps) {
  const { setModalComponent } = useModalContext();

  const mapped = props?.data?.summaryTableRows.map((row, index) => {
    if (row.title === "Primary Nature") {
      return (
        <div
          onClick={() => {
            if (props.auth) {
              if (props.notOwn && props.publicProfile) {
                setModalComponent(
                  <NatureModalConnection
                    uid={props.publicProfile.ownerUUID}
                    imageUrl={props.imageUrl ?? "/default-image.png"}
                  />
                );
              } else {
                setModalComponent(<NatureModal />);
              }
            }
          }}
          key={index}
        >
          <SumamryTableRow
            index={index}
            row={row}
            natureColor={props.natureColor}
          />
        </div>
      );
    }
    return (
      <SumamryTableRow
        key={index}
        index={index}
        row={row}
        natureColor={props.natureColor}
      />
    );
  });
  return (
    <div className={styles.summaryTableMain}>
      <div>
        <h2 className={styles.summaryTableHeader}>Summary</h2>
      </div>
      {mapped}
    </div>
  );
}
