import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Roboto_Mono } from "next/font/google";

import styles from "./UserPollResponseModal.module.css";

import { RootState } from "@/redux/store";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import PollIcon from "../PollIcon";
import PollPercentages from "./PollPercentages/PollPercentages";
import PollMostLikelyToChoose from "./PollMostLikelyToChoose/PollMostLikelyToChoose";
import CommentModal from "../../CommentModal/CommentModal";
import { Mixpanel } from "@/helpers/mixpanel";
import { DailyPollsInterractionBar } from "@/components/results/Snippets/SnippetInterractionBar/SnippetInterractionBar";
import { CommentAndAudiencePreviewsInsights } from "../../CommentModal/CommentAndAudiencePreviews/CommentAndAudiencePreviews";
import useUpdateCommentThreadData from "../../CommentModal/useUpdateCommentThreadData";
import { useModalContext } from "@/context/ModalContext";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function UserPollResponseModal(props: {
  elementName: "UserPollResponseModal";
  showComment?: boolean;
  commentId?: string;
}) {
  const { removeModalByName } = useModalContext();

  const pollState = useSelector((state: RootState) => state.userPoll);
  const commentThread = useSelector(
    (state: RootState) => state.userPoll
  ).commentThread;

  const commentCountRoot = commentThread?.rootCommentCount ?? 0;
  const commentCountReplies = commentThread?.replyCount ?? 0;

  const { showComments, setShowComments } = useUpdateCommentThreadData(
    pollState.userPoll?.commentThreadId,
    props.showComment
  );

  useEffect(() => {
    Mixpanel?.track("Poll Viewed", {
      slug: pollState.userPoll?.slug,
      body: pollState.userPoll?.title,
    });
    removeModalByName("UserPollModal");
  }, []);

  return (
    <>
      {showComments && (
        <CommentModal
          onClose={() => setShowComments(false)}
          commentId={props.commentId}
          slug={pollState.userPoll?.slug ?? ""}
          audienceGroupSize={commentThread?.audienceUids?.length ?? 0}
          source="daily poll"
          content={pollState.userPoll?.title ?? ""}
        />
      )}
      <ModalWrapper title="Today's poll">
        <main className={styles.main}>
          <section className={styles.top}>
            <h6 className={`${styles.pollText} ${font.className}`}>POLL</h6>
            <PollIcon />
          </section>
          <p className={styles.pollTitle}>{pollState.userPoll?.title}</p>
          <div className={styles.commentInfoDiv}>
            <CommentAndAudiencePreviewsInsights
              audienceNumber={commentThread?.audienceUids?.length ?? 0}
              commentCount={pollState.userPoll?.voteCount ?? 0}
              pollRepsonses={pollState.userPoll?.voteCount ?? 0}
              all
            />
          </div>
          <PollPercentages />
          <PollMostLikelyToChoose />
          <div className={styles.interactionBarDiv}>
            <DailyPollsInterractionBar
              onCommentClick={() => {
                setShowComments(true);
                Mixpanel?.track("Comment modal summoned", {
                  source: "daily poll",
                  audience_group_size: "all",
                  type: "from Discuss CTA",
                });
              }}
              commentCount={
                commentCountRoot + commentCountReplies === 99
                  ? 99
                  : commentCountRoot + commentCountReplies
              }
            />
          </div>
        </main>
      </ModalWrapper>
    </>
  );
}
