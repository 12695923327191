import { PublicProfile } from "@dimensional-engineering/dimensional-models";

import styles from "./AuthLeftBar.module.css";

import useConnectionStatus from "@/helpers/useConnectionStatus";
import BasicConnectionIcon from "@/components/shared/icons/BasicConnectionIcon";
import CloseConnectionIcon from "@/components/shared/icons/CloseConnectionIcon";
import DeepConnectionIcon from "@/components/shared/icons/DeepConnectionIcon";
import { useModalContext } from "@/context/ModalContext";
import CloseConnectionModal from "@/components/connections/ConnectionRequestModal/CloseConnectionModal";
import DeepConnectionModal from "@/components/connections/ConnectionRequestModal/DeepConnectionModal";

export default function ConnectionTypeBar(props: {
  publicProfile: PublicProfile;
}) {
  const friendStatus = useConnectionStatus(props.publicProfile.ownerUUID);

  const { setModalComponent } = useModalContext();

  if (
    friendStatus === "unconnected" ||
    friendStatus === "sent" ||
    friendStatus === "received"
  ) {
    return null;
  }

  return (
    <div className={styles.typeBarMain}>
      <div
        className={
          friendStatus === "connected" ? styles.activeLarge : styles.active
        }
      >
        <BasicConnectionIcon active />
      </div>
      <div
        onClick={() => {
          if (friendStatus === "connected") {
            setModalComponent(
              <CloseConnectionModal
                publicProfile={props.publicProfile}
                elementName="ConnectionModal"
              />
            );
          }
        }}
        className={
          friendStatus === "close"
            ? styles.activeLarge
            : friendStatus === "connected"
            ? styles.inactive
            : styles.active
        }
      >
        <CloseConnectionIcon
          active={friendStatus === "connected" ? false : true}
        />
      </div>
      <div
        onClick={() => {
          if (friendStatus !== "deep") {
            setModalComponent(
              <DeepConnectionModal
                publicProfile={props.publicProfile}
                elementName="ConnectionModal"
              />
            );
          }
        }}
        className={
          friendStatus === "deep" ? styles.activeLarge : styles.inactive
        }
      >
        <DeepConnectionIcon active={friendStatus === "deep" ? true : false} />
      </div>
    </div>
  );
}
