import { useEffect, useState } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import {
  DiscoverableCompatibilitySnippet,
  DiscoveredCompatibilitySnippet,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "../../../styles/app/AppProfile.module.css";

import { useModalContext } from "@/context/ModalContext";
import AuthLeftBar from "../AuthLeftBar/AuthLeftBar";
import { getDefaultProfilePhoto } from "@/context/utils";
import { useConnectionsAndImages } from "@/helpers/useConnectionsAndImages";
import useConnectedDynamicProfileData from "@/helpers/useConnectedDynamicProfileData";
import { firebaseStorage } from "@/_firebase/firebaseConfig";
import { AuthRightBarConnectedModal } from "../AuthRightBar/AuthRightBarConnected";
import LoadingModal from "@/components/shared/LoadingModal/LoadingModal";
import { largeSnippets } from "../tabs/AuthCompareTab/Compatibility/CompatibilityData";
import { useAuth } from "@/context/AuthContext";
import {
  getCompatibilitySnippetFromResults,
  getRomanticCompatibilitySnippetFromResults,
  getWorkCompatibilitySnippetFromResults,
} from "../tabs/AuthCompareTab/Compatibility/utils";
import { ArchetypeDataModel } from "../tabs/AuthCompareTab/Compatibility/CompatibilitySnippetBlock";
import CompatibilityModal from "../tabs/AuthCompareTab/Compatibility/CompatibilityModal";
import RomanticCompatibilityModal from "../tabs/AuthCompareTab/Compatibility/RomanticCompatibilityModal";
import WorkCompatibilityModal from "../tabs/AuthCompareTab/Compatibility/WorkCompatibilityModal";
import ResultsHeader from "@/components/shared/AuthenticatedHeader/ResultsHeader";

export default function ConnectedProfileModal(props: { uid: string }) {
  const { modalComponent } = useModalContext();

  const profileData = useConnectedDynamicProfileData({
    uid: props.uid,
  });
  const [nonOwnerImageUrl, setNonOwnerImageUrl] = useState<string | null>(null);
  const { connectionListWithFriendDetails, imageUrls } =
    useConnectionsAndImages(props.uid);

  useShowCompatibilityModalFromNotification({
    publicProfile: profileData.publicProfile,
  });

  useEffect(() => {
    getDownloadURL(
      ref(
        firebaseStorage,
        `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${props.uid}/cropped.jpg`
      )
    )
      .then((url) => {
        setNonOwnerImageUrl(url);
      })
      .catch(async (err) => {
        return await getDefaultProfilePhoto(props.uid as string).then(
          (value) => {
            return setNonOwnerImageUrl(value);
          }
        );
      });
  }, [profileData, props.uid]);

  return (
    <>
      <div className={styles.main}>
        <ResultsHeader
          title={
            profileData.publicProfile?.name
              ? `${profileData.publicProfile?.name ?? ""}`
              : `@${profileData.publicProfile?.userName ?? ""}`
          }
          underline
          downArrow={modalComponent.length < 2 ? true : false}
        />
        <div className={styles.barContainerOwn}>
          <AuthLeftBar
            connectionList={connectionListWithFriendDetails}
            imageUrl={nonOwnerImageUrl}
            publicProfile={profileData.publicProfile}
            ownProfile={false}
          />
          <AuthRightBarConnectedModal
            data={profileData}
            imageUrl={nonOwnerImageUrl ?? ""}
            uid={props.uid}
          />
        </div>
      </div>
    </>
  );
}

function useShowCompatibilityModalFromNotification(props: {
  publicProfile: PublicProfile | null;
}) {
  const { setModalComponent, removeModalByName } = useModalContext();
  const LSCompatibilitySlug = localStorage.getItem("copatibilitySnippetSlug");
  const { user } = useAuth();

  useEffect(() => {
    if (LSCompatibilitySlug && props.publicProfile) {
      setModalComponent(<LoadingModal elementName="LoadingModal" />);
      if (!largeSnippets.includes(LSCompatibilitySlug)) {
        getCompatibilitySnippetFromResults(
          LSCompatibilitySlug,
          props.publicProfile,
          user?.uid
        )
          .then((results) => {
            let disoverableSnippet: DiscoverableCompatibilitySnippet | null =
              null;
            let disoveredSnippet: DiscoveredCompatibilitySnippet | null = null;
            //index 0 is discoverable , 1 is discovered, 2 is archetypes
            results[0].forEach((doc) => {
              disoverableSnippet =
                doc.data() as DiscoverableCompatibilitySnippet;
            });
            results[1].forEach((doc) => {
              disoveredSnippet = doc.data() as DiscoveredCompatibilitySnippet;
            });
            const archetypeData: ArchetypeDataModel[] = results[2]
              .data as ArchetypeDataModel[];

            if (
              disoveredSnippet &&
              disoverableSnippet &&
              props.publicProfile &&
              archetypeData
            ) {
              removeModalByName("LoadingModal");
              localStorage.removeItem("copatibilitySnippetSlug");
              return setModalComponent(
                <CompatibilityModal
                  publicProfile={props.publicProfile}
                  discoverableSnippet={disoverableSnippet}
                  discoveredSnippets={[disoveredSnippet]}
                  archetypeData={archetypeData}
                />
              );
            }
          })
          .catch((err) => {
            localStorage.removeItem("copatibilitySnippetSlug");
            return removeModalByName("LoadingModal");
          });
      } else {
        if (
          LSCompatibilitySlug ===
          "compatibility-snippet-romantic-compatibility-report"
        ) {
          getRomanticCompatibilitySnippetFromResults(
            props.publicProfile,
            user?.uid
          )
            .then((res) => {
              removeModalByName("LoadingModal");
              localStorage.removeItem("copatibilitySnippetSlug");
              return setModalComponent(
                <RomanticCompatibilityModal
                  romanticCompatibilityReport={res}
                  publicProfile={props.publicProfile ?? null}
                />
              );
            })
            .catch((err) => {
              localStorage.removeItem("copatibilitySnippetSlug");
              return removeModalByName("LoadingModal");
            });
        } else if (
          LSCompatibilitySlug ===
          "compatibility-snippet-work-compatibility-report"
        ) {
          getWorkCompatibilitySnippetFromResults(props.publicProfile, user?.uid)
            .then((res) => {
              removeModalByName("LoadingModal");
              localStorage.removeItem("copatibilitySnippetSlug");
              return setModalComponent(
                <WorkCompatibilityModal
                  workCompatibilityReport={res}
                  publicProfile={props.publicProfile ?? null}
                />
              );
            })
            .catch((err) => {
              localStorage.removeItem("copatibilitySnippetSlug");
              return removeModalByName("LoadingModal");
            });
        }
      }
    }
  }, [LSCompatibilitySlug, props.publicProfile]);
}
