import { SocialMediaMap } from "@dimensional-engineering/dimensional-models";

import styles from "./SocialLinks.module.css";

import {
  InstagramImage,
  LinkedInImage,
  TikTokImage,
  TwitterImage,
} from "./SocialMediaImages";

export default function SocialLinks(props: SocialMediaMap) {
  return (
    <div className={styles.socialLinksContainer}>
      {props.twitter?.userName && <TwitterImage url={props.twitter.userName} />}
      {props.linkedIn?.userName && (
        <LinkedInImage url={props.linkedIn.userName} />
      )}
      {props.instagram?.userName && (
        <InstagramImage url={props.instagram.userName} />
      )}
      {props.tikTok?.userName && <TikTokImage url={props.tikTok.userName} />}
    </div>
  );
}
