import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Archetype,
  DiscoverableCompatibilitySnippet,
  DiscoveredCompatibilitySnippet,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "./CompatibilitySnippets.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import Loader from "@/components/shared/Loader";
import { useModalContext } from "@/context/ModalContext";
import CompatibilityModal from "./CompatibilityModal";
import { RootState } from "@/redux/store";

type Props = {
  discoverableSnippet: DiscoverableCompatibilitySnippet;
  publicProfile: PublicProfile | null;
  elementName: "ComputingStateSnippetModal";
  archetypeData: ArchetypeDataModel[] | null;
};

type ArchetypeDataModel = {
  dimensionSlug: string;
  archetypeScore: number;
  archetypeSlug: string;
  archetype: Archetype;
};

export default function ComputingStateSnippetModal(props: Props) {
  const discoveredCompatibilitySnippets = useSelector(
    (state: RootState) => state.compatibilitySnippets
  ).discoveredCompatibilitySnippets;
  useDisplayCompatibilityModalOnceLoaded({
    discoverableSnippet: props.discoverableSnippet,
    discoveredCompatibilitySnippets: discoveredCompatibilitySnippets,
    publicProfile: props.publicProfile,
    archetypeData: props.archetypeData,
  });

  return (
    <ModalWrapper backgroundColor="#2D2927" title="" noLine>
      <section className={styles.generateModalMain}>
        <Loader height="100px" mobileHeight="100px" />
        <div className={styles.computeContent}>
          <h3 className={styles.computeText}>Computing.</h3>
          <h3 className={styles.computeText}>
            {"We'll notify you when it's ready."}
          </h3>
        </div>
        <p className={styles.computeBottomText}>Still not working?</p>
        <p className={styles.computeBottomTextTwo}>Contact the Support team</p>
      </section>
    </ModalWrapper>
  );
}

function useDisplayCompatibilityModalOnceLoaded(props: {
  discoveredCompatibilitySnippets: DiscoveredCompatibilitySnippet[];
  discoverableSnippet: DiscoverableCompatibilitySnippet;
  publicProfile: PublicProfile | null;
  archetypeData: ArchetypeDataModel[] | null;
}) {
  const { setModalComponent, removeModalByName } = useModalContext();

  useEffect(() => {
    props.discoveredCompatibilitySnippets.forEach((s) => {
      if (s?.snippetSlug === props.discoverableSnippet?.snippetSlug) {
        setModalComponent(
          <CompatibilityModal
            publicProfile={props.publicProfile}
            discoverableSnippet={props.discoverableSnippet}
            discoveredSnippets={props.discoveredCompatibilitySnippets}
            archetypeData={props.archetypeData}
          />
        );
        removeModalByName("GenerateCompatibilityModal");
        removeModalByName("ComputingStateSnippetModal");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.discoveredCompatibilitySnippets]);
}
