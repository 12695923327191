import { useRouter } from "next/router";

import styles from "./AuthAboutTab.module.css";

import AuthMobileBioAndLinks from "./AuthMobileBioAndLinks/AuthMobileBioAndLinks";
import SummaryTable from "@/components/publicProfiles/SummaryTable/SummaryTable";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import { DynamicProfileResultData } from "@/models/sharedModels";
import Archetypes from "@/components/traits/arcehtype/Archetypes/Archetypes";
import Loader from "@/components/shared/Loader";
import ResultsHeading from "@/components/results/ResultsHeading/ResultsHeading";
import { AnalysisSectionUnConnected } from "./AnalysisSection/AnalysisSection";
import Shimmer from "@/components/shared/Shimmer/Shimmer";

type Props = {
  data: DynamicProfileResultData;
  imageUrl?: string;
  connection?: "connected" | "isClose" | "isDeep";
};

export default function AuthNonOwnerAboutTab(props: Props) {
  const colorMap = ProfileColorMap;
  const { slug } = useRouter().query;

  return (
    <div className={styles.main}>
      <AuthMobileBioAndLinks
        imageUrl={props.imageUrl ?? null}
        bio={props.data.publicProfile?.bio!!}
        links={props.data.publicProfile?.socialMediaMap!!}
        publicProfile={props.data.publicProfile}
      />

      {props.data.summaryTableData ? (
        <SummaryTable
          notOwn
          auth
          publicProfile={props.data.publicProfile}
          imageUrl={props.imageUrl}
          natureColor={
            props.data.publicProfile?.primaryNature?.nature
              ? colorMap[props.data.publicProfile?.primaryNature?.nature]
              : null
          }
          data={props.data.summaryTableData}
        />
      ) : (
        <Loader height="300px" mobileHeight="300px" />
      )}

      {props.data.archetypeData ? (
        <Archetypes
          archetypes={props.data.archetypeData}
          username={props.data.publicProfile?.userName}
          uid={slug as string}
        />
      ) : props.data.archetypeLoading ? (
        <div style={{ marginTop: "40px" }}>
          <ResultsHeading title="Archetypes" />
          <Loader height="100px" mobileHeight="100px" />
        </div>
      ) : (
        <Archetypes
          archetypes={[]}
          username={props.data.publicProfile?.userName}
          uid={slug as string}
        />
      )}
      <div className={styles.storiesDiv}>
        <ResultsHeading title="Analysis" />
        <AnalysisSectionUnConnected
          publicProfile={props.data.publicProfile}
          imageUrl={props.imageUrl ?? ""}
        />
      </div>
    </div>
  );
}

type PropsModal = {
  data: DynamicProfileResultData;
  imageUrl?: string;
  connection?: "connected" | "isClose" | "isDeep";
  uid: string;
};

export function AuthNonOwnerAboutTabModal(props: PropsModal) {
  const colorMap = ProfileColorMap;

  return (
    <div className={styles.main}>
      <AuthMobileBioAndLinks
        imageUrl={props.imageUrl ?? null}
        bio={props.data.publicProfile?.bio!!}
        links={props.data.publicProfile?.socialMediaMap!!}
        publicProfile={props.data.publicProfile}
      />

      {props.data.summaryTableData ? (
        <SummaryTable
          notOwn
          auth
          publicProfile={props.data.publicProfile}
          imageUrl={props.imageUrl}
          natureColor={
            props.data.publicProfile?.primaryNature?.nature
              ? colorMap[props.data.publicProfile?.primaryNature?.nature]
              : null
          }
          data={props.data.summaryTableData}
        />
      ) : (
        <div style={{ width: "100%", padding: "0 2%" }}>
          <Shimmer height={350} />
        </div>
      )}

      {props.data.archetypeData ? (
        <Archetypes
          archetypes={props.data.archetypeData}
          username={props.data.publicProfile?.userName}
          uid={props.uid}
        />
      ) : props.data.archetypeLoading ? (
        <div style={{ marginTop: "40px" }}>
          <ResultsHeading title="Archetypes" />
          <div style={{ width: "100%", padding: "0 2%" }}>
            <Shimmer height={100} />
          </div>
        </div>
      ) : (
        <Archetypes
          archetypes={[]}
          username={props.data.publicProfile?.userName}
          uid={props.uid}
        />
      )}
      <div className={styles.storiesDiv}>
        <ResultsHeading title="Analysis" />
        <AnalysisSectionUnConnected
          publicProfile={props.data.publicProfile}
          imageUrl={props.imageUrl ?? ""}
        />
      </div>
    </div>
  );
}
