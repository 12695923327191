import { useEffect, useState } from "react";

import { useModalContext } from "@/context/ModalContext";

export function DailtInsightIconEmptyVariant() {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_62694_21311"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="73"
        height="73"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.44565 15.172C3.30527 17.3123 3.25896 20.7363 5.34222 22.8195L22.5228 40.0001L5.96006 56.5629C3.8768 58.6462 3.92311 62.0701 6.06349 64.2105L15.8218 73.9688C17.9622 76.1092 21.3861 76.1555 23.4694 74.0722L40.0321 57.5095L56.2279 73.7053C58.3112 75.7885 61.7352 75.7422 63.8755 73.6019L73.6338 63.8436C75.7742 61.7032 75.8205 58.2792 73.7373 56.196L57.5414 40.0001L74.3551 23.1865C76.4384 21.1032 76.3921 17.6793 74.2517 15.5389L64.4934 5.78061C62.353 3.64022 58.9291 3.59392 56.8458 5.67718L40.0321 22.4908L22.8515 5.31024C20.7683 3.22698 17.3443 3.27328 15.2039 5.41366L5.44565 15.172Z"
          fill="#FFFFF2"
        />
      </mask>
      <g mask="url(#mask0_62694_21311)">
        <rect x="1.90527" width="100" height="78.0952" fill="#928E8C" />
        <rect x="26.666" width="100" height="78.0952" fill="#928E8C" />
        <rect x="53.334" width="100" height="78.0952" fill="#928E8C" />
      </g>
    </svg>
  );
}

export function DailtInsightIconWhiteVariant() {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_63035_32065"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="73"
        height="73"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.44559 15.1722C3.3052 17.3126 3.2589 20.7365 5.34216 22.8198L22.5227 40.0003L5.95989 56.5631C3.87663 58.6464 3.92293 62.0703 6.06332 64.2107L15.8216 73.969C17.962 76.1094 21.3859 76.1557 23.4692 74.0724L40.032 57.5096L56.2279 73.7055C58.3111 75.7888 61.7351 75.7425 63.8755 73.6021L73.6338 63.8438C75.7741 61.7034 75.8204 58.2795 73.7372 56.1962L57.5413 40.0003L74.3549 23.1867C76.4382 21.1035 76.3919 17.6795 74.2515 15.5391L64.4932 5.78083C62.3528 3.64045 58.9289 3.59414 56.8456 5.6774L40.032 22.491L22.8515 5.31049C20.7682 3.22722 17.3443 3.27353 15.2039 5.41391L5.44559 15.1722Z"
          fill="#FFFFF2"
        />
      </mask>
      <g mask="url(#mask0_63035_32065)">
        <rect x="1.90479" width="100" height="78.0952" fill="#FFFFF2" />
        <rect x="40" width="100" height="78.0952" fill="#FFFFF2" />
      </g>
    </svg>
  );
}

export function DailyInsightIconActiveVariant(props: { colors: string[] }) {
  const [divs, setDivs] = useState<[] | JSX.Element[]>([]);

  const { isModalOpen } = useModalContext();

  useEffect(() => {
    if (isModalOpen) {
      const icon = document.getElementById("dailyInsightIconMain");
      if (icon) {
        icon.style.clipPath = "url(#dailyInsightsClipPath)";
        icon.style.display = "flex";
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    setDivs([]);
    props.colors.forEach((c, i) => {
      const div = (
        <div
          className="innerInsightDiv"
          style={{ backgroundColor: c }}
          key={i}
        ></div>
      );
      setDivs((current) => [...current, div]);
    });
  }, [props.colors]);

  return (
    <div className="dailyInsightIconMain">
      <div className="dailyInsightContainer">
        {divs.map((d, i) => {
          return d;
        })}
      </div>
    </div>
  );
}

export function DailyInsightIconActiveVariantLarge(props: {
  colors: string[];
}) {
  const [divs, setDivs] = useState<[] | JSX.Element[]>([]);

  useEffect(() => {
    setDivs([]);
    props.colors.forEach((c, i) => {
      const div = (
        <div
          className="innerInsightDiv"
          style={{ backgroundColor: c }}
          key={i}
        ></div>
      );
      setDivs((current) => [...current, div]);
    });
  }, [props.colors]);

  return (
    <div className="dailyInsightIconMainLarge">
      <div className="dailyInsightContainer">
        {divs.map((d, i) => {
          return d;
        })}
      </div>
    </div>
  );
}

export function DailyInsightIconActiveVariantSmall(props: {
  colors: string[];
}) {
  const [divs, setDivs] = useState<[] | JSX.Element[]>([]);

  useEffect(() => {
    setDivs([]);
    props.colors.forEach((c, i) => {
      const div = (
        <div
          className="innerInsightDiv"
          style={{ backgroundColor: c }}
          key={i}
        ></div>
      );
      setDivs((current) => [...current, div]);
    });
  }, [props.colors]);

  return (
    <div className="dailyInsightIconSmall">
      <div className="dailyInsightContainer">
        {divs.map((d, i) => {
          return d;
        })}
      </div>
    </div>
  );
}

export function HighDailyInsightScaleScoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
    >
      <path
        d="M1.374 11.2089L4.74645 7.83799L8.11888 11.2089C8.44065 11.5087 8.94221 11.5 9.25376 11.1889C9.5648 10.8774 9.57348 10.3758 9.27368 10.0541L5.32411 6.1045L5.32411 6.10399C5.00489 5.78528 4.48802 5.78528 4.16881 6.10399L0.219237 10.0536L0.219237 10.0541C-0.0805729 10.3758 -0.0718912 10.8774 0.239156 11.1889C0.550707 11.5 1.05224 11.5087 1.374 11.2089Z"
        fill="#FFFFF2"
      />
      <path
        d="M1.374 5.84387L4.74645 2.473L8.11888 5.84387C8.44065 6.14368 8.94221 6.135 9.25376 5.82395C9.5648 5.5124 9.57348 5.01084 9.27368 4.68908L5.32411 0.739509L5.32411 0.738998C5.00489 0.420294 4.48802 0.420294 4.16881 0.738998L0.219237 4.68857L0.219237 4.68908C-0.0805729 5.01084 -0.0718912 5.5124 0.239156 5.82395C0.550707 6.13499 1.05224 6.14367 1.374 5.84387Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}

export function NeutralDailyInsightScaleScoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
    >
      <rect
        x="5"
        y="9.5"
        width="9"
        height="1.71429"
        rx="0.857143"
        fill="#FFFFF2"
      />
    </svg>
  );
}

export function LowDailyInsightScaleScoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
    >
      <path
        d="M8.626 0.791109L5.25355 4.16198L1.88112 0.791108C1.55935 0.491298 1.05779 0.499981 0.746242 0.811028C0.4352 1.12258 0.426519 1.62414 0.726323 1.9459L4.67589 5.89547L4.67589 5.89598C4.99511 6.21469 5.51198 6.21469 5.83119 5.89598L9.78076 1.94641L9.78076 1.9459C10.0806 1.62414 10.0719 1.12258 9.76084 0.811028C9.44929 0.499987 8.94776 0.491304 8.626 0.791109Z"
        fill="#FFFFF2"
      />
      <path
        d="M8.626 6.1561L5.25355 9.52697L1.88112 6.1561C1.55935 5.85629 1.05779 5.86497 0.746242 6.17602C0.4352 6.48757 0.426519 6.98913 0.726323 7.31089L4.67589 11.2605L4.67589 11.261C4.99511 11.5797 5.51198 11.5797 5.83119 11.261L9.78076 7.3114L9.78076 7.31089C10.0806 6.98913 10.0719 6.48757 9.76084 6.17602C9.44929 5.86498 8.94776 5.85629 8.626 6.1561Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
