import { useEffect, useState } from "react";

import styles from "./AuthRightBar.module.css";

import { DynamicProfileResultData } from "@/models/sharedModels";
import AuthAboutTab from "../tabs/AuthAboutTab/AuthAboutTab";

type Props = {
  natureColor: string | null;
  ownProfile: boolean;
  data: DynamicProfileResultData;
  imageUrl?: string;
};

export default function AuthRightBar(props: Props) {
  const [currentTab, setCurrentTab] = useState<string | null>(null);

  useEffect(() => {
    const currentTabLS = localStorage.getItem("currentTab");
    const tabUid = localStorage.getItem("currentTabUid");
    if (
      currentTabLS &&
      tabUid &&
      tabUid === props.data.publicProfile?.ownerUUID
    ) {
      setCurrentTab(currentTabLS);
      localStorage.removeItem("currentTab");
    } else {
      setCurrentTab(null);
      localStorage.removeItem("currentTab");
    }
  }, [props.data.publicProfile?.ownerUUID]);

  return (
    <div id="rightBarMain" className={styles.main}>
      <AuthAboutTab />
    </div>
  );
}
