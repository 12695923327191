import styles from "./Shimmer.module.css";

export default function Shimmer(props: {
  width?: number;
  height?: number;
  rounded?: boolean;
}) {
  return (
    <div
      className={styles.main}
      style={{
        width: props.width ? `${props.width}px` : "100%",
        height: props.height ? `${props.height}px` : "100%",
        borderRadius: props.rounded ? "50%" : "inherit",
      }}
    ></div>
  );
}
