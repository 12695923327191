import {
  CommentThread,
  PollResponse,
  UserPoll,
} from "@dimensional-engineering/dimensional-models";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type UserPollSlice = {
  userPoll: UserPoll | null;
  userCurrentPollResponse: PollResponse | null;
  commentThread: CommentThread | null;
  userPollLoaded: boolean;
};

const initialState: UserPollSlice = {
  userPoll: null,
  userCurrentPollResponse: null,
  commentThread: null,
  userPollLoaded: false,
};

export const userPoll = createSlice({
  name: "userPoll",
  initialState: initialState,
  reducers: {
    setUserPoll: (state, action: PayloadAction<UserPoll>) => {
      return { ...state, userPoll: action.payload };
    },
    setUserPollResponse: (
      state,
      action: PayloadAction<PollResponse | null>
    ) => {
      return { ...state, userCurrentPollResponse: action.payload };
    },
    setCommentThreadUserPoll: (state, action: PayloadAction<CommentThread>) => {
      return { ...state, commentThread: action.payload };
    },
    setUserPollLoaded: (state, action: PayloadAction<boolean>) => {
      return { ...state, userPollLoaded: action.payload };
    },
  },
});

export const {
  setUserPoll,
  setUserPollResponse,
  setCommentThreadUserPoll,
  setUserPollLoaded,
} = userPoll.actions;

export default userPoll.reducer;
