import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Roboto_Mono } from "next/font/google";
import { PollOption } from "@dimensional-engineering/dimensional-models";

import styles from "./UserPollModal.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { RootState } from "@/redux/store";
import { useAuth } from "@/context/AuthContext";
import PollIcon from "../PollIcon";
import { pollOptionIndexMap, submitPollResponse } from "../utils";
import { setUserPollResponse } from "@/redux/slices/userPoll";
import { useModalContext } from "@/context/ModalContext";
import UserPollResponseModal from "../UserPollResponseModal/UserPollResponseModal";
import { Mixpanel } from "@/helpers/mixpanel";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function UserPollModal(props: { elementName: "UserPollModal" }) {
  const dispatch = useDispatch();

  const { user } = useAuth();
  const { setModalComponent } = useModalContext();

  const pollState = useSelector((state: RootState) => state.userPoll);

  useEffect(() => {
    Mixpanel?.track("Poll Viewed", {
      slug: pollState.userPoll?.slug,
      body: pollState.userPoll?.title,
    });
  }, []);

  if (!pollState.userPoll) {
    return (
      <ModalWrapper title="Today's poll">
        <h1>No poll today</h1>
      </ModalWrapper>
    );
  }

  function handleResponse(option?: PollOption) {
    if (pollState.userPoll) {
      submitPollResponse(pollState.userPoll.slug, user?.uid, option).then(
        (res) => {
          Mixpanel?.track("Poll Responded", {
            slug: pollState.userPoll?.slug,
            body: pollState.userPoll?.title,
            response: option ? option.label : "just show me the responses",
          });
          if (pollState.userPoll) {
            dispatch(
              setUserPollResponse({
                selectedOption: option,
                pollSlug: pollState.userPoll.slug,
              })
            );
            setModalComponent(
              <UserPollResponseModal elementName="UserPollResponseModal" />
            );
          }
        }
      );
    }
  }

  const mappedOptions = [...pollState.userPoll?.pollOptions]
    .sort((a, b) => a.id - b.id)
    .map((option, index) => {
      return (
        <div
          onClick={() => {
            handleResponse(option);
            const target = document.getElementById(`answerBox${index}`);
            if (target) {
              target.classList.add("active");
              setTimeout(() => {
                target.classList.remove("active");
              }, 200);
            }
          }}
          className={styles.optionDiv}
          key={option.id}
          id={`answerBox${index}`}
        >
          <p className={styles.optionLabel}>
            {pollOptionIndexMap[index as keyof typeof pollOptionIndexMap]}{" "}
            {option.label}
          </p>
        </div>
      );
    });

  return (
    <ModalWrapper title="Today's poll">
      <main className={styles.main}>
        <section className={styles.top}>
          <h6 className={`${styles.pollText} ${font.className}`}>POLL</h6>
          <PollIcon />
        </section>
        <p className={styles.pollTitle}>{pollState.userPoll?.title}</p>
        <div className={styles.optionsWrapper}>{mappedOptions}</div>
        <div className={styles.showResponseDiv}>
          <p
            onClick={() => handleResponse()}
            className={styles.showResponseText}
          >
            Just show me the responses
          </p>
        </div>
      </main>
    </ModalWrapper>
  );
}
