/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Image from "next/image";
import {
  ContentType,
  PeriodicQuiz,
  PeriodicQuizResult,
} from "@dimensional-engineering/dimensional-models";

import styles from "./PeriodicQuizResultModal.module.css";

import BackButton from "@/components/shared/buttons/BackButton";
import { useModalContext } from "@/context/ModalContext";
import {
  formatPeriodicQuizPercentileDescriptionTemplate,
  getPeriodicScorePercentileScore,
  periodicQuizSampleNameColourMap,
} from "../utils";
import { RootState } from "@/redux/store";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import {
  PeriodicQuizInstagramIcon,
  PeriodicQuizSnapchatIcon,
} from "../PeriodicQuizShareIcons";
import { useAuth } from "@/context/AuthContext";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import PeriodicQuizModal from "../PeriodicQuizModal/PeriodicQuizModal";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "@/components/shared/Alerts/Alert";
import { Mixpanel } from "@/helpers/mixpanel";
import PeriodicQuizResultsShareModal from "./PeriodicQuizResultsShareModal";
import { SharedPostWithCommentThread } from "@/models/sharedModels";
import useUpdateCommentThreadData from "../../CommentModal/useUpdateCommentThreadData";
import CommentModal from "../../CommentModal/CommentModal";
import { PeriodicQuizResultInterractionBar } from "@/components/results/Snippets/SnippetInterractionBar/SnippetInterractionBar";
import SharedPostModal from "../../ABSTRACTS/AppHomeSharedTab/SharedPostModal/SharedPostModal";
import ShareCaptionModal from "../../ABSTRACTS/AppHomeSharedTab/ShareCaptionModal/ShareCaptionModal";
import PostToConnectionsButton from "../../ABSTRACTS/AppHomeSharedTab/PostToConnectionsButton/PostToConnectionsButton";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";

type Props = {
  periodicQuizResult: PeriodicQuizResult | null | undefined;
  periodicQuiz: PeriodicQuiz;
  elementName: "PeriodicQuizResultModal";
  showComment?: boolean;
  commentId?: string;
};

type FilterTypes =
  | "Gender"
  | "Age"
  | "Nature"
  | "I/E"
  | "S/N"
  | "T/F"
  | "J/P"
  | null;

export default function PeriodicQuizResultModal(props: Props) {
  const { setModalOpen, setModalComponent } = useModalContext();
  const { setAlertComponent } = useAlertContext();

  const quizStateCommentThreadId = useSelector(
    (state: RootState) => state.periodicQuiz
  ).availableQuiz?.commentThreadId;
  const quizTitle = useSelector((state: RootState) => state.periodicQuiz)
    .availableQuiz?.title;
  const quizSlug = useSelector((state: RootState) => state.periodicQuiz)
    .availableQuiz?.slug;
  const commentThread = useSelector(
    (state: RootState) => state.periodicQuiz
  ).commentThread;

  const sharedQuizResults = useGetConnectionScores();

  const connectionState = useSelector((state: RootState) => state.connections);

  const userPublicProfile = useSelector(
    (state: RootState) => state.user
  ).publicProfileData;
  const profileImage = useSelector((state: RootState) => state.user).imageURL;

  const commentCountRoot = commentThread?.rootCommentCount ?? 0;
  const commentCountReplies = commentThread?.replyCount ?? 0;

  const { showComments, setShowComments } = useUpdateCommentThreadData(
    quizStateCommentThreadId,
    props.showComment
  );

  const { user } = useAuth();

  const [filter, setFilter] = useState<FilterTypes>(null);
  const [showCaptionModal, setShowCaptionModal] = useState<boolean>(false);
  const [caption, setCaption] = useState<string>("");

  const percentileScore = getPeriodicScorePercentileScore(
    props.periodicQuiz,
    props.periodicQuizResult
  );

  const foundPost = useIsPeriodicQuizShared({ userUid: user?.uid });

  function handlePost() {
    if (foundPost) {
      Mixpanel?.track("Post viewed", {
        source: "from own results modal",
        content_type: "daily quiz",
        content_type_slug:
          foundPost.postContent.periodicQuizData?.periodicQuiz.slug,
        caption_text: foundPost.caption,
      });
      return setModalComponent(
        <SharedPostModal
          profileImage={profileImage ?? ""}
          publicProfile={userPublicProfile}
          sharedPost={foundPost}
        />
      );
    }
    return setShowCaptionModal(true);
  }

  const sampleBreakdown = props.periodicQuiz.sampleData?.sampleBreakdowns?.find(
    (s) => s.name === filter
  );

  const mappedLabels = sampleBreakdown?.categoryData?.map((s) => {
    const color =
      periodicQuizSampleNameColourMap[
        s.slug as keyof typeof periodicQuizSampleNameColourMap
      ];
    return (
      <div className={styles.labelDiv} key={s.slug}>
        <div style={{ backgroundColor: color }} className={styles.circle}></div>
        <p className={styles.legendText}>{s.name}</p>
      </div>
    );
  });

  const mappedFilters = [
    "Gender",
    "Age",
    "Nature",
    "I/E",
    "S/N",
    "T/F",
    "J/P",
  ].map((option) => {
    return (
      <p
        className={styles.filterText}
        onClick={() => {
          if (props.periodicQuiz.sampleData?.isFinalized) {
            setFilter(option as FilterTypes);
            Mixpanel?.track("Quiz Breakdown Viewed", {
              type: option.toLowerCase(),
            });
          } else {
            setAlertComponent(
              <Alert
                elementName="Alert"
                type="warning"
                message="Breakdowns will be available once more responses have been collected."
              />,
              7000
            );
          }
        }}
        key={option}
        style={
          filter === option
            ? { color: "#FFFFF2", textDecorationLine: "underline" }
            : {}
        }
      >
        {option.toUpperCase()}
      </p>
    );
  });

  const mappedConnectionScores = [...sharedQuizResults]
    .sort((a, b) => {
      if (
        a.postContent.periodicQuizData?.periodicQuizResult.percentScore &&
        b.postContent.periodicQuizData?.periodicQuizResult.percentScore
      ) {
        return (
          b.postContent.periodicQuizData?.periodicQuizResult.percentScore -
          a.postContent.periodicQuizData?.periodicQuizResult.percentScore
        );
      }
      return 0;
    })
    .map((post) => {
      const publicProfile = connectionState.publicProfiles?.find(
        (c) => c.ownerUUID === post.authorUid
      );
      const score =
        post.postContent.periodicQuizData?.periodicQuizResult.percentScore;
      if (publicProfile && score) {
        return (
          <div
            onClick={() => {
              Mixpanel?.track("Post viewed", {
                source: "from own results modal",
                content_type: "daily quiz",
                content_type_slug:
                  post.postContent.periodicQuizData?.periodicQuiz.slug,
                caption_text: post.caption,
              });
              setModalComponent(
                <SharedPostModal
                  sharedPost={post}
                  publicProfile={publicProfile}
                  profileImage={publicProfile.imageUrl}
                />
              );
            }}
            className={styles.connectionScoreBox}
            key={post.slug}
          >
            <SmallProfileImage
              medium
              noLink
              publicProfile={publicProfile}
              imageUrl={publicProfile.imageUrl}
            />
            <p className={styles.connectionUsername}>
              <span style={{ color: "#fffff2" }}>
                @{publicProfile.userName}{" "}
              </span>
              <span className={styles.caption}>{post.caption}</span>
            </p>
            <p className={styles.connectionScore}>
              <span className={styles.connectionScoreSpan}>
                {(score * 100).toFixed(0)}
              </span>
              /100
            </p>
          </div>
        );
      }
    });

  return (
    <>
      {showComments && (
        <CommentModal
          onClose={() => setShowComments(false)}
          commentId={props.commentId}
          slug={quizSlug ?? ""}
          audienceGroupSize={commentThread?.audienceUids?.length ?? 0}
          source="daily quiz"
          content={quizTitle ?? ""}
        />
      )}
      {showCaptionModal && quizSlug && (
        <ShareCaptionModal
          contentIdentifier={{
            contentSlug: quizSlug,
            contentType: ContentType.periodicQuiz,
          }}
          onClose={(captionText) => {
            setCaption(captionText);
            setShowCaptionModal(false);
          }}
          savedCaption={caption}
        />
      )}
      <ModalWrapper noHeader noLine title="">
        <main className={styles.main}>
          <section className={styles.header}>
            <h2 className={styles.quizTitle}>Results</h2>
            <h3 className={styles.quizSubTitle}>Daily Quiz</h3>
            <div className={styles.progressBar}></div>
            <div className={styles.downArrorDiv}>
              <BackButton step={true} onBack={() => setModalOpen(false)} />
            </div>
          </section>
          <section className={styles.titleSection}>
            {props.periodicQuiz.emojiString !== "" && (
              <h1 className={styles.emojiString}>
                {props.periodicQuiz.emojiString}
              </h1>
            )}
            <h1 className={styles.quizTitleTitleSection}>
              {props.periodicQuiz.title}
            </h1>
          </section>

          {props.periodicQuizResult ? (
            <section className={styles.scoreBoxSection}>
              <div className={styles.scoreBox}>
                <div className={styles.scoreTextBig}>
                  <h2 className={styles.score}>
                    <span className={styles.scoreBig}>
                      {(props.periodicQuizResult.percentScore * 100).toFixed(0)}
                    </span>
                    /100
                  </h2>
                  {props.periodicQuiz.scoreName && (
                    <p className={styles.scoreName}>
                      {props.periodicQuiz.scoreName.toUpperCase()}
                    </p>
                  )}
                </div>
                <p className={styles.percentileDescriptionTemplate}>
                  {formatPeriodicQuizPercentileDescriptionTemplate(
                    props.periodicQuiz.percentileDescriptionTemplate,
                    percentileScore
                  )}
                </p>
                <PeriodicQuizHistogram
                  periodicQuiz={props.periodicQuiz}
                  filter={filter}
                  periodicQuizResult={props.periodicQuizResult}
                />
                {!props.periodicQuiz.sampleData?.isFinalized ? (
                  <>
                    <CollectingDataGhostBox />
                  </>
                ) : filter !== null ? (
                  <div className={styles.labelWrapper}>{mappedLabels}</div>
                ) : (
                  <AverageUserScoreLabel />
                )}
                <div className={styles.filterParent}>
                  <p className={styles.showAveragesByText}>Show averages by:</p>
                  {mappedFilters}
                </div>
              </div>
            </section>
          ) : (
            <UnknownPeriodicResultBox />
          )}
          {props.periodicQuizResult && (
            <section className={styles.postResultsSection}>
              <h3>Share results</h3>
              <PeriodicQuizInstagramIcon
                onClick={() => {
                  Mixpanel?.track("Shared to Social", {
                    platform: "Instagram",
                    slug: props.periodicQuiz.slug,
                    body: props.periodicQuiz.title,
                    type: "periodicQuiz",
                  });
                  setModalComponent(
                    <PeriodicQuizResultsShareModal
                      periodicQuiz={props.periodicQuiz}
                      periodicQuizResult={
                        props.periodicQuizResult as PeriodicQuizResult
                      }
                      type="instagram"
                    />
                  );
                }}
              />
              <PeriodicQuizSnapchatIcon
                onClick={() => {
                  Mixpanel?.track("Shared to Social", {
                    platform: "Snapchat",
                    slug: props.periodicQuiz.slug,
                    body: props.periodicQuiz.title,
                    type: "periodicQuiz",
                  });
                  setModalComponent(
                    <PeriodicQuizResultsShareModal
                      periodicQuiz={props.periodicQuiz}
                      periodicQuizResult={
                        props.periodicQuizResult as PeriodicQuizResult
                      }
                      type="snapchat"
                    />
                  );
                }}
              />
            </section>
          )}
          <section className={styles.connectionScoresSection}>
            <h3 className={styles.connectionResultsTitle}>
              Connection results
            </h3>
            <div className={styles.connectionScoresWrapper}>
              {mappedConnectionScores?.length ? (
                mappedConnectionScores
              ) : (
                <ConnectionScoresGhostBox />
              )}
            </div>
          </section>
          <section className={styles.postButtonSection}>
            <PostToConnectionsButton
              posted={foundPost ? true : false}
              onClick={() => {
                Mixpanel?.track("Post to connections CTA pressed", {
                  source: "modal body",
                  content_type: "daily quiz",
                  content_type_slug: quizSlug,
                });
                handlePost();
              }}
            />
          </section>

          <div className={styles.interactionBarDiv}>
            <PeriodicQuizResultInterractionBar
              onCommentClick={() => {
                setShowComments(true);
                Mixpanel?.track("Comment modal summoned", {
                  source: "daily quiz",
                  audience_group_size: "all",
                  type: "from Discuss CTA",
                });
              }}
              onShare={(value) => {
                Mixpanel?.track("Shared to Social", {
                  platform: value === "snapchat" ? "Snapchat" : "Instagram",
                  slug: props.periodicQuiz.slug,
                  body: props.periodicQuiz.title,
                  type: "periodicQuiz",
                });
                if (value === "instagram") {
                  setModalComponent(
                    <PeriodicQuizResultsShareModal
                      periodicQuiz={props.periodicQuiz}
                      periodicQuizResult={
                        props.periodicQuizResult as PeriodicQuizResult
                      }
                      type="instagram"
                    />
                  );
                } else {
                  setModalComponent(
                    <PeriodicQuizResultsShareModal
                      periodicQuiz={props.periodicQuiz}
                      periodicQuizResult={
                        props.periodicQuizResult as PeriodicQuizResult
                      }
                      type="snapchat"
                    />
                  );
                }
              }}
              commentCount={
                commentCountRoot + commentCountReplies === 99
                  ? 99
                  : commentCountRoot + commentCountReplies
              }
              onPostClick={() => {
                Mixpanel?.track("Post to connections CTA pressed", {
                  source: "interaction bar",
                  content_type: "daily quiz",
                  content_type_slug: quizSlug,
                });
                handlePost();
              }}
              posted={foundPost ? true : false}
            />
          </div>
        </main>
      </ModalWrapper>
    </>
  );
}

function PeriodicQuizHistogram(props: {
  filter: FilterTypes;
  periodicQuiz: PeriodicQuiz;
  periodicQuizResult: PeriodicQuizResult;
}) {
  const [multiplier, setMultiplier] = useState<number>(0);

  const userState = useSelector((state: RootState) => state.user);

  const sampleBreakdown = props.periodicQuiz.sampleData?.sampleBreakdowns?.find(
    (s) => s.name === props.filter
  );

  useEffect(() => {
    let high = 0;
    if (props.periodicQuiz.sampleData?.histogramData) {
      props.periodicQuiz.sampleData.histogramData.forEach((point) => {
        if (point > high) {
          high = point;
        }
      });
    }
    setMultiplier(160 / (high * 100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapped = props.periodicQuiz.sampleData?.histogramData?.map(
    (point, index) => {
      return (
        <div
          className={styles.histogramBar}
          style={
            point === 0
              ? { height: `6px` }
              : { height: `${point * 100 * multiplier}px` }
          }
          key={index}
        ></div>
      );
    }
  );

  const userScore = (
    <div
      style={{
        left: `calc(${props.periodicQuizResult.percentScore * 100}% - 1px)`,
      }}
      className={styles.averageScoreDiv}
    >
      <div
        style={
          props
            ? {
                border: `3px solid ${
                  ProfileColorMap[
                    userState.publicProfileData?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                }`,
              }
            : { border: "3px solid #55504F" }
        }
        className={styles.userScoreCircle}
      >
        <img src={userState.imageURL ?? ""} alt="" />
      </div>
      <div
        style={
          props
            ? {
                backgroundColor: `${
                  ProfileColorMap[
                    userState.publicProfileData?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                }`,
              }
            : { backgroundColor: "#55504F" }
        }
        className={styles.userScoreLine}
      ></div>
    </div>
  );

  const averageScore =
    props.filter === null &&
    props.periodicQuiz.sampleData?.averageUserScore &&
    props.periodicQuiz.sampleData.isFinalized ? (
      <div
        style={{
          left: `calc(${
            props.periodicQuiz.sampleData?.averageUserScore * 100
          }% - 1px)`,
          height: "150px",
        }}
        className={styles.averageScoreDiv}
      >
        <div
          style={{ backgroundColor: "#7886FF" }}
          className={styles.circle}
        ></div>
        <div
          style={{ backgroundColor: "#7886FF" }}
          className={styles.userScoreLine}
        ></div>
      </div>
    ) : null;

  const mappedSampleBreakdown = sampleBreakdown?.categoryData?.map((data) => {
    const color =
      periodicQuizSampleNameColourMap[
        data.slug as keyof typeof periodicQuizSampleNameColourMap
      ];
    if (data.averageUserScore) {
      return (
        <div
          style={{
            left: `calc(${data.averageUserScore * 100}% - 1px)`,
            height: "150px",
            zIndex: "20",
          }}
          className={styles.averageScoreDiv}
          key={data.slug}
        >
          <div
            style={{ backgroundColor: color }}
            className={styles.circle}
          ></div>
          <div
            style={{ backgroundColor: color }}
            className={styles.userScoreLine}
          ></div>
        </div>
      );
    }
  });

  return (
    <div className={styles.histogramSection}>
      <div className={styles.histogramWrapper}>
        {averageScore}
        {userScore}
        {mappedSampleBreakdown}
        {mapped}
      </div>
      <div className={styles.histogramLabelWrapper}>
        <p className={styles.histogramLabel}>0</p>
        <p className={styles.histogramLabel}>25</p>
        <p className={styles.histogramLabel}>50</p>
        <p className={styles.histogramLabel}>75</p>
        <p className={styles.histogramLabel}>100</p>
      </div>
    </div>
  );
}

function UnknownPeriodicResultBox() {
  const { removeModalByName, setModalComponent } = useModalContext();

  function handleClick() {
    setModalComponent(<PeriodicQuizModal elementName="PeriodicQuizModal" />);
    removeModalByName("PeriodicQuizResultModal");
  }

  return (
    <section className={styles.unknownSection}>
      <div className={styles.unknownBox}>
        <h1 className={styles.unknownBigText}>??</h1>
        <h4 className={styles.unknownScoreSmallText}>YOUR SCORE: UNKNOWN</h4>
        <button
          onClick={() => handleClick()}
          className={styles.unknownButtonTakeTheQuiz}
        >
          Take the quiz
        </button>
      </div>
    </section>
  );
}

function CollectingDataGhostBox() {
  return (
    <div className={styles.collectingGhostBoxSection}>
      <div className={styles.collectingGhostBox}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="31"
          viewBox="0 0 30 31"
          fill="none"
        >
          <path
            d="M1.44227 21.1466C0.120267 17.3 0.472467 13.0155 2.5001 9.43064C4.52773 5.93346 7.87628 3.48544 11.8434 2.69805L11.5793 0.686768L16.0748 3.13476L12.5492 6.89401L12.1969 4.88273C8.93522 5.58267 6.11534 7.59276 4.4409 10.4788C2.76667 13.5395 2.41326 17.124 3.55916 20.4456L1.44347 21.1456L1.44227 21.1466Z"
            fill="#FFFFF2"
          />
          <path
            d="M25.6814 25.5184C22.9493 28.6665 18.9821 30.5019 14.8401 30.5019C10.786 30.5019 6.99474 28.8411 4.26291 25.8679L2.85285 27.2666L2.14722 22.1958L7.2592 22.8084L5.84914 24.2944C8.14094 26.8298 11.4027 28.3157 14.839 28.3157C18.3646 28.3157 21.6263 26.7424 23.9181 24.1185L25.6804 25.5172L25.6814 25.5184Z"
            fill="#FFFFF2"
          />
          <path
            d="M17.3956 2.61182C21.5377 3.31176 25.0636 5.75987 27.0912 9.43176C29.1189 12.9289 29.5591 17.1253 28.3252 20.8857L29.9994 22.0224L25.0636 23.6831L24.6233 18.6123L26.2975 19.6616C27.2673 16.6009 26.8259 13.2796 25.1516 10.481C23.3893 7.50891 20.48 5.41025 16.9543 4.79787L17.3946 2.61191L17.3956 2.61182Z"
            fill="#FFFFF2"
          />
          <rect
            x="8.71216"
            y="14.9988"
            width="3.3062"
            height="5.66466"
            rx="0.752753"
            fill="#FFFFF2"
          />
          <rect
            x="13.2207"
            y="10.5266"
            width="3.3062"
            height="10.1368"
            rx="0.752753"
            fill="#FFFFF2"
          />
          <rect
            x="17.729"
            y="13.21"
            width="3.3062"
            height="7.45349"
            rx="0.752753"
            fill="#FFFFF2"
          />
        </svg>
        <p className={styles.collectingGhostBoxText}>
          Collecting more scores from other users. Come back to see how you
          accurately compare.
        </p>
      </div>
    </div>
  );
}

export function AverageUserScoreLabel() {
  return (
    <div
      style={{ justifyContent: "flex-start" }}
      className={styles.labelWrapper}
    >
      <div className={styles.labelDiv}>
        <div
          style={{ backgroundColor: "#7886FF" }}
          className={styles.circle}
        ></div>
        <p className={styles.legendText}>User average</p>
      </div>
    </div>
  );
}

function ConnectionScoresGhostBox() {
  return (
    <div
      style={{ marginTop: "5px", height: "70px" }}
      className={styles.collectingGhostBox}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
      >
        <path
          d="M11.3886 14.472C15.8827 14.472 19.6285 18.0251 19.6285 22.2882H23C23 16.2467 17.7576 11.6289 11.7629 11.6289C5.77217 11.6289 0.525879 16.6017 0.525879 22.2882H3.89741C3.14907 18.0213 6.89485 14.472 11.3888 14.472H11.3886Z"
          fill="#FFFFF2"
        />
        <path
          d="M7.64269 2.38857C7.64269 4.75862 3.89697 4.75862 3.89697 2.38857C3.89697 0.0223317 7.64269 0.0223317 7.64269 2.38857Z"
          fill="#FFFFF2"
        />
        <path
          d="M19.2538 2.38857C19.2538 4.75862 15.5081 4.75862 15.5081 2.38857C15.5081 0.0223317 19.2538 0.0223317 19.2538 2.38857Z"
          fill="#FFFFF2"
        />
      </svg>
      <p className={styles.collectingGhostBoxText}>
        No connections have shared their results yet.
      </p>
    </div>
  );
}

export function PeriodicQuizHistogramShareMode(props: {
  periodicQuiz: PeriodicQuiz;
  periodicQuizResult: PeriodicQuizResult;
}) {
  const [multiplier, setMultiplier] = useState<number>(0);

  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    let high = 0;
    if (props.periodicQuiz.sampleData?.histogramData) {
      props.periodicQuiz.sampleData.histogramData.forEach((point) => {
        if (point > high) {
          high = point;
        }
      });
    }
    setMultiplier(160 / (high * 100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapped = props.periodicQuiz.sampleData?.histogramData?.map(
    (point, index) => {
      return (
        <div
          className={styles.histogramBar}
          style={
            point === 0
              ? { height: `6px` }
              : { height: `${point * 100 * multiplier}px` }
          }
          key={index}
        ></div>
      );
    }
  );

  const userScore = (
    <div
      style={{
        left: `calc(${props.periodicQuizResult.percentScore * 100}% - 1px)`,
      }}
      className={styles.averageScoreDiv}
    >
      <div
        style={
          props
            ? {
                border: `3px solid ${
                  ProfileColorMap[
                    userState.publicProfileData?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                }`,
              }
            : { border: "3px solid #55504F" }
        }
        className={styles.userScoreCircleShare}
      >
        <Image priority fill src={userState.imageURL ?? ""} alt="" />
      </div>
      <div
        style={
          props
            ? {
                backgroundColor: `${
                  ProfileColorMap[
                    userState.publicProfileData?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                }`,
              }
            : { backgroundColor: "#55504F" }
        }
        className={styles.userScoreLine}
      ></div>
    </div>
  );

  const averageScore =
    props.periodicQuiz.sampleData?.averageUserScore &&
    props.periodicQuiz.sampleData.isFinalized ? (
      <div
        style={{
          left: `calc(${
            props.periodicQuiz.sampleData?.averageUserScore * 100
          }% - 1px)`,
          height: "150px",
          zIndex: "20",
        }}
        className={styles.averageScoreDiv}
      >
        <div
          style={{ backgroundColor: "#7886FF" }}
          className={styles.circle}
        ></div>
        <div
          style={{ backgroundColor: "#7886FF" }}
          className={styles.userScoreLine}
        ></div>
      </div>
    ) : null;

  return (
    <div className={styles.histogramSection}>
      <div className={styles.histogramWrapper}>
        {averageScore}
        {userScore}
        {mapped}
      </div>
      <div className={styles.histogramLabelWrapper}>
        <p className={styles.histogramLabel}>0</p>
        <p className={styles.histogramLabel}>25</p>
        <p className={styles.histogramLabel}>50</p>
        <p className={styles.histogramLabel}>75</p>
        <p className={styles.histogramLabel}>100</p>
      </div>
    </div>
  );
}

function useGetConnectionScores() {
  const { user } = useAuth();
  const quizState = useSelector((state: RootState) => state.periodicQuiz);
  const sharedPosts = useSelector(
    (state: RootState) => state.sharedPosts
  ).sharedPosts;

  const [sharedQuizResults, setSharedQuizResults] = useState<
    SharedPostWithCommentThread[]
  >([]);

  useEffect(() => {
    setSharedQuizResults([]);
    sharedPosts.forEach((post) => {
      if (
        post.postContent.periodicQuizData?.periodicQuiz.slug ===
          quizState.availableQuiz?.slug &&
        post.authorUid !== user?.uid
      ) {
        setSharedQuizResults((current) => [...current, post]);
      }
    });
  }, [quizState.availableQuiz?.slug, sharedPosts.length]);

  return sharedQuizResults;
}

function useIsPeriodicQuizShared(props: { userUid: string | undefined }) {
  const sharedPosts = useSelector(
    (state: RootState) => state.sharedPosts
  ).sharedPosts;
  const periodicQuiz = useSelector(
    (state: RootState) => state.periodicQuiz
  ).availableQuiz;

  const [foundPost, setFoundPost] =
    useState<SharedPostWithCommentThread | null>(null);

  useEffect(() => {
    const slug = `${props.userUid}-periodicQuiz-${periodicQuiz?.slug}`;
    const isFound = sharedPosts.find((post) => post.slug === slug);

    if (isFound) {
      setFoundPost(isFound);
    } else {
      setFoundPost(null);
    }
  }, [periodicQuiz?.slug, props.userUid, sharedPosts.length]);

  return foundPost;
}
