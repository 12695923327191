import React, { useState, TouchEvent } from "react";

import styles from "./AuthHeader.module.css";

import BackButton from "../buttons/BackButton";

export default function ModalAuthHeader(props: {
  title: string;
  subTitle?: string;
  step?: boolean;
  onBack?: () => void;
  downArrow?: boolean;
  noLine?: boolean;
  noCloser?: boolean;
}) {
  return (
    <div
      style={props.noLine ? { borderBottom: "none" } : {}}
      className={styles.mainModal}
    >
      {!props.noCloser && (
        <div
          className={props.downArrow ? styles.backDivDown : styles.backDivSmall}
        >
          <BackButton
            step={true}
            onBack={() => props.onBack && props.onBack()}
          />
        </div>
      )}
      <h1 className={styles.modalTitle}>{props.title}</h1>
      <h2 className={styles.subTitle}>{props.subTitle}</h2>
    </div>
  );
}
