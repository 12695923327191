import { useEffect, useState } from "react";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";
import { doc, getDoc } from "firebase/firestore";

import { db, firebaseStorage } from "@/_firebase/firebaseConfig";
import { getDownloadURL, ref } from "firebase/storage";
import { getDefaultProfilePhoto } from "@/context/utils";
import { FriendsPublicProfile } from "@/models/sharedModels";

export function useConnectionsAndImages(userUid: string | undefined) {
  const [connectionList, setConnectionList] = useState<PublicProfile[]>([]);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [connectionListWithFriendDetails, setConnectionListWithFriendDetails] =
    useState<FriendsPublicProfile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function handleConnections() {
      setConnectionList([]);
      setImageUrls([]);
      setConnectionListWithFriendDetails([]);
      let profilelist: PublicProfile[] = [];
      let extendedProfileList: FriendsPublicProfile[] = [];
      const reference = doc(db, `members/${userUid}/accessMaps/friends`);
      const document = await getDoc(reference);
      let nullsList: string[] = [];
      if (document.exists()) {
        let list: string[] = Object.keys(document.data().accessMap);
        for (let i = 0; i < list.length; i++) {
          let id = list[i];
          let profileRef = doc(db, "publicProfiles", id);
          const docSnap = await getDoc(profileRef);
          if (!docSnap.exists()) {
            let toAdd = id;
            nullsList.push(toAdd);
          } else {
            const docData = docSnap.data() as FriendsPublicProfile;
            docData.isClose = document.data().accessMap[id].isClose;
            docData.grantedOn = document.data().accessMap[id].grantedOn;
            extendedProfileList.push(docData);

            profilelist.push(docSnap.data() as PublicProfile);
          }
        }

        setConnectionList(profilelist);

        const result = list.filter((l) => {
          return !nullsList.includes(l);
        });

        let promises = result.map(async (id, index) => {
          try {
            const url = await getDownloadURL(
              ref(
                firebaseStorage,
                `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${id}/cropped.jpg`
              )
            );
            extendedProfileList[index].imageUrl = url;
            return url;
          } catch (err) {
            return await getDefaultProfilePhoto(id).then((value) => {
              extendedProfileList[index].imageUrl = value;
              return value;
            });
          }
        });
        setImageUrls(await Promise.all(promises));
        setConnectionListWithFriendDetails(extendedProfileList);
        setLoading(false);
      }
    }
    if (userUid) {
      handleConnections();
    }
  }, [userUid]);

  return {
    connectionList,
    imageUrls,
    connectionListWithFriendDetails,
    connectionsLoading: loading,
  };
}
