import { db } from "@/_firebase/firebaseConfig";
import { PollResponse } from "@dimensional-engineering/dimensional-models";
import { doc, getDoc } from "firebase/firestore";

export async function getUserPollResponse(
  slug: string,
  uid: string | undefined
): Promise<PollResponse | null> {
  const pollResponseRef = doc(db, `/members/${uid}/pollResponses/${slug}`);
  return await getDoc(pollResponseRef)
    .then((res) => {
      if (res.exists()) {
        const data = res.data() as PollResponse;
        return data;
      } else {
        return null;
      }
    })
    .catch((err) => {
      return null;
    });
}
