import { useEffect, useState } from "react";
import type { RootState } from "@/redux/store";
import { useSelector } from "react-redux";

import { useAuth } from "@/context/AuthContext";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import LoadingModal from "@/components/shared/LoadingModal/LoadingModal";
import OwnProfileModal from "../OwnProfileModal";
import ConnectedProfileModal from "../ConnectedProfileModal";
import UnConnectedProfileModal from "../UnConnectedProfileModal";
import useFriendRequestModalHook from "@/helpers/useFriendRequestModalHook";

export default function ProfileModalHandler(props: {
  uid: string | undefined;
  elementName: "profileModal";
}) {
  const { user } = useAuth();

  const [ownProfile, setOwnProfile] = useState<boolean | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (props.uid) {
      if (props.uid !== user?.uid) {
        setOwnProfile(false);
        if (userState.friendUidList.includes(props.uid as string)) {
          setIsConnected(true);
        } else {
          setIsConnected(false);
        }
      } else if (props.uid === user?.uid) {
        setOwnProfile(true);
      } else {
        setOwnProfile(null);
      }
    }
  }, [props.uid, user?.uid, userState.friendUidList.length]);

  useFriendRequestModalHook({ uid: props.uid });

  if (ownProfile === null) {
    return <LoadingModal elementName="LoadingModal" />;
  } else {
    if (ownProfile === true) {
      return (
        <ModalWrapper title="" noLine noHeader>
          <OwnProfileModal />
        </ModalWrapper>
      );
    } else {
      if (isConnected && props.uid) {
        return (
          <ModalWrapper title="" noLine noHeader>
            <ConnectedProfileModal uid={props.uid} />
          </ModalWrapper>
        );
      } else {
        if (props.uid) {
          return (
            <ModalWrapper title="" noLine noHeader>
              <UnConnectedProfileModal uid={props.uid} />
            </ModalWrapper>
          );
        } else {
          return <></>;
        }
      }
    }
  }
}
