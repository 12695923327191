import React from "react";

import styles from "./CircularScoreBar.module.css";

import { manipulateScorebarValue } from "../utils";

export default function CircularScoreBarLarge(props: {
  score: number | null | undefined;
  doubleQuestionMark?: boolean;
}) {
  const radius = 64.2; // Adjust as needed
  const circumference = 2 * Math.PI * radius;
  const openPercentage = props.score
    ? 100 - manipulateScorebarValue(props.score)
    : 100;

  const openLength = (circumference * openPercentage) / 100;
  const closedLength = circumference - openLength;

  const strokeDashoffset = closedLength;

  return (
    <div className={styles.main}>
      <div className={styles.mainCircleLarge}></div>
      <svg className={styles.innerLarge} width={radius * 2} height={radius * 2}>
        <circle
          cx={radius}
          cy={radius}
          r={radius - 5}
          fill="transparent"
          strokeWidth={6.5}
          stroke="#FFFFDE"
          strokeDasharray={`${closedLength} ${openLength}`}
          strokeDashoffset={strokeDashoffset}
          style={
            props.score !== null && props.score
              ? { strokeLinecap: "round" }
              : {}
          }
        />
      </svg>
      <p className={styles.scoreLarge}>
        {props.score !== null && props.score !== undefined
          ? `${props.score.toFixed(0)}`
          : "??"}
      </p>
    </div>
  );
}
