import { useRouter } from "next/router";

import styles from "./AuthAboutTab.module.css";

import SummaryTable from "@/components/publicProfiles/SummaryTable/SummaryTable";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import { DynamicProfileResultData } from "@/models/sharedModels";
import ConnectedArchetypes from "@/components/traits/arcehtype/Archetypes/ConnectedArchetypes";
import AuthMobileBioAndLinks from "./AuthMobileBioAndLinks/AuthMobileBioAndLinks";
import Loader from "@/components/shared/Loader";
import ResultsHeading from "@/components/results/ResultsHeading/ResultsHeading";
import { AnalysisSectionConnected } from "./AnalysisSection/AnalysisSection";
import Shimmer from "@/components/shared/Shimmer/Shimmer";

type Props = {
  data: DynamicProfileResultData;
  imageUrl?: string;
  connection:
    | "connected"
    | "close"
    | "deep"
    | "sent"
    | "unconnected"
    | "received"
    | null;
};

export default function AuthAboutTabConnected(props: Props) {
  const colorMap = ProfileColorMap;
  const { slug } = useRouter().query;

  return (
    <div className={styles.main}>
      <AuthMobileBioAndLinks
        imageUrl={props.imageUrl ?? null}
        bio={props.data.publicProfile?.bio!!}
        links={props.data.publicProfile?.socialMediaMap!!}
        publicProfile={props.data.publicProfile}
      />

      {props.data.summaryTableData ? (
        <SummaryTable
          notOwn
          auth
          publicProfile={props.data.publicProfile}
          imageUrl={props.imageUrl}
          natureColor={
            props.data.publicProfile?.primaryNature?.nature
              ? colorMap[props.data.publicProfile?.primaryNature?.nature]
              : null
          }
          data={props.data.summaryTableData}
        />
      ) : (
        <div style={{ width: "100%", padding: "0 2%" }}>
          <Shimmer height={350} />
        </div>
      )}

      {props.data.archetypeData ? (
        <ConnectedArchetypes
          archetypes={props.data.archetypeData}
          username={props.data.publicProfile?.userName}
          uid={slug as string}
          connection={props.connection}
          publicProfile={props.data.publicProfile}
        />
      ) : (
        <div style={{ marginTop: "40px" }}>
          <ResultsHeading title="Archetypes" />
          <div style={{ width: "100%", padding: "0 2%" }}>
            <Shimmer height={100} />
          </div>
        </div>
      )}

      <div className={styles.storiesDiv}>
        <ResultsHeading title="Analysis" />
        <AnalysisSectionConnected
          publicProfile={props.data.publicProfile}
          imageUrl={props.imageUrl ?? ""}
        />
      </div>
    </div>
  );
}

type PropsModal = {
  data: DynamicProfileResultData;
  imageUrl?: string;
  connection:
    | "connected"
    | "close"
    | "deep"
    | "sent"
    | "unconnected"
    | "received"
    | null;
  uid: string;
};

export function AuthAboutTabConnectedModal(props: PropsModal) {
  const colorMap = ProfileColorMap;

  return (
    <div className={styles.main}>
      <AuthMobileBioAndLinks
        imageUrl={props.imageUrl ?? null}
        bio={props.data.publicProfile?.bio!!}
        links={props.data.publicProfile?.socialMediaMap!!}
        publicProfile={props.data.publicProfile}
      />

      {props.data.summaryTableData ? (
        <SummaryTable
          notOwn
          auth
          publicProfile={props.data.publicProfile}
          imageUrl={props.imageUrl}
          natureColor={
            props.data.publicProfile?.primaryNature?.nature
              ? colorMap[props.data.publicProfile?.primaryNature?.nature]
              : null
          }
          data={props.data.summaryTableData}
        />
      ) : (
        <div style={{ width: "100%", padding: "0 2%" }}>
          <Shimmer height={350} />
        </div>
      )}

      {props.data.archetypeData ? (
        <ConnectedArchetypes
          archetypes={props.data.archetypeData}
          username={props.data.publicProfile?.userName}
          uid={props.uid}
          connection={props.connection}
          publicProfile={props.data.publicProfile}
        />
      ) : (
        <div style={{ marginTop: "40px" }}>
          <ResultsHeading title="Archetypes" />
          <div style={{ width: "100%", padding: "0 2%" }}>
            <Shimmer height={100} />
          </div>
        </div>
      )}

      <div className={styles.storiesDiv}>
        <ResultsHeading title="Analysis" />
        <AnalysisSectionConnected
          publicProfile={props.data.publicProfile}
          imageUrl={props.imageUrl ?? ""}
        />
      </div>
    </div>
  );
}
