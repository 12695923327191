import { useEffect, useState } from "react";
import { getDownloadURL, ref } from "firebase/storage";

import styles from "../../../styles/app/AppProfile.module.css";

import AuthLeftBar from "../AuthLeftBar/AuthLeftBar";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import { getDefaultProfilePhoto } from "@/context/utils";
import { useConnectionsAndImages } from "@/helpers/useConnectionsAndImages";
import { firebaseStorage } from "@/_firebase/firebaseConfig";
import useUnConnectedDynamicProfileData from "@/helpers/useUnconnectedDynamicProfileData";
import ResultsHeader from "@/components/shared/AuthenticatedHeader/ResultsHeader";
import AuthRightBarUnconnectedModal from "../AuthRightBar/AuthRightBarUnconnectedModal";
import { useModalContext } from "@/context/ModalContext";

export default function UnConnectedProfileModal(props: { uid: string }) {
  const profileData = useUnConnectedDynamicProfileData({
    uid: props.uid,
  });

  const { modalComponent } = useModalContext();

  const [nonOwnerImageUrl, setNonOwnerImageUrl] = useState<string | null>(null);
  const { connectionListWithFriendDetails } = useConnectionsAndImages(
    props.uid
  );

  const natureColor = profileData.publicProfile?.primaryNature
    ? ProfileColorMap[profileData.publicProfile.primaryNature?.nature]
    : "#55504F";

  useEffect(() => {
    getDownloadURL(
      ref(
        firebaseStorage,
        `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${props.uid}/cropped.jpg`
      )
    )
      .then((url) => {
        setNonOwnerImageUrl(url);
      })
      .catch(async (err) => {
        return await getDefaultProfilePhoto(props.uid).then((value) => {
          return setNonOwnerImageUrl(value);
        });
      });
  }, [profileData, props.uid]);

  return (
    <>
      <div className={styles.main}>
        <ResultsHeader
          underline
          title={
            profileData.publicProfile?.name
              ? `${profileData.publicProfile?.name ?? ""}`
              : `@${profileData.publicProfile?.userName ?? ""}`
          }
          downArrow={modalComponent.length < 2 ? true : false}
        />
        <div className={styles.barContainerOwn}>
          <AuthLeftBar
            connectionList={connectionListWithFriendDetails}
            imageUrl={nonOwnerImageUrl}
            publicProfile={profileData.publicProfile}
            ownProfile={false}
          />

          <AuthRightBarUnconnectedModal
            data={profileData}
            ownProfile={false}
            natureColor={natureColor}
            imageUrl={nonOwnerImageUrl ?? ""}
            uid={props.uid}
          />
        </div>
      </div>
    </>
  );
}
