import { useEffect, useState } from "react";
import { SummaryRowValue } from "@dimensional-engineering/dimensional-models";

import styles from "./SummaryTable.module.css";

type ValueProps = {
  value: SummaryRowValue[] | undefined;
};

export default function TopStrengthValue(props: ValueProps) {
  const [string, setString] = useState<string>("");
  const [permission, setPermission] = useState<boolean>(true);

  useEffect(() => {
    let str = "";
    if (props.value) {
      if (props.value[0].text === "Connection Required") {
        setPermission(false);
      } else {
        setPermission(true);
      }
      for (let i = 0; i < props.value?.length; i++) {
        if (i !== props.value.length - 1) {
          str += `${props.value[i].text}, `;
        } else {
          str += `${props.value[i].text}`;
        }
      }
    } else {
      setString("?");
    }
    setString(str);
  }, [props.value]);

  if (permission === true) {
    return <p className={styles.topStrengthsValue}>{string}</p>;
  } else {
    return <p className={styles.summaryTableValue}>Connection Required</p>;
  }
}
