import { useEffect, useRef, useState } from "react";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import {
  CommentReply,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";

import styles from "./CommentThread.module.css";

import useGetPublicProfile from "@/helpers/useGetPublicProfile";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import CommentLikeIcon from "@/components/shared/icons/CommentLikeIcon";
import CommentUnLikeIcon from "@/components/shared/icons/CommentUnLikeIcon";
import { CommentWithReply } from "@/models/sharedModels";
import { getPublicProfileAnImageUrlDataFromUid } from "../../utils";
import { fetchMoreReplies, likeOrUnlikeComment } from "../utils";
import { useAuth } from "@/context/AuthContext";
import CommentThreadReply from "./CommentThreadReply";
import { RootState } from "@/redux/store";
import { Mixpanel } from "@/helpers/mixpanel";
import { convertTimestampToTimeSincePosting } from "../../ABSTRACTS/AppHomeSharedTab/utils";

type Props = {
  comment: CommentWithReply;
  onReply: (
    username: string,
    parentCommentId: string,
    authorId: string
  ) => void;
  onDelete: (
    commentThreadId: string,
    commentId: string,
    body: string,
    replyId?: string | null | undefined
  ) => void;
  source:
    | "daily insight"
    | "daily poll"
    | "daily story"
    | "daily quiz"
    | "daily community question"
    | "posted daily insight"
    | "posted daily quiz"
    | "posted daily story";
  slug: string;
  audienceGroupSize: number;
  captionAuthorUid?: string;
};

export interface ResolvedReply {
  replyData: [string, PublicProfile | null];
  replyFormatted: string;
  reply: CommentReply;
}

export default function CommentThread(props: Props) {
  const { user } = useAuth();

  const [replies, setReplies] = useState<ResolvedReply[]>([]);
  const [loadingComments, setLoadingComments] = useState<boolean>(false);
  const [upvoteCount, setUpvoteCount] = useState<number>(0);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [hideMoreRepliesButton, sethideMoreRepliesButton] =
    useState<boolean>(false);

  const commentThreadId = useSelector(
    (state: RootState) => state.comments
  ).commentThreadId;

  const commentScore = useCommentVoteData(props.comment.id);

  const lastReplyRef = useRef<
    QueryDocumentSnapshot<DocumentData> | null | undefined
  >(null);
  const mainRef = useRef<HTMLDivElement | null>(null);
  const editBoxRef = useRef<HTMLDivElement | null>(null);
  const heightRef = useRef<HTMLDivElement | null>(null);

  const [touchStartX, setTouchStartX] = useState<number | null>(null);

  useEffect(() => {
    setUpvoteCount(props.comment.upvoteCount ?? 0);
  }, [props.comment.upvoteCount]);

  useEffect(() => {
    if (props.comment.searchIndex && props.comment.searchIndex < -10) {
      setIsHidden(true);
    }
  }, [props.comment.searchIndex]);

  useEffect(() => {
    lastReplyRef.current = props.comment.lastReply;
  }, [props.comment.lastReply?.id]);

  const publicProfile = useGetPublicProfile(props.comment.author);
  const profileImage = useGetProfilePhoto(props.comment.author);
  const commentDateFormatted = convertTimestampToTimeSincePosting(
    props.comment.createdOn
  );

  useEffect(() => {
    const fetchReplies = async () => {
      if (!props.comment.replies) return;

      const resolvedReplies = await Promise.all(
        props.comment.replies.map(async (reply) => {
          const replyData = await getPublicProfileAnImageUrlDataFromUid(
            reply.author
          );
          const replyFormatted = convertTimestampToTimeSincePosting(
            reply.createdOn
          );

          return {
            replyData,
            replyFormatted,
            reply,
          };
        })
      );

      setReplies(resolvedReplies);
    };

    fetchReplies();
  }, [props.comment.id, props.comment.replies?.length]);

  async function loadMoreReplies(reply: CommentReply) {
    setLoadingComments(true);

    if (props.comment.lastReply && lastReplyRef.current) {
      await fetchMoreReplies(
        lastReplyRef.current,
        props.comment.threadId,
        reply.parentCommentId
      )
        .then((res) => {
          if (res.resolvedReplies.length < 10) {
            sethideMoreRepliesButton(true);
          }
          setLoadingComments(false);
          res.resolvedReplies.forEach((reply) => {
            const found = replies.find((r) => r.reply.id === reply.reply.id);
            if (!found) {
              setReplies((current) => [...current, reply]);
            }
          });
          lastReplyRef.current = res.lastReply;
        })
        .catch((err) => {
          console.log(err);
          setLoadingComments(false);
        });
    } else {
      console.log(props.comment);

      console.log("No hit", props.comment.lastReply, lastReplyRef.current);
      setTimeout(() => {
        setLoadingComments(false);
      }, 2000);
    }
  }

  const mappedReplies = replies?.map((reply, index, a) => {
    return (
      <CommentThreadReply
        key={reply.reply.id}
        reply={reply}
        index={index}
        array={a}
        loadingComments={loadingComments}
        onReply={(username, parentCommentId, authorId) => {
          props.onReply(username, parentCommentId, authorId);
        }}
        onLoadMoreReplies={(r) => loadMoreReplies(r)}
        onDelete={(commentThreadId, commentID, body, replyId) => {
          props.onDelete(commentThreadId, commentID, body, replyId);
        }}
        source={props.source}
        slug={props.slug}
        audienceGroupSize={props.audienceGroupSize}
        hideRepliesButton={hideMoreRepliesButton}
        captionAuthorUid={props.captionAuthorUid}
      />
    );
  });

  const handleTouchStart = (e: React.TouchEvent) => {
    if (props.comment.author !== user?.uid) {
      return;
    }
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!touchStartX || !editBoxRef.current) return;

    const currentX = e.touches[0].clientX;
    const deltaX = currentX - touchStartX;

    if (deltaX < -100) {
      editBoxRef.current.style.width = "50px";
      editBoxRef.current.style.height = `${heightRef.current?.offsetHeight}px`;
      editBoxRef.current.style.marginLeft = "17px";
    } else {
      editBoxRef.current.style.width = `0px`;
      editBoxRef.current.style.marginLeft = "0";
    }
  };

  const handleTouchEnd = () => {
    setTouchStartX(null);
  };

  return (
    <div ref={mainRef} className={styles.wholeThread}>
      <div className={styles.infoAndEditBoxWrapper}>
        <div className={styles.wrapper}>
          <div
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
            onTouchStart={handleTouchStart}
            ref={heightRef}
            className={styles.main}
          >
            <SmallProfileImage
              publicProfile={publicProfile}
              imageUrl={profileImage}
            />
            <div className={styles.rightDiv}>
              <div className={styles.commentMetaDiv}>
                <p className={styles.commentMeta}>
                  @{publicProfile?.userName} - {commentDateFormatted}
                </p>
                {props.captionAuthorUid === props.comment.author && (
                  <p className={styles.captionAuthorTag}>Author</p>
                )}
              </div>
              {props.comment.isDeleted ? (
                <p className={styles.commentMeta}>~Comment deleted~</p>
              ) : isHidden ? (
                <p
                  onClick={() => setIsHidden(false)}
                  className={styles.commentMeta}
                >
                  Downvoted comment. Tap to reveal.
                </p>
              ) : (
                <p className={styles.commentBody}>{props.comment.body}</p>
              )}
              <div
                style={props.comment.isDeleted ? { opacity: "0.3" } : {}}
                className={styles.bottom}
              >
                <p
                  onClick={() => {
                    if (publicProfile?.userName && !props.comment.isDeleted) {
                      props.onReply(
                        publicProfile?.userName,
                        props.comment.id,
                        props.comment.author
                      );
                    }
                  }}
                  className={styles.replyText}
                >
                  Reply
                </p>
                <div className={styles.bottomRight}>
                  <div
                    onClick={() => {
                      if (commentThreadId && !props.comment.isDeleted) {
                        const score = commentScore === 1 ? 0 : 1;

                        commentScore === 1 //liked
                          ? setUpvoteCount((current) => current - 1)
                          : setUpvoteCount((current) => current + 1);

                        likeOrUnlikeComment(
                          user?.uid,
                          props.comment.id,
                          commentThreadId,
                          score
                        ).then((res) => {
                          console.log("Comment is liked");
                          Mixpanel?.track("Comment voted", {
                            type: "root comment",
                            source: props.source,
                            slug: props.slug,
                            body: props.comment.body,
                            score: 1,
                          });
                        });
                      }
                    }}
                    className={styles.likeDiv}
                  >
                    <CommentLikeIcon liked={commentScore === 1} />
                    <p>{upvoteCount < 0 ? 0 : upvoteCount}</p>
                  </div>
                  <div
                    className={styles.unlikeDiv}
                    onClick={() => {
                      if (commentThreadId && !props.comment.isDeleted) {
                        const score = commentScore === -1 ? 0 : -1;

                        commentScore === -1
                          ? setUpvoteCount((current) => current + 1)
                          : setUpvoteCount((current) => current - 1);

                        likeOrUnlikeComment(
                          user?.uid,
                          props.comment.id,
                          commentThreadId,
                          score
                        ).then((res) => {
                          Mixpanel?.track("Comment voted", {
                            type: "root comment",
                            source: props.source,
                            slug: props.slug,
                            body: props.comment.body,
                            score: -1,
                          });
                          console.log("Comment is unliked");
                        });
                      }
                    }}
                  >
                    <CommentUnLikeIcon active={commentScore === -1} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={editBoxRef} className={styles.editBoxParent}>
          <CommentThreadDeleteAndEditBox
            onDelete={() => {
              if (commentThreadId)
                props.onDelete(
                  commentThreadId,
                  props.comment.id,
                  props.comment.body
                );
            }}
          />
        </div>
      </div>
      {mappedReplies.length ? (
        <div className={styles.repliesWrapper}>{mappedReplies}</div>
      ) : null}
    </div>
  );
}

export function CommentThreadDeleteAndEditBox(props: { onDelete: () => void }) {
  return (
    <div onClick={() => props.onDelete()} className={styles.editBox}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M15.7783 5.50776C15.8125 5.64155 15.9502 5.74995 16.0879 5.74995H18.7499C19.2333 5.74995 19.6249 6.14155 19.6249 6.62495C19.6249 7.02438 19.3573 7.3603 18.993 7.46578L18.536 20.0323C18.4989 21.0547 17.6483 21.8751 16.6229 21.8751H8.37692C7.35347 21.8751 6.50095 21.0538 6.46385 20.0323L6.00683 7.46578C5.64257 7.36031 5.375 7.02438 5.375 6.62495C5.375 6.14155 5.7666 5.74995 6.25 5.74995H8.912C9.0497 5.74995 9.18838 5.6396 9.22158 5.50776L9.4081 4.75874C9.63954 3.83296 10.5458 3.12494 11.4989 3.12494H13.5009C14.455 3.12494 15.3602 3.83294 15.5917 4.75874L15.7783 5.50776ZM11.875 9.75002V18.25C11.875 18.5947 12.1553 18.875 12.5 18.875C12.8448 18.875 13.125 18.5947 13.125 18.25V9.75002C13.125 9.40529 12.8448 9.12502 12.5 9.12502C12.1553 9.12502 11.875 9.40529 11.875 9.75002ZM9.00003 9.76857L9.25003 18.2686C9.26077 18.6133 9.54885 18.8848 9.89358 18.875C10.2383 18.8643 10.5098 18.5762 10.5 18.2315L10.25 9.73147C10.2393 9.38674 9.9512 9.11527 9.60648 9.12502C9.26175 9.13576 8.99028 9.42384 9.00003 9.76857ZM14.75 9.73146L14.5 18.2315C14.4903 18.5762 14.7618 18.8643 15.1065 18.875C15.4512 18.8848 15.7393 18.6133 15.75 18.2686L16 9.76856C16.0098 9.42384 15.7383 9.13576 15.3936 9.12501C15.0489 9.11524 14.7608 9.38673 14.75 9.73146ZM11.0888 5.74996H13.911C13.9794 5.74996 14.0224 5.69625 14.0058 5.62984L13.8934 5.18257C13.8573 5.03608 13.6513 4.87494 13.5009 4.87494H11.4989C11.3485 4.87494 11.1425 5.03607 11.1063 5.18257L10.994 5.62984C10.9774 5.69722 11.0204 5.74996 11.0888 5.74996Z"
          fill="#FFFFF2"
        />
      </svg>
    </div>
  );
}

function useCommentVoteData(commentId: string) {
  const [commentScore, setCommentScore] = useState<number | null>(null);

  const commentThreadId = useSelector(
    (state: RootState) => state.comments
  ).commentThreadId;
  const commentVotes = useSelector(
    (state: RootState) => state.comments
  ).commentVotes;

  useEffect(() => {
    const found = commentVotes.find((c) => c.commentId === commentId);

    if (found && commentThreadId && !found.replyId) {
      setCommentScore(found.voteScore);
    }
  }, [commentVotes]);

  return commentScore;
}
