/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import { Archetype as ArchetypeModelFromNPM } from "@dimensional-engineering/dimensional-models";

import styles from "./Archetypes.module.css";

import { AppArchetype } from "./Archetype";
import { ArchetypeUnknownApp } from "./ArchetypeUnknown";
import { useModalContext } from "@/context/ModalContext";
import ArchetypeModal from "../../TraitsModals/Archetype/ArchetypeModal";
import ResultsHeading from "@/components/results/ResultsHeading/ResultsHeading";

type ArchetypeModel = {
  dimensionSlug: string;
  archetypeScore: number;
  archetypeSlug: string;
  archetype: ArchetypeModelFromNPM;
};

type ArchetypesProps = {
  archetypes: ArchetypeModel[];
  username: string | undefined;
  uid: string | null;
};

export default function Archetypes(props: ArchetypesProps) {
  const leftOutArchs = useArchetypeLeftOutData(props);
  const { setModalComponent } = useModalContext();

  const mapped = props.archetypes
    .filter((a) => a.archetype.archetypeGroupSlug !== "spirit")
    .map((arch, index) => {
      return (
        <div
          onClick={() =>
            setModalComponent(<ArchetypeModal slug={arch.archetypeSlug} />)
          }
          style={{ cursor: "pointer" }}
          key={index}
        >
          <AppArchetype {...arch} />
        </div>
      );
    });

  const nonDiscoveredMap = leftOutArchs.map((l, i) => {
    return <ArchetypeUnknownApp key={i} locked={false} name={l} />;
  });

  return (
    <div className={styles.archetypesMain}>
      <ResultsHeading title="Archetypes" />
      <div className={styles.archetypesParent}>
        {props.archetypes && mapped}
        {nonDiscoveredMap}
      </div>
    </div>
  );
}

function useArchetypeLeftOutData(props: ArchetypesProps) {
  const [leftOutArchs, setLeftOutArchs] = useState<string[]>([]);

  useEffect(() => {
    let has = [];
    let leftOut = [];

    for (let i = 0; i < props.archetypes.length; i++) {
      has.push(props.archetypes[i].dimensionSlug);
    }
    if (!has.includes("values")) {
      leftOut.push("values");
    }
    if (!has.includes("identity")) {
      leftOut.push("cognition");
    }
    if (!has.includes("love")) {
      leftOut.push("love");
    }
    if (!has.includes("work")) {
      leftOut.push("work");
    }
    if (!has.includes("career")) {
      leftOut.push("career");
    }

    setLeftOutArchs(leftOut);
  }, [props.archetypes]);

  return leftOutArchs;
}
