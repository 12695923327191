import styles from "./AuthRightBar.module.css";

import SwipeTabs from "@/components/shared/SwipeTabs/SwipeTabs";
import { DynamicProfileResultData } from "@/models/sharedModels";
import { AuthNonOwnerAboutTabModal } from "../tabs/AuthAboutTab/AuthAboutTabUnconnected";
import { AuthCompareTabModal } from "../tabs/AuthCompareTab/AuthCompareTab";

type Props = {
  natureColor: string | null;
  ownProfile: boolean;
  data: DynamicProfileResultData;
  imageUrl?: string;
  uid: string;
};

export default function AuthRightBarUnconnectedModal(props: Props) {
  return (
    <div className={styles.main}>
      <SwipeTabs
        tabs={["About", "Compare"]}
        content={[
          <AuthNonOwnerAboutTabModal
            data={props.data}
            key={1}
            imageUrl={props.imageUrl}
            uid={props.uid}
          />,
          <AuthCompareTabModal
            otherPublicProfile={props.data.publicProfile}
            imageUrl={props.imageUrl}
            data={props.data}
            key={2}
            uid={props.uid}
          />,
        ]}
        grow={true}
        onProfile
        sticky
      />
    </div>
  );
}
