import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";

import styles from "./TraitScoreBar.module.css";

import DownArrowIcon from "@/components/shared/icons/DownArrowIcon";
import UknownQuestionMarkIcon from "@/components/shared/icons/UknownQuestionMarkIcon";
import UpArrowIcon from "@/components/shared/icons/UpArrowIcon";
import { RootState } from "@/redux/store";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";

export default function TraitScoreBar(props: {
  score: number | undefined | null;
  friendPublicProfile?: PublicProfile | null;
  nonOwner?: boolean;
}) {
  const userState = useSelector((state: RootState) => state.user);
  const [scoreText, setScoreText] = useState<
    "Very low" | "Low" | "Average" | "High" | "Very high"
  >();
  const friendImageUrl = useGetProfilePhoto(
    props.friendPublicProfile?.ownerUUID
  );

  useEffect(() => {
    if (props.score) {
      if (props.score <= 12) {
        setScoreText("Very low");
      } else if (props.score > 12 && props.score <= 29) {
        setScoreText("Low");
      } else if (props.score > 29 && props.score <= 69) {
        setScoreText("Average");
      } else if (props.score > 69 && props.score <= 88) {
        setScoreText("High");
      } else {
        setScoreText("Very high");
      }
    }
  }, [props.score]);

  return (
    <div className={styles.main}>
      {props.nonOwner ? (
        <SmallProfileImage
          publicProfile={props.friendPublicProfile ?? null}
          imageUrl={friendImageUrl}
        />
      ) : (
        <SmallProfileImage
          publicProfile={userState.publicProfileData}
          imageUrl={userState.imageURL ?? ""}
        />
      )}
      {props.score && (
        <>
          <div className={styles.scoreDiv}>
            <h3 className={styles.scoreText}>
              {props.nonOwner ? "Their score" : "Self-rated score"}
            </h3>
            <div className={styles.scoreWrapper}>
              <div
                style={{ marginLeft: "4" }}
                className={styles.selected}
              ></div>
              <div
                className={
                  props.score > 12 ? styles.selected : styles.unselected
                }
              ></div>
              <div
                className={
                  props.score > 30 ? styles.selected : styles.unselected
                }
              ></div>
              <div
                className={
                  props.score > 70 ? styles.selected : styles.unselected
                }
              ></div>
              <div
                className={
                  props.score > 88 ? styles.selected : styles.unselected
                }
              ></div>
            </div>
          </div>
          <div className={styles.scoreDescDiv}>
            <h4>{scoreText}</h4>
            <div className={styles.iconDiv}>
              {scoreText === "Very low" && (
                <>
                  <div className={styles.arrow}>
                    <DownArrowIcon />
                  </div>
                  <div className={styles.arrow}>
                    <DownArrowIcon />
                  </div>
                </>
              )}
              {scoreText === "Low" && (
                <div className={styles.arrow}>
                  <DownArrowIcon />
                </div>
              )}
              {scoreText === "Average" && (
                <div className={styles.neutral}></div>
              )}
              {scoreText === "High" && (
                <div className={styles.arrow}>
                  <UpArrowIcon />
                </div>
              )}
              {scoreText === "Very high" && (
                <>
                  <div className={styles.arrow}>
                    <UpArrowIcon />
                  </div>
                  <div className={styles.arrow}>
                    <UpArrowIcon />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      {!props.score && (
        <>
          <div className={styles.scoreDiv}>
            <h3 className={styles.scoreText}>
              {props.nonOwner ? "Their score" : "Your score"}
            </h3>
            <div className={styles.scoreWrapper}>
              <div
                style={{ marginLeft: "4" }}
                className={styles.unselected}
              ></div>
              <div className={styles.unselected}></div>
              <div className={styles.unselected}></div>
              <div className={styles.unselected}></div>
              <div className={styles.unselected}></div>
              <div className={styles.restrictIcon}>
                <UknownQuestionMarkIcon />
              </div>
            </div>
          </div>
          <div className={styles.scoreDescDiv}>
            <h4>Unknown</h4>
          </div>
        </>
      )}
    </div>
  );
}
