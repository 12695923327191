import styles from "./PostToConnectionsButton.module.css";

import { PostIconDark } from "@/components/results/Snippets/SnippetInterractionBar/SnippetInterractionBar";

type Props = {
  posted: boolean;
  onClick: () => void;
};

export default function PostToConnectionsButton(props: Props) {
  const viewSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M1.51494 15.3517H13.2019C13.6609 15.3517 14.0367 14.9759 14.0367 14.5169V7.83868H12.3671V13.6822H2.34973V3.66477H8.1932V1.9952H1.51494C1.05598 1.9952 0.680161 2.37102 0.680161 2.82998V14.5169C0.680161 14.9759 1.05598 15.3517 1.51494 15.3517Z"
        fill="#55504F"
      />
      <path
        d="M7.12843 7.75457L8.29745 8.9236L13.7023 3.53905V5.35453H15.3718V0.700409H10.6975V2.36997H12.5129L7.12843 7.75457Z"
        fill="#55504F"
      />
    </svg>
  );

  if (props.posted) {
    return (
      <button onClick={() => props.onClick()} className={styles.btn}>
        {viewSvg} View shared post
      </button>
    );
  }

  return (
    <button className={styles.btn} onClick={() => props.onClick()}>
      <PostIconDark /> Post to connections
    </button>
  );
}
