export default function RateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
    >
      <rect
        opacity="0.3"
        x="4.25049"
        y="8.74976"
        width="7.5"
        height="3.75"
        rx="0.75"
        fill="#181716"
      />
      <rect
        opacity="0.7"
        x="13.2505"
        y="8.74976"
        width="7.5"
        height="3.75"
        rx="0.75"
        fill="#181716"
      />
      <rect
        opacity="0.9"
        x="22.2505"
        y="7.24951"
        width="8.25"
        height="6.75"
        rx="0.75"
        fill="#181716"
      />
      <path
        d="M24.2198 12.0731C23.3983 11.486 22.2759 11.7353 21.7218 12.5122L21.7218 12.5122L21.7178 12.5179L18.5431 17.0584C18.402 16.3467 17.8203 15.769 17.061 15.6706C16.0824 15.5099 15.1669 16.2019 15.0521 17.1842L15.047 17.2276V17.2335C15.0453 17.2473 15.0431 17.2654 15.0401 17.2884C15.0373 17.3108 15.0336 17.3401 15.0293 17.3739C15.0226 17.426 15.0146 17.4886 15.0065 17.5529C14.9784 17.7761 14.9407 18.0844 14.903 18.4373C14.8282 19.137 14.7505 20.0352 14.7505 20.802C14.7505 22.789 15.1365 24.0013 15.3588 24.5955L15.3591 24.5964C15.4773 24.9111 15.6262 25.2006 15.786 25.4428C15.9381 25.6733 16.1296 25.9061 16.3507 26.0642C16.4681 26.1482 17.0066 26.5294 17.5128 26.8875L18.1962 27.371L18.4162 27.5266L18.4774 27.5698L18.4935 27.5812L18.4954 27.5825C19.0645 27.9939 19.8233 28.0924 20.4844 27.8389L21.2697 27.5448C21.2703 27.5445 21.2709 27.5443 21.2715 27.5441C22.9468 26.925 24.4148 25.7338 25.4809 24.2414L25.4827 24.2388L26.6042 22.6545L26.6042 22.6546L26.6092 22.6474C27.09 21.9512 27.0686 20.9469 26.3904 20.3041C26.1582 20.0606 25.8793 19.9074 25.587 19.8349C25.5706 19.3859 25.389 18.9397 25.0183 18.5994C24.7748 18.3736 24.4927 18.2331 24.2006 18.1692C24.1806 17.7256 23.9989 17.2858 23.6326 16.9494C23.4984 16.8249 23.3524 16.7264 23.1995 16.6523L24.6606 14.568C25.245 13.7469 24.9956 12.6266 24.2198 12.0731ZM24.2198 12.0731C24.2199 12.0732 24.2201 12.0733 24.2202 12.0734L23.784 12.6834L24.2194 12.0728C24.2195 12.0729 24.2197 12.073 24.2198 12.0731ZM15.0504 17.2026C15.0505 17.2024 15.0503 17.2048 15.0496 17.2107C15.0501 17.2058 15.0504 17.2028 15.0504 17.2026Z"
        fill="#181716"
        stroke="#FFFFDE"
        strokeWidth="1.5"
      />
    </svg>
  );
}
