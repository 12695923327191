import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";

import styles from "./CommentAndAudiencePreviews.module.css";

import { RootState } from "@/redux/store";
import AudienceIcon from "@/components/shared/icons/AudienceIcon";
import { FriendsPublicProfile } from "@/models/sharedModels";
import { getPublicProfileAnImageUrlDataFromUid } from "../../utils";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import { SmallLoader } from "@/components/shared/Loader";

type Props = {
  audienceNumber: number | null | undefined;
  commentCount: number;
  all?: boolean;
  pollRepsonses?: number;
};

export function CommentAndAudiencePreviewsInsights(props: Props) {
  const { profiles, loading } = useGetPublicPRofilesAndImageFromRootComments();

  const mappedPhotos = profiles.slice(0, 5).map((profile) => {
    return (
      <SmallProfileImage
        publicProfile={profile}
        imageUrl={profile.imageUrl}
        key={profile?.ownerUUID}
      />
    );
  });

  const totalCount = props.commentCount - mappedPhotos.length;
  const dispalayCount = totalCount < 0 ? 0 : totalCount;

  return (
    <section className={styles.main}>
      <div className={styles.top}>
        <AudienceIcon />
        {props.all ? (
          <p className={styles.audienceText}>All Dimensional users</p>
        ) : (
          <p className={styles.audienceText}>
            <span className={styles.audienceTextBold}>
              {props.audienceNumber ?? 0}
            </span>{" "}
            users received this insight
          </p>
        )}
      </div>
      {!loading ? (
        mappedPhotos.length ? (
          <div className={styles.bottom}>
            <div className={styles.wrapper}>{mappedPhotos}</div>
            {props.pollRepsonses ? (
              <p className={styles.count}>{props.pollRepsonses} responses</p>
            ) : (
              <p className={styles.count}>+{dispalayCount} more</p>
            )}
          </div>
        ) : null
      ) : (
        <SmallLoader />
      )}
    </section>
  );
}

function useGetPublicPRofilesAndImageFromRootComments() {
  const commentsData = useSelector((state: RootState) => state.comments);

  const [profiles, setProfiles] = useState<FriendsPublicProfile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getProfiles() {
      let uids: Set<string> = new Set();
      commentsData.comments.forEach((c) => {
        uids.add(c.author);
      });
      let promises: Promise<[string, PublicProfile | null]>[] = [];

      uids.forEach(async (uid) => {
        promises.push(getPublicProfileAnImageUrlDataFromUid(uid));
      });
      const results = await Promise.all(promises);

      let tempProfiles: FriendsPublicProfile[] = [];

      results.forEach((res) => {
        const imageUrl = res[0];
        const pubProf: FriendsPublicProfile | null =
          res[1] as FriendsPublicProfile;
        pubProf.imageUrl = imageUrl;
        tempProfiles.push(pubProf);
      });
      tempProfiles.sort((a, b) => {
        if (a.hasPhoto !== b.hasPhoto) {
          return b.hasPhoto ? 1 : -1;
        }

        const aHasNature = a.primaryNature?.nature ? true : false;
        const bHasNature = b.primaryNature?.nature ? true : false;
        if (aHasNature !== bHasNature) {
          return bHasNature ? 1 : -1;
        }

        return 0;
      });
      setProfiles(tempProfiles);
      setLoading(false);
    }

    if (commentsData.loading === false) {
      getProfiles();
    }
  }, [commentsData.loading]);

  return { profiles, loading };
}
