import { useState } from "react";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import { DiscoveredStory } from "@dimensional-engineering/dimensional-models";
import { Roboto_Mono } from "next/font/google";

import styles from "../../../styles/app/AppResults.module.css";

import StoryCard from "./StoryCard";
import { storyColorMap } from "./storyColorMap";
import useGetDiscoveredStoriesSlugsHook from "../../../helpers/useGetDiscoveredStoriesSlugsHook";
import StoriesModal from "./StoriesModal";
import { useModalContext } from "@/context/ModalContext";
import DiscoveryGate from "../../shared/DiscoveryGate/DiscoveryGate";
import useIsMobile from "@/helpers/useIsMobile";
import { summonProfileLink } from "@/components/shared/utils";
import { useAuth } from "@/context/AuthContext";
import ForwardArrow from "@/components/shared/buttons/ForwardArrow";
import { onMoreClickExtended } from "@/components/appHome/utils";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function StoriesMap() {
  const stories = useSelector(
    (state: RootState) => state.user.discoverableStories
  );
  const discoveredStories = useSelector(
    (state: RootState) => state.user.discoveredStories
  );
  const discoveredSlugs = useGetDiscoveredStoriesSlugsHook();
  const { setModalComponent, setModalOpen } = useModalContext();
  const [expanded, setExpanded] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const { user } = useAuth();

  const allMapped = [
    stories?.filter((a) => !discoveredSlugs.includes(a.storySlug)),
    discoveredStories,
  ]
    .flat()
    .sort((a, b) => {
      if (
        a?.storySlug &&
        b?.storySlug &&
        discoveredSlugs.includes(a?.storySlug) &&
        !discoveredSlugs.includes(b?.storySlug)
      ) {
        return -1;
      } else if (
        a?.storySlug &&
        b?.storySlug &&
        !discoveredSlugs.includes(a?.storySlug) &&
        discoveredSlugs.includes(b?.storySlug)
      ) {
        return -1;
      } else {
        return 0;
      }
    })
    .slice(...(isMobile ? [] : expanded ? [] : [0, 8]))
    .map((story, index) => {
      if (
        story &&
        story?.storySlug &&
        discoveredSlugs.includes(story?.storySlug)
      ) {
        return (
          <div
            onClick={() => {
              setModalComponent(
                <StoriesModal story={story as DiscoveredStory} own={true} />
              );
            }}
            key={index}
          >
            <StoryCard
              imageSlug={`${story?.storySlug}.png`}
              title={story?.name}
              color={`${
                storyColorMap[story?.storySlug as keyof typeof storyColorMap] ??
                "#fffff2"
              }`}
              showReadMore
            />
          </div>
        );
      } else if (
        story?.storySlug &&
        !discoveredSlugs.includes(story?.storySlug)
      ) {
        return (
          <div
            key={index}
            onClick={() => {
              setModalComponent(
                <DiscoveryGate
                  {...story.discoveryGate}
                  onClose={() => setModalOpen(false)}
                  onOpenShareLink={() => {
                    summonProfileLink(
                      user?.uid,
                      "gating modal",
                      "Invite to Dimensional"
                    );
                  }}
                  fromStory
                />
              );
            }}
          >
            <StoryCard
              key={index}
              imageSlug={`${story?.storySlug}.png`}
              title={story?.name}
              color={`${
                storyColorMap[story?.storySlug as keyof typeof storyColorMap] ??
                "#fffff2"
              }`}
              showReadMore
              blackedOut={true}
              numSuccessfulInvitesRequired={
                story.discoveryGate.numSuccessfulInvitesRequired
              }
            />
          </div>
        );
      }
    });
  if (allMapped.length) {
    return (
      <>
        <div id="storiesWrapper" className={styles.storiesWrapper}>
          {allMapped}
        </div>
        <div
          onClick={() => onMoreClickExtended("storiesWrapper")}
          className={styles.moreButton}
        >
          <p className={`${styles.moreText} ${font.className}`}>More</p>
          <ForwardArrow />
        </div>
      </>
    );
  } else {
    return <div></div>;
  }
}
