import { useEffect, useState } from "react";
import {
  SummaryTable as SummaryTableType,
  Archetype,
  PublicProfile,
  DiscoveredStory,
  ProfileNutshell,
} from "@dimensional-engineering/dimensional-models";
import { doc, getDoc } from "firebase/firestore";
import { useSelector } from "react-redux";

import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { db } from "@/_firebase/firebaseConfig";
import { HighestScoringTraitsModel } from "@/models/sharedModels";
import { RootState } from "@/redux/store";

type ArchetypeDataModel = {
  dimensionSlug: string;
  archetypeScore: number;
  archetypeSlug: string;
  archetype: Archetype;
};

type useGetProfileDataProps = {
  uid: string | undefined;
};

export default function useConnectedDynamicProfileData(
  props: useGetProfileDataProps
) {
  const [archetypeData, setArchetypeData] = useState<
    null | ArchetypeDataModel[]
  >(null);
  const [archetypeLoading, setArchetypeLoading] = useState<boolean>(true);
  const [summaryTableData, setSummaryTableData] =
    useState<SummaryTableType | null>(null);
  const [summaryLoading, setSummaryLoading] = useState<boolean>(true);
  const [publicProfile, setPublicProfile] = useState<PublicProfile | null>(
    null
  );
  const [highestScoringTraits, setHighestScoringTraits] =
    useState<HighestScoringTraitsModel>();
  const [storiesLoading, setStoriesLoading] = useState<boolean>(true);
  const [stories, setStories] = useState<DiscoveredStory[]>([]);
  const [nutshellLoading, setNutshellLoading] = useState<boolean>(true);
  const [nutshell, setNutshell] = useState<string | undefined>(undefined);

  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    async function getUserProfileData() {
      const publicProfileRef = doc(db, "publicProfiles", props.uid as string);
      const document = await getDoc(publicProfileRef);
      let data: PublicProfile | null = null;
      if (document.exists()) {
        data = document.data() as PublicProfile;
      }
      if (data) {
        if (props.uid) {
          setPublicProfile(data);

          const callForHighestTraits = () => {
            const getHighestScores = callFirebaseFunctions(
              "fetchProfileHighestScoringTraits"
            );
            getHighestScores({ uid: props.uid })
              .then((res) => {
                setHighestScoringTraits(res.data as HighestScoringTraitsModel);
              })
              .catch((err) => {
                setHighestScoringTraits(undefined);
              });
          };

          callForHighestTraits();

          const getSummaryTable = callFirebaseFunctions("fetchSummaryTable");
          getSummaryTable({ uid: props.uid })
            .then((result) => {
              setSummaryTableData(result.data as SummaryTableType);
              setSummaryLoading(false);
            })
            .catch((err) => {
              console.log("summary table error:", err);
              setSummaryTableData(null);
              setSummaryLoading(false);
            });

          // const getProfileNutshell = callFirebaseFunctions(
          //   "fetchProfileNutshell"
          // );
          // getProfileNutshell({ uid: props.uid }).then((res) => {
          //   setNutshellLoading(false);
          //   const nutshellData = res.data as ProfileNutshell | null;
          //   setNutshell(nutshellData?.body);
          // });

          const getArchetypeData = callFirebaseFunctions(
            "fetchProfileArchetypes"
          );

          getArchetypeData({ uid: props.uid })
            .then((result) => {
              if (result.data) {
                setArchetypeData(result.data as ArchetypeDataModel[]);
              }
              setArchetypeLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setArchetypeData(null);
              setArchetypeLoading(false);
            });

          const getStories = callFirebaseFunctions(
            "fetchDiscoveredListicleStories"
          );
          getStories({ memberUUID: props.uid }).then((res) => {
            setStoriesLoading(false);
            setStories(res.data as DiscoveredStory[]);
          });
        }
      }
    }

    if (props.uid && userState.friendUidList.includes(props.uid)) {
      getUserProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.uid]);

  return {
    summaryTableData: summaryTableData,
    archetypeData: archetypeData,
    archetypeLoading: archetypeLoading,
    summaryLoading: summaryLoading,
    publicProfile: publicProfile,
    highestScoringTraits: highestScoringTraits,
    stories: stories,
    storiesLoading: storiesLoading,
    nutshellLoading: nutshellLoading,
    nutshell: nutshell,
  };
}
