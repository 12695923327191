import { useEffect, useState } from "react";
import Link from "next/link";
import { useSelector } from "react-redux";

import styles from "./AuthLeftBar.module.css";

import { RootState } from "@/redux/store";

export default function ProfileCompletion() {
  const { percentageComplete, nextStep, link } = useProfileCompletion();

  return (
    <>
      {percentageComplete < 100 && (
        <div className={styles.mainCompletion}>
          <p>PROFILE COMPLETION: {percentageComplete}%</p>
          <div className={styles.compBarMain}>
            <div
              className={styles.compBar}
              style={{ width: `${percentageComplete}%` }}
            ></div>
          </div>
          <Link href={link}>
            <h2 className={styles.nextStepText}>Next step: {nextStep}</h2>
          </Link>
        </div>
      )}
    </>
  );
}

export function useProfileCompletion() {
  const [percentageComplete, setPercentageComplete] = useState<number>(0);
  const [nextStep, setNextStep] = useState<string>("Add name");
  const [link, setLink] = useState<string>("");
  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (userState.publicProfileData?.name) {
      setPercentageComplete((current) => (current += 10));
    } else {
      setNextStep("Add name");
      setLink(`/app/settings/name`);
    }

    if (
      userState.cognitiveFinished === true &&
      userState.primaryFinished === true &&
      userState.valueFinished === true
    ) {
      setPercentageComplete((current) => (current += 45));
    } else {
      setNextStep("Complete Core Dimensions");
      setLink("/app/assessment");
    }

    if (userState.imageURL !== null) {
      setPercentageComplete((current) => (current += 20));
    } else {
      setNextStep("Add profile photo");
      setLink("/app/settings");
    }

    if (userState.publicProfileData?.bio) {
      setPercentageComplete((current) => (current += 20));
    } else {
      setNextStep("Add bio");
      setLink("/app/settings/bio");
    }

    const viewedPermissionsLS = localStorage.getItem("viewedPermissions");
    if (viewedPermissionsLS) {
      setPercentageComplete((current) => (current += 5));
    } else {
      setNextStep("Customize public profile");
      setLink("/app/settings/public-permissions");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { percentageComplete, nextStep, link };
}
