export default function AmbassadorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M6.95987 25.8409C7.60051 26.0816 8.00051 26.8003 7.83955 27.4816C7.11923 31.0409 10.5192 34.0408 13.9191 32.8816C14.5988 32.6409 15.3191 32.9613 15.6395 33.5612C17.3598 36.7612 21.8803 36.8409 23.7191 33.7612C24.0784 33.1612 24.8394 32.9206 25.4784 33.1612C28.88 34.4409 32.4004 31.6003 31.8408 28.0408C31.7205 27.3612 32.1611 26.6815 32.8002 26.4408C36.2002 25.2815 37.0798 20.8408 34.4002 18.4408C33.8799 17.9612 33.7596 17.2002 34.1205 16.6002C36.0002 13.5205 33.8408 9.52061 30.2408 9.44101C29.5205 9.44101 28.9611 8.92069 28.8408 8.20037C28.2815 4.60037 24.1204 2.95957 21.2816 5.24101C20.7223 5.68165 19.9613 5.68165 19.441 5.20038C16.7207 2.80038 12.4818 4.24102 11.7614 7.80038C11.6411 8.48006 11.002 9.00038 10.3207 8.95974C6.72074 8.88005 4.40034 12.7597 6.12074 15.9189C6.44105 16.5596 6.32074 17.3189 5.76136 17.7596C2.9598 20.0408 3.64111 24.5205 6.95987 25.8409ZM14.2007 19.7205C14.8413 19.0799 15.8803 19.0799 16.56 19.7205L18.0803 21.2408L24.1211 15.2C24.7618 14.5594 25.8008 14.5594 26.4805 15.2C27.1602 15.8407 27.1211 16.8797 26.4805 17.5594L19.2805 24.7594C18.9602 25.0797 18.5211 25.2391 18.1211 25.2391C17.6805 25.2391 17.2805 25.0797 16.9618 24.7594L14.2821 22.0797C13.5602 21.4406 13.56 20.4002 14.2007 19.7205Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
