import { SharedPostWithCommentThread } from "@/models/sharedModels";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type SharedPostSlice = {
  sharedPosts: SharedPostWithCommentThread[];
  isShareTabClicked: boolean;
  sharedPostsLoaded: boolean;
};

const initialState: SharedPostSlice = {
  sharedPosts: [],
  isShareTabClicked: false,
  sharedPostsLoaded: false,
};

export const sharedPosts = createSlice({
  name: "sharedPosts",
  initialState: initialState,
  reducers: {
    setSharedPosts: (
      state,
      action: PayloadAction<SharedPostWithCommentThread[]>
    ) => {
      return { ...state, sharedPosts: action.payload };
    },
    setIsShareTabClicked: (state, action: PayloadAction<boolean>) => {
      return { ...state, isShareTabClicked: action.payload };
    },
    setSharedPostsLoaded: (state, action: PayloadAction<boolean>) => {
      return { ...state, sharedPostsLoaded: action.payload };
    },
  },
});

export const { setSharedPosts, setIsShareTabClicked, setSharedPostsLoaded } =
  sharedPosts.actions;

export default sharedPosts.reducer;
