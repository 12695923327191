import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ContentType } from "@dimensional-engineering/dimensional-models";

import Loader from "../Loader";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { RootState } from "@/redux/store";
import { useModalContext } from "@/context/ModalContext";
import PeriodicQuizResultModal from "@/components/appHome/PeriodicQuizzes/PeriodicQuizResultModal/PeriodicQuizResultModal";
import PeriodicQuizModal from "@/components/appHome/PeriodicQuizzes/PeriodicQuizModal/PeriodicQuizModal";
import DailyStoriesModal from "@/components/appHome/DailyStories/DailyStoriesModal";
import DailyInsightsModal from "@/components/appHome/DailyInsights/DailyInsightsModal/DailyInsightsModal";
import UserPollResponseModal from "@/components/appHome/UserPolls/UserPollResponseModal/UserPollResponseModal";
import UserPollModal from "@/components/appHome/UserPolls/UserPollModal/UserPollModal";
import { getPublicProfileAnImageUrlDataFromUid } from "@/components/appHome/utils";
import { Mixpanel } from "@/helpers/mixpanel";
import SharedPostModal from "@/components/appHome/ABSTRACTS/AppHomeSharedTab/SharedPostModal/SharedPostModal";
import ExpirationModal from "../GeneralModal/ExpirationModal";
import { useAuth } from "@/context/AuthContext";
import { getUserPollResponse } from "./utils";
import CommunityQuestionsModal from "@/components/appHome/CommunityQuestions/CommunityQuestionsModal";

export default function LoadingModal(props: { elementName: "LoadingModal" }) {
  return (
    <ModalWrapper noLine title="">
      <Loader mobileHeight="500px" height="500px" />
    </ModalWrapper>
  );
}

type Props = {
  elementName: "NotificationLoadingModal";
  notificationType:
    | "viewPeriodicQuiz"
    | "periodicQuizResultShared"
    | "viewDeliveredDailyInsight"
    | "viewDeliveredDailyStoryNew"
    | "viewUserPoll"
    | "viewSharedPost"
    | "comment"
    | "viewCommunityQuestion";
  contentSlug?: string | null | undefined;
  commentContent?:
    | "periodicQuizResultShared"
    | "viewDeliveredDailyInsight"
    | "viewDeliveredDailyStoryNew"
    | "viewUserPoll"
    | "viewSharedPost"
    | "viewCommunityQuestion";
  commentId?: string;
};

export function NotificationLoadingModal(props: Props) {
  const { user } = useAuth();
  const { setModalComponent, removeModalByName } = useModalContext();

  const [modalDisplayed, setModalDisplayed] = useState(false);

  const quizState = useSelector((state: RootState) => state.periodicQuiz);
  const dailyInsightState = useSelector(
    (state: RootState) => state.dailyInsight
  );
  const dailyStoryState = useSelector((state: RootState) => state.dailyStory);
  const userPollState = useSelector((state: RootState) => state.userPoll);
  const sharedPosts = useSelector(
    (state: RootState) => state.sharedPosts
  ).sharedPosts;
  const communityQuestion = useSelector(
    (state: RootState) => state.communityQuestion
  ).communityQuestion;

  useEffect(() => {
    if (modalDisplayed) return; // Prevent redundant execution

    if (
      props.notificationType === "periodicQuizResultShared" ||
      props.notificationType === "viewPeriodicQuiz"
    ) {
      const foundQuizResult = quizState.periodicQuizResults?.find(
        (q) => q.periodicQuizSlug === quizState.availableQuiz?.slug
      );
      if (quizState.availableQuiz) {
        if (foundQuizResult) {
          setModalComponent(
            <PeriodicQuizResultModal
              periodicQuiz={quizState.availableQuiz}
              periodicQuizResult={foundQuizResult}
              elementName="PeriodicQuizResultModal"
            />
          );
        } else {
          setModalComponent(
            <PeriodicQuizModal elementName="PeriodicQuizModal" />
          );
        }
        removeModalByName("NotificationLoadingModal");
        setModalDisplayed(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.notificationType,
    quizState.availableQuiz,
    quizState.periodicQuizResults,
    modalDisplayed,
  ]);

  useEffect(() => {
    if (modalDisplayed) return;

    if (
      props.notificationType === "viewDeliveredDailyStoryNew" &&
      dailyStoryState.dailyStory &&
      dailyStoryState.dailyStory?.assignmentRuleset?.body &&
      dailyStoryState.dailyStory?.name
    ) {
      setModalComponent(<DailyStoriesModal elementName="DailyStory" />);
      removeModalByName("NotificationLoadingModal");
      setModalDisplayed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.notificationType,
    dailyStoryState.dailyStory,
    dailyStoryState.dailyStory?.assignmentRuleset?.body,
    dailyStoryState.dailyStory?.name,
    modalDisplayed,
  ]);

  useEffect(() => {
    if (modalDisplayed) return;

    if (
      props.notificationType === "viewDeliveredDailyInsight" &&
      dailyInsightState.deliveredDailyInsight
    ) {
      setModalComponent(
        <DailyInsightsModal elementName="DailyInsightsModal" />
      );
      removeModalByName("NotificationLoadingModal");
      setModalDisplayed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.notificationType,
    dailyInsightState.deliveredDailyInsight,
    dailyInsightState.insightDelivery,
    modalDisplayed,
  ]);

  useEffect(() => {
    if (modalDisplayed) return;

    if (
      props.notificationType === "viewUserPoll" &&
      userPollState.userPoll?.slug
    ) {
      getUserPollResponse(userPollState.userPoll.slug, user?.uid)
        .then((res) => {
          if (res !== null) {
            setModalComponent(
              <UserPollResponseModal elementName="UserPollResponseModal" />
            );
            removeModalByName("UserPollModal");
            removeModalByName("NotificationLoadingModal");
            setModalDisplayed(true);
          } else {
            setModalComponent(<UserPollModal elementName="UserPollModal" />);
            removeModalByName("NotificationLoadingModal");
            setModalDisplayed(true);
          }
        })
        .catch(() => {
          setModalComponent(<UserPollModal elementName="UserPollModal" />);
          removeModalByName("NotificationLoadingModal");
          setModalDisplayed(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.notificationType, userPollState.userPoll?.slug, modalDisplayed]);

  useEffect(() => {
    if (modalDisplayed) return;
    if (
      props.notificationType === "viewSharedPost" &&
      sharedPosts &&
      sharedPosts.length &&
      props.contentSlug
    ) {
      const userUid = props.contentSlug.split("-")[0];
      getPublicProfileAnImageUrlDataFromUid(userUid).then((res) => {
        const profileImage = res[0];
        const publicProfile = res[1];
        const post = sharedPosts.find((p) => p.slug === props.contentSlug);
        if (props.contentSlug && post) {
          Mixpanel?.track("Post viewed", {
            source: "from notification or feed",
            content_type:
              post.postContent.contentIdentifier.contentType ===
              ContentType.periodicQuiz
                ? "daily quiz"
                : post.postContent.contentIdentifier.contentType ===
                  ContentType.deliveredDailyStory
                ? "daily story"
                : "daily insight",
            content_type_slug:
              post.postContent.contentIdentifier.contentType ===
              ContentType.periodicQuiz
                ? post.postContent.periodicQuizData?.periodicQuiz.slug
                : post.postContent.contentIdentifier.contentType ===
                  ContentType.deliveredDailyStory
                ? post.postContent.dailyStoryData?.deliveredDailyStory
                    .computeableStoryModelSlug
                : post.postContent.dailyInsightData?.insightDelivery
                    .computeableInsightModelSlug,
            caption_text: post.caption,
          });
          setModalComponent(
            <SharedPostModal
              publicProfile={publicProfile}
              sharedPost={post}
              profileImage={profileImage}
              showComment={props.commentId ? true : false}
              commentId={props.commentId}
            />
          );
          removeModalByName("NotificationLoadingModal");
        } else {
          removeModalByName("NotificationLoadingModal");
          setModalComponent(
            <ExpirationModal copy="You're too late. This content and associated comments have expired" />
          );
        }
      });
      setModalDisplayed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.notificationType, props.contentSlug, sharedPosts.length]);

  useEffect(() => {
    if (modalDisplayed) return;
    if (
      props.notificationType === "comment" &&
      props.commentContent === "viewDeliveredDailyStoryNew" &&
      dailyStoryState.dailyStory &&
      dailyStoryState.dailyStory?.assignmentRuleset?.body &&
      dailyStoryState.dailyStory?.name &&
      props.commentId
    ) {
      setModalComponent(
        <DailyStoriesModal
          elementName="DailyStory"
          showComment
          commentId={props.commentId}
        />
      );
      removeModalByName("NotificationLoadingModal");
      return setModalDisplayed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dailyStoryState.dailyStory,
    dailyStoryState.dailyStory?.assignmentRuleset?.body,
    dailyStoryState.dailyStory?.name,
    props.commentContent,
    props.notificationType,
    props.commentId,
  ]);

  useEffect(() => {
    if (modalDisplayed) return;
    if (
      props.notificationType === "comment" &&
      props.commentContent === "viewDeliveredDailyInsight" &&
      dailyInsightState.deliveredDailyInsight &&
      props.commentId
    ) {
      setModalComponent(
        <DailyInsightsModal
          elementName="DailyInsightsModal"
          showComment
          commentId={props.commentId}
        />
      );
      removeModalByName("NotificationLoadingModal");
      return setModalDisplayed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dailyInsightState.deliveredDailyInsight,
    props.commentContent,
    props.notificationType,
    props.commentId,
  ]);

  useEffect(() => {
    if (modalDisplayed) return;
    if (
      props.notificationType === "comment" &&
      props.commentContent === "viewSharedPost" &&
      props.commentId
    ) {
      const foundPost = sharedPosts.find(
        (post) => post.slug === props.contentSlug
      );
      if (foundPost) {
        const userUid = props.contentSlug?.split("-")[0];
        getPublicProfileAnImageUrlDataFromUid(userUid)
          .then((profileImageAndPublicProfile) => {
            const profileImage = profileImageAndPublicProfile[0];
            const publicProfile = profileImageAndPublicProfile[1];
            Mixpanel?.track("Comment modal summoned", {
              source: props.contentSlug?.includes("periodicQuiz")
                ? "posted daily quiz"
                : props.contentSlug?.includes("deliveredDailyStory")
                ? "posted daily story"
                : "posted daily insight",
              type: "from notification or feed",
            });
            Mixpanel?.track("Post viewed", {
              source: "from notification or feed",
              content_type: props.contentSlug?.includes("periodicQuiz")
                ? "daily quiz"
                : props.contentSlug?.includes("deliveredDailyStory")
                ? "daily story"
                : "daily insight",
              content_type_slug: props.contentSlug,
              caption_text: foundPost.caption,
            });
            setModalComponent(
              <SharedPostModal
                publicProfile={publicProfile}
                sharedPost={foundPost}
                profileImage={profileImage}
                showComment
                commentId={props.commentId}
              />
            );
          })
          .catch(() => {
            setModalComponent(
              <ExpirationModal copy="You're too late. This content and associated comments have expired" />
            );
            removeModalByName("NotificationLoadingModal");
            setModalDisplayed(true);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.notificationType,
    props.commentContent,
    props.commentId,
    props.contentSlug,
    sharedPosts.length,
    modalDisplayed,
  ]);

  useEffect(() => {
    if (modalDisplayed) return;
    if (
      props.notificationType === "comment" &&
      props.commentContent === "periodicQuizResultShared" &&
      props.commentId
    ) {
      const foundQuizResult = quizState.periodicQuizResults?.find(
        (q) => q.periodicQuizSlug === quizState.availableQuiz?.slug
      );
      if (quizState.availableQuiz) {
        if (foundQuizResult) {
          setModalComponent(
            <PeriodicQuizResultModal
              periodicQuiz={quizState.availableQuiz}
              periodicQuizResult={foundQuizResult}
              elementName="PeriodicQuizResultModal"
              commentId={props.commentId}
              showComment
            />
          );
        } else {
          setModalComponent(
            <PeriodicQuizModal elementName="PeriodicQuizModal" />
          );
        }
        removeModalByName("NotificationLoadingModal");
        return setModalDisplayed(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.notificationType,
    props.commentContent,
    props.commentId,
    props.contentSlug,
    quizState.availableQuiz,
    quizState.periodicQuizResults,
    modalDisplayed,
  ]);

  useEffect(() => {
    if (modalDisplayed) return;
    if (
      props.notificationType === "comment" &&
      props.commentContent === "viewUserPoll" &&
      userPollState.userPoll?.slug &&
      props.commentId
    ) {
      getUserPollResponse(userPollState.userPoll.slug, user?.uid)
        .then((res) => {
          if (res !== null) {
            setModalComponent(
              <UserPollResponseModal elementName="UserPollResponseModal" />
            );
            removeModalByName("UserPollModal");
            removeModalByName("NotificationLoadingModal");
            return setModalDisplayed(true);
          } else {
            setModalComponent(<UserPollModal elementName="UserPollModal" />);
            removeModalByName("NotificationLoadingModal");
            return setModalDisplayed(true);
          }
        })
        .catch(() => {
          setModalComponent(<UserPollModal elementName="UserPollModal" />);
          removeModalByName("NotificationLoadingModal");
          return setModalDisplayed(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.notificationType,
    props.commentContent,
    props.commentId,
    props.contentSlug,
    userPollState.userPoll?.slug,
    userPollState.userCurrentPollResponse?.pollSlug,
    modalDisplayed,
  ]);

  useEffect(() => {
    if (modalDisplayed) return;
    if (
      props.notificationType === "comment" &&
      props.commentContent === "viewCommunityQuestion" &&
      communityQuestion?.slug &&
      props.commentId
    ) {
      if (props.contentSlug === communityQuestion.slug) {
        Mixpanel?.track("Comment modal summoned", {
          source: "daily community question",
          slug: props.contentSlug,
        });
        setModalComponent(
          <CommunityQuestionsModal
            commentId={props.commentId}
            elementName="CommunityQuestionsModal"
          />
        );
        removeModalByName("NotificationLoadingModal");
        return setModalDisplayed(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modalDisplayed,
    communityQuestion?.slug,
    props.contentSlug,
    props.notificationType,
    props.commentId,
    props.commentContent,
  ]);

  useEffect(() => {
    if (modalDisplayed) return;
    if (
      props.notificationType === "viewCommunityQuestion" &&
      communityQuestion?.slug
    ) {
      if (props.contentSlug === communityQuestion.slug) {
        Mixpanel?.track("Comment modal summoned", {
          source: "daily community question",
          slug: props.contentSlug,
        });
        localStorage.setItem("viewCommunityQuestion", "true");
        removeModalByName("NotificationLoadingModal");
        return setModalDisplayed(true);
      } else {
        setModalComponent(
          <ExpirationModal copy="You're too late! This content and associated comments have expired." />
        );
        removeModalByName("NotificationLoadingModal");
        setModalDisplayed(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modalDisplayed,
    communityQuestion?.slug,
    props.contentSlug,
    props.notificationType,
  ]);

  return (
    <ModalWrapper noLine title="">
      <Loader mobileHeight="500px" height="500px" />
    </ModalWrapper>
  );
}
