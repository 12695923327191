import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { ContentIdentifier } from "@dimensional-engineering/dimensional-models";

export function convertTimestampToTimeSincePosting(createdOn: number): string {
  // Time since posting: minutes, hours, or days
  // e.g., “12m”, “12h”, “3d”, or "Now"

  const currentTime = Date.now();
  const timeElapsed = currentTime - createdOn;

  const minutesElapsed = Math.floor(timeElapsed / (1000 * 60));
  if (minutesElapsed < 1) {
    return "Now";
  }

  if (minutesElapsed < 60) {
    return `${minutesElapsed}m`;
  }

  const hoursElapsed = Math.floor(minutesElapsed / 60);
  if (hoursElapsed < 24) {
    return `${hoursElapsed}h`;
  }

  const daysElapsed = Math.floor(hoursElapsed / 24);
  return `${daysElapsed}d`;
}

export async function createSharedPost(
  contentIdentifier: ContentIdentifier,
  caption?: string | null | undefined
) {
  const call = callFirebaseFunctions("createSharedPost");

  console.log({
    contentIdentifier: contentIdentifier,
    caption: caption,
  });

  return await call({
    contentIdentifier: contentIdentifier,
    caption: caption,
  });
}

export async function likeOrUnlikePost(
  sharedPostId: string,
  isUnliked: boolean
) {
  const call = callFirebaseFunctions("likeSharedPost");

  return await call({
    sharedPostId: sharedPostId,
    isUnliked: isUnliked,
  });
}
