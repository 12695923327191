import { RootState } from "@/redux/store";
import {
  Archetype,
  DiscoverableCompatibilitySnippet,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import styles from "./CompatibilitySnippets.module.css";

import LargeProfileImage from "@/components/shared/SmallProfileImage/LargeProfileImage";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { compatibilityIconsMap } from "./CompatibilityData";
import { computeCompatibilitySnippets } from "./utils";
import { useModalContext } from "@/context/ModalContext";
import ComparedMediumProfileImage from "@/components/shared/SmallProfileImage/ComparedMediumProfileImage";
import ComputingStateSnippetModal from "./ComputingStateSnippetModal";
import { Mixpanel } from "@/helpers/mixpanel";

type ArchetypeDataModel = {
  dimensionSlug: string;
  archetypeScore: number;
  archetypeSlug: string;
  archetype: Archetype;
};

export default function GenerateCompatibilityModal(props: {
  publicProfile: PublicProfile | null;
  snippet: DiscoverableCompatibilitySnippet;
  elementName: "GenerateCompatibilityModal";
  archetypeData: ArchetypeDataModel[] | null;
}) {
  const userState = useSelector((state: RootState) => state.user);
  const connectionListWithFriendDetails = useSelector(
    (state: RootState) => state.connections.publicProfiles
  );
  const { setModalOpen, setModalComponent, removeModalByName } =
    useModalContext();

  const connection = connectionListWithFriendDetails?.find(
    (f) => f.ownerUUID === props.publicProfile?.ownerUUID
  );
  const [loading, setLoading] = useState<boolean>(false);

  const maxLength = 13;
  const myDisplayNAme = userState.publicProfileData?.userName || "";
  const thierDisplayNAme = connection?.userName || "";

  function handleGenerate() {
    if (connection?.ownerUUID) {
      Mixpanel?.track("Generate CTA Clicked", {
        triggerer: userState.publicProfileData?.userName,
        triggeree: connection.userName,
        compatibility_snippet_slug: props.snippet?.snippetSlug,
      });
      setLoading(true);
      computeCompatibilitySnippets(
        props.snippet?.snippetSlug,
        connection?.ownerUUID
      )
        .then((res) => {})
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setModalOpen(false);
        });
    }
  }

  useEffect(() => {
    if (loading) {
      removeModalByName("GenerateCompatibilityModal");
      setModalComponent(
        <ComputingStateSnippetModal
          discoverableSnippet={props.snippet}
          publicProfile={props.publicProfile}
          elementName="ComputingStateSnippetModal"
          archetypeData={props.archetypeData}
        />
      );
    } else {
      removeModalByName("ComputingStateSnippetModal");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <ModalWrapper backgroundColor="#2D2927" title="" noLine>
      <section className={styles.generateModalMain}>
        <div className={styles.generateModalTop}>
          <div className={styles.generatePersonDiv}>
            <LargeProfileImage
              publicProfile={userState.publicProfileData}
              imageUrl={userState.imageURL ?? "/default-image.png"}
            />
            <p className={styles.generateUsername}>
              {myDisplayNAme.length <= maxLength
                ? `@${myDisplayNAme}`
                : `@${myDisplayNAme.slice(0, 11)}...`}
            </p>
          </div>
          <div className={styles.snippetIconSmall}>
            {
              compatibilityIconsMap[
                props.snippet?.snippetSlug as keyof typeof compatibilityIconsMap
              ]
            }
          </div>
          <div className={styles.generatePersonDiv}>
            <ComparedMediumProfileImage
              publicProfile={props.publicProfile}
              imageUrl={connection?.imageUrl ?? "/default-image.png"}
              large
              noLink
            />
            <p className={styles.generateUsername}>
              {thierDisplayNAme.length <= maxLength
                ? `@${thierDisplayNAme}`
                : `@${thierDisplayNAme.slice(0, 11)}...`}
            </p>
          </div>
        </div>
        <h3 className={styles.generateContent}>
          This content needs to be generated.
        </h3>
        <button onClick={() => handleGenerate()} className={styles.generateBtn}>
          ✨ Generate ✨
        </button>
      </section>
    </ModalWrapper>
  );
}
