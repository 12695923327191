/* eslint-disable @next/next/no-img-element */
import Link from "next/link";

import styles from "./ConnectionsModal.module.css";

import { ProfileColorMap } from "@/components/shared/ColorMap";
import ConnectionsImage from "@/components/shared/GhostBox/ConnectionsImage";
import GhostBox from "@/components/shared/GhostBox/GhostBox";
import { FriendsPublicProfile } from "@/models/sharedModels";
import XMark from "../../shared/XMark";
import { useModalContext } from "@/context/ModalContext";
import ProfileModalHandler from "@/components/authenticatedProfile/Abstracts/ProfileModal/ProfileModalHandler";

type ConnectionsModalProps = {
  connectionList: FriendsPublicProfile[] | [];
  closeModal: (close: boolean) => void;
  permission: boolean;
  userName: string;
  auth?: boolean;
};

export default function ConnectionsModal(props: ConnectionsModalProps) {
  const colorMap = ProfileColorMap;
  const { setModalComponent } = useModalContext();

  const mapped = props.connectionList.map((person, index) => {
    if (props.auth) {
      return (
        <div
          className={styles.personDiv}
          style={{ display: "flex" }}
          key={index}
          onClick={(e) => {
            setModalComponent(
              <ProfileModalHandler
                uid={person.ownerUUID}
                elementName="profileModal"
              />
            );
            props.closeModal(false);
          }}
        >
          <div
            className={styles.connectionsImageContainer}
            style={{
              border: `3px solid ${colorMap[person.primaryNature?.nature!!]}`,
            }}
          >
            <img
              width={50}
              height={50}
              alt=""
              src={person.imageUrl ?? "/default-image.png"}
              className={styles.smallImage}
            />
          </div>
          <div className={styles.bioAndUsername}>
            <h5>{person.name}</h5>
            <p>{`@${person.userName}`}</p>
          </div>
        </div>
      );
    }
    return (
      <Link
        href={`/${person.userName}`}
        className={styles.personDiv}
        style={{ display: "flex" }}
        key={index}
        onClick={(e) => {
          props.closeModal(false);
        }}
      >
        <div
          className={styles.connectionsImageContainer}
          style={{
            border: `3px solid ${colorMap[person.primaryNature?.nature!!]}`,
          }}
        >
          <img
            width={50}
            height={50}
            alt=""
            src={person.imageUrl ?? "/default-image.png"}
            className={styles.smallImage}
          />
        </div>
        <div className={styles.bioAndUsername}>
          <h5>{person.name}</h5>
          <p>{`@${person.userName}`}</p>
        </div>
      </Link>
    );
  });

  return (
    <div
      onClick={() => props.closeModal(true)}
      className={styles.connectionMain}
    >
      <div
        onClick={(e) => !props.auth && e.stopPropagation()}
        className={styles.connectionTopDiv}
      >
        <h1>Connections</h1>
        <div
          onClick={() => {
            props.closeModal(false);
          }}
          className={styles.connectionCloseDiv}
        >
          <XMark color="#FFFFF2" />
        </div>
      </div>
      <div
        onClick={(e) => !props.auth && e.stopPropagation()}
        className={styles.connectionBody}
      >
        {props.permission === true ? (
          <div className={styles.connectionList}>{mapped}</div>
        ) : (
          <div className={styles.ghostBoxParent}>
            <GhostBox
              message="only allows their Connections to see this"
              username={props.userName}
              image={<ConnectionsImage />}
            />
          </div>
        )}
      </div>
    </div>
  );
}
