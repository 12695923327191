import { useEffect, useRef, useState } from "react";
import { CommentReply } from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";

import styles from "./CommentThread.module.css";

import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import CommentLikeIcon from "@/components/shared/icons/CommentLikeIcon";
import CommentUnLikeIcon from "@/components/shared/icons/CommentUnLikeIcon";
import ShowMoreRepliesCTA from "../ShowMoreRepliesCTA/ShowMoreRepliesCTA";
import { SmallLoader } from "@/components/shared/Loader";
import { useAuth } from "@/context/AuthContext";
import { CommentThreadDeleteAndEditBox, ResolvedReply } from "./CommentThread";
import { RootState } from "@/redux/store";
import { likeOrUnlikeComment } from "../utils";
import { Mixpanel } from "@/helpers/mixpanel";
import { useModalContext } from "@/context/ModalContext";
import useGetPublicProfile from "@/helpers/useGetPublicProfile";
import ProfileModalHandler from "@/components/authenticatedProfile/Abstracts/ProfileModal/ProfileModalHandler";

export default function CommentThreadReply(props: {
  reply: ResolvedReply;
  index: number;
  array: ResolvedReply[];
  loadingComments: boolean;
  onReply: (
    username: string,
    parentCommentId: string,
    authorId: string
  ) => void;
  onLoadMoreReplies: (reply: CommentReply) => void;
  onDelete: (
    commentThreadId: string,
    commentId: string,
    body: string,
    replyId?: string | null | undefined
  ) => void;
  source:
    | "daily insight"
    | "daily poll"
    | "daily story"
    | "daily quiz"
    | "daily community question"
    | "posted daily insight"
    | "posted daily quiz"
    | "posted daily story";
  slug: string;
  audienceGroupSize: number;
  hideRepliesButton: boolean;
  captionAuthorUid?: string;
}) {
  const { user } = useAuth();
  const { setModalComponent } = useModalContext();

  const [upvoteCount, setUpvoteCount] = useState<number>(0);
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const [touchStartX, setTouchStartX] = useState<number | null>(null);

  const commentThreadId = useSelector(
    (state: RootState) => state.comments
  ).commentThreadId;

  const commentScore = useCommentVoteData(
    props.reply.reply.parentCommentId,
    props.reply.reply.id
  );

  const taggedMemebrId = props.reply.reply.taggedMemberId;
  const taggedPublicProfile = useGetPublicProfile(taggedMemebrId);

  useEffect(() => {
    setUpvoteCount(props.reply.reply.upvoteCount ?? 0);
  }, [props.reply.reply.upvoteCount]);

  useEffect(() => {
    if (props.reply.reply?.searchIndex && props.reply.reply.searchIndex < -10) {
      setIsHidden(true);
    }
  }, [props.reply.reply.searchIndex]);

  const mainRef = useRef<HTMLDivElement | null>(null);
  const editBoxRef = useRef<HTMLDivElement | null>(null);
  const heightRef = useRef<HTMLDivElement | null>(null);

  const handleTouchStart = (e: React.TouchEvent) => {
    if (props.reply.reply.author !== user?.uid) {
      return;
    }
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!touchStartX || !editBoxRef.current) return;

    const currentX = e.touches[0].clientX;
    const deltaX = currentX - touchStartX;

    if (deltaX < -100) {
      editBoxRef.current.style.width = "50px";
      editBoxRef.current.style.height = `${heightRef.current?.offsetHeight}px`;
      editBoxRef.current.style.marginLeft = "17px";
    } else {
      editBoxRef.current.style.width = `0px`;
      editBoxRef.current.style.marginLeft = "0";
    }
  };

  const handleTouchEnd = () => {
    setTouchStartX(null);
  };

  return (
    <div ref={mainRef} className={styles.whole}>
      <div className={styles.mainReplyDiv} key={props.reply.reply.id}>
        <div
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
          ref={heightRef}
          className={styles.main}
        >
          <SmallProfileImage
            publicProfile={props.reply.replyData[1]}
            imageUrl={props.reply.replyData[0]}
          />
          <div className={styles.rightDiv}>
            <div className={styles.commentMetaDiv}>
              <p className={styles.commentMeta}>
                @{props.reply.replyData[1]?.userName} -{" "}
                {props.reply.replyFormatted}
              </p>
              {props.captionAuthorUid === props.reply.reply.author && (
                <p className={styles.captionAuthorTag}>Author</p>
              )}
            </div>

            {props.reply.reply.isDeleted ? (
              <p className={styles.commentMeta}>~Comment deleted~</p>
            ) : isHidden ? (
              <p
                onClick={() => setIsHidden(false)}
                className={styles.commentMeta}
              >
                Downvoted comment. Tap to reveal.
              </p>
            ) : (
              <p className={styles.commentBody}>
                <div
                  className={styles.replyingUsername}
                  onClick={() => {
                    setModalComponent(
                      <ProfileModalHandler
                        uid={taggedPublicProfile?.ownerUUID}
                        elementName="profileModal"
                      />
                    );
                  }}
                >
                  @{taggedPublicProfile?.userName}
                </div>{" "}
                {props.reply.reply.body}
              </p>
            )}
            <div
              style={props.reply.reply.isDeleted ? { opacity: "0.3" } : {}}
              className={styles.bottom}
            >
              <p
                onClick={() => {
                  if (
                    props.reply.replyData[1]?.userName &&
                    !props.reply.reply.isDeleted
                  ) {
                    props.onReply(
                      props.reply.replyData[1]?.userName,
                      props.reply.reply.parentCommentId,
                      props.reply.reply.author
                    );
                  }
                }}
                className={styles.replyText}
              >
                Reply
              </p>
              <div className={styles.bottomRight}>
                <div
                  onClick={() => {
                    if (commentThreadId && !props.reply.reply.isDeleted) {
                      const score = commentScore === 1 ? 0 : 1;

                      commentScore === 1 //liked
                        ? setUpvoteCount((current) => current - 1)
                        : setUpvoteCount((current) => current + 1);

                      likeOrUnlikeComment(
                        user?.uid,
                        props.reply.reply.parentCommentId,
                        commentThreadId,
                        score,
                        props.reply.reply.id
                      ).then((res) => {
                        Mixpanel?.track("Comment voted", {
                          type: "reply",
                          source: props.source,
                          slug: props.slug,
                          body: props.reply.reply.body,
                          score: 1,
                        });
                      });
                    }
                  }}
                  className={styles.likeDiv}
                >
                  <CommentLikeIcon liked={commentScore === 1} />
                  <p>{upvoteCount < 0 ? 0 : upvoteCount}</p>
                </div>
                <div
                  className={styles.unlikeDiv}
                  onClick={() => {
                    if (commentThreadId && !props.reply.reply.isDeleted) {
                      const score = commentScore === -1 ? 0 : -1;

                      commentScore === -1 //unliked
                        ? setUpvoteCount((current) => current + 1)
                        : setUpvoteCount((current) => current - 1);

                      likeOrUnlikeComment(
                        user?.uid,
                        props.reply.reply.parentCommentId,
                        commentThreadId,
                        score,
                        props.reply.reply.id
                      ).then((res) => {
                        Mixpanel?.track("Comment voted", {
                          type: "reply",
                          source: props.source,
                          slug: props.slug,
                          body: props.reply.reply.body,
                          score: -1,
                        });
                      });
                    }
                  }}
                >
                  <CommentUnLikeIcon active={commentScore === -1} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {props.index > 3 &&
        props.index === props.array.length - 1 &&
        !props.hideRepliesButton ? (
          props.loadingComments ? (
            <div style={{ width: "100%", height: "20px" }}>
              <SmallLoader />
            </div>
          ) : (
            <ShowMoreRepliesCTA
              onClick={() => {
                props.onLoadMoreReplies(props.reply.reply);
              }}
            />
          )
        ) : null}
      </div>
      <div ref={editBoxRef} className={styles.editBoxParent}>
        <CommentThreadDeleteAndEditBox
          onDelete={() => {
            if (commentThreadId) {
              props.onDelete(
                commentThreadId,
                props.reply.reply.parentCommentId,
                props.reply.reply.body,
                props.reply.reply.id
              );
            }
          }}
        />
      </div>
    </div>
  );
}

function useCommentVoteData(commentId: string, replyId: string) {
  const [commentScore, setCommentScore] = useState<number | null>(null);

  const commentThreadId = useSelector(
    (state: RootState) => state.comments
  ).commentThreadId;
  const commentVotes = useSelector(
    (state: RootState) => state.comments
  ).commentVotes;

  useEffect(() => {
    const filter = commentVotes.filter((c) => c.commentId === commentId);
    const found = filter.find((c) => c.replyId === replyId);

    if (found && commentThreadId) {
      setCommentScore(found.voteScore);
    }
  }, [commentVotes]);

  return commentScore;
}
