import {
  CommentThread,
  CommunityQuestion,
} from "@dimensional-engineering/dimensional-models";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type UserPollSlice = {
  communityQuestion: CommunityQuestion | null;
  commentThread: CommentThread | null;
  communityQuestionLoaded: boolean;
};

const initialState: UserPollSlice = {
  communityQuestion: null,
  commentThread: null,
  communityQuestionLoaded: false,
};

export const communityQuestion = createSlice({
  name: "communityQuestion",
  initialState: initialState,
  reducers: {
    setCommunityQuestion: (
      state,
      action: PayloadAction<CommunityQuestion | null>
    ) => {
      return { ...state, communityQuestion: action.payload };
    },
    setCommentThreadCommunityQuestion: (
      state,
      action: PayloadAction<CommentThread>
    ) => {
      return { ...state, commentThread: action.payload };
    },
    setCommunityQuestionLoaded: (state, action: PayloadAction<boolean>) => {
      return { ...state, communityQuestionLoaded: action.payload };
    },
  },
});

export const {
  setCommunityQuestion,
  setCommentThreadCommunityQuestion,
  setCommunityQuestionLoaded,
} = communityQuestion.actions;

export default communityQuestion.reducer;
