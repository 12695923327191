import { SummaryTableRow as SummaryTableRowModel } from "@dimensional-engineering/dimensional-models";
import { Roboto_Mono } from "next/font/google";

import styles from "./SummaryTable.module.css";

import SummaryTableValue from "./SummaryTableValue";
import TopStrengthValue from "./TopStrengthValue";

type RowProps = {
  row: SummaryTableRowModel;
  index: number;
  natureColor: string | null;
};
const font = Roboto_Mono({ subsets: ["latin"] });

export default function SumamryTableRow(props: RowProps) {
  return (
    <div className={`${styles.summaryTableRow} ${font.className}`}>
      <p
        className={`${styles.summaryTableTitle} ${
          props.row.title === "Top strengths" ? styles.topStrengths : ""
        }`}
      >
        {props.row.title}
      </p>
      <div className={styles.summaryTableValueDiv}>
        {props.row.values !== null ? (
          props.row.title !== "Top strengths" ? (
            props.row.values?.map((value, i) => {
              return (
                <SummaryTableValue
                  index={props.index}
                  isLast={
                    props.row.values && i === props.row.values.length - 1
                      ? true
                      : false
                  }
                  key={i}
                  natureColor={props.natureColor}
                  row={props.row}
                  value={value}
                />
              );
            })
          ) : (
            <TopStrengthValue value={props.row.values} />
          )
        ) : (
          <p className={styles.summaryTableValue}>?</p>
        )}
      </div>
    </div>
  );
}
