import styles from "./AuthLeftBarOwnProfile.module.css";

import LinkIcon from "@/components/settings/LinkIcon";

export default function ViewWebProfileCTA() {
  return (
    <button className={styles.ctaButton}>
      <div className={styles.linkIcon}>
        <LinkIcon profile />
      </div>
      View web profile
    </button>
  );
}
