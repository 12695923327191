import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type ModalContextModel = {
  isModalOpen: boolean;
  modalComponent: JSX.Element[];
  setModalOpen: (value: boolean) => void;
  setModalComponent: (value: JSX.Element) => void;
  emptyModal: () => void;
  removeModalByName: (name: string) => void;
};

const ModalDefaultValue: ModalContextModel = {
  isModalOpen: false,
  modalComponent: [],
  setModalOpen: () => {},
  setModalComponent: () => {},
  emptyModal: () => {},
  removeModalByName: (name: string) => {},
};

type Props = {
  children: ReactNode;
};

const ModalContext = createContext<ModalContextModel>(ModalDefaultValue);

export function useModalContext() {
  return useContext(ModalContext);
}

export function ModalContextProvider({ children }: Props) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalComponent, setModalJSXComponent] = useState<JSX.Element[]>([]);

  function handleUi() {
    const dialogComp = document.querySelectorAll("#dialog");
    if (dialogComp.length) {
      let last = dialogComp[dialogComp.length - 1] as HTMLDivElement;
      if (dialogComp.length > 1) {
        last.style.transform = "translateX(0)";
      } else {
        last.style.transform = "translateY(0)";
      }

    }
  }

  useEffect(() => {
    if (modalComponent.length === 0) {
      setIsModalOpen(false);
      document.body.style.overflowY = "visible";
    } else {
      setIsModalOpen(true);
      document.body.style.overflowY = "hidden";
    }
  }, [modalComponent.length]);

  function setModalOpen(value: boolean) {
    if (value) {
      return;
    }
    const dialogComp = document.querySelectorAll("#dialog");
    if (dialogComp.length) {
      const first = dialogComp[dialogComp.length - 1] as HTMLDivElement;
      if (modalComponent.length > 1) {
        // Slide out to the left animation
        first.style.transform = "translateX(200%)";
        setTimeout(() => {
          setModalJSXComponent((current) => current.slice(0, -1));
        }, 200);
      } else {
        // Slide down animation
        first.style.transform = "translateY(100%)";
        setTimeout(() => {
          setModalJSXComponent((current) => current.slice(0, -1));
        }, 200);
      }
    } else {
      setModalJSXComponent((current) => current.slice(0, -1));
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function setModalComponent(value: JSX.Element) {
    setModalJSXComponent((current) => [...current, value]);
  }

  useEffect(() => {
    setTimeout(() => {
      handleUi();
    }, 100);
  }, [setModalComponent]);

  function emptyModal() {
    setModalJSXComponent([]);
  }

  function removeModalByName(name: string) {
    // warning: This function removes the modal with no sliding ui changes.
    // It is for removing modals in the background
    setModalJSXComponent((prevComponents) =>
      prevComponents.filter((component) =>
        component.type ? component.props.elementName !== name : true
      )
    );
  }

  const value = {
    isModalOpen: isModalOpen,
    modalComponent: modalComponent,
    setModalOpen: setModalOpen,
    setModalComponent: setModalComponent,
    emptyModal: emptyModal,
    removeModalByName: removeModalByName,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}
