import { useEffect, useRef } from "react";
import {
  DeliveredDailyInsight,
  RuleDirection,
} from "@dimensional-engineering/dimensional-models";
import html2canvas from "html2canvas";
import removeMarkdown from "markdown-to-text";
import { DM_Sans, Roboto_Mono } from "next/font/google";

import styles from "./DailyInsightsModal.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import {
  DailtInsightIconWhiteVariant,
  HighDailyInsightScaleScoreIcon,
  LowDailyInsightScaleScoreIcon,
  NeutralDailyInsightScaleScoreIcon,
} from "../DailyInsightsIcon";
import { useModalContext } from "@/context/ModalContext";
import { useGetDailyInsightIconColors } from "../DailyInsightsCTABlock/DailyInsightsCTABlock";
import elementsJsonData from "../../../../assets/traitInfo/elements.json";
import archetypesJsonData from "../../../../assets/traitInfo/archetypes.json";
import { natureData } from "@/assets/traitInfo/natureData";

const font = Roboto_Mono({ subsets: ["latin"] });

const fontSans = DM_Sans({ weight: "500", subsets: ["latin"] });

type Props = {
  deliveredDailyInsight: DeliveredDailyInsight | null;
  type: "instagram" | "snapchat";
};

export default function DailyInsightsModalShareView(props: Props) {
  const imageRef = useRef<HTMLDivElement>(null);

  const { setModalOpen } = useModalContext();

  const colors = useGetDailyInsightIconColors();

  const body = removeMarkdown(
    props.deliveredDailyInsight?.assignmentRuleset?.body ?? ""
  );

  const mappedScales =
    props.deliveredDailyInsight?.assignmentRuleset.scaleRules.map((scale) => {
      const icon =
        scale.direction === RuleDirection.up ? (
          <HighDailyInsightScaleScoreIcon />
        ) : scale.direction === RuleDirection.middle ? (
          <NeutralDailyInsightScaleScoreIcon />
        ) : (
          <LowDailyInsightScaleScoreIcon />
        );

      const element = elementsJsonData.find((el) => el.slug === scale?.slug);
      const foundNature = natureData?.find((n) => n.slug === element?.nature);
      let scaleColor = foundNature?.color;

      if (element && foundNature) {
        return (
          <div
            className={styles.scaleShareView}
            style={{ borderColor: scaleColor }}
            key={scale.slug}
          >
            {icon}
            <p style={{ fontSize: "12px" }} className={styles.scaleName}>
              {element.alias ?? element.name}
            </p>
          </div>
        );
      }
    });

  const identityArchetypeFound = archetypesJsonData.find(
    (a) =>
      a.slug ===
      props.deliveredDailyInsight?.assignmentRuleset.identityArchetypeSlug
  );

  const interactionArchetypeFound = archetypesJsonData.find(
    (a) =>
      a.slug ===
      props.deliveredDailyInsight?.assignmentRuleset.interactionArchetypeSlug
  );

  const identityArchetype = identityArchetypeFound ? (
    <div className={styles.scaleShareView}>
      <p style={{ fontSize: "12px" }} className={styles.scaleName}>
        {identityArchetypeFound.acronym ?? identityArchetypeFound.name}
      </p>
    </div>
  ) : null;

  const interactionArchetype = interactionArchetypeFound ? (
    <div className={styles.scaleShareView}>
      <p style={{ fontSize: "12px" }} className={styles.scaleName}>
        {interactionArchetypeFound.acronym ?? interactionArchetypeFound.name}
      </p>
    </div>
  ) : null;

  useEffect(() => {
    const handleDownloadImage = async () => {
      try {
        if (imageRef.current) {
          const canvas = await html2canvas(imageRef.current);
          const data = canvas.toDataURL("image/jpg");
          // var a = document.createElement("a");
          // a.href = canvas
          //   .toDataURL("image/jpeg")
          //   .replace("image/jpeg", "image/octet-stream");
          // a.download = "somefilename.jpg";
          // a.click();

          if (window.Android) {
            window.Android.onHaptic();

            if (props.type === "instagram") {
              window.Android.shareToInstagramQuiz(data);
            } else {
              window.Android.shareToSnapChat(data);
            }
          }
        }
      } catch (error) {
        console.error("Error generating image:", error);
      }
    };

    document.body.style.backgroundColor = "#181716";

    setTimeout(() => {
      handleDownloadImage();
      setModalOpen(false);
    }, 110);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalWrapper noHeader noLine title="">
      <main ref={imageRef} className={styles.shareViewMain}>
        <div className={styles.iconDiv}>
          <DailtInsightIconWhiteVariant />
        </div>
        <h2 style={{ marginTop: "20px" }} className={styles.title}>
          Personal Insight
        </h2>
        <h3 className={`${styles.insightTitle} ${font.className}`}>
          {props.deliveredDailyInsight?.name}
        </h3>
        <p className={styles.bodyShareView}>{body}</p>
        {/* <p
          style={{ marginTop: "31px" }}
          className={`${styles.ruleSetText} ${font.className}`}
        >
          BECAUSE OF YOUR SCORES ON:
        </p>
        <div style={{ marginBottom: "0" }} className={styles.scalesDiv}>
          {mappedScales}
          {identityArchetype}
          {interactionArchetype}
        </div> */}
        <p className={`${styles.bottomTextShare} ${fontSans.className}`}>
          www.dimensional.me
        </p>
      </main>
    </ModalWrapper>
  );
}
