import { useEffect, useState } from "react";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";

import styles from "./AuthLeftBarOwnProfile.module.css";

import { FriendsPublicProfile } from "@/models/sharedModels";
import ProfileConnectButton from "../../ProfileConnectButton/ProfileConnectButton";
import useScrollBlock from "../../../../helpers/useScroll";
import ConnectionTypeBar from "../ConnectionTypeBar";
import { VeryLargeProfileImage } from "@/components/shared/SmallProfileImage/LargeProfileImage";
import ConnectionsModal from "@/components/publicProfiles/ConnectionsModal/ConnectionsModal";
import SocialLinks from "@/components/publicProfiles/SocialLinks/SocialLinks";
import Link from "next/link";
import { useModalContext } from "@/context/ModalContext";
import { GeneralModalArrayParagraph } from "@/components/shared/GeneralModal/GeneralModal";
import AmbassadorIcon from "@/components/shared/icons/AmbassadorIcon";
import { Mixpanel } from "@/helpers/mixpanel";

type LeftBarProps = {
  imageUrl: string | null;
  connectionList: FriendsPublicProfile[] | [];
  publicProfile: PublicProfile;
};

export default function AuthLeftBarConnectedAndUnconnectedProfile(
  props: LeftBarProps
) {
  const [showConnections, setShowConnections] = useState<boolean>(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const { setModalComponent } = useModalContext();

  useEffect(() => {
    if (showConnections === true) {
      blockScroll();
    } else {
      allowScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConnections]);

  return (
    <div className={styles.mainConnected}>
      <div className={styles.top}>
        <div
          onClick={(e) => {
            setShowConnections(true);
          }}
          className={styles.topInfoDiv}
          style={{ minWidth: "81px" }}
        >
          <p className={styles.numConnections}>
            {props.publicProfile.numFriends
              ? props.publicProfile.numFriends
              : "0"}
          </p>
          <p className={styles.connectionsText}>Connections</p>
          {props.publicProfile.isAmbassador && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setModalComponent(
                  <GeneralModalArrayParagraph
                    title="Dimensional Ambassador Program"
                    modalCta={"viewAmbassador"}
                    body={[
                      "Dimensional Ambassadors are a diverse group connected by a shared passion for self-understanding, living intently, and supporting the growth of those around them.",
                      "Ambassadors get early access to features, monthly check-ins with founders and other members, and a private community.",
                    ]}
                  />
                );
                Mixpanel?.track("Profile / ambassador check clicked", {
                  username: props.publicProfile.userName,
                });
              }}
              className={styles.ambassDiv}
            >
              <AmbassadorIcon />
              <p className={styles.connectionsText}>Ambassador</p>
            </div>
          )}
        </div>
        <VeryLargeProfileImage
          publicProfile={props.publicProfile}
          imageUrl={props.imageUrl ?? ""}
          showNature
          noLink
        />
        <div style={{ minWidth: "81px" }} className={styles.topInfoDiv}>
          <ConnectionTypeBar publicProfile={props.publicProfile} />
        </div>
      </div>

      <div className={styles.bottom}>
        <h1 className={styles.name}>
          {props.publicProfile?.name ?? props.publicProfile?.userName}
        </h1>
        <Link
          href={`/${props.publicProfile?.userName}`}
          target="__blank"
          className={styles.url}
        >
          {props.publicProfile?.userName
            ? `dimensional.me/${props.publicProfile?.userName}`
            : ""}
        </Link>
      </div>

      <ProfileConnectButton publicProfile={props.publicProfile} />

      <SocialLinks {...props.publicProfile.socialMediaMap} />

      {showConnections === true && (
        <ConnectionsModal
          connectionList={props.connectionList}
          closeModal={(e) => {
            setShowConnections(false);
          }}
          permission={
            props.publicProfile.publicProfilePermissions?.connectionList
              ? true
              : false
          }
          userName={props.publicProfile.userName!!}
          auth
        />
      )}
    </div>
  );
}
