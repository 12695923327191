import styles from "../../../styles/app/AppResults.module.css";

export default function ResultsHeading(props: {
  title: string;
  noMargin?: boolean;
}) {
  return (
    <h1
      style={props.noMargin ? { marginBottom: "0" } : {}}
      className={styles.heading}
    >
      {props.title}
    </h1>
  );
}
