import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doc, getDoc } from "firebase/firestore";
import {
  SummaryTable as SummaryTableType,
  Archetype,
} from "@dimensional-engineering/dimensional-models";

import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { db } from "@/_firebase/firebaseConfig";
import { useAuth } from "@/context/AuthContext";
import { setUser } from "@/redux/slices/userSlice";
import { RootState } from "@/redux/store";

type ArchetypeDataModel = {
  dimensionSlug: string;
  archetypeScore: number;
  archetypeSlug: string;
  archetype: Archetype;
};

export default function useOwnDynamicProfileData() {
  const [archetypeData, setArchetypeData] = useState<
    null | ArchetypeDataModel[]
  >(null);
  const [summaryTableData, setSummaryTableData] =
    useState<SummaryTableType | null>(null);
  const [adjectivesData, setAdjectivesDAta] = useState<null | string[]>(null);
  const [nutshell, setnutshell] = useState<string | undefined>(undefined);

  const { user } = useAuth();
  const publicProfileData = useSelector(
    (state: RootState) => state.user.publicProfileData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    async function getUserProfileData() {
      if (publicProfileData) {
        const getSummaryTable = callFirebaseFunctions("fetchSummaryTable");
        getSummaryTable({ uid: user?.uid as string })
          .then((result) => {
            setSummaryTableData(result.data as SummaryTableType);
          })
          .catch((err) => {
            setSummaryTableData(null);
          });

        const getNutShellDataDoc = doc(
          db,
          `members/${user?.uid}/profileNutshells/gpt3`
        );

        const nutshellData = await getDoc(getNutShellDataDoc);
        let nutshellString: string | null = null;
        if (nutshellData.exists()) {
          nutshellString = nutshellData.data().body as unknown as string;
          setnutshell(nutshellString);
        }

        const getArchetypeData = callFirebaseFunctions(
          "fetchProfileArchetypes"
        );

        getArchetypeData({ uid: user?.uid as string })
          .then((result) => {
            if (result.data) {
              setArchetypeData(result.data as ArchetypeDataModel[]);
            }
          })
          .catch(() => {
            setArchetypeData(null);
          });
      }
    }

    if (user?.uid as string) {
      getUserProfileData().then(() => {
        dispatch(
          setUser({
            summaryTableData: summaryTableData,
            adjectivesData: adjectivesData,
            archetypeData: archetypeData,
            nutshell: nutshell,
          })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid]);

  useEffect(() => {
    dispatch(
      setUser({
        summaryTableData: summaryTableData,
        adjectivesData: adjectivesData,
        archetypeData: archetypeData,
        nutshell: nutshell,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjectivesData, archetypeData, summaryTableData, nutshell]);

  return null;
}
