export default function LinkIcon(props: { dark?: boolean; profile?: boolean }) {
  if (props.profile) {
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.7496 2.62467C12.1284 2.62467 11.6253 2.12158 11.6253 1.50036C11.6253 0.879144 12.1284 0.375 12.7496 0.375H19.4996C19.7981 0.375 20.085 0.493125 20.2959 0.704076C20.5068 0.915027 20.625 1.2019 20.625 1.50036V8.25036C20.625 8.87158 20.1209 9.37467 19.4996 9.37467C18.8784 9.37467 18.3753 8.87158 18.3753 8.25036V4.21629L7.92012 14.6704C7.48137 15.1102 6.76841 15.1102 6.32966 14.6704C5.88986 14.2317 5.88986 13.5187 6.32966 13.08L16.7838 2.62475L12.7496 2.62467ZM0.375 4.87536C0.375 3.63187 1.38224 2.62467 2.62464 2.62467H8.25036C8.87158 2.62467 9.37467 3.12881 9.37467 3.75003C9.37467 4.37124 8.87158 4.87539 8.25036 4.87539H2.62464V18.3754H16.1246V12.7497C16.1246 12.1285 16.6288 11.6254 17.25 11.6254C17.8712 11.6254 18.3754 12.1285 18.3754 12.7497V18.3754C18.3754 19.6178 17.3681 20.625 16.1247 20.625H2.62467C1.38223 20.625 0.375027 19.6178 0.375027 18.3754L0.375 4.87536Z"
          fill="#2D2927"
        />
      </svg>
    );
  }
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7496 2.62467C12.1284 2.62467 11.6253 2.12158 11.6253 1.50036C11.6253 0.879144 12.1284 0.375 12.7496 0.375H19.4996C19.7981 0.375 20.085 0.493125 20.2959 0.704076C20.5068 0.915027 20.625 1.2019 20.625 1.50036V8.25036C20.625 8.87158 20.1209 9.37467 19.4996 9.37467C18.8784 9.37467 18.3753 8.87158 18.3753 8.25036V4.21629L7.92012 14.6704C7.48137 15.1102 6.76841 15.1102 6.32966 14.6704C5.88986 14.2317 5.88986 13.5187 6.32966 13.08L16.7838 2.62475L12.7496 2.62467ZM0.375 4.87536C0.375 3.63187 1.38224 2.62467 2.62464 2.62467H8.25036C8.87158 2.62467 9.37467 3.12881 9.37467 3.75003C9.37467 4.37124 8.87158 4.87539 8.25036 4.87539H2.62464V18.3754H16.1246V12.7497C16.1246 12.1285 16.6288 11.6254 17.25 11.6254C17.8712 11.6254 18.3754 12.1285 18.3754 12.7497V18.3754C18.3754 19.6178 17.3681 20.625 16.1247 20.625H2.62467C1.38223 20.625 0.375027 19.6178 0.375027 18.3754L0.375 4.87536Z"
        fill={props.dark ? "#181716" : "#FFFFF2"}
      />
    </svg>
  );
}
