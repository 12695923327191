import styles from "./DailyContentStatusBar.module.css";

import AudienceIcon from "@/components/shared/icons/AudienceIcon";
import { MessageIconStatusBar } from "@/components/shared/icons/MessageIcon";
import { TimeIconLarge } from "@/components/assessments-new/root/icons/TimeIcon";

type Props = {
  time: string;
  audienceNumber: number | null | undefined | "everyone";
  commentCount: number | null | undefined;
  onCommentClick: () => void;
  inactive?: boolean;
};

export default function DailyContentStatusBar(props: Props) {
  return (
    <div className={styles.main}>
      <div className={styles.iconAndTextDiv}>
        <TimeIconLarge />
        <p className={styles.timeText}>{props.time}</p>
      </div>
      {!props.inactive && (
        <div className={styles.rightSideDiv}>
          <div className={styles.iconAndTextDiv}>
            <AudienceIcon />
            <p className={styles.countText}>
              {props.audienceNumber === "everyone"
                ? "Everyone"
                : props.audienceNumber ?? 0}
            </p>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              props.onCommentClick();
            }}
            className={styles.iconAndTextDiv}
          >
            <MessageIconStatusBar />
            <p className={styles.countText}>
              {props.commentCount
                ? props.commentCount > 99
                  ? "99+"
                  : props.commentCount
                : 0}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
