import { useEffect, useRef, useState } from "react";

import styles from "./CommentTextBox.module.css";

type Props = {
  replyingUsername: string | null;
  onMessage: (message: string) => void;
  onCancel: () => void;
  loading: boolean;
};

export default function CommentTextBox(props: Props) {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const [prependText, setPrependText] = useState<string>(
    props.replyingUsername ? `@${props.replyingUsername} ` : ""
  );
  const [rows, setRows] = useState(1);
  const [message, setMessage] = useState<string>(prependText);

  const maxRows = 4;

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textareaLineHeight = 24;
    const previousRows = e.target.rows;
    e.target.rows = 1;

    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      e.target.rows = maxRows;
      e.target.scrollTop = e.target.scrollHeight;
    }

    setRows(currentRows < maxRows ? currentRows : maxRows);

    const newValue = e.target.value;

    if (!newValue.startsWith(prependText)) {
      setMessage(prependText);
    } else {
      setMessage(newValue);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const prependText = props.replyingUsername
      ? `@${props.replyingUsername} `
      : "";

    if (
      e.key === "Backspace" &&
      textAreaRef.current?.selectionStart &&
      textAreaRef.current?.selectionStart <= prependText.length
    ) {
      e.preventDefault();
    }

    if (
      e.key === "ArrowLeft" &&
      textAreaRef.current?.selectionStart === prependText.length
    ) {
      e.preventDefault();
    }
  };

  function handlePost() {
    if (props.loading) {
      return;
    }
    if (props.replyingUsername) {
      props.onMessage(message.trim().replace(`@${props.replyingUsername}`, ""));
    } else {
      props.onMessage(message.trim());
    }
    textAreaRef.current?.blur();
  }

  useEffect(() => {
    const newPrependText = props.replyingUsername
      ? `@${props.replyingUsername} `
      : "";
    setPrependText(newPrependText);
    setMessage((prevMessage) => newPrependText + prevMessage);
    if (props.replyingUsername) {
      textAreaRef.current?.focus();
    }
  }, [props.replyingUsername]);

  useEffect(() => {
    if (props.loading === false) {
      setMessage("");
      setRows(1);
    }
  }, [props.loading]);

  if (props.loading) {
    return (
      <>
        {props.replyingUsername && (
          <ReplyingUsernameDiv
            replyingUsername={props.replyingUsername}
            onCancel={() => {}}
          />
        )}
        <div className={styles.main}>
          <textarea
            ref={textAreaRef}
            onChange={handleInput}
            onKeyDown={handleKeyDown}
            className={styles.textAreaDisabled}
            placeholder="Write a comment..."
            rows={rows}
            value={message}
            disabled
          />
          {message !== prependText && (
            <p
              onClick={handlePost}
              style={
                textAreaRef.current
                  ? {
                      top: `${textAreaRef.current.offsetHeight / 2}px`,
                      opacity: 0.3,
                    }
                  : {}
              }
              className={styles.postButton}
            >
              Post
            </p>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      {props.replyingUsername && (
        <ReplyingUsernameDiv
          replyingUsername={props.replyingUsername}
          onCancel={() => {
            props.onCancel();

            setMessage((prevMessage) => {
              const newPrependText = props.replyingUsername
                ? `@${props.replyingUsername} `
                : "";
              return prevMessage.startsWith(newPrependText)
                ? prevMessage.slice(newPrependText.length)
                : prevMessage;
            });
          }}
        />
      )}
      <div className={styles.main}>
        <textarea
          ref={textAreaRef}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          className={styles.textArea}
          placeholder="Write a comment..."
          rows={rows}
          value={message}
        />
        {message !== prependText && (
          <p
            onClick={handlePost}
            style={
              textAreaRef.current
                ? { top: `${textAreaRef.current.offsetHeight / 2}px` }
                : {}
            }
            className={styles.postButton}
          >
            Post
          </p>
        )}
      </div>
    </>
  );
}

function ReplyingUsernameDiv(props: {
  replyingUsername: string;
  onCancel: () => void;
}) {
  return (
    <div className={styles.replyingDiv}>
      <p className={styles.replyingText}>
        Replying to @{props.replyingUsername}
      </p>
      <div
        onClick={() => props.onCancel()}
        className={styles.replyingCloseButton}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
        >
          <g clipPath="url(#clip0_64996_20831)">
            <path
              d="M6.04992 4.99998L9.53002 1.51988C9.81986 1.23004 9.81986 0.759722 9.53002 0.469882C9.24018 0.180042 8.76986 0.180042 8.48002 0.469882L4.99992 3.94998L1.51982 0.469882C1.22998 0.180042 0.759661 0.180042 0.469821 0.469882C0.179981 0.759722 0.179981 1.23004 0.469821 1.51988L3.94992 4.99998L0.469821 8.48008C0.179981 8.76992 0.179981 9.24024 0.469821 9.53008C0.609661 9.66992 0.799901 9.75 0.989742 9.75C1.17958 9.75 1.36982 9.68008 1.50966 9.53008L4.99986 6.04998L8.47996 9.53008C8.6198 9.66992 8.81004 9.75 8.99988 9.75C9.18972 9.75 9.37996 9.68008 9.5198 9.53008C9.80964 9.24024 9.80964 8.76992 9.5198 8.48008L6.04992 4.99998Z"
              fill="#928E8C"
            />
          </g>
          <defs>
            <clipPath id="clip0_64996_20831">
              <rect width="10" height="10" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
}
