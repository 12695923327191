export default function XMark(props: { color: string }) {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ paddingLeft: "2px" }}
    >
      <path
        d="M7.89656 0.991699L4.74148 4.14678L1.5864 0.991699L0.0351562 2.54295L3.19024 5.69803L0.0351562 8.8531L1.5864 10.4044L4.74148 7.24927L7.89656 10.4044L9.44781 8.8531L6.29273 5.69803L9.44781 2.54295L7.89656 0.991699Z"
        fill={props.color}
      />
    </svg>
  );
}
