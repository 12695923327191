import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  ConnectionAvailability,
  ConnectionLlmRequest,
  ContentType,
  DimensionEvent,
  DiscoverableCompatibilitySnippet,
  DiscoveredCompatibilitySnippet,
  LlmRequest,
  LlmRequestStatus,
  PublicProfile,
  TraitIdentifier,
} from "@dimensional-engineering/dimensional-models";

import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { isTimestampWithin1Hour } from "@/components/authenticatedProfile/utils";
import elementsJsonData from "../../../../../assets/traitInfo/elements.json";
import patternsJsonData from "../../../../../assets/traitInfo/patterns.json";
import archetypesJsonData from "../../../../../assets/traitInfo/archetypes.json";
import { db } from "@/_firebase/firebaseConfig";
import { Mixpanel } from "@/helpers/mixpanel";
import {
  ExtendedArchetype,
  ExtendedPattern,
  ExtendedScale,
  RomanticCompatibilityReport,
  WorkCompatibilityReport,
} from "@/models/sharedModels";
import { traitPermissionStatus } from "@/components/traits/utils";

export function computeCompatibilitySnippets(slug: string, friendUid: string) {
  const func = callFirebaseFunctions("computeCompatibilitySnippet");
  return func({
    connectionUid: friendUid,
    snippetSlug: slug,
  });
}

export function snippetConnectionRequirement(
  connectionAvail: ConnectionAvailability[]
): "basic" | "close" | "deep" | "private" | "unconnected" {
  const levelMap: Record<ConnectionAvailability, number> = {
    [ConnectionAvailability.basic]: 0,
    [ConnectionAvailability.close]: 1,
    [ConnectionAvailability.deep]: 2,
    [ConnectionAvailability.private]: 3,
    [ConnectionAvailability.unconnected]: 4,
  };

  const includedLevels = connectionAvail
    .filter((level) => level in levelMap)
    .map((level) => levelMap[level]);

  if (includedLevels.length === 0) {
    return "basic";
  }

  const minLevel = Math.min(...includedLevels);

  const levelInverseMap: Record<number, ConnectionAvailability> = {
    0: ConnectionAvailability.basic,
    1: ConnectionAvailability.close,
    2: ConnectionAvailability.deep,
  };

  return levelInverseMap[minLevel];
}

export function sortDiscoverableCompatibilitySnippets(
  a: DiscoverableCompatibilitySnippet,
  b: DiscoverableCompatibilitySnippet
): number {
  const order: Record<string, number> = {
    "compatibility-snippet-cognitive-compatibility": 0,
    "compatibility-snippet-friendship-summary": 1,
    "compatibility-snippet-motivational-values": 2,
    "compatibility-snippet-interaction-styles": 3,
    "compatibility-snippet-lifestyle-compared": 4,
    "compatibility-snippet-emoji-friends": 5,
    "compatibility-snippet-primary-traits-compared": 6,
    "compatibility-snippet-ideology-compared": 7,
    "compatibility-snippet-love-attitudes-compared": 8,
    "compatibility-snippet-love-style-compatibility": 9,
    "compatibility-snippet-emoji-lovers": 10,
    "compatibility-snippet-sexual-compatibility": 11,
    "compatibility-snippet-attachment-styles": 12,
    "compatibility-snippet-romantic-compatibility-report": 13,
    "compatibility-snippet-core-selves-compared": 14,
    "compatibility-snippet-outlooks-and-worldviews": 15,
    "compatibility-snippet-how-you-complement": 16,
    "compatibility-snippet-work-compatibility-report": 17,
    "compatibility-snippet-questions-to-ask": 18,
    "compatibility-snippet-conflict-styles-compared": 19,
    "compatibility-snippet-predictions-of-future": 20,
  };

  return order[a?.snippetSlug] - order[b?.snippetSlug];
}

export function checkDimensionDiscoveryGate(
  userDimensionsionsCompleted: DimensionEvent[] | null,
  requiredDimensionSlugs?: string[] | null | undefined
) {
  if (!userDimensionsionsCompleted || !userDimensionsionsCompleted.length) {
    return false;
  }
  let allSlugs: string[] = [];
  userDimensionsionsCompleted?.forEach((e) => {
    if (e.event === "complete") {
      allSlugs.push(e.dimensionSlug);
    }
  });
  let isAllComplete = true;
  requiredDimensionSlugs?.forEach((s) => {
    if (!allSlugs.includes(s)) {
      isAllComplete = false;
    }
  });

  return isAllComplete;
}

export function checkConnectionDimensionDiscoveryGate(
  dimensionData: string[],
  requiredDimensionSlugs?: string[] | null | undefined
) {
  let isAllComplete = true;
  requiredDimensionSlugs?.forEach((s) => {
    if (!dimensionData.includes(s)) {
      isAllComplete = false;
    }
  });
  return isAllComplete;
}

export function checkIfCompatibilityLlmRequestExists(
  llmRequests: ConnectionLlmRequest[],
  snippetSlug: string
): boolean {
  let exists = false;
  llmRequests.forEach((req) => {
    if (req.contentIdentifier.contentSlug === snippetSlug) {
      exists = true;
    }
  });

  return exists;
}

export function checkCompatibilityStatus(
  llmRequests: ConnectionLlmRequest[],
  snippetSlug: string
): LlmRequestStatus | null {
  let status: LlmRequestStatus | null = null;
  llmRequests.forEach((req) => {
    if (req.contentIdentifier.contentSlug === snippetSlug) {
      status = req.status;
    }
  });

  return status;
}

export function isCompatibilityErrorButtonActive(
  llmRequests: ConnectionLlmRequest[],
  snippetSlug: string
): boolean {
  let pastOneHour: boolean | null = null;
  llmRequests.forEach((req) => {
    if (req.contentIdentifier.contentSlug === snippetSlug) {
      pastOneHour = isTimestampWithin1Hour(req.createdOn);
    }
  });

  if (pastOneHour !== null) {
    return pastOneHour;
  } else {
    return true;
  }
}

export async function updateCompatibilitSnippetsFeedback(
  slug: string,
  uid: string,
  value: number,
  connectionUid: string
) {
  const ref = doc(
    db,
    "members",
    `${uid}`,
    "discoveredCompatibilitySnippets",
    `${connectionUid}-${slug}`
  );
  Mixpanel?.track("Feedback given", {
    slug: slug,
    content_type: "compatibility_snippet",
    score: value,
  });
  return await updateDoc(ref, {
    feedbackScore: value,
  });
}

export async function updateRomanticCompatibilitFeedback(
  uid: string,
  value: number,
  connectionUid: string
) {
  const ref = doc(
    db,
    "members",
    `${uid}`,
    "romanticCompatibilityReports",
    `${connectionUid}`
  );
  Mixpanel?.track("Feedback given", {
    slug: "compatibility-snippet-romantic-compatibility-report",
    content_type: "compatibility_snippet",
    score: value,
  });
  return await updateDoc(ref, {
    feedbackScore: value,
  });
}

export async function updateWorkCompatibilitFeedback(
  uid: string,
  value: number,
  connectionUid: string
) {
  const ref = doc(
    db,
    "members",
    `${uid}`,
    "workCompatibilityReports",
    `${connectionUid}`
  );
  Mixpanel?.track("Feedback given", {
    slug: "compatibility-snippet-work-compatibility-report",
    content_type: "compatibility_snippet",
    score: value,
  });
  return await updateDoc(ref, {
    feedbackScore: value,
  });
}

export async function getCompatibilitySnippetFeedbackScore(
  slug: string,
  uid: string,
  connectionUid: string
): Promise<number | null> {
  const ref = doc(
    db,
    "members",
    `${uid}`,
    "discoveredCompatibilitySnippets",
    `${connectionUid}-${slug}`
  );
  const docSnap = await getDoc(ref);

  if (docSnap.exists()) {
    if (
      docSnap.data().feedbackScore !== null &&
      docSnap.data().feedbackScore !== undefined
    ) {
      return docSnap.data().feedbackScore as number;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export async function getRomanticCompatibilityFeedbackScore(
  uid: string,
  connectionUid: string
): Promise<number | null> {
  const ref = doc(
    db,
    "members",
    `${uid}`,
    "romanticCompatibilityReports",
    `${connectionUid}`
  );
  const docSnap = await getDoc(ref);

  if (docSnap.exists()) {
    if (
      docSnap.data().feedbackScore !== null &&
      docSnap.data().feedbackScore !== undefined
    ) {
      return docSnap.data().feedbackScore as number;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export async function getWorkCompatibilityFeedbackScore(
  uid: string,
  connectionUid: string
): Promise<number | null> {
  const ref = doc(
    db,
    "members",
    `${uid}`,
    "workCompatibilityReports",
    `${connectionUid}`
  );
  const docSnap = await getDoc(ref);

  if (docSnap.exists()) {
    if (
      docSnap.data().feedbackScore !== null &&
      docSnap.data().feedbackScore !== undefined
    ) {
      return docSnap.data().feedbackScore as number;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function getTraitIdentifierName(trait: TraitIdentifier) {
  let name = "";
  if (trait.type === "element") {
    elementsJsonData.forEach((el) => {
      if (el.slug === trait.slug) {
        name = el.alias ?? el.name;
      }
    });
  } else if (trait.type === "pattern") {
    patternsJsonData.forEach((patt) => {
      if (patt.slug === trait.slug) {
        name = patt.name;
      }
    });
  } else {
    archetypesJsonData.forEach((arch) => {
      if (arch.slug === trait.slug) {
        name = arch.name;
      }
    });
  }

  return name;
}

export function getElementFromTraitIdentifier(
  trait: TraitIdentifier
): ExtendedArchetype | ExtendedPattern | ExtendedScale | null {
  let element: ExtendedArchetype | ExtendedPattern | ExtendedScale | null =
    null;
  if (trait.type === "element") {
    elementsJsonData.forEach((el) => {
      if (el.slug === trait.slug) {
        element = el as ExtendedScale;
      }
    });
  } else if (trait.type === "pattern") {
    patternsJsonData.forEach((patt) => {
      if (patt.slug === trait.slug) {
        element = patt as unknown as ExtendedPattern;
      }
    });
  } else {
    archetypesJsonData.forEach((arch) => {
      if (arch.slug === trait.slug) {
        element = arch as unknown as ExtendedArchetype;
      }
    });
  }

  return element;
}

export function toSentenceCase(input: string): string {
  if (input.length === 0) {
    return input;
  }

  const words = input?.split(" ");

  const result = words
    .map((word, index) => (index === 0 ? word : word.toLowerCase()))
    .join(" ");

  return result;
}

export async function getCompatibilitySnippetLLMRequest(
  uid: string | undefined,
  snippet: DiscoveredCompatibilitySnippet,
  connectionUid: string
) {
  const snippetsManualLlmRef = query(
    collection(db, `connectionLlmRequests/${uid}/compatibilitySnippet`),
    where("contentIdentifier", "==", {
      contentType: ContentType.compatibilitySnippet,
      contentSlug: snippet?.snippetSlug,
    }),
    where("trigger", "==", "manualRegenerate"),
    where("requesteeUid", "==", connectionUid),
    orderBy("createdOn", "desc"),
    limit(1)
  );

  let allRequests: LlmRequest[] = [];

  await getDocs(snippetsManualLlmRef)
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        allRequests.push(doc.data() as LlmRequest);
      });
    })
    .catch((err) => {
      console.log(err);
    });

  return allRequests[0];
}

export async function getRomanticCompatibilitySnippetLLMRequest(
  uid: string | undefined,
  connectionUid: string
) {
  const snippetsManualLlmRef = query(
    collection(db, `connectionLlmRequests/${uid}/compatibilitySnippet`),
    where("contentIdentifier", "==", {
      contentType: ContentType.compatibilitySnippet,
      contentSlug: "compatibility-snippet-romantic-compatibility-report",
    }),
    where("trigger", "==", "manualRegenerate"),
    where("requesteeUid", "==", connectionUid),
    orderBy("createdOn", "desc"),
    limit(1)
  );

  let allRequests: LlmRequest[] = [];

  await getDocs(snippetsManualLlmRef)
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        allRequests.push(doc.data() as LlmRequest);
      });
    })
    .catch((err) => {
      console.log(err);
    });

  return allRequests[0];
}

export async function getWorkCompatibilitySnippetLLMRequest(
  uid: string | undefined,
  connectionUid: string
) {
  const snippetsManualLlmRef = query(
    collection(db, `connectionLlmRequests/${uid}/compatibilitySnippet`),
    where("contentIdentifier", "==", {
      contentType: ContentType.compatibilitySnippet,
      contentSlug: "compatibility-snippet-work-compatibility-report",
    }),
    where("trigger", "==", "manualRegenerate"),
    where("requesteeUid", "==", connectionUid),
    orderBy("createdOn", "desc"),
    limit(1)
  );

  let allRequests: LlmRequest[] = [];

  await getDocs(snippetsManualLlmRef)
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        allRequests.push(doc.data() as LlmRequest);
      });
    })
    .catch((err) => {
      console.log(err);
    });

  return allRequests[0];
}

export async function getCompatibilitySnippetFromResults(
  slug: string,
  publicProfile: PublicProfile | null,
  userId: string | undefined
) {
  const discoveredRef = query(
    collection(db, `members/${userId}/discoveredCompatibilitySnippets`),
    where("connectionUid", "==", publicProfile?.ownerUUID),
    where("snippetSlug", "==", slug)
  );

  const discoverableRef = query(
    collection(db, "discoverableCompatibilitySnippets"),
    where("snippetSlug", "==", slug)
  );

  const getDiscoveredSnippets = await getDocs(discoveredRef);
  const getDiscoverableSnippets = await getDocs(discoverableRef);

  const getArchetypeData = callFirebaseFunctions("fetchProfileArchetypes");

  return await Promise.all([
    getDiscoverableSnippets,
    getDiscoveredSnippets,
    getArchetypeData({ uid: publicProfile?.ownerUUID }),
  ]);
}

export async function getRomanticCompatibilitySnippetFromResults(
  publicProfile: PublicProfile | null,
  userId: string | undefined
) {
  const romaticReportRef = doc(
    db,
    `members/${userId}/romanticCompatibilityReports/${publicProfile?.ownerUUID}`
  );

  return await getDoc(romaticReportRef)
    .then((res) => {
      if (res.exists()) {
        return res.data() as RomanticCompatibilityReport;
      } else {
        throw new Error("Romantic compatibility report does not exist.");
      }
    })
    .catch((err) => {
      return null;
    });
}

export async function getWorkCompatibilitySnippetFromResults(
  publicProfile: PublicProfile | null,
  userId: string | undefined
) {
  const workReportRef = doc(
    db,
    `members/${userId}/workCompatibilityReports/${publicProfile?.ownerUUID}`
  );

  return await getDoc(workReportRef)
    .then((res) => {
      if (res.exists()) {
        return res.data() as WorkCompatibilityReport;
      } else {
        throw new Error("Work compatibility report does not exist.");
      }
    })
    .catch((err) => {
      return null;
    });
}

export async function handleGatingLogicForResultsSnippets(
  discoverableSnippet: DiscoverableCompatibilitySnippet | null,
  friendStatus:
    | "connected"
    | "close"
    | "deep"
    | "sent"
    | "unconnected"
    | "received"
    | null,
  dimensionEvents: DimensionEvent[] | null,
  publicProfile: PublicProfile | null | undefined
): Promise<
  | "close"
  | "deep"
  | "private"
  | "basic"
  | { requiredDimensionSlugs: string[] | null | undefined }
  | "Nudge view"
  | "Error"
  | null
  | undefined
> {
  const connectionStatus = traitPermissionStatus(
    friendStatus,
    discoverableSnippet?.connectionAvailabilities
  );

  if (connectionStatus !== null) {
    //dealing with connection issues
    return connectionStatus;
  }

  const userCompletedAllDimensions = checkDimensionDiscoveryGate(
    dimensionEvents,
    discoverableSnippet?.discoveryGate.requiredDimensionSlugs
  );

  if (!userCompletedAllDimensions) {
    return {
      requiredDimensionSlugs:
        discoverableSnippet?.discoveryGate.requiredDimensionSlugs,
    };
  }

  //get connection dimension events
  let dimensionEventRef = collection(
    db,
    `members/${publicProfile?.ownerUUID}/dimensionEvents`
  );
  await getDocs(dimensionEventRef).then((querySnap) => {
    let dimEvents: string[] = [];
    querySnap.forEach((doc) => {
      const d = doc.data() as DimensionEvent;
      if (d.event === "complete") {
        dimEvents.push(d.dimensionSlug);
      }
    });
    const connectionCompletedAllDimentsions =
      checkConnectionDimensionDiscoveryGate(
        dimEvents,
        discoverableSnippet?.discoveryGate.requiredDimensionSlugs
      );
    if (!connectionCompletedAllDimentsions) {
      return "Nudge view";
    }
  });

  return "Error";
}

export function getSimilarityText(score: number) {
  if (score < 16) {
    return "Polar opposites";
  } else if (score < 36) {
    return "Very different";
  } else if (score < 51) {
    return "Somewhat different";
  } else if (score < 66) {
    return "Somewhat similar";
  } else if (score < 86) {
    return "Very similar";
  } else {
    return "Nearly identical";
  }
}

export function getCompatibilityText(score: number) {
  if (score < 16) {
    return "Very incompatible";
  } else if (score < 36) {
    return "Somewhat incompatible";
  } else if (score < 51) {
    return "Somewhat compatible";
  } else if (score < 66) {
    return "Somewhat compatible";
  } else if (score < 86) {
    return " Very compatible";
  } else {
    return "Great match";
  }
}
