export default function PollIcon() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="26" cy="26" r="26" fill="#55504F" />
      <path
        d="M18.8555 24.8938C18.8555 24.3415 19.3032 23.8938 19.8555 23.8938H41.1519C41.7042 23.8938 42.1519 24.3415 42.1519 24.8938V27.9309C42.1519 28.4832 41.7042 28.9309 41.1519 28.9309H19.8555C19.3032 28.9309 18.8555 28.4832 18.8555 27.9309V24.8938Z"
        fill="#FFFFF2"
      />
      <path
        d="M18.8555 16.2776C18.8555 15.7253 19.3032 15.2776 19.8555 15.2776H34.5408C35.093 15.2776 35.5408 15.7253 35.5408 16.2776V19.3147C35.5408 19.8669 35.093 20.3147 34.5408 20.3147H19.8555C19.3032 20.3147 18.8555 19.8669 18.8555 19.3147V16.2776Z"
        fill="#FFFFF2"
      />
      <path
        d="M18.8555 32.9629C18.8555 32.4106 19.3032 31.9629 19.8555 31.9629H26.0407C26.593 31.9629 27.0407 32.4106 27.0407 32.9629V36C27.0407 36.5522 26.593 37 26.0407 37H19.8555C19.3032 37 18.8555 36.5522 18.8555 36V32.9629Z"
        fill="#FFFFF2"
      />
      <path
        d="M15.8137 17.7898C15.8137 19.1622 14.7007 20.2751 13.3271 20.2751C11.9547 20.2751 10.8418 19.1621 10.8418 17.7898C10.8418 16.4174 11.9547 15.3044 13.3271 15.3044C14.7008 15.3044 15.8137 16.4174 15.8137 17.7898Z"
        fill="#6AB4EA"
      />
      <path
        d="M15.8137 26.3257C15.8137 27.6981 14.7007 28.811 13.3271 28.811C11.9547 28.811 10.8418 27.698 10.8418 26.3257C10.8418 24.952 11.9547 23.8391 13.3271 23.8391C14.7008 23.8391 15.8137 24.9521 15.8137 26.3257Z"
        fill="#E0CC65"
      />
      <path
        d="M15.8137 34.3696C15.8137 35.742 14.7007 36.8549 13.3271 36.8549C11.9547 36.8549 10.8418 35.742 10.8418 34.3696C10.8418 32.9972 11.9547 31.8843 13.3271 31.8843C14.7008 31.8843 15.8137 32.9972 15.8137 34.3696Z"
        fill="#FFA0B9"
      />
    </svg>
  );
}
