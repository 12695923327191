import { useSelector } from "react-redux";

import styles from "./PollPercentages.module.css";

import { RootState } from "@/redux/store";
import WarningIcon from "@/components/shared/GhostBox/WarningIcon";

export default function PollPercentages() {
  const pollState = useSelector((state: RootState) => state.userPoll);
  if (
    !pollState.userPoll ||
    !pollState.userPoll.voteCount ||
    pollState.userPoll.voteCount < 2
  ) {
    return (
      <section className={styles.ghostboxMain}>
        <div className={styles.ghostboxDiv}>
          <div className={styles.warningIconDiv}>
            <WarningIcon active />
          </div>
          <p className={styles.ghostboxText}>No responses yet</p>
        </div>
      </section>
    );
  }

  const optionIndexMap = {
    0: "A.",
    1: "B.",
    2: "C.",
    3: "D.",
    4: "E.",
    5: "F.",
    6: "G.",
  };

  const mappedOptions = [...pollState.userPoll?.pollOptions]
    .sort((a, b) => a.id - b.id)
    .map((option, index) => {
      const total = pollState.userPoll?.voteCount ?? 0;
      const optionTotal = option.voteCount ?? 0;
      const percentage = (optionTotal / total) * 100;
      return (
        <div
          style={
            pollState.userCurrentPollResponse?.selectedOption?.id === option.id
              ? { border: "1px solid #2CB0FF" }
              : {}
          }
          className={styles.optionDiv}
          key={option.id}
        >
          <div
            style={{ width: `${percentage}%` }}
            className={styles.pollScoreDiv}
          ></div>
          <p className={styles.optionLabel}>
            {optionIndexMap[index as keyof typeof optionIndexMap]}{" "}
            {option.label}
          </p>
          <p className={styles.optionLabelPercentage}>
            {percentage.toFixed(0)}%
          </p>
        </div>
      );
    });

  return <section className={styles.wrapper}>{mappedOptions}</section>;
}
