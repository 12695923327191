import { ReactNode, useEffect, useState, createContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { useRouter } from "next/router";

import { useAlertContext } from "./AlertContext";
import Alert from "@/components/shared/Alerts/Alert";
import { useModalContext } from "./ModalContext";
import GeneralModal from "@/components/shared/GeneralModal/GeneralModal";
import ConversionModal from "@/components/premium/ConversionModal/ConversionModal";
import RewardsModal from "@/components/appHome/rewards/RewardsModal";
import DailyStoriesModal from "@/components/appHome/DailyStories/DailyStoriesModal";
import PeerFeedbackModal from "@/components/appHome/PeerFeedBackModule/PeerFeedbackModal/PeerFeedbackModal";
import {
  ExtendedClientNotificationData,
  ExtendedClientNotificationType,
} from "@/models/sharedModels";
import { openInNewTab } from "@/components/shared/utils";
import ProfileModalHandler from "@/components/authenticatedProfile/Abstracts/ProfileModal/ProfileModalHandler";

interface Props {
  children?: ReactNode;
}

export const NotificationContext = createContext<{}>({});

export const InAppNotificationHandler = ({ children }: Props): JSX.Element => {
  const router = useRouter();
  const [notificationData, setNotificationData] = useState<any | null>(null);

  const userState = useSelector((state: RootState) => state.user);
  const connectionState = useSelector((state: RootState) => state.connections);

  const { setAlertComponent } = useAlertContext();
  const { setModalComponent } = useModalContext();

  function clearNotificationData() {
    localStorage.removeItem("inAppNotificationType");
    setNotificationData(null);
  }

  useEffect(() => {
    const checkLocalStorage = () => {
      const LSNotificationType = localStorage.getItem("inAppNotificationType");
      if (LSNotificationType) {
        const data = JSON.parse(LSNotificationType);
        if (notificationData === null) {
          setNotificationData(data);
        }
        localStorage.removeItem("inAppNotificationType");
      }
    };

    const intervalId = setInterval(checkLocalStorage, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (notificationData) {
      const {
        notificationType,
        dimensionSlug,
        peerUid,
        genericModalTitle,
        genericModalBody,
        linkUrl,
        reportSlug,
        storySlug,
        participantUids,
      } = JSON.parse(notificationData) as ExtendedClientNotificationData;

      switch (notificationType as ExtendedClientNotificationType) {
        case "viewProfile":
          if (peerUid && peerUid !== "null") {
            const profile = connectionState.publicProfiles?.find(
              (p) => p.ownerUUID === peerUid
            );
            if (profile) {
              setAlertComponent(
                <Alert
                  buttonText="View"
                  message={`@${profile?.userName} just discovered their Primary Nature.`}
                  elementName="Alert"
                  type="neutral"
                  onButtonClick={() => {
                    setModalComponent(
                      <ProfileModalHandler
                        uid={peerUid}
                        elementName="profileModal"
                      />
                    );
                  }}
                />,
                7000
              );
            }
          }
          clearNotificationData();
          break;

        case "viewGenericModal":
          if (genericModalTitle && genericModalBody) {
            setAlertComponent(
              <Alert
                buttonText="View"
                message={genericModalTitle}
                elementName="Alert"
                type="neutral"
                onButtonClick={() => {
                  setModalComponent(
                    <GeneralModal
                      title={genericModalTitle}
                      body={genericModalBody}
                    />
                  );
                }}
              />,
              7000
            );
            clearNotificationData();
          }
          break;

        case "viewPremiumConversionModal":
          setAlertComponent(
            <Alert
              buttonText="Details"
              message={"Dimensional Plus now available on Android"}
              elementName="Alert"
              type="neutral"
              onButtonClick={() => {
                setModalComponent(<ConversionModal />);
              }}
            />,
            7000
          );
          clearNotificationData();
          break;

        case "viewRewards":
          setAlertComponent(
            <Alert
              buttonText="Details"
              message={`You've reached invite reward level ${userState.inviteLevel}`}
              elementName="Alert"
              type="neutral"
              onButtonClick={() => {
                router.push("/app/home");
                setModalComponent(<RewardsModal />);
              }}
            />,
            7000
          );
          clearNotificationData();
          break;

        case "viewReport":
          if (reportSlug) {
            // router.push(`/app/results`);

            // if (userState.memberProfile?.shareHash) {
            //   console.log(
            //     `https://www.dimensional.me/reports/${reportSlug}/${userState.memberProfile?.shareHash}`
            //   );
            //   openInNewTab(
            //     `https://www.dimensional.me/reports/${reportSlug}/${userState.memberProfile?.shareHash}`
            //   );
            // }

            clearNotificationData();
          }

          break;

        case "surveyLinkReceived":
          if (linkUrl) {
            setAlertComponent(
              <Alert
                buttonText="View"
                message={`New survey available.`}
                elementName="Alert"
                type="neutral"
                onButtonClick={() => {
                  openInNewTab(linkUrl);
                }}
              />,
              7000
            );
          }
          clearNotificationData();
          break;

        case "viewPeerAssessment":
          if (peerUid) {
            const profile = connectionState.publicProfiles?.find(
              (p) => p.ownerUUID === peerUid
            );
            setAlertComponent(
              <Alert
                buttonText="GO"
                message={`@${profile?.userName} wants you to take their Peer Assessment.`}
                elementName="Alert"
                type="neutral"
                onButtonClick={() => {
                  router.push(`/app/peer-assessment/peer-core/${peerUid}`);
                }}
              />,
              7000
            );
            return clearNotificationData();
          }
          break;

        case "viewDailyStory":
          setAlertComponent(
            <Alert
              buttonText="View"
              message={`New Daily Story availbale.`}
              elementName="Alert"
              type="neutral"
              onButtonClick={() => {
                if (storySlug) {
                  if (router.asPath === "/app/home") {
                    setModalComponent(
                      <DailyStoriesModal elementName="DailyStory" />
                    );
                    return clearNotificationData();
                  } else {
                    router.push("/app/home");
                    setModalComponent(
                      <DailyStoriesModal elementName="DailyStory" />
                    );
                    return clearNotificationData();
                  }
                }
              }}
            />,
            7000
          );
          break;

        case "viewPeerFeedbackLevelsModal":
          setAlertComponent(
            <Alert
              buttonText="View"
              message={`New rewards unlocked 🎁`}
              elementName="Alert"
              type="success"
              onButtonClick={() => {
                router.push("/app/home");
                setModalComponent(<PeerFeedbackModal />);
                return clearNotificationData();
              }}
            />,
            7000
          );

        default:
          console.warn(`Unhandled notification type: ${notificationType}`);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationData]);

  return (
    <NotificationContext.Provider value={{}}>
      {children}
    </NotificationContext.Provider>
  );
};
