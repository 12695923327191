import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";

import styles from "./AuthAboutTab.module.css";

import AuthMobileBioAndLinks from "./AuthMobileBioAndLinks/AuthMobileBioAndLinks";
import SummaryTable from "@/components/publicProfiles/SummaryTable/SummaryTable";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import { useAuth } from "@/context/AuthContext";
import AuthArchetypes from "@/components/traits/arcehtype/Archetypes/AuthArchetypes";
import DiscoveryGate from "@/components/shared/DiscoveryGate/DiscoveryGate";
import { useModalContext } from "@/context/ModalContext";
import ResultsHeading from "@/components/results/ResultsHeading/ResultsHeading";
import StoriesMap from "@/components/results/stories/StoriesMap";
import { summonProfileLink } from "@/components/shared/utils";
import { AnalysisSectionOwn } from "./AnalysisSection/AnalysisSection";
import Shimmer from "@/components/shared/Shimmer/Shimmer";

export default function AuthAboutTab() {
  const colorMap = ProfileColorMap;
  const userState = useSelector((state: RootState) => state.user);
  const { user } = useAuth();
  const { setModalOpen, setModalComponent } = useModalContext();

  return (
    <div className={styles.main}>
      <AuthMobileBioAndLinks
        imageUrl={userState.imageURL}
        bio={userState.publicProfileData?.bio!!}
        links={userState.publicProfileData?.socialMediaMap!!}
        publicProfile={userState?.publicProfileData}
      />
      {userState.summaryTableData ? (
        <SummaryTable
          auth
          natureColor={
            userState?.publicProfileData?.primaryNature?.nature
              ? colorMap[userState.publicProfileData.primaryNature?.nature]
              : null
          }
          data={userState.summaryTableData}
        />
      ) : (
        <div style={{ width: "100%", padding: "0 2%" }}>
          <Shimmer height={350} />
        </div>
      )}

      <div className={styles.storiesDiv}>
        <ResultsHeading title="Stories" />
        <StoriesMap />
      </div>

      <AuthArchetypes
        username={userState.publicProfileData?.userName}
        archetypes={userState.archetypesDiscovered ?? []}
        uid={user?.uid ?? null}
        onUnknownClick={(slug) => {
          setModalComponent(
            <DiscoveryGate
              fromArchetypes
              onClose={() => setModalOpen(false)}
              requiredDimensionSlugs={slug}
              onOpenShareLink={() => {
                summonProfileLink(
                  user?.uid,
                  "gating modal",
                  "Invite to Dimensional"
                );
              }}
            />
          );
        }}
      />

      <div className={styles.storiesDiv}>
        <ResultsHeading title="Analysis" />
        <AnalysisSectionOwn />
      </div>
    </div>
  );
}
