import { PublicProfile } from "@dimensional-engineering/dimensional-models";

import styles from "./AuthLeftBar.module.css";

import { FriendsPublicProfile } from "@/models/sharedModels";
import AuthLeftBarOwnProfile from "./AuthLeftBarAbstracts/AuthLeftBarOwnProfile";
import AuthLeftBarConnectedAndUnconnectedProfile from "./AuthLeftBarAbstracts/AuthLeftBarConnectedAndUnconnectedProfile";
import Shimmer from "@/components/shared/Shimmer/Shimmer";

type LeftBarProps = {
  imageUrl: string | null;
  connectionList: FriendsPublicProfile[] | [];
  publicProfile: PublicProfile | null;
  ownProfile: boolean;
};

export default function AuthLeftBar(props: LeftBarProps) {
  if (props.publicProfile) {
    if (props.ownProfile) {
      //Own Profile
      return <AuthLeftBarOwnProfile />;
    } else {
      //connected/unconnected
      return (
        <AuthLeftBarConnectedAndUnconnectedProfile
          imageUrl={props.imageUrl}
          publicProfile={props.publicProfile}
          connectionList={props.connectionList}
        />
      );
    }
  } else {
    return (
      <div className={styles.shimmerDiv}>
        <div className={styles.imageShimmer}>
          <Shimmer rounded />
        </div>
        <div className={styles.shimmerInfo}>
          <Shimmer />
        </div>
      </div>
    );
  }
}
