import styles from "./Archetypes.module.css";

import { usePublicProfileContext } from "@/context/PublicProfileContext";
import useIsMobile from "@/helpers/useIsMobile";

type ArchetypeUnknownProps = {
  name: string;
  locked: boolean;
  noMargin?: boolean;
};

export default function ArchetypeUnknown(props: ArchetypeUnknownProps) {
  const isMobile = useIsMobile();
  const { setModal } = usePublicProfileContext();

  if (isMobile) {
    return (
      <div
        onClick={() => {
          props.locked === true ? setModal(true) : null;
        }}
        className={styles.architypeBodyUnknown}
        style={props.noMargin ? { margin: "0" } : {}}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
          }}
        >
          <p className={styles.topTextUnknown}>{props.name.toUpperCase()}</p>
          <svg
            className={styles.archSVGUnknown}
            width="91"
            height="51"
            viewBox="0 0 51 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M50.143 43.8755C51.1417 45.5674 49.922 47.7037 47.9573 47.7037H3.0427C1.078 47.7037 -0.141709 45.5674 0.856999 43.8755L23.3143 5.83018C24.2964 4.16631 26.7036 4.16631 27.6857 5.83018L50.143 43.8755ZM25.5415 35.6319C27.8626 35.6319 29.7442 33.7503 29.7442 31.4293C29.7442 29.1082 27.8626 27.2266 25.5415 27.2266C23.2205 27.2266 21.3389 29.1082 21.3389 31.4293C21.3389 33.7503 23.2205 35.6319 25.5415 35.6319Z"
              stroke="#FFFFF2"
            />
          </svg>
          <p className={styles.bottomTextUnknown}>
            {props.locked ? "LOCKED" : "UNKNOWN"}
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div
        onClick={() => {
          props.locked === true ? setModal(true) : null;
        }}
        className={styles.architypeBodyUnknown}
        style={props.noMargin ? { margin: "0" } : {}}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p className={styles.topTextUnknown}>{props.name.toUpperCase()}</p>
          <svg
            className={styles.archSVGUnknown}
            width="51"
            height="51"
            viewBox="0 0 51 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M50.143 43.8755C51.1417 45.5674 49.922 47.7037 47.9573 47.7037H3.0427C1.078 47.7037 -0.141709 45.5674 0.856999 43.8755L23.3143 5.83018C24.2964 4.16631 26.7036 4.16631 27.6857 5.83018L50.143 43.8755ZM25.5415 35.6319C27.8626 35.6319 29.7442 33.7503 29.7442 31.4293C29.7442 29.1082 27.8626 27.2266 25.5415 27.2266C23.2205 27.2266 21.3389 29.1082 21.3389 31.4293C21.3389 33.7503 23.2205 35.6319 25.5415 35.6319Z"
              stroke="#FFFFF2"
            />
          </svg>
          <p className={styles.bottomTextUnknown}>
            {props.locked ? "LOCKED" : "UNKNOWN"}
          </p>
        </div>
      </div>
    );
  }
}

export function ArchetypeUnknownApp(props: ArchetypeUnknownProps) {
  return (
    <div
      className={styles.architypeBodyUnknownApp}
      style={props.noMargin ? { margin: "0" } : {}}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <p className={styles.topTextUnknown}>{props.name.toUpperCase()}</p>
        <svg
          className={styles.archSVGUnknownApp}
          width="91"
          height="51"
          viewBox="0 0 51 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M50.143 43.8755C51.1417 45.5674 49.922 47.7037 47.9573 47.7037H3.0427C1.078 47.7037 -0.141709 45.5674 0.856999 43.8755L23.3143 5.83018C24.2964 4.16631 26.7036 4.16631 27.6857 5.83018L50.143 43.8755ZM25.5415 35.6319C27.8626 35.6319 29.7442 33.7503 29.7442 31.4293C29.7442 29.1082 27.8626 27.2266 25.5415 27.2266C23.2205 27.2266 21.3389 29.1082 21.3389 31.4293C21.3389 33.7503 23.2205 35.6319 25.5415 35.6319Z"
            stroke="#FFFFF2"
          />
        </svg>
        <p className={styles.bottomTextUnknown}>
          {props.locked ? "LOCKED" : "UNKNOWN"}
        </p>
      </div>
    </div>
  );
}
