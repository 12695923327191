import {
  CommentThread,
  PeriodicQuiz,
  PeriodicQuizResult,
} from "@dimensional-engineering/dimensional-models";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface PeriodicQuizSliceInterface {
  availableQuiz: PeriodicQuiz | null;
  periodicQuizResults: PeriodicQuizResult[];
  commentThread: CommentThread | null;
  periodicQuizLoaded: boolean;
}

const initialState: PeriodicQuizSliceInterface = {
  availableQuiz: null,
  periodicQuizResults: [],
  commentThread: null,
  periodicQuizLoaded: false,
};

export const periodicQuizSlice = createSlice({
  name: "periodicQuiz",
  initialState: initialState,
  reducers: {
    setAvailableQuiz: (state, action: PayloadAction<PeriodicQuiz>) => {
      return { ...state, availableQuiz: action.payload };
    },
    setPeriodicQuizResults: (
      state,
      action: PayloadAction<PeriodicQuizResult[]>
    ) => {
      return { ...state, periodicQuizResults: action.payload };
    },
    setPeriodicQuizCommentThread: (
      state,
      action: PayloadAction<CommentThread | null>
    ) => {
      return { ...state, commentThread: action.payload };
    },
    setPeriodicQuizLoaded: (state, action: PayloadAction<boolean>) => {
      return { ...state, periodicQuizLoaded: action.payload };
    },
  },
});

export const {
  setAvailableQuiz,
  setPeriodicQuizResults,
  setPeriodicQuizCommentThread,
  setPeriodicQuizLoaded,
} = periodicQuizSlice.actions;

export default periodicQuizSlice.reducer;
