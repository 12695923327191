import styles from "./SocialLinks.module.css";

export function TwitterImage(props: {
  url: string | null;
  onClick?: () => void;
  noMoragin?: boolean;
}) {
  return (
    <div className={styles.socialImageContainer}>
      <svg
        onClick={(e) => {
          props.onClick && props.onClick();
          props.url
            ? window.open(`https://twitter.com/${props.url}`, "_blank")
            : null;
        }}
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.socialImage}
        style={props.noMoragin ? { margin: "0" } : {}}
      >
        <path
          d="M21.7778 2.309C20.9765 2.701 20.1154 2.965 19.2116 3.084C20.1345 2.475 20.8431 1.51 21.1762 0.36C20.3132 0.924 19.3568 1.334 18.3387 1.555C17.5248 0.598 16.3624 0 15.0775 0C12.1928 0 10.0731 2.966 10.7246 6.045C7.01244 5.84 3.72037 3.88 1.51628 0.901C0.345722 3.114 0.909222 6.009 2.89826 7.475C2.16689 7.449 1.47726 7.228 0.875648 6.859C0.826648 9.14 2.31026 11.274 4.459 11.749C3.83017 11.937 3.14144 11.981 2.44093 11.833C3.00896 13.789 4.65863 15.212 6.615 15.252C4.73667 16.875 2.37015 17.6 0 17.292C1.97724 18.689 4.32652 19.504 6.84911 19.504C15.1446 19.504 19.8314 11.783 19.5483 4.858C20.4212 4.163 21.1789 3.296 21.7778 2.309Z"
          fill={props.url ? "#FFFFF2" : "grey"}
        />
      </svg>
      <p
        onClick={(e) => {
          props.url
            ? window.open(`https://twitter.com/${props.url}`, "_blank")
            : null;
        }}
      >
        {`twitter.com/${props.url}`}
      </p>
    </div>
  );
}

export function InstagramImage(props: {
  url: string | null;
  onClick?: () => void;
  noMoragin?: boolean;
}) {
  return (
    <div className={styles.socialImageContainer}>
      <svg
        onClick={(e) => {
          props.onClick && props.onClick();
          props.url
            ? window.open(`https://www.instagram.com/${props.url}`, "_blank")
            : null;
        }}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.socialImage}
        style={props.noMoragin ? { margin: "0" } : {}}
      >
        <path
          d="M12 2.163C15.204 2.163 15.584 2.175 16.85 2.233C20.102 2.381 21.621 3.924 21.769 7.152C21.827 8.417 21.838 8.797 21.838 12.001C21.838 15.206 21.826 15.585 21.769 16.85C21.62 20.075 20.105 21.621 16.85 21.769C15.584 21.827 15.206 21.839 12 21.839C8.796 21.839 8.416 21.827 7.151 21.769C3.891 21.62 2.38 20.07 2.232 16.849C2.174 15.584 2.162 15.205 2.162 12C2.162 8.796 2.175 8.417 2.232 7.151C2.381 3.924 3.896 2.38 7.151 2.232C8.417 2.175 8.796 2.163 12 2.163ZM12 0C8.741 0 8.333 0.014 7.053 0.072C2.695 0.272 0.273 2.69 0.073 7.052C0.014 8.333 0 8.741 0 12C0 15.259 0.014 15.668 0.072 16.948C0.272 21.306 2.69 23.728 7.052 23.928C8.333 23.986 8.741 24 12 24C15.259 24 15.668 23.986 16.948 23.928C21.302 23.728 23.73 21.31 23.927 16.948C23.986 15.668 24 15.259 24 12C24 8.741 23.986 8.333 23.928 7.053C23.732 2.699 21.311 0.273 16.949 0.073C15.668 0.014 15.259 0 12 0V0ZM12 5.838C8.597 5.838 5.838 8.597 5.838 12C5.838 15.403 8.597 18.163 12 18.163C15.403 18.163 18.162 15.404 18.162 12C18.162 8.597 15.403 5.838 12 5.838ZM12 16C9.791 16 8 14.21 8 12C8 9.791 9.791 8 12 8C14.209 8 16 9.791 16 12C16 14.21 14.209 16 12 16ZM18.406 4.155C17.61 4.155 16.965 4.8 16.965 5.595C16.965 6.39 17.61 7.035 18.406 7.035C19.201 7.035 19.845 6.39 19.845 5.595C19.845 4.8 19.201 4.155 18.406 4.155Z"
          fill={props.url ? "#FFFFF2" : "grey"}
        />
      </svg>
      <p
        onClick={(e) => {
          props.url
            ? window.open(`https://www.instagram.com/${props.url}`, "_blank")
            : null;
        }}
      >
        {`instagram.com/${props.url}`}
      </p>
    </div>
  );
}

export function LinkedInImage(props: {
  url: string | null;
  onClick?: () => void;
  noMoragin?: boolean;
}) {
  return (
    <div className={styles.socialImageContainer}>
      <svg
        onClick={(e) => {
          props.onClick && props.onClick();
          props.url
            ? window.open(`https://www.linkedin.com/in/${props.url}`, "_blank")
            : null;
        }}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.socialImage}
        style={props.noMoragin ? { margin: "0" } : {}}
      >
        <path
          d="M19 0H5C2.239 0 0 2.239 0 5V19C0 21.761 2.239 24 5 24H19C21.762 24 24 21.761 24 19V5C24 2.239 21.762 0 19 0ZM8 19H5V8H8V19ZM6.5 6.732C5.534 6.732 4.75 5.942 4.75 4.968C4.75 3.994 5.534 3.204 6.5 3.204C7.466 3.204 8.25 3.994 8.25 4.968C8.25 5.942 7.467 6.732 6.5 6.732ZM20 19H17V13.396C17 10.028 13 10.283 13 13.396V19H10V8H13V9.765C14.396 7.179 20 6.988 20 12.241V19Z"
          fill={props.url ? "#FFFFF2" : "grey"}
        />
      </svg>
      <p
        onClick={(e) => {
          props.url
            ? window.open(`https://www.linkedin.com/in/${props.url}`, "_blank")
            : null;
        }}
      >
        {`linkedin.com/in/${props.url}`}
      </p>
    </div>
  );
}

export function TikTokImage(props: {
  url: string | null;
  onClick?: () => void;
  noMoragin?: boolean;
}) {
  return (
    <div className={styles.socialImageContainer}>
      <svg
        onClick={(e) => {
          props.onClick && props.onClick();
          props.url
            ? window.open(`https://www.tiktok.com/@${props.url}`, "_blank")
            : null;
        }}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.socialImage}
        style={props.noMoragin ? { margin: "0" } : {}}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7534 0H2.25383C1.0153 0 0 1.0153 0 2.24662V21.7462C0 22.9847 1.0153 23.9928 2.25383 23.9928H21.7534C22.9919 23.9928 24.0072 22.9775 24.0072 21.7462V2.24662C24.0072 1.0081 22.9919 0 21.7534 0ZM14.9415 4.48605C15.1935 6.61746 16.3816 7.89199 18.4482 8.0288V10.4266C17.2457 10.5419 16.1944 10.153 14.9703 9.41134V13.8974C14.9703 19.6004 8.75607 21.3861 6.25743 17.2961C4.65167 14.6679 5.63096 10.0522 10.7867 9.86499V12.3996C10.3978 12.4644 9.973 12.5653 9.59136 12.6877C8.44644 13.0765 7.79838 13.8038 7.9784 15.0783C8.32403 17.5338 12.8317 18.2538 12.45 13.4581V4.47885H14.9343L14.9415 4.48605Z"
          fill={props.url ? "#FFFFF2" : "grey"}
        />
      </svg>
      <p
        onClick={(e) => {
          props.url
            ? window.open(`https://www.tiktok.com/@${props.url}`, "_blank")
            : null;
        }}
      >
        {`tiktok.com/@${props.url}`}
      </p>
    </div>
  );
}
