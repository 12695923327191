import { useEffect } from "react";
import { useSelector } from "react-redux";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import useUpdateCommentThreadData from "../CommentModal/useUpdateCommentThreadData";
import { RootState } from "@/redux/store";
import CommentModal from "../CommentModal/CommentModal";
import { useModalContext } from "@/context/ModalContext";
import { Mixpanel } from "@/helpers/mixpanel";

export default function CommunityQuestionsModal(props: {
  elementName: "CommunityQuestionsModal";
  commentId?: string;
}) {
  const { setModalOpen } = useModalContext();

  const communityQuestion = useSelector(
    (state: RootState) => state.communityQuestion
  ).communityQuestion;
  const { showComments, setShowComments } = useUpdateCommentThreadData(
    communityQuestion?.commentThreadId,
    true
  );

  useEffect(() => {
    Mixpanel?.track("Community question viewed", {
      slug: communityQuestion?.slug,
      body: communityQuestion?.body,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showComments && (
        <CommentModal
          onClose={() => {
            setModalOpen(false);
            setShowComments(false);
          }}
          commentId={props.commentId}
          slug={communityQuestion?.slug ?? ""}
          audienceGroupSize={Infinity}
          source="daily community question"
          content={communityQuestion?.body ?? ""}
        />
      )}
      <ModalWrapper noHeader noLine title="" backgroundColor="transparent">
        <></>
      </ModalWrapper>
    </>
  );
}
