import {
  CommentThread,
  StoryDelivery,
} from "@dimensional-engineering/dimensional-models";
import { DeliveredDailyStoryNew } from "@dimensional-engineering/dimensional-models/lib/models/stories/DeliveredDailyStoryNew";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface DailyStorySlice {
  storyDelivery: StoryDelivery | null;
  dailyStory: DeliveredDailyStoryNew | null;
  commentThread: CommentThread | null;
  dailyStoryLoaded: boolean;
}

const initialState: DailyStorySlice = {
  dailyStory: null,
  storyDelivery: null,
  commentThread: null,
  dailyStoryLoaded: false,
};

export const dailyStorySlice = createSlice({
  name: "dailyStory",
  initialState: initialState,
  reducers: {
    setStoryDelivery: (state, action: PayloadAction<StoryDelivery | null>) => {
      return { ...state, storyDelivery: action.payload };
    },
    setDailyStory: (
      state,
      action: PayloadAction<DeliveredDailyStoryNew | null>
    ) => {
      return { ...state, dailyStory: action.payload };
    },
    setDailyStoryCommentThread: (
      state,
      action: PayloadAction<CommentThread | null>
    ) => {
      return { ...state, commentThread: action.payload };
    },
    setDailyStoryLoaded: (state, action: PayloadAction<boolean>) => {
      return { ...state, dailyStoryLoaded: action.payload };
    },
  },
});

export const {
  setDailyStory,
  setStoryDelivery,
  setDailyStoryCommentThread,
  setDailyStoryLoaded,
} = dailyStorySlice.actions;

export default dailyStorySlice.reducer;
