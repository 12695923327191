import { useDispatch, useSelector } from "react-redux";
import {
  Archetype,
  CompatibilitySnippetType,
  ContentType,
  DiscoverableCompatibilitySnippet,
  DiscoveredCompatibilitySnippet,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "./CompatibilitySnippets.module.css";

import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";
import { useModalContext } from "@/context/ModalContext";
import LoadingModal from "@/components/shared/LoadingModal/LoadingModal";
import {
  checkCompatibilityStatus,
  checkConnectionDimensionDiscoveryGate,
  checkDimensionDiscoveryGate,
  checkIfCompatibilityLlmRequestExists,
  getCompatibilitySnippetFromResults,
  getRomanticCompatibilitySnippetFromResults,
  getWorkCompatibilitySnippetFromResults,
  handleGatingLogicForResultsSnippets,
  isCompatibilityErrorButtonActive,
} from "./utils";
import { useAuth } from "@/context/AuthContext";
import CompatibilityModal from "./CompatibilityModal";
import RomanticCompatibilityModal from "./RomanticCompatibilityModal";
import WorkCompatibilityModal from "./WorkCompatibilityModal";
import useConnectionStatus from "@/helpers/useConnectionStatus";
import { RootState } from "@/redux/store";
import ConnectionRequestModal from "@/components/connections/ConnectionRequestModal/ConnectionRequestModal";
import CloseConnectionModal from "@/components/connections/ConnectionRequestModal/CloseConnectionModal";
import DeepConnectionModal from "@/components/connections/ConnectionRequestModal/DeepConnectionModal";
import DiscoveryGate from "@/components/shared/DiscoveryGate/DiscoveryGate";
import NudgeModal from "@/components/shared/NudgeModal/NudgeModal";
import ErrorStateCompatibilitySnippet from "./ErrorStateCompatibilitySnippet";
import { traitPermissionStatus } from "@/components/traits/utils";
import ConversionModal from "@/components/premium/ConversionModal/ConversionModal";
import GenerateCompatibilityModal from "./GenerateCompatibilityModal";
import ComputingStateSnippetModal from "./ComputingStateSnippetModal";
import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";
import CompatiblityTwoByTwoIcon from "./icons/CompatiblityTwoByTwoIcon";
import CompatiblitySpectrumsIcon from "./icons/CompatiblitySpectrumsIcon";
import CompatibilityRankingListIcon from "./icons/CompatibilityRankingListIcon";
import {
  compatibilityIconsMap,
  compatibilityIconsMapMaxWidth,
  largeSnippets,
  wideSnippets,
} from "./CompatibilityData";
import Loader from "@/components/shared/Loader";
import CircularScoreBarLarge from "../CircularScoreBar/CircularScoreBarLarge";
import {
  CircularScoreBarSmallWithIcon,
  CircularScoreBarThin,
} from "../CircularScoreBar/CircularScoreBar";
import { getCompatibilityScore, getSimilarityScore } from "../utils";
import { setSimilarityAndCompatibilityScores } from "@/redux/slices/connectionSlice";
import Shimmer from "@/components/shared/Shimmer/Shimmer";

export type ArchetypeDataModel = {
  dimensionSlug: string;
  archetypeScore: number;
  archetypeSlug: string;
  archetype: Archetype;
};

export default function CompatibilitySnippetBlock(props: {
  slug: string;
  publicProfile: PublicProfile | undefined | null;
  large?: boolean;
}) {
  const { setModalComponent, removeModalByName, setModalOpen } =
    useModalContext();
  const dispatch = useDispatch();

  const userState = useSelector((state: RootState) => state.user);
  const compatibilitySnippetState = useSelector(
    (state: RootState) => state.compatibilitySnippets
  );

  const imageUrl = useGetProfilePhoto(props.publicProfile?.ownerUUID);
  const friendStatus = useConnectionStatus(
    props.publicProfile?.ownerUUID as string
  );
  const { user } = useAuth();

  const username = props.publicProfile?.userName ?? "";
  const dispayUsername =
    username.length > 13 ? `@${username.slice(0, 13)}...` : username;

  const discoverableSnippet =
    compatibilitySnippetState.discoverableCompatibilitySnippets?.find(
      (s) => s?.snippetSlug === props.slug
    );

  function handleGatingLogic(
    gating:
      | "close"
      | "deep"
      | "private"
      | "basic"
      | { requiredDimensionSlugs: string[] | null | undefined }
      | "Nudge view"
      | "Error"
      | undefined
      | null,
    discoverableSnippet: DiscoverableCompatibilitySnippet
  ) {
    if (gating === "basic") {
      return setModalComponent(
        <ConnectionRequestModal
          publicProfile={props.publicProfile as PublicProfile}
          text="Requires basic connection"
          elementName="ConnectionModal"
        />
      );
    }

    if (gating === "close") {
      return setModalComponent(
        <CloseConnectionModal
          fromContentLock
          publicProfile={props.publicProfile as PublicProfile}
          elementName="ConnectionModal"
        />
      );
    }

    if (gating === "deep") {
      return setModalComponent(
        <DeepConnectionModal
          fromContentLock
          publicProfile={props.publicProfile as PublicProfile}
          elementName="ConnectionModal"
        />
      );
    }

    if (typeof gating === "object") {
      return setModalComponent(
        <DiscoveryGate
          requiredDimensionSlugs={gating?.requiredDimensionSlugs as string[]}
          onClose={() => setModalOpen(false)}
          onOpenShareLink={() => {}}
        />
      );
    }

    if (gating === "Nudge view") {
      return setModalComponent(
        <NudgeModal
          publicProfile={props.publicProfile as PublicProfile}
          content={{
            contentType: ContentType.compatibilitySnippet,
            contentSlug: discoverableSnippet?.snippetSlug,
          }}
        />
      );
    }

    return setModalComponent(
      <ErrorStateCompatibilitySnippet
        disabled={true}
        snippet={discoverableSnippet}
        connectionUid={props.publicProfile?.ownerUUID}
      />
    );
  }

  function hanldeClick() {
    if (props.publicProfile) {
      setModalComponent(<LoadingModal elementName="LoadingModal" />);
      if (!largeSnippets.includes(props.slug)) {
        //regular snippets
        getCompatibilitySnippetFromResults(
          props.slug,
          props.publicProfile,
          user?.uid
        )
          .then(async (results) => {
            let disoverableSnippet: DiscoverableCompatibilitySnippet | null =
              null;
            let disoveredSnippet: DiscoveredCompatibilitySnippet | null = null;
            //index 0 is discoverable , 1 is discovered, 2 is archetypes
            results[0].forEach((doc) => {
              disoverableSnippet =
                doc.data() as DiscoverableCompatibilitySnippet;
            });
            results[1].forEach((doc) => {
              disoveredSnippet = doc.data() as DiscoveredCompatibilitySnippet;
            });
            const archetypeData: ArchetypeDataModel[] = results[2]
              .data as ArchetypeDataModel[];

            if (
              disoveredSnippet &&
              disoverableSnippet &&
              props.publicProfile &&
              archetypeData
            ) {
              let score: number | null = null;
              if (discoverableSnippet?.compatibilityComputePoolSlug) {
                score = (
                  await getCompatibilityScore(
                    discoverableSnippet?.snippetSlug,
                    props.publicProfile.ownerUUID
                  )
                ).score;
              }
              if (discoverableSnippet?.similarityComputePoolSlug) {
                score = (
                  await getSimilarityScore(
                    discoverableSnippet?.snippetSlug,
                    props.publicProfile.ownerUUID
                  )
                ).score;
              }

              dispatch(
                setSimilarityAndCompatibilityScores([
                  { score: score, slug: props.slug },
                ])
              );

              removeModalByName("LoadingModal");
              return setModalComponent(
                <CompatibilityModal
                  publicProfile={props.publicProfile}
                  discoverableSnippet={disoverableSnippet}
                  discoveredSnippets={[disoveredSnippet]}
                  archetypeData={archetypeData}
                />
              );
            } else {
              //handling gating logic
              handleGatingLogicForResultsSnippets(
                disoverableSnippet,
                friendStatus,
                userState.dimensionEvents,
                props.publicProfile
              ).then((res) => {
                if (disoverableSnippet) {
                  removeModalByName("LoadingModal");
                  handleGatingLogic(res, disoverableSnippet);
                }
              });
              removeModalByName("LoadingModal");
            }
          })
          .catch((err) => {
            return removeModalByName("LoadingModal");
          });
      } else {
        if (
          props.slug === "compatibility-snippet-romantic-compatibility-report"
        ) {
          getRomanticCompatibilitySnippetFromResults(
            props.publicProfile,
            user?.uid
          )
            .then((res) => {
              removeModalByName("LoadingModal");
              return setModalComponent(
                <RomanticCompatibilityModal
                  romanticCompatibilityReport={res}
                  publicProfile={props.publicProfile ?? null}
                />
              );
            })
            .catch((err) => {
              //handling gating logic
              return removeModalByName("LoadingModal");
            });
        } else if (
          props.slug === "compatibility-snippet-work-compatibility-report"
        ) {
          getWorkCompatibilitySnippetFromResults(props.publicProfile, user?.uid)
            .then((res) => {
              removeModalByName("LoadingModal");
              return setModalComponent(
                <WorkCompatibilityModal
                  workCompatibilityReport={res}
                  publicProfile={props.publicProfile ?? null}
                />
              );
            })
            .catch((err) => {
              //handling gating logic
              return removeModalByName("LoadingModal");
            });
        }
      }
    }
  }

  return (
    <div
      onClick={() => {
        hanldeClick();
      }}
      className={styles.snippetBlockLatestResults}
    >
      <p className={styles.usernameTextResults}>{dispayUsername}</p>
      <div className={styles.resultsImageDiv}>
        <SmallProfileImage
          publicProfile={props.publicProfile ?? null}
          imageUrl={imageUrl}
          medium
          noLink
        />
        {props.slug !== "compatibility-snippet-romantic-compatibility-report" &&
          props.slug !== "compatibility-snippet-work-compatibility-report" &&
          props.slug !== "compatibility-snippet-overall-similarity" &&
          props.slug !== "compatibility-snippet-dark-sides-similarity" && (
            <div className={styles.resultIconDiv}>
              {
                compatibilityIconsMap[
                  props.slug as keyof typeof compatibilityIconsMap
                ]
              }
            </div>
          )}
      </div>

      <p className={styles.snippetName}>{discoverableSnippet?.name}</p>
    </div>
  );
}

export function CompatibilitySnippetBlockCompareTab(props: {
  slug: string;
  friendStatus:
    | "connected"
    | "close"
    | "deep"
    | "sent"
    | "unconnected"
    | "received"
    | null;
  loading: boolean;
}) {
  const { setModalComponent, setModalOpen } = useModalContext();
  const userState = useSelector((state: RootState) => state.user);
  const compatibilitySnippetState = useSelector(
    (state: RootState) => state.compatibilitySnippets
  );

  function handleOpenCompatibilityModal(s: DiscoverableCompatibilitySnippet) {
    if (
      s?.snippetSlug ===
        "compatibility-snippet-romantic-compatibility-report" &&
      compatibilitySnippetState.romanticCompatibilityReport
    ) {
      return setModalComponent(
        <RomanticCompatibilityModal
          romanticCompatibilityReport={
            compatibilitySnippetState.romanticCompatibilityReport
          }
          publicProfile={compatibilitySnippetState.publicProfile}
        />
      );
    }

    if (
      s?.snippetSlug === "compatibility-snippet-work-compatibility-report" &&
      compatibilitySnippetState.workCompatibilityReport
    ) {
      return setModalComponent(
        <WorkCompatibilityModal
          workCompatibilityReport={
            compatibilitySnippetState.workCompatibilityReport
          }
          publicProfile={compatibilitySnippetState.publicProfile}
        />
      );
    }

    if (compatibilitySnippetState.profileData) {
      return setModalComponent(
        <CompatibilityModal
          publicProfile={compatibilitySnippetState.publicProfile}
          discoverableSnippet={s}
          discoveredSnippets={
            compatibilitySnippetState.discoveredCompatibilitySnippets
          }
          archetypeData={compatibilitySnippetState.profileData.archetypeData}
        />
      );
    }
  }

  function handleUndiscoveredCompatibilityModal(
    s: DiscoverableCompatibilitySnippet
  ) {
    const status = traitPermissionStatus(
      compatibilitySnippetState.friendStatus,
      s.connectionAvailabilities
    );

    const userCompletedAllDimensions = checkDimensionDiscoveryGate(
      userState.dimensionEvents,
      s.discoveryGate.requiredDimensionSlugs
    );

    const connectionCompletedAllDimentsions =
      checkConnectionDimensionDiscoveryGate(
        compatibilitySnippetState.dimensionComplete,
        s.discoveryGate.requiredDimensionSlugs
      );

    const doesLlmRequestExist = checkIfCompatibilityLlmRequestExists(
      compatibilitySnippetState.llmRequests,
      s?.snippetSlug
    );

    const llmRequestStatus = checkCompatibilityStatus(
      compatibilitySnippetState.llmRequests,
      s?.snippetSlug
    );

    const isLastRequestWithin1Hour = isCompatibilityErrorButtonActive(
      compatibilitySnippetState.llmRequests,
      s?.snippetSlug
    );

    if (props.friendStatus === "unconnected") {
      return setModalComponent(
        <ConnectionRequestModal
          publicProfile={
            compatibilitySnippetState.publicProfile as PublicProfile
          }
          noMessage
          text="Requires basic connection"
          elementName="ConnectionModal"
        />
      );
    }

    if (!userState.isPremium && s.isPremium) {
      return setModalComponent(<ConversionModal initialSlide={3} />);
    }

    if (status !== null) {
      ///connection gated
      if (status === "basic") {
        return setModalComponent(
          <ConnectionRequestModal
            publicProfile={
              compatibilitySnippetState.publicProfile as PublicProfile
            }
            text="Requires basic connection"
            elementName="ConnectionModal"
          />
        );
      } else if (status === "close") {
        return setModalComponent(
          <CloseConnectionModal
            fromContentLock
            publicProfile={
              compatibilitySnippetState.publicProfile as PublicProfile
            }
            elementName="ConnectionModal"
          />
        );
      } else {
        return setModalComponent(
          <DeepConnectionModal
            fromContentLock
            publicProfile={
              compatibilitySnippetState.publicProfile as PublicProfile
            }
            elementName="ConnectionModal"
          />
        );
      }
    } else if (userCompletedAllDimensions === false) {
      return setModalComponent(
        <DiscoveryGate
          requiredDimensionSlugs={s.discoveryGate.requiredDimensionSlugs}
          onClose={() => setModalOpen(false)}
          onOpenShareLink={() => {}}
        />
      );
    } else if (connectionCompletedAllDimentsions === false) {
      return setModalComponent(
        <NudgeModal
          publicProfile={
            compatibilitySnippetState.publicProfile as PublicProfile
          }
          content={{
            contentType: ContentType.compatibilitySnippet,
            contentSlug: s?.snippetSlug,
          }}
        />
      );
    } else if (
      doesLlmRequestExist === false &&
      compatibilitySnippetState.profileData
    ) {
      return setModalComponent(
        <GenerateCompatibilityModal
          publicProfile={compatibilitySnippetState.publicProfile}
          snippet={s}
          elementName="GenerateCompatibilityModal"
          archetypeData={compatibilitySnippetState.profileData.archetypeData}
        />
      );
    } else if (
      llmRequestStatus === "created" ||
      llmRequestStatus === "processing"
    ) {
      if (compatibilitySnippetState.profileData) {
        return setModalComponent(
          <ComputingStateSnippetModal
            publicProfile={compatibilitySnippetState.publicProfile}
            discoverableSnippet={s}
            elementName="ComputingStateSnippetModal"
            archetypeData={compatibilitySnippetState.profileData.archetypeData}
          />
        );
      }
    } else {
      return setModalComponent(
        <ErrorStateCompatibilitySnippet
          disabled={isLastRequestWithin1Hour}
          snippet={s}
          connectionUid={compatibilitySnippetState.publicProfile?.ownerUUID}
        />
      );
    }
  }

  const discoverableSnippet =
    compatibilitySnippetState.discoverableCompatibilitySnippets?.find(
      (s) => s?.snippetSlug === props.slug
    );

  if (!discoverableSnippet) {
    return <></>;
  }

  if (
    discoverableSnippet.compatibilityComputePoolSlug ||
    discoverableSnippet.similarityComputePoolSlug
  ) {
    return <CompatibilitySnippetWithScoreBlockCompareTab {...props} />;
  }

  const iconTypeMatch = {
    twoByTwo: <CompatiblityTwoByTwoIcon />,
    spectrums: <CompatiblitySpectrumsIcon />,
    rankedList: <CompatibilityRankingListIcon />,
  };

  const icon = discoverableSnippet.snippetTypes.includes(
    CompatibilitySnippetType.twoByTwo
  ) ? (
    iconTypeMatch["twoByTwo"]
  ) : discoverableSnippet.snippetTypes.includes(
      CompatibilitySnippetType.spectrums
    ) ? (
    iconTypeMatch["spectrums"]
  ) : discoverableSnippet.snippetTypes.includes(
      CompatibilitySnippetType.rankedList
    ) ? (
    iconTypeMatch["rankedList"]
  ) : (
    <></>
  );

  return (
    <div
      className={
        largeSnippets.includes(discoverableSnippet?.snippetSlug)
          ? styles.snippetBlockLarge
          : styles.snippetBlock
      }
      key={discoverableSnippet?.snippetSlug}
      style={
        largeSnippets.includes(discoverableSnippet?.snippetSlug)
          ? {
              backgroundImage: `url(/compatibility-snippets/${discoverableSnippet?.snippetSlug}.jpg)`,
              minWidth: "0",
            }
          : { minWidth: "0" }
      }
      onClick={() =>
        compatibilitySnippetState.discoveredSlugs.includes(props.slug)
          ? handleOpenCompatibilityModal(discoverableSnippet)
          : handleUndiscoveredCompatibilityModal(discoverableSnippet)
      }
    >
      <div className={styles.typeIcon}>{icon}</div>
      {discoverableSnippet.isPremium && !userState.isPremium && (
        <div className={styles.premIconDiv}>
          <PremiumPillIcon />
        </div>
      )}

      <div
        style={
          !compatibilitySnippetState.discoveredSlugs.includes(props.slug)
            ? { opacity: 0.4 }
            : {}
        }
        className={styles.snippetIcon}
      >
        {
          compatibilityIconsMap[
            discoverableSnippet?.snippetSlug as keyof typeof compatibilityIconsMap
          ]
        }
      </div>
      <p
        style={
          !compatibilitySnippetState.discoveredSlugs.includes(props.slug)
            ? { opacity: 0.4 }
            : {}
        }
        className={styles.snippetName}
      >
        {discoverableSnippet.name}
      </p>
    </div>
  );
}

export function CompatibilitySnippetWithScoreBlockCompareTab(props: {
  slug: string;
  friendStatus:
    | "connected"
    | "close"
    | "deep"
    | "sent"
    | "unconnected"
    | "received"
    | null;
  loading: boolean;
}) {
  const { setModalComponent, setModalOpen } = useModalContext();
  const userState = useSelector((state: RootState) => state.user);
  const compatibilitySnippetState = useSelector(
    (state: RootState) => state.compatibilitySnippets
  );
  const scores = useSelector(
    (state: RootState) => state.connections
  ).similarityAndCompatibilityScores;
  const score = scores?.find((s) => s.slug === props.slug);

  function handleOpenCompatibilityModal(s: DiscoverableCompatibilitySnippet) {
    if (
      s?.snippetSlug ===
        "compatibility-snippet-romantic-compatibility-report" &&
      compatibilitySnippetState.romanticCompatibilityReport
    ) {
      return setModalComponent(
        <RomanticCompatibilityModal
          romanticCompatibilityReport={
            compatibilitySnippetState.romanticCompatibilityReport
          }
          publicProfile={compatibilitySnippetState.publicProfile}
        />
      );
    }

    if (
      s?.snippetSlug === "compatibility-snippet-work-compatibility-report" &&
      compatibilitySnippetState.workCompatibilityReport
    ) {
      return setModalComponent(
        <WorkCompatibilityModal
          workCompatibilityReport={
            compatibilitySnippetState.workCompatibilityReport
          }
          publicProfile={compatibilitySnippetState.publicProfile}
        />
      );
    }

    if (compatibilitySnippetState.profileData) {
      return setModalComponent(
        <CompatibilityModal
          publicProfile={compatibilitySnippetState.publicProfile}
          discoverableSnippet={s}
          discoveredSnippets={
            compatibilitySnippetState.discoveredCompatibilitySnippets
          }
          archetypeData={compatibilitySnippetState.profileData.archetypeData}
        />
      );
    }
  }

  function handleUndiscoveredCompatibilityModal(
    s: DiscoverableCompatibilitySnippet
  ) {
    const status = traitPermissionStatus(
      compatibilitySnippetState.friendStatus,
      s.connectionAvailabilities
    );

    const userCompletedAllDimensions = checkDimensionDiscoveryGate(
      userState.dimensionEvents,
      s.discoveryGate.requiredDimensionSlugs
    );

    const connectionCompletedAllDimentsions =
      checkConnectionDimensionDiscoveryGate(
        compatibilitySnippetState.dimensionComplete,
        s.discoveryGate.requiredDimensionSlugs
      );

    const doesLlmRequestExist = checkIfCompatibilityLlmRequestExists(
      compatibilitySnippetState.llmRequests,
      s?.snippetSlug
    );

    const llmRequestStatus = checkCompatibilityStatus(
      compatibilitySnippetState.llmRequests,
      s?.snippetSlug
    );

    const isLastRequestWithin1Hour = isCompatibilityErrorButtonActive(
      compatibilitySnippetState.llmRequests,
      s?.snippetSlug
    );

    if (props.friendStatus === "unconnected") {
      return setModalComponent(
        <ConnectionRequestModal
          publicProfile={
            compatibilitySnippetState.publicProfile as PublicProfile
          }
          noMessage
          text="Requires basic connection"
          elementName="ConnectionModal"
        />
      );
    }

    if (!userState.isPremium && s.isPremium) {
      return setModalComponent(<ConversionModal initialSlide={3} />);
    }

    if (status !== null) {
      ///connection gated
      if (status === "basic") {
        return setModalComponent(
          <ConnectionRequestModal
            publicProfile={
              compatibilitySnippetState.publicProfile as PublicProfile
            }
            text="Requires a connection"
            elementName="ConnectionModal"
          />
        );
      } else if (status === "close") {
        return setModalComponent(
          <CloseConnectionModal
            fromContentLock
            publicProfile={
              compatibilitySnippetState.publicProfile as PublicProfile
            }
            elementName="ConnectionModal"
          />
        );
      } else {
        return setModalComponent(
          <DeepConnectionModal
            fromContentLock
            publicProfile={
              compatibilitySnippetState.publicProfile as PublicProfile
            }
            elementName="ConnectionModal"
          />
        );
      }
    } else if (userCompletedAllDimensions === false) {
      return setModalComponent(
        <DiscoveryGate
          requiredDimensionSlugs={s.discoveryGate.requiredDimensionSlugs}
          onClose={() => setModalOpen(false)}
          onOpenShareLink={() => {}}
        />
      );
    } else if (connectionCompletedAllDimentsions === false) {
      return setModalComponent(
        <NudgeModal
          publicProfile={
            compatibilitySnippetState.publicProfile as PublicProfile
          }
          content={{
            contentType: ContentType.compatibilitySnippet,
            contentSlug: s?.snippetSlug,
          }}
        />
      );
    } else if (
      doesLlmRequestExist === false &&
      compatibilitySnippetState.profileData
    ) {
      return setModalComponent(
        <GenerateCompatibilityModal
          publicProfile={compatibilitySnippetState.publicProfile}
          snippet={s}
          elementName="GenerateCompatibilityModal"
          archetypeData={compatibilitySnippetState.profileData.archetypeData}
        />
      );
    } else if (
      llmRequestStatus === "created" ||
      llmRequestStatus === "processing"
    ) {
      if (compatibilitySnippetState.profileData) {
        return setModalComponent(
          <ComputingStateSnippetModal
            publicProfile={compatibilitySnippetState.publicProfile}
            discoverableSnippet={s}
            elementName="ComputingStateSnippetModal"
            archetypeData={compatibilitySnippetState.profileData.archetypeData}
          />
        );
      }
    } else {
      return setModalComponent(
        <ErrorStateCompatibilitySnippet
          disabled={isLastRequestWithin1Hour}
          snippet={s}
          connectionUid={compatibilitySnippetState.publicProfile?.ownerUUID}
        />
      );
    }
  }

  const discoverableSnippet =
    compatibilitySnippetState.discoverableCompatibilitySnippets?.find(
      (s) => s?.snippetSlug === props.slug
    );

  if (!discoverableSnippet) {
    return <></>;
  }

  const size: "large" | "wide" | "block" = largeSnippets.includes(
    discoverableSnippet?.snippetSlug
  )
    ? "large"
    : wideSnippets.includes(discoverableSnippet?.snippetSlug)
    ? "wide"
    : "block";

  const iconTypeMatch = {
    twoByTwo: <CompatiblityTwoByTwoIcon />,
    spectrums: <CompatiblitySpectrumsIcon />,
    rankedList: <CompatibilityRankingListIcon />,
  };

  const icon = discoverableSnippet.snippetTypes.includes(
    CompatibilitySnippetType.twoByTwo
  ) ? (
    iconTypeMatch["twoByTwo"]
  ) : discoverableSnippet.snippetTypes.includes(
      CompatibilitySnippetType.spectrums
    ) ? (
    iconTypeMatch["spectrums"]
  ) : discoverableSnippet.snippetTypes.includes(
      CompatibilitySnippetType.rankedList
    ) ? (
    iconTypeMatch["rankedList"]
  ) : (
    <></>
  );

  const isUndiscovered =
    !compatibilitySnippetState.discoveredSlugs.includes(props.slug) &&
    (score?.score == null || score.score === 0);

  if (props.loading) {
    return (
      <div
        style={{
          padding: "0",
          border: "1px solid #928e8c",
        }}
        className={
          size === "wide"
            ? styles.snippetBlockWide
            : size === "block"
            ? styles.snippetBlock
            : styles.snippetBlockLarge
        }
      >
        <Shimmer
          height={size === "large" ? 254 : 123}
          width={size === "wide" ? 181 : undefined}
        />
      </div>
    );
  }

  if (size === "wide") {
    //wide
    return (
      <div
        onClick={() =>
          compatibilitySnippetState.discoveredSlugs.includes(props.slug)
            ? handleOpenCompatibilityModal(discoverableSnippet)
            : handleUndiscoveredCompatibilityModal(discoverableSnippet)
        }
        className={styles.snippetBlockWide}
        style={isUndiscovered ? { opacity: "0.4" } : {}}
      >
        <CircularScoreBarThin score={score?.score} />
        <p
          style={isUndiscovered ? { opacity: "0.4" } : {}}
          className={styles.snippetNameLarge}
        >
          {discoverableSnippet.name}
        </p>
      </div>
    );
  } else if (size === "large") {
    //large
    return (
      <div
        onClick={() =>
          compatibilitySnippetState.discoveredSlugs.includes(props.slug)
            ? handleOpenCompatibilityModal(discoverableSnippet)
            : handleUndiscoveredCompatibilityModal(discoverableSnippet)
        }
        className={styles.snippetBlockLarge}
        key={discoverableSnippet?.snippetSlug}
        style={
          isUndiscovered
            ? {
                backgroundImage: `url(/compatibility-snippets/${discoverableSnippet?.snippetSlug}.jpg)`,
                opacity: "0.4",
                minWidth: "0",
              }
            : {
                backgroundImage: `url(/compatibility-snippets/${discoverableSnippet?.snippetSlug}.jpg)`,
                minWidth: "0",
              }
        }
      >
        <div className={styles.typeIcon}>{icon}</div>
        {discoverableSnippet.isPremium && !userState.isPremium && (
          <div className={styles.premIconDiv}>
            <PremiumPillIcon />
          </div>
        )}

        <CircularScoreBarLarge score={score?.score} />
        <p className={styles.snippetName}>{discoverableSnippet.name}</p>
      </div>
    );
  } else {
    //normal
    return (
      <div
        className={styles.snippetBlock}
        key={discoverableSnippet?.snippetSlug}
        onClick={() =>
          compatibilitySnippetState.discoveredSlugs.includes(props.slug)
            ? handleOpenCompatibilityModal(discoverableSnippet)
            : handleUndiscoveredCompatibilityModal(discoverableSnippet)
        }
      >
        <div className={styles.typeIcon}>{icon}</div>
        {discoverableSnippet.isPremium && !userState.isPremium && (
          <div className={styles.premIconDiv}>
            <PremiumPillIcon />
          </div>
        )}
        <CircularScoreBarSmallWithIcon
          score={score?.score}
          icon={
            compatibilityIconsMapMaxWidth[
              discoverableSnippet?.snippetSlug as keyof typeof compatibilityIconsMap
            ]
          }
          inactive={isUndiscovered}
        />

        <p
          style={isUndiscovered ? { opacity: "0.4" } : {}}
          className={styles.snippetName}
        >
          {discoverableSnippet.name}
        </p>
      </div>
    );
  }
}
