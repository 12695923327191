/* eslint-disable @next/next/no-img-element */
import { PublicProfile } from "@dimensional-engineering/dimensional-models";
import Image from "next/image";

import styles from "./SmallProfileImage.module.css";

import { ProfileColorMap } from "../ColorMap";
import { useModalContext } from "@/context/ModalContext";
import ProfileModalHandler from "@/components/authenticatedProfile/Abstracts/ProfileModal/ProfileModalHandler";

export default function VerySmallProfileImage(props: {
  publicProfile: PublicProfile | null;
  imageUrl: string;
  noLink?: boolean;
}) {
  const { setModalComponent } = useModalContext();

  if (props.noLink) {
    return (
      <div
        className={styles.verySmallImageContatiner}
        style={
          props.publicProfile?.primaryNature?.nature
            ? {
                border: `3px solid ${
                  ProfileColorMap[props.publicProfile.primaryNature?.nature]
                }`,
              }
            : {
                border: `3px solid #55504F`,
              }
        }
      >
        <Image
          fill
          alt=""
          src={props.imageUrl}
          className={styles.verySmallImage}
        />
      </div>
    );
  }

  return (
    <div
      onClick={(e) => {
        setModalComponent(
          <ProfileModalHandler
            uid={props.publicProfile?.ownerUUID}
            elementName="profileModal"
          />
        );
      }}
      className={styles.verySmallImageContatiner}
      style={
        props.publicProfile?.primaryNature?.nature
          ? {
              border: `3px solid ${
                ProfileColorMap[props.publicProfile.primaryNature?.nature]
              }`,
            }
          : {
              border: `3px solid #55504F`,
            }
      }
    >
      <Image
        fill
        alt=""
        src={props.imageUrl}
        className={styles.verySmallImage}
      />
    </div>
  );
}
