import { useSelector } from "react-redux";
import {
  ContentType,
  DiscoveredSnippet,
  PublicProfile,
  SnippetType,
} from "@dimensional-engineering/dimensional-models";

import styles from "./NutshellSnippet.module.css";

import { NustshellSnippetIconPreview } from "./NutshellSnippetIcon";
import { RootState } from "@/redux/store";
import { useModalContext } from "@/context/ModalContext";
import SnippetsModal, { SnippetsModalNonOwner } from "../SnippetsModal";
import SnippetDiscoveryGate from "@/components/shared/DiscoveryGate/SnippetDiscoveryGate";
import { summonProfileLink } from "@/components/shared/utils";
import { useAuth } from "@/context/AuthContext";
import NudgeModal from "@/components/shared/NudgeModal/NudgeModal";
import ConnectionRequestModal from "@/components/connections/ConnectionRequestModal/ConnectionRequestModal";

export default function NutshellSnippetPreviewOwn() {
  const snippetState = useSelector((state: RootState) => state.snippet);
  const discoveredSnippet = snippetState.snippets?.find(
    (s) => s?.snippetSlug === "snippet-nutshell"
  );
  const { user } = useAuth();

  const { setModalComponent, setModalOpen } = useModalContext();

  const mapped = discoveredSnippet?.snippetDetail.nutshellDetail?.llmContent
    ?.slice(0, 2)
    .map((llmContent) => {
      return (
        <div className={styles.previewContentRow} key={llmContent.title}>
          <h5 className={styles.previewContentTitle}>{llmContent.title}</h5>
          {llmContent.paragraphs?.map((para) => {
            const paragraph =
              para.length > 80 ? para.slice(0, 77) + "..." : para;
            return (
              <p className={styles.previewContentParagraph} key={para}>
                {paragraph}
              </p>
            );
          })}
        </div>
      );
    });

  const unknownMap = [
    { title: "THEY ARE", paragraph: "?" },
    { title: "THEY GET ALONG BEST WITH", paragraph: "?" },
  ].map((llmContent) => {
    return (
      <div className={styles.previewContentRow} key={llmContent.title}>
        <h5 className={styles.previewContentTitle}>{llmContent.title}</h5>
        <p className={styles.previewContentParagraph}>{llmContent.paragraph}</p>
      </div>
    );
  });

  function handleClick() {
    if (discoveredSnippet) {
      setModalComponent(
        <SnippetsModal discoveredSnippetSlug="snippet-nutshell" />
      );
    } else {
      setModalComponent(
        <SnippetDiscoveryGate
          snippetSlug={"snippet-nutshell"}
          snippetType={SnippetType.nutshell}
          requiredDimensionSlugs={["personality", "personality-II", "values"]}
          onClose={() => {
            setModalOpen(false);
          }}
          onOpenShareLink={() => {
            summonProfileLink(user?.uid, "dashboard", "Invite to Dimensional");
          }}
        />
      );
    }
  }

  return (
    <div
      style={!discoveredSnippet ? { opacity: "0.4" } : {}}
      className={styles.previewMain}
      onClick={() => handleClick()}
    >
      <div className={styles.previewTop}>
        <NustshellSnippetIconPreview />
        <h3 className={styles.previewTitle}>In Summary</h3>
      </div>
      <div className={styles.previewContentWrapper}>
        {discoveredSnippet ? mapped : unknownMap}
      </div>
    </div>
  );
}

export function NutshellSnippetPreviewNonOwner(props: {
  discoveredSnippet: DiscoveredSnippet | undefined;
  connectionState: "connected" | "unconnected";
  publicProfile: PublicProfile | null;
  imageUrl: string;
}) {
  const { setModalComponent } = useModalContext();

  const mapped =
    props.discoveredSnippet?.snippetDetail.nutshellDetail?.llmContent
      ?.slice(0, 2)
      .map((llmContent) => {
        return (
          <div className={styles.previewContentRow} key={llmContent.title}>
            <h5 className={styles.previewContentTitle}>{llmContent.title}</h5>
            {llmContent.paragraphs?.map((para) => {
              const paragraph =
                para.length > 75 ? para.slice(0, 72) + "..." : para;
              return (
                <p className={styles.previewContentParagraph} key={para}>
                  {paragraph}
                </p>
              );
            })}
          </div>
        );
      });

  const unknownMap = [
    {
      title: "THEY ARE",
      paragraph:
        props.connectionState === "connected" ? "?" : "Connection required",
    },
    {
      title: "THEY GET ALONG BEST WITH",
      paragraph:
        props.connectionState === "connected" ? "?" : "Connection required",
    },
  ].map((llmContent) => {
    return (
      <div className={styles.previewContentRow} key={llmContent.title}>
        <h5 className={styles.previewContentTitle}>{llmContent.title}</h5>
        <p className={styles.previewContentParagraph}>{llmContent.paragraph}</p>
      </div>
    );
  });

  function handleClick() {
    if (props.discoveredSnippet) {
      setModalComponent(
        <SnippetsModalNonOwner
          publicProfile={props.publicProfile}
          discoveredSnippet={props.discoveredSnippet}
          imageUrl={props.imageUrl}
        />
      );
    } else {
      if (props.connectionState === "connected") {
        setModalComponent(
          <NudgeModal
            publicProfile={props.publicProfile}
            content={{
              contentSlug: "snippet-nutshell",
              contentType: ContentType.resultSnippet,
            }}
          />
        );
      } else {
        if (props.publicProfile) {
          setModalComponent(
            <ConnectionRequestModal
              publicProfile={props.publicProfile}
              noMessage
              text="Requires basic connection"
              elementName="ConnectionModal"
            />
          );
        }
      }
    }
  }

  return (
    <div
      style={!props.discoveredSnippet ? { opacity: "0.4" } : {}}
      className={styles.previewMain}
      onClick={() => {
        handleClick();
      }}
    >
      <div className={styles.previewTop}>
        <NustshellSnippetIconPreview />
        <h3 className={styles.previewTitle}>In Summary</h3>
      </div>
      <div className={styles.previewContentWrapper}>
        {props.discoveredSnippet ? mapped : unknownMap}
      </div>
    </div>
  );
}
