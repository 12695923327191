import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  ContentType,
  DiscoverableCompatibilitySnippet,
  DiscoveredCompatibilitySnippet,
  FeedHandlerDetail,
  FeedHandlerType,
  FeedItemNew,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { useRouter } from "next/router";

import styles from "./NotificationViewModal.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { RootState } from "@/redux/store";
import FeedItemPrimaryIcon from "./FeedItemPrimaryIcon";
import { useAuth } from "@/context/AuthContext";
import { setLastFeedItemIdViewed } from "@/redux/slices/feedSlice";
import { useModalContext } from "@/context/ModalContext";
import GeneralModal from "@/components/shared/GeneralModal/GeneralModal";
import ConversionModal from "@/components/premium/ConversionModal/ConversionModal";
import RewardsModal from "../rewards/RewardsModal";
import { summonProfileLink } from "@/components/shared/utils";
import FeedItemSecondaryIcon from "./FeedItemSecondaryIcon";
import ArchetypeModal from "@/components/traits/TraitsModals/Archetype/ArchetypeModal";
import DiscoveryGate from "@/components/shared/DiscoveryGate/DiscoveryGate";
import { archetypeDiscoveryMap } from "@/assets/traitInfo/dimensionData";
import {
  getConnectionStatusFromUid,
  getPublicProfileAnImageUrlDataFromUid,
  getPublicProfileDataFromUid,
} from "../utils";
import {
  getCompatibilitySnippetFromResults,
  getRomanticCompatibilitySnippetFromResults,
  getWorkCompatibilitySnippetFromResults,
  handleGatingLogicForResultsSnippets,
} from "@/components/authenticatedProfile/tabs/AuthCompareTab/Compatibility/utils";
import { ArchetypeDataModel } from "@/components/authenticatedProfile/tabs/AuthCompareTab/Compatibility/CompatibilitySnippetBlock";
import CompatibilityModal from "@/components/authenticatedProfile/tabs/AuthCompareTab/Compatibility/CompatibilityModal";
import { largeSnippets } from "@/components/authenticatedProfile/tabs/AuthCompareTab/Compatibility/CompatibilityData";
import RomanticCompatibilityModal from "@/components/authenticatedProfile/tabs/AuthCompareTab/Compatibility/RomanticCompatibilityModal";
import WorkCompatibilityModal from "@/components/authenticatedProfile/tabs/AuthCompareTab/Compatibility/WorkCompatibilityModal";
import NatureModal from "@/components/authenticatedProfile/NatureModal/NatureModal";
import StoriesModal from "@/components/results/stories/StoriesModal";
import { Mixpanel } from "@/helpers/mixpanel";
import LoadingModal from "@/components/shared/LoadingModal/LoadingModal";
import ConnectionRequestModal from "@/components/connections/ConnectionRequestModal/ConnectionRequestModal";
import CloseConnectionModal from "@/components/connections/ConnectionRequestModal/CloseConnectionModal";
import DeepConnectionModal from "@/components/connections/ConnectionRequestModal/DeepConnectionModal";
import NudgeModal from "@/components/shared/NudgeModal/NudgeModal";
import ErrorStateCompatibilitySnippet from "@/components/authenticatedProfile/tabs/AuthCompareTab/Compatibility/ErrorStateCompatibilitySnippet";
import { useGetDimensionStatusAndTraitCount } from "@/pages/app/assessment";
import PeerFeedbackModal from "../PeerFeedBackModule/PeerFeedbackModal/PeerFeedbackModal";
import NotificationBar from "@/components/NotificationModule/NotificationBar/NotificationBar";
import SnippetsModal from "@/components/results/Snippets/SnippetsModal";
import PeriodicQuizResultModal from "../PeriodicQuizzes/PeriodicQuizResultModal/PeriodicQuizResultModal";
import DailyInsightsModal from "../DailyInsights/DailyInsightsModal/DailyInsightsModal";
import ExpirationModal from "@/components/shared/GeneralModal/ExpirationModal";
import DailyStoriesModal from "../DailyStories/DailyStoriesModal";
import PeriodicQuizModal from "../PeriodicQuizzes/PeriodicQuizModal/PeriodicQuizModal";
import UserPollResponseModal from "../UserPolls/UserPollResponseModal/UserPollResponseModal";
import UserPollModal from "../UserPolls/UserPollModal/UserPollModal";
import SharedPostModal from "../ABSTRACTS/AppHomeSharedTab/SharedPostModal/SharedPostModal";
import ProfileModalHandler from "@/components/authenticatedProfile/Abstracts/ProfileModal/ProfileModalHandler";
import CommunityQuestionsModal from "../CommunityQuestions/CommunityQuestionsModal";

export default function NotificationViewModal() {
  const { user } = useAuth();

  const { paginateFeedItems } = useAuth();
  const { setModalComponent, setModalOpen, removeModalByName } =
    useModalContext();

  const dispatch = useDispatch();
  const router = useRouter();

  const appInfo = useSelector((state: RootState) => state.appInfo);
  const feed = useSelector((state: RootState) => state.feed);
  const userState = useSelector((state: RootState) => state.user);
  const discoverableStories = useSelector(
    (state: RootState) => state.user.discoverableStories
  );
  const discoveredStories = useSelector(
    (state: RootState) => state.user.discoveredStories
  );
  const quizState = useSelector((state: RootState) => state.periodicQuiz);

  const dailyInsightDelivery = useSelector(
    (state: RootState) => state.dailyInsight
  ).insightDelivery;

  const dailyStoryState = useSelector((state: RootState) => state.dailyStory);

  const userPollState = useSelector((state: RootState) => state.userPoll);

  const sharedPosts = useSelector(
    (state: RootState) => state.sharedPosts
  ).sharedPosts;

  const communityQuestions = useSelector(
    (state: RootState) => state.communityQuestion
  );

  const { dimensionsComplete } = useGetDimensionStatusAndTraitCount();

  useEffect(() => {
    if (feed.feedItems[0]) {
      dispatch(setLastFeedItemIdViewed(feed.feedItems[0].createdOn));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed.feedItems]);

  function handleMixpanelEvents(feedItem: FeedItemNew) {
    const types: FeedHandlerType[] = [
      FeedHandlerType.viewPeriodicQuiz,
      FeedHandlerType.viewSharedPost,
      FeedHandlerType.viewStory,
    ];
    if (
      feedItem.feedItemHandler?.handlerType &&
      types.includes(feedItem.feedItemHandler.handlerType)
    ) {
      const contentSlug = feedItem.feedItemHandler.handlerDetail?.contentSlug;
      Mixpanel?.track("Feed Item Clicked", {
        type: feedItem.feedEvent,
        content_type:
          dailyInsightDelivery?.id === contentSlug
            ? "daily insight"
            : dailyStoryState.dailyStory?.deliveryId === contentSlug
            ? "daily story"
            : quizState.availableQuiz?.slug === contentSlug
            ? "daily quiz"
            : userPollState.userPoll?.slug === contentSlug
            ? "daily poll"
            : contentSlug?.includes("periodicQuiz")
            ? "posted daily quiz"
            : contentSlug?.includes("deliveredDailyStory")
            ? "posted daily story"
            : "posted daily insight",
      });
    } else {
      Mixpanel?.track("Feed Item Clicked", { type: feedItem.feedEvent });
    }
  }

  function handleCompatibilitySnippetGatingLogic(
    gating:
      | "close"
      | "deep"
      | "private"
      | "basic"
      | { requiredDimensionSlugs: string[] | null | undefined }
      | "Nudge view"
      | "Error"
      | undefined
      | null,
    discoverableSnippet: DiscoverableCompatibilitySnippet,
    publicProfile: PublicProfile | null
  ) {
    if (gating === "basic") {
      return setModalComponent(
        <ConnectionRequestModal
          publicProfile={publicProfile as PublicProfile}
          text="Requires a connection"
          elementName="ConnectionModal"
        />
      );
    }

    if (gating === "close") {
      return setModalComponent(
        <CloseConnectionModal
          fromContentLock
          publicProfile={publicProfile as PublicProfile}
          elementName="ConnectionModal"
        />
      );
    }

    if (gating === "deep") {
      return setModalComponent(
        <DeepConnectionModal
          fromContentLock
          publicProfile={publicProfile as PublicProfile}
          elementName="ConnectionModal"
        />
      );
    }

    if (typeof gating === "object") {
      return setModalComponent(
        <DiscoveryGate
          requiredDimensionSlugs={gating?.requiredDimensionSlugs as string[]}
          onClose={() => setModalOpen(false)}
          onOpenShareLink={() => {}}
        />
      );
    }

    if (gating === "Nudge view") {
      return setModalComponent(
        <NudgeModal
          publicProfile={publicProfile as PublicProfile}
          content={{
            contentType: ContentType.compatibilitySnippet,
            contentSlug: discoverableSnippet?.snippetSlug,
          }}
        />
      );
    }

    return setModalComponent(
      <ErrorStateCompatibilitySnippet
        disabled={false}
        snippet={discoverableSnippet}
        connectionUid={publicProfile?.ownerUUID}
      />
    );
  }

  const noticiationActionHandler: Record<
    FeedHandlerType,
    (arg: FeedHandlerDetail | undefined) => void
  > = {
    viewProfile: (arg) => {
      if (arg?.peerUid) {
        setModalComponent(
          <ProfileModalHandler uid={arg?.peerUid} elementName="profileModal" />
        );
      }
    },
    genericModal: (arg) => {
      if (arg?.genericModalBody && arg?.genericModalTitle) {
        setModalComponent(
          <GeneralModal
            title={arg?.genericModalTitle}
            body={arg?.genericModalBody}
            modalCta={arg.genericModalCTA}
          />
        );
      }
    },
    viewFeatureCarousel: (arg) => {
      return; //leave out
    },
    viewPeerEndorsements: (arg) => {
      return; //leave out
    },
    viewPersonalInsight: (arg) => {
      setModalComponent(
        <DailyInsightsModal elementName="DailyInsightsModal" />
      );
    },
    viewEndorsementDigest: (arg) => {
      return; //leave out
    },
    viewTraitRatingModal: (arg) => {
      return;
    },
    viewPeerAssessment: (arg) => {
      if (arg?.peerUid) {
        return router.push(`/app/peer-assessment/peer-core/${arg?.peerUid}`);
      }
    },
    viewExternalLink: (arg) => {
      let url = arg?.linkUrl ?? appInfo.appInfo?.currentSurvey?.baseUrl;
      if (url) {
        url = url + "?platform=Android&";
        const age = userState.memberProfile?.age;
        if (age) {
          url += `age=${age}&`;
        }
        const gender = userState.publicProfileData?.memberGender;
        if (gender) {
          url += `gender=${gender}&`;
        }

        const numOfConnections = userState.friendUidList.length;
        if (numOfConnections) {
          url += `num_connections=${numOfConnections}&`;
        }

        const numDimensionsCompleted = dimensionsComplete;

        if (numDimensionsCompleted) {
          url += `num_dimensions_completed=${numDimensionsCompleted}&`;
        }

        const signupDate = userState.memberProfile?.signUpDate;
        if (signupDate) {
          const formatted = moment.unix(signupDate / 1000).format("MM-DD-YYYY");
          url += `signup_date=${formatted}&`;
        }

        const nature = userState.publicProfileData?.primaryNature?.nature;
        if (nature) {
          url += `nature=${nature}`;
        }

        if (arg?.linkUrl) {
          Mixpanel?.track("User survey link clicked", { url: arg.linkUrl });
        } else if (appInfo.appInfo?.currentSurvey?.baseUrl) {
          Mixpanel?.track("User survey link clicked", {
            url: appInfo.appInfo?.currentSurvey?.baseUrl,
          });
        }

        return window.open(url, "__blank");
      }
      return;
    },
    viewPremiumConversionModal: (arg) => {
      setModalComponent(<ConversionModal />);
    },
    viewRewards: (arg) => {
      setModalComponent(<RewardsModal />);
    },
    viewArchetype: (arg) => {
      const foundArch = userState.archetypesDiscovered?.find(
        (a) => a.archetype.archetypeGroupSlug === arg?.archetypeGroupSlug
      );
      if (foundArch) {
        setModalComponent(<ArchetypeModal slug={foundArch.archetypeSlug} />);
      } else {
        setModalComponent(
          <DiscoveryGate
            onOpenShareLink={() => {}}
            fromArchetypes
            onClose={() => setModalOpen(false)}
            requiredDimensionSlugs={
              archetypeDiscoveryMap[
                arg?.archetypeGroupSlug as keyof typeof archetypeDiscoveryMap
              ]
            }
          />
        );
      }
    },
    viewCompatibility: (arg) => {
      localStorage.setItem("currentTab", "Compare");
      localStorage.setItem("currentTabUid", arg?.peerUid ?? "");
      if (arg?.peerUid) {
        setModalComponent(
          <ProfileModalHandler uid={arg?.peerUid} elementName="profileModal" />
        );
      }
    },
    viewCompatibilitySnippet: async (arg) => {
      setModalComponent(<LoadingModal elementName="LoadingModal" />);
      const publicProfile = await getPublicProfileDataFromUid(arg?.peerUid);
      if (publicProfile && arg?.compatibilitySnippetSlug) {
        if (!largeSnippets.includes(arg.compatibilitySnippetSlug)) {
          await getCompatibilitySnippetFromResults(
            arg?.compatibilitySnippetSlug,
            publicProfile,
            user?.uid
          ).then((results) => {
            let disoverableSnippet: DiscoverableCompatibilitySnippet | null =
              null;
            let disoveredSnippet: DiscoveredCompatibilitySnippet | null = null;
            //index 0 is discoverable , 1 is discovered, 2 is archetypes
            results[0].forEach((doc) => {
              disoverableSnippet =
                doc.data() as DiscoverableCompatibilitySnippet;
            });
            results[1].forEach((doc) => {
              disoveredSnippet = doc.data() as DiscoveredCompatibilitySnippet;
            });
            const archetypeData: ArchetypeDataModel[] = results[2]
              .data as ArchetypeDataModel[];
            if (
              disoveredSnippet &&
              disoverableSnippet &&
              publicProfile &&
              archetypeData
            ) {
              removeModalByName("LoadingModal");
              return setModalComponent(
                <CompatibilityModal
                  publicProfile={publicProfile}
                  discoverableSnippet={disoverableSnippet}
                  discoveredSnippets={[disoveredSnippet]}
                  archetypeData={archetypeData}
                />
              );
            } else {
              if (disoverableSnippet) {
                const discoverableCopy =
                  disoverableSnippet as DiscoverableCompatibilitySnippet;
                const friendStatus = getConnectionStatusFromUid(
                  arg.peerUid,
                  userState.friendRequestRecievedUidsList,
                  userState.friendRequestsSentUidList,
                  userState.friendUidList,
                  userState.closeFriendUidList,
                  userState.deepFriendUidList
                );
                handleGatingLogicForResultsSnippets(
                  discoverableCopy,
                  friendStatus,
                  userState.dimensionEvents,
                  publicProfile
                ).then((res: any) => {
                  if (discoverableCopy) {
                    removeModalByName("LoadingModal");
                    handleCompatibilitySnippetGatingLogic(
                      res,
                      discoverableCopy,
                      publicProfile
                    );
                  }
                });
              }
            }
          });
        } else {
          if (
            arg.compatibilitySnippetSlug ===
            "compatibility-snippet-romantic-compatibility-report"
          ) {
            await getRomanticCompatibilitySnippetFromResults(
              publicProfile,
              user?.uid
            ).then((res) => {
              if (res) {
                removeModalByName("LoadingModal");
                return setModalComponent(
                  <RomanticCompatibilityModal
                    romanticCompatibilityReport={res}
                    publicProfile={publicProfile}
                  />
                );
              } else {
                removeModalByName("LoadingModal");
                return setModalComponent(
                  <DiscoveryGate
                    requiredDimensionSlugs={[
                      "personality",
                      "personality-II",
                      "values",
                      "interests",
                      "attachment-style",
                      "secondary-traits",
                      "lifestyle",
                      "sex-attitudes",
                      "strengths",
                      "love-attitudes",
                      "love-style",
                      "communication-style",
                    ]}
                    onClose={() => setModalOpen(false)}
                    onOpenShareLink={() => {}}
                  />
                );
              }
            });
          } else if (
            arg.compatibilitySnippetSlug ===
            "compatibility-snippet-work-compatibility-report"
          ) {
            await getWorkCompatibilitySnippetFromResults(
              publicProfile,
              user?.uid
            ).then((res) => {
              if (res) {
                removeModalByName("LoadingModal");
                return setModalComponent(
                  <WorkCompatibilityModal
                    workCompatibilityReport={res}
                    publicProfile={publicProfile}
                  />
                );
              } else {
                removeModalByName("LoadingModal");
                return setModalComponent(
                  <DiscoveryGate
                    requiredDimensionSlugs={[
                      "personality",
                      "personality-II",
                      "values",
                      "interests",
                      "communication-style",
                      "strengths",
                    ]}
                    onClose={() => setModalOpen(false)}
                    onOpenShareLink={() => {}}
                  />
                );
              }
            });
          }
        }
      }
    },
    viewNatureBreakdown: (arg) => {
      if (userState.publicProfileData?.primaryNature) {
        return setModalComponent(<NatureModal />);
      } else {
        return setModalComponent(
          <DiscoveryGate
            requiredDimensionSlugs={["personality", "personality-II", "values"]}
            onClose={() => setModalOpen(false)}
            onOpenShareLink={() => {}}
          />
        );
      }
    },
    viewSimilarity: (arg) => {},
    viewStory: (arg) => {
      const discoverableStory = discoverableStories?.find(
        (s) => s.storySlug === arg?.storySlug
      );
      const discoveredStory = discoveredStories?.find(
        (s) => s.storySlug === arg?.storySlug
      );

      if (discoveredStory) {
        return setModalComponent(
          <StoriesModal story={discoveredStory} own={true} />
        );
      } else {
        return setModalComponent(
          <DiscoveryGate
            {...discoverableStory?.discoveryGate}
            onClose={() => setModalOpen(false)}
            onOpenShareLink={() => {
              summonProfileLink(user?.uid, "other", "Invite to Dimensional");
            }}
            fromStory
          />
        );
      }
    },
    viewPeerFeedbackLevelsModal: (arg) => {
      setModalComponent(<PeerFeedbackModal />);
    },
    viewPeriodicQuiz: (arg) => {
      const foundQuizResult = quizState.periodicQuizResults?.find(
        (q) => q.periodicQuizSlug === quizState.availableQuiz?.slug
      );
      if (quizState.availableQuiz && arg?.periodicQuizSlug)
        return setModalComponent(
          <PeriodicQuizResultModal
            periodicQuiz={quizState.availableQuiz}
            periodicQuizResult={foundQuizResult}
            elementName="PeriodicQuizResultModal"
          />
        );
    },
    viewResultSnippet: (arg) => {
      if (arg?.resultSnippetSlug) {
        setModalComponent(
          <SnippetsModal discoveredSnippetSlug={arg.resultSnippetSlug} />
        );
      }
    },
    viewCommentThread: async (arg) => {
      const contentSlug = arg?.contentSlug;
      const commentId = arg?.commentId;
      const post = sharedPosts.find((p) => p.slug === contentSlug);

      if (commentId) {
        if (dailyInsightDelivery?.id === contentSlug) {
          Mixpanel?.track("Comment modal summoned", {
            source: "daily insight",
            type: "from notification or feed",
          });
          return setModalComponent(
            <DailyInsightsModal
              elementName="DailyInsightsModal"
              showComment
              commentId={commentId}
            />
          );
        } else if (dailyStoryState.dailyStory?.deliveryId === contentSlug) {
          Mixpanel?.track("Comment modal summoned", {
            source: "daily story",
            type: "from notification or feed",
          });
          return setModalComponent(
            <DailyStoriesModal
              elementName="DailyStory"
              showComment
              commentId={commentId}
            />
          );
        } else if (quizState.availableQuiz?.slug === contentSlug) {
          Mixpanel?.track("Comment modal summoned", {
            source: "daily quiz",
            type: "from notification or feed",
          });
          const foundQuizResult = quizState.periodicQuizResults?.find(
            (r) => r.periodicQuizSlug === quizState.availableQuiz?.slug
          );
          if (foundQuizResult && quizState.availableQuiz) {
            return setModalComponent(
              <PeriodicQuizResultModal
                periodicQuiz={quizState.availableQuiz}
                periodicQuizResult={foundQuizResult}
                elementName="PeriodicQuizResultModal"
                showComment
                commentId={commentId}
              />
            );
          } else {
            return setModalComponent(
              <PeriodicQuizModal elementName="PeriodicQuizModal" />
            );
          }
        } else if (userPollState.userPoll?.slug === contentSlug) {
          Mixpanel?.track("Comment modal summoned", {
            source: "daily poll",
            type: "from notification or feed",
          });
          if (
            userPollState.userCurrentPollResponse?.pollSlug ===
            userPollState.userPoll?.slug
          ) {
            return setModalComponent(
              <UserPollResponseModal
                elementName="UserPollResponseModal"
                showComment
                commentId={commentId}
              />
            );
          } else {
            return setModalComponent(
              <UserPollModal elementName="UserPollModal" />
            );
          }
        } else if (post) {
          setModalComponent(<LoadingModal elementName="LoadingModal" />);
          const userUid = contentSlug?.split("-")[0];
          const profileImageAndPublicProfile =
            await getPublicProfileAnImageUrlDataFromUid(userUid);
          const profileImage = profileImageAndPublicProfile[0];
          const publicProfile = profileImageAndPublicProfile[1];
          if (post && publicProfile && profileImage) {
            Mixpanel?.track("Comment modal summoned", {
              source: contentSlug?.includes("periodicQuiz")
                ? "posted daily quiz"
                : contentSlug?.includes("deliveredDailyStory")
                ? "posted daily story"
                : "posted daily insight",
              type: "from notification or feed",
            });

            Mixpanel?.track("Post viewed", {
              source: "from notification or feed",
              content_type: contentSlug?.includes("periodicQuiz")
                ? "daily quiz"
                : contentSlug?.includes("deliveredDailyStory")
                ? "daily story"
                : "daily insight",
              content_type_slug: contentSlug,
              caption_text: post.caption,
            });

            removeModalByName("LoadingModal");

            return setModalComponent(
              <SharedPostModal
                publicProfile={publicProfile}
                sharedPost={post}
                profileImage={profileImage}
                showComment
                commentId={arg.commentId ?? ""}
              />
            );
          } else {
            removeModalByName("LoadingModal");
            return setModalComponent(
              <ExpirationModal copy="You're too late. This content and associated comments have expired" />
            );
          }
        } else if (contentSlug === communityQuestions.communityQuestion?.slug) {
          Mixpanel?.track("Comment modal summoned", {
            source: "daily community question",
            slug: communityQuestions.communityQuestion?.slug,
          });
          return setModalComponent(
            <CommunityQuestionsModal
              elementName="CommunityQuestionsModal"
              commentId={commentId}
            />
          );
        } else {
          return setModalComponent(
            <ExpirationModal copy="You're too late. This content and associated comments have expired" />
          );
        }
      }
    },
    viewSharedPost: async (arg) => {
      const post = sharedPosts.find((p) => p.slug === arg?.contentSlug);
      if (post && arg?.contentSlug) {
        const userUid = arg.contentSlug.split("-")[0];
        const profileImageAndPublicProfile =
          await getPublicProfileAnImageUrlDataFromUid(userUid);
        const profileImage = profileImageAndPublicProfile[0];
        const publicProfile = profileImageAndPublicProfile[1];
        const post = sharedPosts.find((p) => p.slug === arg.contentSlug);
        if (post && publicProfile && profileImage) {
          Mixpanel?.track("Post viewed", {
            source: "from notification or feed",
            content_type: arg.contentSlug?.includes("periodicQuiz")
              ? "daily quiz"
              : arg.contentSlug.includes("deliveredDailyStory")
              ? "daily story"
              : "daily insight",
            content_type_slug: arg.contentSlug,
            caption_text: post.caption,
          });
          removeModalByName("LoadingModal");
          return setModalComponent(
            <SharedPostModal
              publicProfile={publicProfile}
              sharedPost={post}
              profileImage={profileImage}
            />
          );
        } else {
          removeModalByName("LoadingModal");
          return setModalComponent(
            <ExpirationModal copy="You're too late. This content and associated comments have expired" />
          );
        }
      }
    },
  };

  const mapped = feed.feedItems.map((feedItem, index) => {
    return (
      <div
        onClick={() => {
          handleMixpanelEvents(feedItem);
          if (feedItem.feedItemHandler) {
            const action =
              noticiationActionHandler[feedItem.feedItemHandler.handlerType];
            if (feedItem.feedItemHandler.handlerDetail) {
              action(feedItem.feedItemHandler.handlerDetail);
            } else {
              action(undefined);
            }
          }
        }}
        className={styles.notificationDiv}
        key={index}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={styles.leftDiv}
        >
          <FeedItemPrimaryIcon feedItemDetail={feedItem.feedItemDetail} />
          <p className={styles.createdOn}>
            {moment.unix(feedItem.createdOn / 1000).format("MM/DD/YYYY")}
          </p>
        </div>
        <p className={styles.body}>
          {feedItem.text}{" "}
          <span style={{ color: "#928e8c" }}>{feedItem.subtext}</span>
        </p>
        <div className={styles.rightDiv}>
          <FeedItemSecondaryIcon feedItemDetail={feedItem.feedItemDetail} />
        </div>
      </div>
    );
  });

  return (
    <ModalWrapper title="Notifications">
      <div
        className={styles.wrapper}
        onScroll={(e) => {
          const container = e.target as HTMLElement;
          const scrollTop = container.scrollTop;
          const scrollHeight = container.scrollHeight;
          const clientHeight = container.clientHeight;
          const isBottom = scrollTop + clientHeight >= scrollHeight;
          if (isBottom == true) {
            paginateFeedItems();
          }
        }}
      >
        {userState.notificationPermission === false && (
          <NotificationBar source="Notification feed" />
        )}
        {mapped}
      </div>
    </ModalWrapper>
  );
}
