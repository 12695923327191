import { useEffect, useState } from "react";
import removeMarkdown from "markdown-to-text";
import {
  ContentType,
  PublicProfile,
  RuleDirection,
  SharedPost,
} from "@dimensional-engineering/dimensional-models";
import { Roboto_Mono } from "next/font/google";
import Image from "next/image";

import elementsJsonData from "../../../../../assets/traitInfo/elements.json";
import archetypesJsonData from "../../../../../assets/traitInfo/archetypes.json";

import styles from "./SharedPostModal.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import CloseButtonSummary, {
  CloseButtonWhite,
} from "@/components/shared/CloseButtonSummary";
import { useModalContext } from "@/context/ModalContext";
import { useGetDailyInsightIconColorsWithData } from "@/components/appHome/DailyInsights/DailyInsightsCTABlock/DailyInsightsCTABlock";
import {
  DailyInsightIconActiveVariantSmall,
  HighDailyInsightScaleScoreIcon,
  LowDailyInsightScaleScoreIcon,
  NeutralDailyInsightScaleScoreIcon,
} from "@/components/appHome/DailyInsights/DailyInsightsIcon";
import { natureData } from "@/assets/traitInfo/natureData";
import { PostLikeIcon } from "@/components/shared/icons/CommentLikeIcon";
import { MessageIconStatusBar } from "@/components/shared/icons/MessageIcon";
import { SharedPostWithCommentThread } from "@/models/sharedModels";
import { ChatProfileImageWithLink } from "@/components/shared/SmallProfileImage/LargeProfileImage";
import { splitStringIntoMultipleLines } from "@/components/appHome/DailyStories/utils";
import useUpdateCommentThreadData from "@/components/appHome/CommentModal/useUpdateCommentThreadData";
import CommentModal from "@/components/appHome/CommentModal/CommentModal";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import { likeOrUnlikePost } from "../utils";
import { useAuth } from "@/context/AuthContext";
import { Mixpanel } from "@/helpers/mixpanel";
import ProfileModalHandler from "@/components/authenticatedProfile/Abstracts/ProfileModal/ProfileModalHandler";

type Props = {
  sharedPost: SharedPostWithCommentThread;
  publicProfile: PublicProfile | null;
  profileImage: string;
  showComment?: boolean;
  commentId?: string;
};

const font = Roboto_Mono({ subsets: ["latin"] });

export default function SharedPostModal(props: Props) {
  const { user } = useAuth();

  const [liked, setLiked] = useState<boolean>(false);
  const [likeCount, setLikeCount] = useState<number>(0);
  const [likeLocked, setLikeLocked] = useState<boolean>(false);

  const { setModalOpen, setModalComponent } = useModalContext();

  const { showComments, setShowComments } = useUpdateCommentThreadData(
    props.sharedPost.commentThreadId,
    props.showComment
  );

  const uid = user?.uid ?? "";

  useEffect(() => {
    if (!likeLocked) {
      if (props.sharedPost.likedByUids.includes(uid)) {
        setLiked(true);
      } else {
        setLiked(false);
      }
      setLikeCount(props.sharedPost.likedByUids.length);
    }
  }, [props.sharedPost.likedByUids.length, props.sharedPost]);

  function handleLikedUi() {
    if (!liked) {
      Mixpanel?.track("Post liked", {
        content_type: props.sharedPost.postContent.dailyInsightData
          ? "daily insight"
          : props.sharedPost.postContent.dailyStoryData
          ? "daily story"
          : "daily insight",
        content_type_slug:
          props.sharedPost.postContent.contentIdentifier.contentType ===
          ContentType.periodicQuiz
            ? props.sharedPost.postContent.periodicQuizData?.periodicQuiz.slug
            : props.sharedPost.postContent.contentIdentifier.contentType ===
              ContentType.deliveredDailyStory
            ? props.sharedPost.postContent.dailyStoryData?.deliveredDailyStory
                .computeableStoryModelSlug
            : props.sharedPost.postContent.dailyInsightData?.insightDelivery
                .computeableInsightModelSlug,
        source: "modal",
        caption_text: props.sharedPost.caption ?? null,
      });
      setLiked(true);
      setLikeCount((count) => count + 1);
    } else {
      setLiked(false);
      setLikeCount((count) => count - 1);
    }
    setLikeLocked(true);
  }

  return (
    <>
      {showComments && (
        <CommentModal
          onClose={() => setShowComments(false)}
          commentId={props.commentId}
          slug={
            props.sharedPost.postContent.contentIdentifier.contentType ===
            ContentType.periodicQuiz
              ? (props.sharedPost.postContent.periodicQuizData?.periodicQuiz
                  .slug as string)
              : props.sharedPost.postContent.contentIdentifier.contentType ===
                ContentType.deliveredDailyStory
              ? (props.sharedPost.postContent.dailyStoryData
                  ?.deliveredDailyStory.computeableStoryModelSlug as string)
              : (props.sharedPost.postContent.dailyInsightData?.insightDelivery
                  .computeableInsightModelSlug as string)
          }
          audienceGroupSize={
            props.sharedPost.commentThread?.audienceUids?.length ?? 0
          }
          source={
            props.sharedPost.postContent.dailyInsightData
              ? "posted daily insight"
              : props.sharedPost.postContent.dailyStoryData
              ? "posted daily story"
              : "posted daily quiz"
          }
          postDetails={{
            caption: props.sharedPost.caption,
            publicProfile: props.publicProfile,
            profileImage: props.profileImage,
            createdOn: props.sharedPost.createdOn,
            authorUid: props.sharedPost.authorUid,
          }}
          content={
            props.sharedPost.postContent.dailyInsightData?.deliveredDailyInsight
              .assignmentRuleset.body
              ? props.sharedPost.postContent.dailyInsightData
                  .deliveredDailyInsight.assignmentRuleset.body
              : props.sharedPost.postContent.dailyStoryData?.deliveredDailyStory
                  .assignmentRuleset.body
              ? props.sharedPost.postContent.dailyStoryData.deliveredDailyStory
                  .assignmentRuleset.body
              : props.sharedPost.postContent.periodicQuizData?.periodicQuiz
                  .title
              ? props.sharedPost.postContent.periodicQuizData?.periodicQuiz
                  .title
              : ""
          }
        />
      )}
      <ModalWrapper title="" noHeader noLine>
        <main
          style={{
            backgroundImage: `url(/dailyStories/${props.sharedPost.postContent.dailyStoryData?.storyDelivery.computeableStoryModelSlug}.jpg)`,
          }}
          className={styles.main}
        >
          <div className={styles.closeBtn} onClick={() => setModalOpen(false)}>
            {props.sharedPost.postContent.dailyInsightData ||
            props.sharedPost.postContent.periodicQuizData ? (
              <CloseButtonSummary />
            ) : (
              <CloseButtonWhite />
            )}
          </div>
          {props.sharedPost.postContent.dailyInsightData && (
            <DailyInsightBodySharedPostModal
              publicProfile={props.publicProfile}
              sharedPost={props.sharedPost}
            />
          )}
          {props.sharedPost.postContent.dailyStoryData && (
            <DailyStoryBodySharedPostModal sharedPost={props.sharedPost} />
          )}
          {props.sharedPost.postContent.periodicQuizData && (
            <PeriodicQuizBodySharedPostModal
              sharedPost={props.sharedPost}
              publicProfile={props.publicProfile}
              profileImage={props.profileImage}
            />
          )}
          <div
            style={
              props.sharedPost.postContent.periodicQuizData
                ? { marginTop: "0" }
                : {}
            }
            className={styles.bottom}
          >
            <div className={styles.bottomIconContainersParent}>
              <div className={styles.bottomContentDiv}>
                <div>
                  <PostLikeIcon
                    liked={liked}
                    onClick={() => {
                      handleLikedUi();
                      likeOrUnlikePost(
                        props.sharedPost.slug,
                        liked ? true : false
                      ).then((res) => {});
                    }}
                  />
                </div>
                <p className={styles.likeAndCommentCountText}>{likeCount}</p>
              </div>
              <div className={styles.bottomContentDiv}>
                <div
                  onClick={(e) => {
                    Mixpanel?.track("Comment modal summoned", {
                      source: props.sharedPost.postContent.dailyInsightData
                        ? "posted daily insight"
                        : props.sharedPost.postContent.dailyStoryData
                        ? "posted daily story"
                        : "posted daily quiz",
                      audience_group_size:
                        props.sharedPost.commentThread?.audienceUids?.length ??
                        0,
                      type: "from posted content modal view",
                    });
                    setShowComments(true);
                  }}
                >
                  <MessageIconStatusBar />
                </div>
                <p className={styles.likeAndCommentCountText}>
                  {props.sharedPost.commentThread?.rootCommentCount ?? 0}
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                setShowComments(true);
              }}
              className={styles.bottomImageAndCaptionDiv}
            >
              <ChatProfileImageWithLink
                publicProfile={props.publicProfile}
                imageUrl={props.profileImage}
              />
              <div className={styles.bottomImageAndProfileDiv}>
                <div
                  className={styles.usernameText}
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalComponent(
                      <ProfileModalHandler
                        elementName="profileModal"
                        uid={props.publicProfile?.ownerUUID}
                      />
                    );
                  }}
                >
                  @{props.publicProfile?.userName}
                </div>

                {props.sharedPost.caption && (
                  <p className={styles.captionText}>
                    {props.sharedPost.caption}
                  </p>
                )}
              </div>
            </div>
          </div>
        </main>
      </ModalWrapper>
    </>
  );
}

function DailyInsightBodySharedPostModal(props: {
  sharedPost: SharedPost;
  publicProfile: PublicProfile | null | undefined;
}) {
  const colors = useGetDailyInsightIconColorsWithData(
    props.sharedPost.postContent.dailyInsightData?.deliveredDailyInsight
  );

  const dailyInsightText = removeMarkdown(
    props.sharedPost.postContent.dailyInsightData?.deliveredDailyInsight
      .assignmentRuleset.body ?? ""
  );

  const mappedScales =
    props.sharedPost.postContent.dailyInsightData?.deliveredDailyInsight.assignmentRuleset.scaleRules.map(
      (scale) => {
        const icon =
          scale.direction === RuleDirection.up ? (
            <HighDailyInsightScaleScoreIcon />
          ) : scale.direction === RuleDirection.middle ? (
            <NeutralDailyInsightScaleScoreIcon />
          ) : (
            <LowDailyInsightScaleScoreIcon />
          );

        const element = elementsJsonData.find((el) => el.slug === scale?.slug);
        const foundNature = natureData?.find((n) => n.slug === element?.nature);
        let scaleColor = foundNature?.color;

        if (element && foundNature) {
          return (
            <div
              className={styles.scale}
              style={{ borderColor: scaleColor }}
              key={scale.slug}
            >
              {icon}
              <p className={styles.scaleName}>
                {element.alias ?? element.name}
              </p>
            </div>
          );
        }
      }
    );

  const identityArchetypeFound = archetypesJsonData.find(
    (a) =>
      a.slug ===
      props.sharedPost.postContent.dailyInsightData?.deliveredDailyInsight
        .assignmentRuleset.identityArchetypeSlug
  );

  const interactionArchetypeFound = archetypesJsonData.find(
    (a) =>
      a.slug ===
      props.sharedPost.postContent.dailyInsightData?.deliveredDailyInsight
        .assignmentRuleset.interactionArchetypeSlug
  );

  const identityArchetype = identityArchetypeFound ? (
    <div className={styles.scale}>
      <p className={styles.scaleName}>
        {identityArchetypeFound.acronym ?? identityArchetypeFound.name}
      </p>
    </div>
  ) : null;

  const interactionArchetype = interactionArchetypeFound ? (
    <div className={styles.scale}>
      <p className={styles.scaleName}>
        {interactionArchetypeFound.acronym ?? interactionArchetypeFound.name}
      </p>
    </div>
  ) : null;

  return (
    <div className={styles.dialyInsightsDiv}>
      <div className={styles.dailyInsightsTopDiv}>
        <DailyInsightIconActiveVariantSmall colors={colors} />
        <h4 className={`${styles.dailyInsightName} ${font.className}`}>
          {props.sharedPost.postContent.dailyInsightData?.deliveredDailyInsight.name.toUpperCase()}
        </h4>
      </div>
      <p className={styles.dailyInsightBody}>{dailyInsightText}</p>
      {/* <p
        className={`${styles.beacuseOfText} ${font.className}`}
      >{`BECAUSE OF @${props.publicProfile?.userName?.toUpperCase()}'S SCORES ON:`}</p>
      <div className={styles.mappedScalesWrapper}>
        {mappedScales}
        {identityArchetype}
        {interactionArchetype}
      </div> */}
    </div>
  );
}

function DailyStoryBodySharedPostModal(props: { sharedPost: SharedPost }) {
  const dailyStoryBodySplit = splitStringIntoMultipleLines(
    props.sharedPost.postContent.dailyStoryData?.deliveredDailyStory
      .assignmentRuleset?.body,
    30
  );

  const mappedBody = dailyStoryBodySplit.map((line, i) => {
    return (
      <p className={styles.storyBody} key={i}>
        {line}
      </p>
    );
  });

  return (
    <div className={styles.dailyStoryMainDiv}>
      <h5 className={`${styles.storyTitle} ${font.className}`}>
        {props.sharedPost.postContent.dailyStoryData?.storyDelivery.name.toUpperCase()}
        :
      </h5>
      <div className={styles.dailyStoryBodyMapperDiv}>{mappedBody}</div>
    </div>
  );
}

function PeriodicQuizBodySharedPostModal(props: {
  sharedPost: SharedPost;
  publicProfile: PublicProfile | null;
  profileImage: string;
}) {
  const [multiplier, setMultiplier] = useState<number>(0);

  useEffect(() => {
    let high = 0;
    if (
      props.sharedPost.postContent.periodicQuizData?.periodicQuiz.sampleData
        ?.histogramData
    ) {
      props.sharedPost.postContent.periodicQuizData?.periodicQuiz.sampleData.histogramData.forEach(
        (point) => {
          if (point > high) {
            high = point;
          }
        }
      );
    }
    setMultiplier(160 / (high * 100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapped =
    props.sharedPost.postContent.periodicQuizData?.periodicQuiz.sampleData?.histogramData?.map(
      (point, index) => {
        return (
          <div
            className={styles.histogramBar}
            style={
              point === 0
                ? { height: `6px` }
                : { height: `${point * 100 * multiplier}px` }
            }
            key={index}
          ></div>
        );
      }
    );

  const percentScore = props.sharedPost.postContent.periodicQuizData
    ?.periodicQuizResult.percentScore
    ? props.sharedPost.postContent.periodicQuizData.periodicQuizResult
        .percentScore * 100
    : null;

  const userScore = (
    <div
      style={{
        left: `calc(${percentScore}% - 1px)`,
      }}
      className={styles.averageScoreDiv}
    >
      <div
        style={
          props.publicProfile
            ? {
                border: `3px solid ${
                  ProfileColorMap[
                    props.publicProfile?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                }`,
              }
            : { border: "3px solid #55504F" }
        }
        className={styles.userScoreCircleShare}
      >
        <Image priority fill src={props.profileImage ?? ""} alt="" />
      </div>
      <div
        style={
          props.publicProfile
            ? {
                backgroundColor: `${
                  ProfileColorMap[
                    props.publicProfile?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                }`,
              }
            : { backgroundColor: "#55504F" }
        }
        className={styles.userScoreLine}
      ></div>
    </div>
  );

  return (
    <section className={styles.periodicQuizDiv}>
      <div className={styles.scoreTextBig}>
        <h2 className={styles.score}>
          <span className={styles.scoreBig}>
            {percentScore ? percentScore.toFixed(0) : 0}
          </span>
          /100
        </h2>
        {props.sharedPost.postContent.periodicQuizData?.periodicQuiz
          .scoreName ? (
          <p className={styles.scoreName}>
            {props.sharedPost.postContent.periodicQuizData?.periodicQuiz.scoreName.toUpperCase()}
          </p>
        ) : (
          <p className={styles.scoreName}>
            {props.sharedPost.postContent.periodicQuizData?.periodicQuiz.title.toUpperCase()}
          </p>
        )}
      </div>
      <div className={styles.histogramSection}>
        <div className={styles.histogramWrapper}>
          {userScore}
          {mapped}
        </div>
        <div className={styles.histogramLabelWrapper}>
          <p className={styles.histogramLabel}>0</p>
          <p className={styles.histogramLabel}>25</p>
          <p className={styles.histogramLabel}>50</p>
          <p className={styles.histogramLabel}>75</p>
          <p className={styles.histogramLabel}>100</p>
        </div>
      </div>
    </section>
  );
}
