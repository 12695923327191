export default function SettingsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M20.1095 11.3916L16.6094 7.89146L17.4447 7.05615L20.9448 10.5563L20.1095 11.3916Z"
        fill="#FFFFF2"
      />
      <path
        d="M10.7884 20.7212L7.28906 17.2204L16.0007 8.51074L19.5001 12.0116L10.7884 20.7212Z"
        fill="#FFFFF2"
      />
      <path
        d="M22.3203 9.21895C22.8828 8.65645 22.8828 7.75645 22.3203 7.19395L20.8578 5.73145C20.2953 5.16895 19.3391 5.16895 18.8328 5.73145L18.1016 6.4627L21.6454 10.0065L22.3203 9.21895Z"
        fill="#FFFFF2"
      />
      <path
        d="M5.27988 22.1563C5.22363 22.325 5.27988 22.4938 5.39238 22.6063C5.44863 22.6625 5.56113 22.7188 5.67363 22.7188H5.84238L10.0612 21.2L6.79872 17.9375L5.27988 22.1563Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
