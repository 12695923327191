import { useEffect, useRef, useState } from "react";
import {
  ContentIdentifier,
  ContentType,
  SharedPost,
} from "@dimensional-engineering/dimensional-models";

import styles from "./ShareCaptionModal.module.css";

import { PostIconWhite } from "@/components/results/Snippets/SnippetInterractionBar/SnippetInterractionBar";
import { SmallLoader } from "@/components/shared/Loader";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "@/components/shared/Alerts/Alert";
import { createSharedPost } from "../utils";
import { Mixpanel } from "@/helpers/mixpanel";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

type Props = {
  onClose: (caption: string) => void;
  contentIdentifier: ContentIdentifier;
  savedCaption: string;
};

export default function ShareCaptionModal(props: Props) {
  const numberOfConnections = useSelector(
    (state: RootState) => state.connections
  ).publicProfiles?.length;
  const { setAlertComponent } = useAlertContext();

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [caption, setCaption] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [rows, setRows] = useState(1);

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaption(e.target.value);
    const textareaLineHeight = 24;
    const previousRows = e.target.rows;
    e.target.rows = 1;

    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    setRows(currentRows);
  };

  useEffect(() => {
    setCaption(props.savedCaption);
  }, [props.savedCaption]);

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.style.transform = "translateY(0)";
    }
  }, []);

  function onClose(s: string) {
    if (wrapperRef.current) {
      wrapperRef.current.style.transform = "translateY(300%)";
    }
    setTimeout(() => {
      props.onClose(s);
    }, 200);
  }

  function handlePost() {
    if (caption.length > 2048) {
      return setAlertComponent(
        <Alert
          elementName="Alert"
          type="fail"
          message="Your caption cannot exceed 2048 characters."
        />,
        3000
      );
    }
    setLoading(true);
    createSharedPost(props.contentIdentifier, caption)
      .then((res) => {
        const sharedPost = res.data as SharedPost;
        Mixpanel?.track("Post created", {
          caption_text: caption,
          content_type:
            props.contentIdentifier.contentType ===
            ContentType.deliveredDailyStory
              ? "daily story"
              : props.contentIdentifier.contentType === ContentType.periodicQuiz
              ? "daily quiz"
              : "daily insight",
          content_type_slug:
            sharedPost.postContent.contentIdentifier.contentType ===
            ContentType.periodicQuiz
              ? sharedPost.postContent.periodicQuizData?.periodicQuiz.slug
              : sharedPost.postContent.contentIdentifier.contentType ===
                ContentType.deliveredDailyStory
              ? sharedPost.postContent.dailyStoryData?.deliveredDailyStory
                  .computeableStoryModelSlug
              : sharedPost.postContent.dailyInsightData?.insightDelivery
                  .computeableInsightModelSlug,
          audience_group_size: numberOfConnections,
        });
        setAlertComponent(
          <Alert elementName="Alert" type="success" message="Post shared" />,
          3000
        );
        return onClose("");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  return (
    <main
      onClick={() => {
        if (!loading) {
          onClose(caption);
        }
      }}
      className={styles.main}
    >
      <div className={styles.backgroundDiv}></div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={styles.postDiv}
        ref={wrapperRef}
      >
        <div className={styles.top}>
          <PostIconWhite />
          <p className={styles.postToConnectionsDiv}>Post to connections</p>
        </div>
        <textarea
          autoFocus
          className={loading ? styles.inputLoading : styles.input}
          placeholder="Add a caption..."
          value={caption}
          onChange={handleInput}
          disabled={loading}
          rows={rows}
        />
        <div className={styles.buttonDiv}>
          {loading ? (
            <div className={styles.loaderDiv}>
              <SmallLoader />
            </div>
          ) : (
            <button onClick={() => handlePost()} className={styles.btn}>
              Post
            </button>
          )}
          <p className={styles.buttonDivText}>
            Shared content is available to connections for one week
          </p>
        </div>
      </div>
    </main>
  );
}
