import { useSelector } from "react-redux";
import { useRouter } from "next/router";

import styles from "./AuthLeftBarOwnProfile.module.css";

import { RootState } from "@/redux/store";
import ProfileCompletion, { useProfileCompletion } from "../ProfileCompletion";
import SettingsIcon from "@/components/shared/icons/SettingsIcon";
import ViewWebProfileCTA from "./ViewWebProfileCTA";
import { Mixpanel } from "@/helpers/mixpanel";
import { VeryLargeProfileImage } from "@/components/shared/SmallProfileImage/LargeProfileImage";
import AmbassadorIcon from "@/components/shared/icons/AmbassadorIcon";
import { useModalContext } from "@/context/ModalContext";
import { GeneralModalArrayParagraph } from "@/components/shared/GeneralModal/GeneralModal";
import { openInNewTab } from "@/components/shared/utils";

export default function AuthLeftBarOwnProfile() {
  const router = useRouter();
  const userState = useSelector((state: RootState) => state.user);
  const { percentageComplete } = useProfileCompletion();
  const { setModalComponent } = useModalContext();

  return (
    <section className={styles.main}>
      <div className={styles.top}>
        <div className={styles.topInfoDiv}>
          <p className={styles.numConnections}>
            {userState.publicProfileData?.numFriends ?? 0}
          </p>
          <p className={styles.connectionsText}>Connections</p>
          {userState.publicProfileData?.isAmbassador && (
            <div
              onClick={() => {
                setModalComponent(
                  <GeneralModalArrayParagraph
                    title="Dimensional Ambassador Program"
                    modalCta={"viewAmbassador"}
                    body={[
                      "Dimensional Ambassadors are a diverse group connected by a shared passion for self-understanding, living intently, and supporting the growth of those around them.",
                      "Ambassadors get early access to features, monthly check-ins with founders and other members, and a private community.",
                    ]}
                  />
                );
                Mixpanel?.track("Profile / ambassador check clicked", {
                  username: userState.publicProfileData?.userName,
                });
              }}
              className={styles.ambassDiv}
            >
              <AmbassadorIcon />
              <p className={styles.connectionsText}>Ambassador</p>
            </div>
          )}
        </div>

        <VeryLargeProfileImage
          publicProfile={userState.publicProfileData}
          imageUrl={userState.imageURL ?? ""}
          noLink
          showNature
        />
        <div
          onClick={() => {
            router.push("/app/settings");
          }}
          className={styles.topInfoDiv}
          style={{ marginTop: "4px" }}
        >
          <SettingsIcon />
          <p className={styles.connectionsText}>Edit Profile</p>
        </div>
      </div>
      <div className={styles.bottom}>
        <h2 className={styles.name}>
          {userState.publicProfileData?.name ??
            `@${userState.publicProfileData?.userName}`}
        </h2>
      </div>

      {percentageComplete > 99 && (
        <div
          onClick={() => {
            openInNewTab(
              `${window.location.origin}/${userState.publicProfileData?.userName}`
            );
          }}
          className={styles.profileCtaDiv}
        >
          <ViewWebProfileCTA />
        </div>
      )}

      <p
        className={styles.url}
        onClick={() => {
          openInNewTab(`/${userState.publicProfileData?.userName}`);
          Mixpanel?.track("Web profile link clicked", { view: "own profile" });
        }}
        style={percentageComplete > 99 ? { marginTop: "6px" } : {}}
      >{`dimensional.me/${userState.publicProfileData?.userName}`}</p>
      <ProfileCompletion />
    </section>
  );
}
