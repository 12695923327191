import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Roboto_Mono } from "next/font/google";
import {
  AgePollResultGroup,
  AgeRange,
  ArchetypePollResultGroup,
  Gender,
  GenderPollResultGroup,
  Nature,
  NaturePollResultGroup,
  PollOption,
} from "@dimensional-engineering/dimensional-models";

import styles from "./PollMostLikelyToChoose.module.css";
import "swiper/css";

import { RootState } from "@/redux/store";
import { getMostLikelyResults, pollOptionIndexMap } from "../../utils";
import { natureData } from "@/assets/traitInfo/natureData";
import { Mixpanel } from "@/helpers/mixpanel";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function PollMostLikelyToChoose() {
  const [tab, setTab] = useState<"NATURE" | "GENDER" | "AGE" | "COGNITION">(
    "NATURE"
  );
  const [mySwiperRef, setMySwiperRef] = useState<any>(null);

  const tabHeaders = ["NATURE", "GENDER", "AGE", "COGNITION"];

  const pollState = useSelector((state: RootState) => state.userPoll);
  const liveResults = useUpdateResultsBreakdownForPolls();

  const mostLikelyResults = getMostLikelyResults(
    liveResults,
    pollState.userPoll?.pollOptions
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      try {
        if (mySwiperRef) {
          mySwiperRef?.updateAutoHeight(0.5);
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const tabs = [
    <NatureTab
      pollOptions={pollState.userPoll?.pollOptions}
      natureResults={mostLikelyResults.natureResults}
      natureResultGroups={liveResults?.natureResultGroups}
      key={0}
    />,
    <GenderTab
      genderResultGroups={liveResults?.genderResultGroups}
      pollOptions={pollState.userPoll?.pollOptions}
      genderResults={mostLikelyResults.genderResults}
      key={1}
    />,
    <AgeTab
      key={2}
      ageResultGroups={liveResults?.ageResultGroups}
      pollOptions={pollState.userPoll?.pollOptions}
      ageResults={mostLikelyResults.ageResults}
    />,
    <CognitionTab
      key={3}
      cognitionResultGroups={liveResults?.archetypeResultGroups}
      pollOptions={pollState.userPoll?.pollOptions}
      cognitionResults={mostLikelyResults.mbtiResults}
    />,
  ].map((c, i) => {
    return (
      <SwiperSlide
        style={{ padding: "0 15px", paddingBottom: "100px" }}
        key={i}
      >
        {c}
      </SwiperSlide>
    );
  });

  return (
    <section className={styles.main}>
      <h4 className={styles.title}>Breakdown results by:</h4>
      <div className={styles.tabParent}>
        <p
          style={
            tab === "NATURE"
              ? { opacity: "1", borderBottom: "2px solid #fffff2" }
              : {}
          }
          onClick={() => {
            setTab("NATURE");
            if (mySwiperRef) {
              mySwiperRef.slideTo(tabHeaders.indexOf("NATURE"));
            }
            Mixpanel?.track("Poll Breakdown Selected", {
              slug: pollState.userPoll?.slug,
              body: pollState.userPoll?.title,
              type: "nature",
            });
          }}
          className={styles.tabText}
        >
          NATURE
        </p>
        <p
          style={
            tab === "GENDER"
              ? { opacity: "1", borderBottom: "2px solid #fffff2" }
              : {}
          }
          onClick={() => {
            setTab("GENDER");
            if (mySwiperRef) {
              mySwiperRef.slideTo(tabHeaders.indexOf("GENDER"));
            }
            Mixpanel?.track("Poll Breakdown Selected", {
              slug: pollState.userPoll?.slug,
              body: pollState.userPoll?.title,
              type: "gender",
            });
          }}
          className={styles.tabText}
        >
          GENDER
        </p>
        <p
          style={
            tab === "AGE"
              ? { opacity: "1", borderBottom: "2px solid #fffff2" }
              : {}
          }
          onClick={() => {
            setTab("AGE");
            if (mySwiperRef) {
              mySwiperRef.slideTo(tabHeaders.indexOf("AGE"));
            }
            Mixpanel?.track("Poll Breakdown Selected", {
              slug: pollState.userPoll?.slug,
              body: pollState.userPoll?.title,
              type: "age",
            });
          }}
          className={styles.tabText}
        >
          AGE
        </p>
        <p
          style={
            tab === "COGNITION"
              ? { opacity: "1", borderBottom: "2px solid #fffff2" }
              : {}
          }
          onClick={() => {
            setTab("COGNITION");
            if (mySwiperRef) {
              mySwiperRef.slideTo(tabHeaders.indexOf("COGNITION"));
            }
            Mixpanel?.track("Poll Breakdown Selected", {
              slug: pollState.userPoll?.slug,
              body: pollState.userPoll?.title,
              type: "cognition",
            });
          }}
          className={styles.tabText}
        >
          COGNITION
        </p>
      </div>
      <div className={styles.wrapper}>
        <Swiper
          min-height={200}
          autoHeight={true}
          slidesPerView={1}
          touchReleaseOnEdges={true}
          scrollbar={{ draggable: true }}
          threshold={1}
          onSwiper={setMySwiperRef}
          onSlideChange={(e) => {
            const index = e.activeIndex;
            if (index === 0) {
              setTab("NATURE");
            } else if (index === 1) {
              setTab("GENDER");
            } else if (index === 2) {
              setTab("AGE");
            } else {
              setTab("COGNITION");
            }
          }}
        >
          {tabs}
        </Swiper>
      </div>
    </section>
  );
}

function NatureTab(props: {
  natureResultGroups?: NaturePollResultGroup[] | null | undefined;
  natureResults: {
    options: Record<number, Nature | null>;
    orderAndStructureTotalCount: null | number;
    selfEnhancementTotalCount: null | number;
    energyAndExcitementTotalCount: null | number;
    warmthAndAgreeablenessTotalCount: null | number;
    opennessAndIntellectTotalCount: null | number;
    chaosTotalCount: null | number;
    tranquilityTotalCount: null | number;
  };
  pollOptions: PollOption[] | undefined;
}) {
  const mappedMostLikely = props.pollOptions?.map((option, index, array) => {
    const mostLikelyNatureSlug = props.natureResults.options[option.id];
    const foundNature = natureData.find((n) => n.slug === mostLikelyNatureSlug);
    return (
      <div
        style={index !== array.length - 1 ? { borderBottom: "0" } : {}}
        className={styles.mostLikelyRow}
        key={option.id}
      >
        <p className={styles.label}>
          {pollOptionIndexMap[option.id as keyof typeof pollOptionIndexMap]}{" "}
          {option.label}
        </p>
        <p
          style={foundNature ? { color: foundNature.color } : {}}
          className={styles.group}
        >
          {foundNature?.shortName ?? "?"}
        </p>
      </div>
    );
  });

  const mappedBreakdown = [
    "energyAndExcitement",
    "chaos",
    "opennessAndIntellect",
    "orderAndStructure",
    "selfEnhancement",
    "warmthAndAgreeableness",
    "tranquility",
  ].map((slug) => {
    const foundNature = natureData.find((n) => n.slug === slug);
    const natureGroupOptions = props.natureResultGroups?.find(
      (g) => g.nature === slug
    )?.pollOptions;
    const currentNatureTotalCount = natureGroupOptions?.reduce(
      (total, option) => total + (option.voteCount ?? 0),
      0
    );
    if (natureGroupOptions && currentNatureTotalCount) {
      return (
        <div className={styles.breakdownParent} key={slug}>
          <h4
            className={styles.breakdownRowTitle}
            style={foundNature ? { color: foundNature.color } : {}}
          >
            {foundNature?.shortName}
          </h4>
          {natureGroupOptions?.map((option) => {
            const percentage = option.voteCount
              ? ((option.voteCount / currentNatureTotalCount) * 100).toFixed(0)
              : 0;
            return (
              <div className={styles.breakdownRect} key={option.id}>
                <div
                  style={{ width: `${percentage}%` }}
                  className={styles.breakdownPercentageDiv}
                ></div>
                <p className={styles.breakdownLabel}>
                  {
                    pollOptionIndexMap[
                      option.id as keyof typeof pollOptionIndexMap
                    ]
                  }
                </p>
                <p className={styles.breakdownPercentage}>{percentage}%</p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className={styles.breakdownParent} key={slug}>
          <h4
            className={styles.breakdownRowTitle}
            style={foundNature ? { color: foundNature.color } : {}}
          >
            {foundNature?.shortName}
          </h4>
          <BreakdownGhostBox />
        </div>
      );
    }
  });

  return (
    <>
      <h3 className={`${styles.mostLikelyTitle} ${font.className}`}>
        MOST LIKELY TO CHOOSE:
      </h3>
      <div className={styles.mostLikelyWrapper}>{mappedMostLikely}</div>
      <h3 className={`${styles.mostLikelyTitle} ${font.className}`}>
        FULL BREAKDOWN
      </h3>
      <div className={styles.breakdownWrapper}>{mappedBreakdown}</div>
    </>
  );
}

function GenderTab(props: {
  genderResultGroups?: GenderPollResultGroup[] | null | undefined;
  genderResults: {
    options: Record<number, Gender | null>;
    maleTotalCount: null | number;
    femaleTotalCount: null | number;
    otherTotalCount: null | number;
  };
  pollOptions: PollOption[] | undefined;
}) {
  const genderAliasMap: Record<Gender, string> = {
    male: "Men",
    female: "Women",
    other: "Non-binary",
  };

  const mappedMostLikely = props.pollOptions?.map((option, index, array) => {
    const mostLikelyGender = props.genderResults.options[option.id];
    return (
      <div
        style={index !== array.length - 1 ? { borderBottom: "0" } : {}}
        className={styles.mostLikelyRow}
        key={option.id}
      >
        <p className={styles.label}>
          {pollOptionIndexMap[option.id as keyof typeof pollOptionIndexMap]}{" "}
          {option.label}
        </p>
        <p className={styles.group}>
          {mostLikelyGender ? genderAliasMap[mostLikelyGender] : "?"}
        </p>
      </div>
    );
  });

  const mappedBreakdown = [Gender.male, Gender.female, Gender.other].map(
    (gender) => {
      const genderGroupOptions = props.genderResultGroups?.find(
        (g) => g.gender === gender
      )?.pollOptions;
      const currentGenderTotalCount = genderGroupOptions?.reduce(
        (total, option) => total + (option.voteCount ?? 0),
        0
      );

      if (genderGroupOptions && currentGenderTotalCount) {
        return (
          <div className={styles.breakdownParent} key={gender}>
            <h4 className={styles.breakdownRowTitle}>
              {genderAliasMap[gender]}
            </h4>
            {genderGroupOptions?.map((option) => {
              const percentage = option.voteCount
                ? ((option.voteCount / currentGenderTotalCount) * 100).toFixed(
                    0
                  )
                : 0;

              return (
                <div className={styles.breakdownRect} key={option.id}>
                  <div
                    style={{ width: `${percentage}%` }}
                    className={styles.breakdownPercentageDiv}
                  ></div>
                  <p className={styles.breakdownLabel}>
                    {
                      pollOptionIndexMap[
                        option.id as keyof typeof pollOptionIndexMap
                      ]
                    }
                  </p>
                  <p className={styles.breakdownPercentage}>{percentage}%</p>
                </div>
              );
            })}
          </div>
        );
      } else {
        return (
          <div className={styles.breakdownParent} key={gender}>
            <h4 className={styles.breakdownRowTitle}>
              {genderAliasMap[gender]}
            </h4>
            <BreakdownGhostBox />
          </div>
        );
      }
    }
  );

  return (
    <>
      <h3 className={`${styles.mostLikelyTitle} ${font.className}`}>
        MOST LIKELY TO CHOOSE:
      </h3>
      <div className={styles.mostLikelyWrapper}>{mappedMostLikely}</div>
      <h3 className={`${styles.mostLikelyTitle} ${font.className}`}>
        FULL BREAKDOWN
      </h3>
      <div className={styles.breakdownWrapper}>{mappedBreakdown}</div>
    </>
  );
}

function AgeTab(props: {
  ageResultGroups?: AgePollResultGroup[] | null | undefined;
  ageResults: {
    options: Record<number, AgeRange | null>;
    underTwentyTotalCount: null | number;
    twentyToTwentyFiveTotalCount: null | number;
    twentySixToThirtyTotalCount: null | number;
    thirtyOneToThirtyFiveTotalCount: null | number;
    thirtySixPlusTotalCount: null | number;
  };
  pollOptions: PollOption[] | undefined;
}) {
  const ageRangeAliasMap: Record<number | "null", string> = {
    null: "<20",
    20: "20-25",
    26: "26-30",
    31: "31-35",
    36: "36+",
  };

  const mappedMostLikely = props.pollOptions?.map((option, index, array) => {
    const mostLikelyAge = props.ageResults.options[option.id];
    return (
      <div
        style={index !== array.length - 1 ? { borderBottom: "0" } : {}}
        className={styles.mostLikelyRow}
        key={option.id}
      >
        <p className={styles.label}>
          {pollOptionIndexMap[option.id as keyof typeof pollOptionIndexMap]}{" "}
          {option.label}
        </p>
        <p className={styles.group}>
          {mostLikelyAge
            ? ageRangeAliasMap[mostLikelyAge.minAge ?? "null"]
            : "?"}
        </p>
      </div>
    );
  });

  const mappedBreakdown = [null, 20, 26, 31, 36].map((age) => {
    const ageGroupOptions = props.ageResultGroups?.find(
      (g) => g.ageRange.minAge === age
    )?.pollOptions;
    const currentAgeTotalCount = ageGroupOptions?.reduce(
      (total, option) => total + (option.voteCount ?? 0),
      0
    );
    if (ageGroupOptions && currentAgeTotalCount) {
      return (
        <div className={styles.breakdownParent} key={age}>
          <h4 className={styles.breakdownRowTitle}>
            {ageRangeAliasMap[age as "null" | number]}
          </h4>
          {ageGroupOptions.map((option) => {
            const percentage = option.voteCount
              ? ((option.voteCount / currentAgeTotalCount) * 100).toFixed(0)
              : 0;
            return (
              <div className={styles.breakdownRect} key={option.id}>
                <div
                  style={{ width: `${percentage}%` }}
                  className={styles.breakdownPercentageDiv}
                ></div>
                <p className={styles.breakdownLabel}>
                  {
                    pollOptionIndexMap[
                      option.id as keyof typeof pollOptionIndexMap
                    ]
                  }
                </p>
                <p className={styles.breakdownPercentage}>{percentage}%</p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className={styles.breakdownParent} key={age}>
          <h4 className={styles.breakdownRowTitle}>
            {ageRangeAliasMap[age as "null" | number]}
          </h4>
          <BreakdownGhostBox />
        </div>
      );
    }
  });

  return (
    <>
      <h3 className={`${styles.mostLikelyTitle} ${font.className}`}>
        MOST LIKELY TO CHOOSE:
      </h3>
      <div className={styles.mostLikelyWrapper}>{mappedMostLikely}</div>
      <h3 className={`${styles.mostLikelyTitle} ${font.className}`}>
        FULL BREAKDOWN
      </h3>
      <div className={styles.breakdownWrapper}>{mappedBreakdown}</div>
    </>
  );
}

function CognitionTab(props: {
  cognitionResultGroups?: ArchetypePollResultGroup[] | null | undefined;
  cognitionResults: {
    options: Record<number, string | null>;
    ENFJTotalCount: number | null;
    ENFPTotalCount: number | null;
    ENTJTotalCount: number | null;
    ENTPTotalCount: number | null;
    ESFJTotalCount: number | null;
    ESFPTotalCount: number | null;
    ESTJTotalCount: number | null;
    ESTPTotalCount: number | null;
    INFJTotalCount: number | null;
    INFPTotalCount: number | null;
    INTJTotalCount: number | null;
    INTPTotalCount: number | null;
    ISFJTotalCount: number | null;
    ISFPTotalCount: number | null;
    ISTJTotalCount: number | null;
    ISTPotalCount: number | null;
  };
  pollOptions: PollOption[] | undefined;
}) {
  const mappedMostLikely = props.pollOptions?.map((option, index, array) => {
    const mostLikelyArchetype = props.cognitionResults.options[option.id];
    return (
      <div
        style={index !== array.length - 1 ? { borderBottom: "0" } : {}}
        className={styles.mostLikelyRow}
        key={option.id}
      >
        <p className={styles.label}>
          {pollOptionIndexMap[option.id as keyof typeof pollOptionIndexMap]}{" "}
          {option.label}
        </p>
        <p className={styles.group}>
          {mostLikelyArchetype?.replace("mbti-", "").toUpperCase() ?? "?"}
        </p>
      </div>
    );
  });

  const groupSlugs: string[] = [
    "mbti-enfj",
    "mbti-enfp",
    "mbti-entj",
    "mbti-entp",
    "mbti-esfj",
    "mbti-esfp",
    "mbti-estj",
    "mbti-estp",
    "mbti-infj",
    "mbti-infp",
    "mbti-intj",
    "mbti-intp",
    "mbti-isfj",
    "mbti-isfp",
    "mbti-istj",
    "mbti-istp",
  ];

  const mappedBreakdown = groupSlugs.map((slug) => {
    const archetypeGroupOptions = props.cognitionResultGroups?.find(
      (g) => g.archetypeSlug === slug
    )?.pollOptions;
    const currentArchetypeTotalCount = archetypeGroupOptions?.reduce(
      (total, option) => total + (option.voteCount ?? 0),
      0
    );
    if (archetypeGroupOptions && currentArchetypeTotalCount) {
      return (
        <div className={styles.breakdownParent} key={slug}>
          <h4 className={styles.breakdownRowTitle}>
            {slug.replace("mbti-", "").toUpperCase()}
          </h4>
          {archetypeGroupOptions.map((option) => {
            const percentage = option.voteCount
              ? ((option.voteCount / currentArchetypeTotalCount) * 100).toFixed(
                  0
                )
              : 0;
            return (
              <div className={styles.breakdownRect} key={option.id}>
                <div
                  style={{ width: `${percentage}%` }}
                  className={styles.breakdownPercentageDiv}
                ></div>
                <p className={styles.breakdownLabel}>
                  {
                    pollOptionIndexMap[
                      option.id as keyof typeof pollOptionIndexMap
                    ]
                  }
                </p>
                <p className={styles.breakdownPercentage}>{percentage}%</p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className={styles.breakdownParent} key={slug}>
          <h4 className={styles.breakdownRowTitle}>
            {slug.replace("mbti-", "").toUpperCase()}
          </h4>
          <BreakdownGhostBox />
        </div>
      );
    }
  });

  return (
    <>
      <h3 className={`${styles.mostLikelyTitle} ${font.className}`}>
        MOST LIKELY TO CHOOSE:
      </h3>
      <div className={styles.mostLikelyWrapper}>{mappedMostLikely}</div>
      <h3 className={`${styles.mostLikelyTitle} ${font.className}`}>
        FULL BREAKDOWN
      </h3>
      <div className={styles.breakdownWrapper}>{mappedBreakdown}</div>
    </>
  );
}

export type ResultBreakDownPolls =
  | {
      genderResultGroups?: GenderPollResultGroup[] | null | undefined;
      archetypeResultGroups?: ArchetypePollResultGroup[] | null | undefined;
      ageResultGroups?: AgePollResultGroup[] | null | undefined;
      natureResultGroups?: NaturePollResultGroup[] | null | undefined;
    }
  | null
  | undefined;

function useUpdateResultsBreakdownForPolls() {
  const pollStateResults = useSelector((state: RootState) => state.userPoll)
    .userPoll?.resultsBreakdown;

  const [pollResults, setPollResults] = useState<ResultBreakDownPolls>(null);

  useEffect(() => {
    setPollResults(pollStateResults);
  }, [pollStateResults]);

  return pollResults;
}

function BreakdownGhostBox() {
  return (
    <div className={styles.breakdownGHostboxMain}>
      <p className={styles.breakdownGHostboxText}>No responses yet</p>
    </div>
  );
}
