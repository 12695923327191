import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Roboto_Mono } from "next/font/google";
import { doc, onSnapshot } from "firebase/firestore";
import {
  ContentType,
  DeliveredDailyStoryNew,
} from "@dimensional-engineering/dimensional-models";

import styles from "./DailyStories.module.css";

import { RootState } from "@/redux/store";
import {
  getCountOfTraitRulesDailyStoriesForMixpanel,
  getListOfTraitRulesDailyStoriesForMixpanel,
  splitStringIntoMultipleLines,
  updateDailyStoryFeedbackScore,
} from "./utils";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { CloseButtonWhite } from "@/components/shared/CloseButtonSummary";
import { useModalContext } from "@/context/ModalContext";
import { DailyStoryFeedbackScoreBar } from "@/components/results/personalityAnalysis/AnalysisFeedback/AnalysisFeedback";
import { useAuth } from "@/context/AuthContext";
import { db } from "@/_firebase/firebaseConfig";
import DailyStoriesShareView from "./DailyStoriesShareView";
import { Mixpanel } from "@/helpers/mixpanel";
import ExpiredErrorModal from "@/components/shared/ExpiredErrorModal/ExpiredErrorModal";
import { DailyStoriesInterractionBar } from "@/components/results/Snippets/SnippetInterractionBar/SnippetInterractionBar";
import InformationIcon from "@/components/premium/reports/blindspot/ReportAccuracy/InformationIcon";
import GeneralModal from "@/components/shared/GeneralModal/GeneralModal";
import useUpdateCommentThreadData from "../CommentModal/useUpdateCommentThreadData";
import CommentModal from "../CommentModal/CommentModal";
import PostToConnectionsButton from "../ABSTRACTS/AppHomeSharedTab/PostToConnectionsButton/PostToConnectionsButton";
import { SharedPostWithCommentThread } from "@/models/sharedModels";
import SharedPostModal from "../ABSTRACTS/AppHomeSharedTab/SharedPostModal/SharedPostModal";
import ShareCaptionModal from "../ABSTRACTS/AppHomeSharedTab/ShareCaptionModal/ShareCaptionModal";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function DailyStoriesModal(props: {
  elementName: "DailyStory";
  slug?: string;
  showComment?: boolean;
  commentId?: string;
}) {
  const { user } = useAuth();
  const { setModalOpen, setModalComponent } = useModalContext();

  const dailyStory = useSelector((state: RootState) => state.dailyStory);
  const userPublicProfile = useSelector(
    (state: RootState) => state.user
  ).publicProfileData;
  const profileImage = useSelector((state: RootState) => state.user).imageURL;

  const feedbackData = useGetDailyStoryFeedback();

  const [showRating, setShowRating] = useState<boolean>(false);
  const [showCaptionModal, setShowCaptionModal] = useState<boolean>(false);
  const [caption, setCaption] = useState<string>("");

  const { showComments, setShowComments } = useUpdateCommentThreadData(
    dailyStory.dailyStory?.assignmentRuleset.commentThreadId,
    props.showComment
  );

  const foundPost = useIsDailyStoryShared({ userUid: user?.uid });

  useEffect(() => {
    if (dailyStory.dailyStory?.computeableStoryModelSlug) {
      Mixpanel?.track("Daily Story Viewed", {
        slug: dailyStory.dailyStory?.computeableStoryModelSlug,
        body: dailyStory.dailyStory.assignmentRuleset?.body.slice(0, 250),
        trait_rules: getListOfTraitRulesDailyStoriesForMixpanel(
          dailyStory.dailyStory
        ),
        is_novel_ruleset:
          dailyStory.dailyStory.assignmentRuleset.isNovelGenerated,
        trait_count: getCountOfTraitRulesDailyStoriesForMixpanel(
          dailyStory.dailyStory
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyStory.dailyStory?.computeableStoryModelSlug]);

  function handlePost() {
    if (foundPost) {
      Mixpanel?.track("Post viewed", {
        source: "from own results modal",
        content_type: "daily story",
        content_type_slug:
          foundPost.postContent.dailyStoryData?.storyDelivery
            .computeableStoryModelSlug,
        caption_text: foundPost.caption,
      });
      return setModalComponent(
        <SharedPostModal
          profileImage={profileImage ?? ""}
          publicProfile={userPublicProfile}
          sharedPost={foundPost}
        />
      );
    }
    return setShowCaptionModal(true);
  }

  useEffect(() => {
    if (feedbackData !== null && feedbackData !== undefined) {
      setShowRating(false);
    } else {
      setShowRating(true);
    }
  }, [feedbackData]);

  if (!dailyStory.dailyStory) {
    return (
      <ExpiredErrorModal body="You waited too long to see that story! Stories disappear after 24 hours." />
    );
  }

  if (
    props.slug &&
    props.slug !== dailyStory.dailyStory.computeableStoryModelSlug
  ) {
    return (
      <ExpiredErrorModal body="You waited too long to see that story! Stories disappear after 24 hours." />
    );
  }

  const bodySplit = splitStringIntoMultipleLines(
    dailyStory.dailyStory.assignmentRuleset?.body,
    32
  );

  const mappedBody = bodySplit.map((line, i) => {
    return (
      <p className={styles.storyBody} key={i}>
        {line}
      </p>
    );
  });

  const commentCountRoot = dailyStory.commentThread?.rootCommentCount ?? 0;
  const commentCountReplies = dailyStory.commentThread?.replyCount ?? 0;

  function onCopy() {
    if (
      window.Android &&
      window.Android.copyToClipboard &&
      dailyStory.dailyStory?.assignmentRuleset?.body
    ) {
      window.Android.copyToClipboard(
        dailyStory.dailyStory.assignmentRuleset?.body
      );
      Mixpanel?.track("Content copied", {
        slug: dailyStory.dailyStory?.computeableStoryModelSlug,
        body: dailyStory.dailyStory?.assignmentRuleset?.body.slice(0, 250),
        trait_rules: getListOfTraitRulesDailyStoriesForMixpanel(
          dailyStory.dailyStory
        ),
        is_novel_ruleset:
          dailyStory.dailyStory?.assignmentRuleset.isNovelGenerated ?? false,
        trait_count: getCountOfTraitRulesDailyStoriesForMixpanel(
          dailyStory.dailyStory
        ),
      });
    }
  }

  return (
    <>
      {showComments && (
        <CommentModal
          onClose={() => setShowComments(false)}
          commentId={props.commentId}
          slug={dailyStory.dailyStory.computeableStoryModelSlug ?? ""}
          audienceGroupSize={
            dailyStory.commentThread?.audienceUids?.length ?? 0
          }
          source="daily story"
          content={dailyStory.dailyStory.assignmentRuleset.body}
        />
      )}
      {showCaptionModal && dailyStory.dailyStory.deliveryId && (
        <ShareCaptionModal
          contentIdentifier={{
            contentSlug: dailyStory.dailyStory.deliveryId,
            contentType: ContentType.deliveredDailyStory,
          }}
          onClose={(captionText) => {
            setCaption(captionText);
            setShowCaptionModal(false);
          }}
          savedCaption={caption}
        />
      )}
      <ModalWrapper title="" noHeader>
        <main
          style={{
            backgroundImage: `linear-gradient(rgba(37, 0, 0, 0) 54.69%, rgba(6, 0, 0, 0.5) 100%), url(/dailyStories/${dailyStory.dailyStory.computeableStoryModelSlug}.jpg)`,
          }}
          className={styles.modalMain}
        >
          <div
            onClick={() => {
              setModalOpen(false);
            }}
            className={styles.xmark}
          >
            <CloseButtonWhite />
          </div>
          <section className={styles.wrapper}>
            <h5 className={`${styles.storyTitleModal} ${font.className}`}>
              {dailyStory.dailyStory.name.toUpperCase() + ":"}
            </h5>
            <div
              onClick={() => {
                if (dailyStory.dailyStory?.assignmentRuleset.isNovelGenerated) {
                  setModalComponent(
                    <GeneralModal
                      title="Experimental Story"
                      body="Sometimes we sprinkle in a bit of chaos to our stories. The results aren't always good, so feedback on these experimental stories is particularly helpful."
                    />
                  );
                }
              }}
              style={
                dailyStory.dailyStory?.assignmentRuleset.isNovelGenerated
                  ? {}
                  : { opacity: "0" }
              }
              className={styles.experimentalDiv}
            >
              <p className={`${styles.experimentalText} ${font.className}`}>
                EXPERIMENTAL STORY
              </p>
              <div className={styles.infoIcon}>
                <InformationIcon />
              </div>
            </div>
            <div onClick={() => onCopy()} className={styles.bodyWrapper}>
              {mappedBody}
            </div>
          </section>
          <div className={styles.interractionBar}>
            {showRating && (
              <div className={styles.feedbackDiv}>
                <DailyStoryFeedbackScoreBar
                  onAnswer={async (value) => {
                    Mixpanel?.track("Feedback given", {
                      slug: dailyStory.dailyStory?.computeableStoryModelSlug,
                      body: dailyStory.dailyStory?.assignmentRuleset?.body.slice(
                        0,
                        250
                      ),
                      score: value,
                      content_type: "daily_story",
                      trait_rules: getListOfTraitRulesDailyStoriesForMixpanel(
                        dailyStory.dailyStory
                      ),
                      is_novel_ruleset:
                        dailyStory.dailyStory?.assignmentRuleset
                          .isNovelGenerated,
                      trait_count: getCountOfTraitRulesDailyStoriesForMixpanel(
                        dailyStory.dailyStory
                      ),
                    });
                    return await updateDailyStoryFeedbackScore(
                      user?.uid,
                      value,
                      dailyStory.dailyStory?.deliveryId
                    ).then(() => {
                      setShowRating(false);
                    });
                  }}
                  title="How good was this content?"
                  subtitle="Feedback improves your results"
                  currentAnswer={feedbackData ?? null}
                  onClose={() => setShowRating(false)}
                />
              </div>
            )}
            <div
              style={showRating ? { height: "0" } : {}}
              className={styles.bottomGradientDiv}
            ></div>
            <div className={styles.sharedPostButtonDiv}>
              <PostToConnectionsButton
                posted={foundPost ? true : false}
                onClick={() => {
                  Mixpanel?.track("Post to connections CTA pressed", {
                    source: "modal body",
                    content_type: "daily story",
                    content_type_slug:
                      dailyStory.dailyStory?.computeableStoryModelSlug,
                  });
                  handlePost();
                }}
              />
            </div>
            <DailyStoriesInterractionBar
              onCommentClick={() => {
                setShowComments(true);
                Mixpanel?.track("Comment modal summoned", {
                  source: "daily story",
                  audience_group_size:
                    dailyStory.commentThread?.audienceUids?.length ?? 0,
                  type: "from Discuss CTA",
                });
              }}
              onShare={(type) => {
                setModalComponent(<DailyStoriesShareView type={type} />);
                Mixpanel?.track("Shared to Social", {
                  type: "dailyStory",
                  slug: dailyStory.dailyStory?.computeableStoryModelSlug,
                  platform: type,
                  body: dailyStory.dailyStory?.assignmentRuleset?.body.slice(
                    0,
                    250
                  ),
                  user_message:
                    dailyStory.dailyStory?.assignmentRuleset.promptDetails
                      .userMessage,
                  assistant_message:
                    dailyStory.dailyStory?.assignmentRuleset.promptDetails
                      .assistantMessage,
                  system_message:
                    dailyStory.dailyStory?.assignmentRuleset.promptDetails
                      .systemMessage,
                  trait_rules: getListOfTraitRulesDailyStoriesForMixpanel(
                    dailyStory.dailyStory
                  ),
                  is_novel_ruleset:
                    dailyStory.dailyStory?.assignmentRuleset.isNovelGenerated ??
                    false,
                  trait_count: getCountOfTraitRulesDailyStoriesForMixpanel(
                    dailyStory.dailyStory
                  ),
                });
              }}
              onRateClick={() => {
                setShowRating(!showRating);
              }}
              commentCount={
                commentCountRoot + commentCountReplies === 99
                  ? 99
                  : commentCountRoot + commentCountReplies
              }
              onPostClick={() => {
                Mixpanel?.track("Post to connections CTA pressed", {
                  source: "interaction bar",
                  content_type: "daily story",
                  content_type_slug:
                    dailyStory.dailyStory?.computeableStoryModelSlug,
                });
                handlePost();
              }}
              posted={foundPost ? true : false}
            />
          </div>
        </main>
      </ModalWrapper>
    </>
  );
}

function useGetDailyStoryFeedback() {
  const [feedbackData, setFeedbackData] = useState<number | null | undefined>(
    null
  );
  const { user } = useAuth();

  const dailyStory = useSelector((state: RootState) => state.dailyStory);

  useEffect(() => {
    const ref = doc(
      db,
      `/members/${user?.uid}/deliveredDailyStoriesNew/${dailyStory.dailyStory?.deliveryId}`
    );
    const unsubscribe = onSnapshot(
      ref,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data() as DeliveredDailyStoryNew;
          setFeedbackData(data.feedbackScore);
        } else {
          setFeedbackData(null);
        }
      },
      (err) => {
        setFeedbackData(null);
      }
    );

    return () => {
      unsubscribe;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyStory.dailyStory?.deliveryId]);

  return feedbackData;
}

function useIsDailyStoryShared(props: { userUid: string | undefined }) {
  const sharedPosts = useSelector(
    (state: RootState) => state.sharedPosts
  ).sharedPosts;
  const dailyStory = useSelector((state: RootState) => state.dailyStory);

  const [foundPost, setFoundPost] =
    useState<SharedPostWithCommentThread | null>(null);

  useEffect(() => {
    const slug = `${props.userUid}-deliveredDailyStory-${dailyStory.dailyStory?.deliveryId}`;
    const isFound = sharedPosts.find((post) => post.slug === slug);
    if (isFound) {
      setFoundPost(isFound);
    } else {
      setFoundPost(null);
    }
  }, [dailyStory.dailyStory?.deliveryId, props.userUid, sharedPosts.length]);

  return foundPost;
}
