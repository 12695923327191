import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCommentsThread } from "./utils";
import {
  resetCommentState,
  setComments,
  setCommentsLoading,
  setCommentThreadId,
  setLastComment,
} from "@/redux/slices/commentsSlice";
import { RootState } from "@/redux/store";

//This compionent handles showing the mcommenting modal,
//resetting it when the parent component unmounts, and refetching modal data when the modal
// is closed

export default function useUpdateCommentThreadData(
  commentThreadId: string | null | undefined,
  showComment?: boolean
) {
  const [showComments, setShowComments] = useState<boolean>(false);
  const dispatch = useDispatch();

  const commentThreadLoading = useSelector(
    (state: RootState) => state.comments
  ).loading;

  useEffect(() => {
    if (showComment) {
      setShowComments(true);
    }
  }, [showComment]);

  useEffect(() => {
    return () => {
      dispatch(resetCommentState());
    };
  }, []);

  useEffect(() => {
    if (commentThreadId && commentThreadLoading) {
      getCommentsThread(commentThreadId)
        .then((res) => {
          dispatch(setCommentThreadId(commentThreadId));
          dispatch(setComments(res.comments));
          dispatch(setCommentsLoading(false));
          dispatch(setLastComment(res.lastComment));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [showComments]);

  return { showComments, setShowComments };
}
