/* eslint-disable @next/next/no-img-element */
import {
  PublicProfile,
  SocialMediaMap,
} from "@dimensional-engineering/dimensional-models";

import styles from "./AuthMobileBioAndLinks.module.css";

import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import {
  InstagramImage,
  LinkedInImage,
  TikTokImage,
  TwitterImage,
} from "@/components/publicProfiles/SocialLinks/SocialMediaImages";

type Props = {
  bio: string | null;
  imageUrl: string | null;
  links: SocialMediaMap | null;
  publicProfile: PublicProfile | null;
};

export default function AuthMobileBioAndLinks(props: Props) {
  return (
    <div className={styles.mobileBioLinksParent}>
      {props.bio && props.bio !== "" && (
        <div className={styles.imageAndBio}>
          <div className={styles.smallImageContainer}>
            <SmallProfileImage
              publicProfile={props.publicProfile as PublicProfile}
              imageUrl={props.imageUrl ?? "/default-image.png"}
            />
          </div>
          <p className={styles.bio}>{props.bio}</p>
        </div>
      )}
      <div className={styles.mobileSocialLinksContainer}>
        <TwitterImage
          url={
            props?.links?.twitter?.userName
              ? props.links.twitter.userName
              : null
          }
        />
        <LinkedInImage
          url={
            props?.links?.linkedIn?.userName
              ? props.links.linkedIn.userName
              : null
          }
        />
        <InstagramImage
          url={
            props?.links?.instagram?.userName
              ? props.links.instagram.userName
              : null
          }
        />

        <TikTokImage
          url={
            props?.links?.tikTok?.userName ? props.links.tikTok.userName : null
          }
        />
      </div>
    </div>
  );
}
