import { useState } from "react";

import styles from "./HalfWayModal.module.css";

import { useAuth } from "@/context/AuthContext";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import Loader from "../Loader";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "../Alerts/Alert";
import { useModalContext } from "@/context/ModalContext";

type Props = {
  uid: string;
  elementName: "HalfwayModal";
};

export default function HalfWayModalConnections(props: Props) {
  const { user } = useAuth();
  const { setAlertComponent } = useAlertContext();
  const { removeModalByName } = useModalContext();

  const [loading, setLoading] = useState<boolean>(false);

  function handleUnfriend() {
    setLoading(true);
    if (props.uid) {
      const unfriend = callFirebaseFunctions("unfriend");
      unfriend({ unfrienderUUID: user?.uid, unfriendeeUUID: props.uid })
        .then((res) => {
          setLoading(false);
          setAlertComponent(
            <Alert
              elementName="Alert"
              type="success"
              message="Successfully deleted connection."
            />,
            3000
          );
          removeModalByName("HalfwayModal");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }

  return (
    <dialog
      onClick={() => removeModalByName("HalfwayModal")}
      className={styles.dialogue}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={styles.mainConnections}
      >
        <div className={styles.wrapper}>
          {loading ? (
            <Loader height="425px" mobileHeight="425px" />
          ) : (
            <>
              <h1 className={styles.titleRow}>More options</h1>
              <p onClick={() => handleUnfriend()} className={styles.row}>
                Unfriend
              </p>
              <button
                className={styles.dismissButton}
                onClick={() => removeModalByName("HalfwayModal")}
              >
                Dismiss
              </button>
            </>
          )}
        </div>
      </div>
    </dialog>
  );
}
