import { useRouter } from "next/router";
import { useState } from "react";
import {
  DimensionEvent,
  SnippetType,
} from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";

import styles from "./DiscoveryGate.module.css";

import { RootState } from "@/redux/store";
import DiscoveryCheckMark from "./DiscoveryCheckMark";
import ComingSoonIcon from "../icons/ComingSoonIcon";
import UserSuccessInviteGate from "./UserSuccessInviteGate";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { useModalContext } from "@/context/ModalContext";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import useDiscoveryGateHook from "@/helpers/useDiscoveryGateHook";
import Loader from "../Loader";
import UserConnectionGate from "./UserConnectionGate";
import useSnippetsLLMRequestStatus from "@/helpers/useSnippetsLLMRequestStatus";
import WarningIcon from "../GhostBox/WarningIcon";
import SnippetsRegenerateButton from "@/components/results/Snippets/SnippetsRegenerateButton";
import { Mixpanel } from "@/helpers/mixpanel";
import { dimensionIcons } from "@/assets/traitInfo/dimensionData";
import UserPeerAssessmentGate from "./UserPeerAssessmentGate";

type Props = {
  numFriendsRequired?: number | null | undefined;
  requiredDimensionSlugs?: string[] | null | undefined;
  numEndorsementsRequired?: number | null | undefined;
  numSuccessfulInvitesRequired?: number | null | undefined;
  numPeerAssessorsRequired?: number | null | undefined;
  onClose: () => void;
  onOpenShareLink: () => void;
  snippetDimension?: boolean;
  snippetConnection?: boolean;
  snippetType: SnippetType;
  snippetSlug: string;
};

export default function SnippetDiscoveryGate(props: Props) {
  const router = useRouter();
  const dimensionEvents: DimensionEvent[] | null = useSelector(
    (state: RootState) => state.user?.dimensionEvents
  );
  const userSuccessfulInvites = useSelector(
    (state: RootState) => state.user.memberProfile?.recommendeeUids
  );
  const userConnectionCount = useSelector(
    (state: RootState) => state.user.friendUidList.length
  );
  const {
    dimensionData,
    errorState,
    neededDimensionsCompleted,
    connectionComplete,
    invitesComplete,
    peerRatingsComplete,
    peerAssessorComplete,
  } = useDiscoveryGateHook({
    userConnectionCount: userConnectionCount,
    userSuccessfulInvites: userSuccessfulInvites,
    userDimensionEvents: dimensionEvents,
    requiredDimensionSlugs: props.requiredDimensionSlugs,
    numFriendsRequired: props.numFriendsRequired,
    numSuccessfulInvitesRequired: props.numSuccessfulInvitesRequired,
    numPeerAssessorsRequired: props.numPeerAssessorsRequired,
  });
  const { enabled, state, alertMessage } = useSnippetsLLMRequestStatus();
  const [loading, setLoading] = useState<boolean>(false);
  const { setModalOpen } = useModalContext();

  const mappedDimensions = dimensionData
    .filter((a) => a.isNeeded === true)
    .map((dim, index) => {
      return (
        <div className={styles.row} key={dim.slug}>
          <div className={styles.iconDiv}>
            {dim.event === "Retake"
              ? dimensionIcons[dim.slug].iconInactive
              : dimensionIcons[dim.slug].icon}
          </div>
          <div>
            <h2 className={dim.event === "Retake" ? styles.inactive : ""}>
              {dim.title}
            </h2>
            <p className={dim.event === "Retake" ? styles.inactive : ""}>
              {dim.numOfElements} Elements
            </p>
          </div>

          {dim.event !== "Retake" && (
            <button
              onClick={() => {
                router.push(dim.url);
                props.onClose();
              }}
              className={styles.buttonDivActive}
            >
              Go
            </button>
          )}
          {dim.event === "Retake" && (
            <div className={styles.checkParentDiv}>
              <DiscoveryCheckMark
                backgroundColor="#464542"
                checkColor="#fffff2"
              />
            </div>
          )}
        </div>
      );
    });

  function handleSnippetRecompute() {
    Mixpanel?.track("Retry CTA Clicked", {
      content_slug: props?.snippetSlug,
      content_type: "result_snippet",
    });
    setLoading(true);
    const dimensionRecompute = callFirebaseFunctions(
      "updateDimensionGatedSnippets"
    );
    const connectionRecompute = callFirebaseFunctions(
      "updateConnectionGatedSnippets"
    );
    if (props.snippetConnection) {
      connectionRecompute()
        .then((res) => {
          setModalOpen(false);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setModalOpen(false);
          console.log(err);
        });
    } else {
      dimensionRecompute()
        .then((res) => {
          setModalOpen(false);
        })
        .catch((err) => {
          setModalOpen(false);
        });
    }
  }

  if (loading) {
    return (
      <ModalWrapper title="" noHeader backgroundColor="#2d2927">
        <Loader height="500px" mobileHeight="500px" />
      </ModalWrapper>
    );
  }

  if (errorState === true) {
    if (
      props.snippetType === SnippetType.llmTitlesAndContent ||
      props.snippetType === SnippetType.llmList ||
      props.snippetType === SnippetType.llmMatch
    ) {
      ///llm snippets error states
      return (
        <ModalWrapper title="" noHeader backgroundColor="#2d2927">
          <div
            onClick={() => {
              props.onClose();
            }}
            className={styles.closeButton}
            style={{ top: "9px" }}
          >
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                opacity="0.15"
                cx="17.5"
                cy="17.5"
                r="17.5"
                fill="#FFFFF2"
              />
              <g opacity="0.7">
                <path
                  d="M23.8192 11.9618C23.9309 11.8559 23.986 11.7253 23.986 11.5715C23.986 11.4177 23.9309 11.2842 23.8192 11.1725C23.7133 11.0666 23.5827 11.0143 23.4289 11.0143C23.2751 11.0143 23.1416 11.0666 23.0299 11.1725L17.8574 16.3535L12.6763 11.1725C12.5704 11.0666 12.4398 11.0143 12.286 11.0143C12.1322 11.0143 11.9987 11.0666 11.887 11.1725C11.7811 11.2842 11.7289 11.4177 11.7289 11.5715C11.7289 11.7253 11.7811 11.8558 11.887 11.9618L17.0681 17.1428L11.887 22.3153C11.7811 22.4271 11.7289 22.5605 11.7289 22.7143C11.7289 22.8681 11.7811 22.9987 11.887 23.1046C11.9987 23.2164 12.1322 23.2715 12.286 23.2715C12.4398 23.2715 12.5704 23.2164 12.6763 23.1046L17.8574 17.9321L23.0299 23.1046C23.1416 23.2164 23.2751 23.2715 23.4289 23.2715C23.5827 23.2715 23.7132 23.2164 23.8192 23.1046C23.9309 22.9987 23.986 22.8681 23.986 22.7143C23.986 22.5605 23.9309 22.4271 23.8192 22.3153L18.6467 17.1428L23.8192 11.9618Z"
                  fill="#FFFFF2"
                />
              </g>
            </svg>
          </div>
          <div className={styles.gatedParent}>
            {state === "error" && (
              <>
                <div className={styles.soonIcon}>
                  <WarningIcon active />
                </div>
                <h1 className={styles.soonText}>Something went wrong.</h1>
                <SnippetsRegenerateButton
                  disabled={!enabled}
                  message={alertMessage}
                  onClick={() => {
                    handleSnippetRecompute();
                  }}
                />
              </>
            )}
            {state === "pending" && (
              <>
                <div className={styles.soonIcon}>
                  <ComingSoonIcon />
                </div>
                <h1 className={styles.soonText}>
                  {
                    "Results are computing. We'll notify you when they're ready."
                  }
                </h1>
                <SnippetsRegenerateButton
                  disabled={true}
                  message={alertMessage}
                  onClick={() => {}}
                />
              </>
            )}
          </div>
        </ModalWrapper>
      );
    }
    return (
      <ModalWrapper title="" noHeader backgroundColor="#2d2927">
        <div
          onClick={() => {
            props.onClose();
          }}
          className={styles.closeButton}
          style={{ top: "9px" }}
        >
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              opacity="0.15"
              cx="17.5"
              cy="17.5"
              r="17.5"
              fill="#FFFFF2"
            />
            <g opacity="0.7">
              <path
                d="M23.8192 11.9618C23.9309 11.8559 23.986 11.7253 23.986 11.5715C23.986 11.4177 23.9309 11.2842 23.8192 11.1725C23.7133 11.0666 23.5827 11.0143 23.4289 11.0143C23.2751 11.0143 23.1416 11.0666 23.0299 11.1725L17.8574 16.3535L12.6763 11.1725C12.5704 11.0666 12.4398 11.0143 12.286 11.0143C12.1322 11.0143 11.9987 11.0666 11.887 11.1725C11.7811 11.2842 11.7289 11.4177 11.7289 11.5715C11.7289 11.7253 11.7811 11.8558 11.887 11.9618L17.0681 17.1428L11.887 22.3153C11.7811 22.4271 11.7289 22.5605 11.7289 22.7143C11.7289 22.8681 11.7811 22.9987 11.887 23.1046C11.9987 23.2164 12.1322 23.2715 12.286 23.2715C12.4398 23.2715 12.5704 23.2164 12.6763 23.1046L17.8574 17.9321L23.0299 23.1046C23.1416 23.2164 23.2751 23.2715 23.4289 23.2715C23.5827 23.2715 23.7132 23.2164 23.8192 23.1046C23.9309 22.9987 23.986 22.8681 23.986 22.7143C23.986 22.5605 23.9309 22.4271 23.8192 22.3153L18.6467 17.1428L23.8192 11.9618Z"
                fill="#FFFFF2"
              />
            </g>
          </svg>
        </div>
        <div className={styles.gatedParent}>
          <div className={styles.soonIcon}>
            <WarningIcon active />
          </div>
          <h1 className={styles.soonText}>An Error Occured!</h1>
          <button
            className={styles.retrtyButton}
            onClick={(e) => {
              handleSnippetRecompute();
            }}
          >
            Retry
          </button>
        </div>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper title="" noHeader backgroundColor="#2d2927">
      <div className={styles.parent}>
        <div
          onClick={() => {
            props.onClose();
          }}
          className={styles.closeButton}
        >
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              opacity="0.15"
              cx="17.5"
              cy="17.5"
              r="17.5"
              fill="#FFFFF2"
            />
            <g opacity="0.7">
              <path
                d="M23.8192 11.9618C23.9309 11.8559 23.986 11.7253 23.986 11.5715C23.986 11.4177 23.9309 11.2842 23.8192 11.1725C23.7133 11.0666 23.5827 11.0143 23.4289 11.0143C23.2751 11.0143 23.1416 11.0666 23.0299 11.1725L17.8574 16.3535L12.6763 11.1725C12.5704 11.0666 12.4398 11.0143 12.286 11.0143C12.1322 11.0143 11.9987 11.0666 11.887 11.1725C11.7811 11.2842 11.7289 11.4177 11.7289 11.5715C11.7289 11.7253 11.7811 11.8558 11.887 11.9618L17.0681 17.1428L11.887 22.3153C11.7811 22.4271 11.7289 22.5605 11.7289 22.7143C11.7289 22.8681 11.7811 22.9987 11.887 23.1046C11.9987 23.2164 12.1322 23.2715 12.286 23.2715C12.4398 23.2715 12.5704 23.2164 12.6763 23.1046L17.8574 17.9321L23.0299 23.1046C23.1416 23.2164 23.2751 23.2715 23.4289 23.2715C23.5827 23.2715 23.7132 23.2164 23.8192 23.1046C23.9309 22.9987 23.986 22.8681 23.986 22.7143C23.986 22.5605 23.9309 22.4271 23.8192 22.3153L18.6467 17.1428L23.8192 11.9618Z"
                fill="#FFFFF2"
              />
            </g>
          </svg>
        </div>

        <div className={styles.parent}>
          <h1 className={styles.requiredTitle}>REQUIRED TO UNLOCK THIS</h1>
          {props.requiredDimensionSlugs &&
          neededDimensionsCompleted === false ? (
            <>
              <div className={styles.tableHeader}>Dimensions</div>
              {mappedDimensions}
            </>
          ) : (
            <div className={styles.tableHeader}>
              Dimensions
              <div className={styles.checkmarkCompleteDiv}>
                <DiscoveryCheckMark
                  backgroundColor="#e6e1df"
                  checkColor="#2D2927"
                />
              </div>
            </div>
          )}

          {props.numPeerAssessorsRequired && (
            <UserPeerAssessmentGate
              numPeerAssessorsRequired={props.numPeerAssessorsRequired}
            />
          )}

          {props.numFriendsRequired && (
            <UserConnectionGate
              numberOfConnectionsRequired={props.numFriendsRequired}
              onOpenShareLink={() => {
                props.onOpenShareLink();
                props.onClose();
              }}
            />
          )}

          {props.numSuccessfulInvitesRequired && (
            <UserSuccessInviteGate
              numberOfInvitesRequired={props.numSuccessfulInvitesRequired}
              invites={userSuccessfulInvites}
              onOpenShareLink={() => {
                props.onOpenShareLink();
                props.onClose();
              }}
            />
          )}
        </div>
      </div>
    </ModalWrapper>
  );
}
