import { db } from "@/_firebase/firebaseConfig";
import {
  DeliveredDailyStoryNew,
  StoryDay,
} from "@dimensional-engineering/dimensional-models";
import { DeliveredDailyStory } from "@dimensional-engineering/dimensional-models/lib/models/stories/DeliveredDailyStory";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";

export const dayToTitleMap: Record<StoryDay, string> = {
  monday: "Mindful Monday",
  tuesday: "Toxic Tuesday",
  wednesday: "Wildcard Wednesday",
  thursday: "Thirsty Thursday",
  friday: "Fantasy Friday",
  saturday: "Strengths Saturday",
  sunday: "Scary Sunday",
};

export function splitStringIntoThreeLines(
  input: string | undefined,
  maxLineLength: number
): string[] {
  if (!input) {
    return [];
  }
  const words = input?.split(" ");
  let lines = ["", "", ""];
  let currentLineIndex = 0;

  for (const word of words) {
    if (lines[currentLineIndex].length + word.length + 1 <= maxLineLength) {
      lines[currentLineIndex] += (lines[currentLineIndex] ? " " : "") + word;
    } else if (currentLineIndex < 2) {
      currentLineIndex++;
      lines[currentLineIndex] = word;
    } else {
      lines[currentLineIndex] += "...";
      break;
    }
  }

  // If the third line is longer than allowed, truncate it and add ellipsis
  if (lines[2].length > maxLineLength) {
    lines[2] = lines[2].substring(0, maxLineLength - 3) + "...";
  }

  if (lines[2] === "") {
    return [lines[0], lines[1]];
  }

  return lines;
}

export function splitStringIntoMultipleLines(
  input: string | undefined,
  maxLineLength: number,
  ellipsisLength?: number
): string[] {
  if (!input) {
    return [];
  }

  const words = input.split(" ");
  let lines: string[] = [""];
  let currentLineIndex = 0;

  for (const word of words) {
    // Check if adding this word exceeds the current line length
    if (lines[currentLineIndex].length + word.length + 1 <= maxLineLength) {
      lines[currentLineIndex] += (lines[currentLineIndex] ? " " : "") + word;
    } else {
      // If we've reached the ellipsisLength, stop and append ellipsis
      if (ellipsisLength && lines.length === ellipsisLength) {
        lines[currentLineIndex] = lines[currentLineIndex].trimEnd() + "...";
        break;
      }

      // Otherwise, start a new line
      currentLineIndex++;
      lines.push(word);
    }
  }

  // If ellipsisLength is defined and we're under the limit, ensure the last line ends with ellipsis
  if (ellipsisLength && lines.length > ellipsisLength) {
    lines = lines.slice(0, ellipsisLength);
    lines[ellipsisLength - 1] = lines[ellipsisLength - 1].trimEnd() + "...";
  }

  return lines;
}

export function formatDailyStoryTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours}h:${minutes}m:${remainingSeconds}s`;
}

export async function updateDailyStoryFeedbackScore(
  userUid: string | undefined,
  score: number | null | undefined,
  id: string | undefined
) {
  if (userUid) {
    return await updateDoc(
      doc(db, `/members/${userUid}/deliveredDailyStoriesNew/${id}`),
      {
        feedbackScore: score,
      }
    );
  }
}

export async function updateInstagramShareFeedbackData(
  dailyStory: DeliveredDailyStory,
  userUid: string | undefined
) {
  if (dailyStory.storyFeedbackId) {
    return await updateDoc(
      doc(
        db,
        `/members/${userUid}/dailyStoryFeedback/${dailyStory.storyFeedbackId}`
      ),
      {
        isInstagramShared: true,
      }
    );
  } else {
    const docRef = await addDoc(
      collection(db, `/members/${userUid}/dailyStoryFeedback`),
      {
        storySlug: dailyStory?.slug,
        deliveredDailyStory: dailyStory,
        feedbackScore: null,
        isInstagramShared: true,
      }
    );
    const id = docRef.id;
    await updateDoc(doc(db, `/members/${userUid}/dailyStoryFeedback/${id}`), {
      storyFeedbackId: id,
    });

    return await updateDoc(
      doc(db, `/members/${userUid}/deliveredDailyStories/0`),
      { storyFeedbackId: id }
    );
  }
}

export function getListOfTraitRulesDailyStoriesForMixpanel(
  dailyStory: DeliveredDailyStoryNew | null
) {
  let list: string[] = [];
  dailyStory?.assignmentRuleset.scaleRules.forEach((scale) => {
    const string = `${scale.slug} ${scale.direction}`;
    list.push(string);
  });
  if (dailyStory?.assignmentRuleset.identityArchetypeSlug) {
    list.push(dailyStory?.assignmentRuleset.identityArchetypeSlug);
  }
  if (dailyStory?.assignmentRuleset.interactionArchetypeSlug) {
    list.push(dailyStory?.assignmentRuleset.interactionArchetypeSlug);
  }

  return list;
}

export function getCountOfTraitRulesDailyStoriesForMixpanel(
  dailyStory: DeliveredDailyStoryNew | null
) {
  let list: string[] = [];
  dailyStory?.assignmentRuleset.scaleRules.forEach((scale) => {
    const string = `${scale.slug} ${scale.direction}`;
    list.push(string);
  });
  if (dailyStory?.assignmentRuleset.identityArchetypeSlug) {
    list.push(dailyStory?.assignmentRuleset.identityArchetypeSlug);
  }
  if (dailyStory?.assignmentRuleset.interactionArchetypeSlug) {
    list.push(dailyStory?.assignmentRuleset.interactionArchetypeSlug);
  }

  return list.length;
}
