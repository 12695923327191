import styles from "./SnippetInterractionBar.module.css";

import RegenerateIcon from "@/components/shared/icons/RegenerateIcon";
import {
  PeriodicQuizInstagramIcon,
  PeriodicQuizSnapchatIcon,
} from "@/components/appHome/PeriodicQuizzes/PeriodicQuizShareIcons";
import { MessageIconStatusBar } from "@/components/shared/icons/MessageIcon";

type Props = {
  hideShare?: boolean;
  hideRate?: boolean;
  disabledRegenerate?: boolean;
  hideRegenerate?: boolean;
  onRegenerate?: () => void;
  slug: string;
  type: "SP snippet" | "report" | "MP snippet";
  onRateClick?: () => void;
  onShare?: (value: "instagram" | "snapchat") => void;
};

type InsightProps = {
  onRateClick: () => void;
  onShare: (value: "instagram" | "snapchat") => void;
  onCommentClick: () => void;
  onPostClick: () => void;
  commentCount?: number | null | undefined;
  inactiveComments?: boolean;
  posted: boolean;
};

type DailyQuizResultsProps = {
  onShare: (value: "instagram" | "snapchat") => void;
  onCommentClick: () => void;
  onPostClick: () => void;
  commentCount?: number | null | undefined;
  inactiveComments?: boolean;
  posted: boolean;
};

type DailyPollProps = {
  onCommentClick: () => void;
  commentCount?: number | null | undefined;
};

type DimensionProps = {
  onShare: (value: "instagram" | "snapchat") => void;
  onRetake: () => void;
};

type DimensionOutroProps = {
  onContinue: () => void;
};

export default function SnippetInterractionBar(props: Props) {
  return (
    <div className={styles.main}>
      {props.onRateClick && (
        <div
          onClick={() => {
            props.onRateClick && props.onRateClick();
          }}
          className={styles.buttonDiv}
        >
          <HappySadFaceIcon />
          <p className={styles.text}>Rate</p>
        </div>
      )}
      {!props.hideRegenerate && (
        <div
          onClick={() => props.onRegenerate && props.onRegenerate()}
          style={props.disabledRegenerate ? { opacity: 0.5 } : {}}
          className={styles.buttonDiv}
        >
          <RegenerateIcon />
          <p className={styles.text}>Regenerate</p>
        </div>
      )}
      {props.onShare && (
        <div className={styles.shareDivParent}>
          <div className={styles.shareDiv}>
            <PeriodicQuizInstagramIcon
              onClick={() => {
                props.onShare && props.onShare("instagram");
              }}
            />
            <PeriodicQuizSnapchatIcon
              onClick={() => {
                props.onShare && props.onShare("snapchat");
              }}
            />
          </div>
          <p className={styles.shareToText}>Share to</p>
        </div>
      )}
    </div>
  );
}

export function DailyInsightsInterractionBar(props: InsightProps) {
  return (
    <div className={styles.main}>
      <div
        onClick={() => {
          props.onRateClick();
        }}
        className={styles.buttonDiv}
      >
        <HappySadFaceIcon />
        <p className={styles.text}>Rate</p>
      </div>
      <div
        onClick={() => {
          props.onPostClick();
        }}
        className={styles.buttonDiv}
      >
        <PostIconWhite />
        <p className={styles.text}>{props.posted ? "Posted" : "Post"}</p>
      </div>

      {!props.inactiveComments && (
        <div
          onClick={() => {
            props.onCommentClick();
          }}
          className={styles.buttonDiv}
        >
          <div className={styles.commentDiv}>
            <p className={styles.commentCount}>
              {props.commentCount
                ? props.commentCount > 99
                  ? 99
                  : props.commentCount
                : 0}
            </p>
            <MessageIconStatusBar />
          </div>
          <p className={styles.text}>Discuss</p>
        </div>
      )}

      <div className={styles.shareDivParent}>
        <div className={styles.shareDiv}>
          <PeriodicQuizInstagramIcon
            onClick={() => {
              props.onShare("instagram");
            }}
          />
          <PeriodicQuizSnapchatIcon
            onClick={() => {
              props.onShare("snapchat");
            }}
          />
        </div>
        <p className={styles.shareToText}>Share to</p>
      </div>
    </div>
  );
}

export function DailyStoriesInterractionBar(props: InsightProps) {
  return (
    <div className={styles.mainStories}>
      <div
        onClick={() => {
          props.onRateClick();
        }}
        className={styles.buttonDiv}
      >
        <HappySadFaceIcon />
        <p className={styles.text}>Rate</p>
      </div>
      <div
        onClick={() => {
          props.onPostClick();
        }}
        className={styles.buttonDiv}
      >
        <PostIconWhite />
        <p className={styles.text}>{props.posted ? "Posted" : "Post"}</p>
      </div>
      <div
        onClick={() => {
          props.onCommentClick();
        }}
        className={styles.buttonDiv}
      >
        <div className={styles.commentDiv}>
          <p className={styles.commentCount}>
            {props.commentCount === 99 ? "99+" : props.commentCount}
          </p>
          <MessageIconStatusBar />
        </div>
        <p className={styles.text}>Discuss</p>
      </div>

      <div className={styles.shareDivParent}>
        <div className={styles.shareDiv}>
          <PeriodicQuizInstagramIcon
            onClick={() => {
              props.onShare("instagram");
            }}
          />
          <PeriodicQuizSnapchatIcon
            onClick={() => {
              props.onShare("snapchat");
            }}
          />
        </div>
        <p className={styles.shareToText}>Share to</p>
      </div>
    </div>
  );
}

export function DailyPollsInterractionBar(props: DailyPollProps) {
  return (
    <div className={styles.main}>
      <div
        onClick={() => {
          props.onCommentClick();
        }}
        className={styles.buttonDiv}
      >
        <div className={styles.commentDiv}>
          <p className={styles.commentCount}>
            {props.commentCount === 99 ? "99+" : props.commentCount}
          </p>
          <MessageIconStatusBar />
        </div>
        <p className={styles.text}>Discuss</p>
      </div>
    </div>
  );
}

export function PeriodicQuizResultInterractionBar(
  props: DailyQuizResultsProps
) {
  return (
    <div className={styles.main}>
      <div
        onClick={() => {
          props.onPostClick();
        }}
        className={styles.buttonDiv}
      >
        <PostIconWhite />
        <p className={styles.text}>{props.posted ? "Posted" : "Post"}</p>
      </div>
      <div
        onClick={() => {
          props.onCommentClick();
        }}
        className={styles.buttonDiv}
      >
        <div className={styles.commentDiv}>
          <p className={styles.commentCount}>
            {props.commentCount
              ? props.commentCount > 99
                ? 99
                : props.commentCount
              : 0}
          </p>
          <MessageIconStatusBar />
        </div>
        <p className={styles.text}>Discuss</p>
      </div>

      <div className={styles.shareDivParent}>
        <div className={styles.shareDiv}>
          <PeriodicQuizInstagramIcon
            onClick={() => {
              props.onShare("instagram");
            }}
          />
          <PeriodicQuizSnapchatIcon
            onClick={() => {
              props.onShare("snapchat");
            }}
          />
        </div>
        <p className={styles.shareToText}>Share to</p>
      </div>
    </div>
  );
}

export function DimensionResultInterractionBar(props: DimensionProps) {
  return (
    <div className={styles.main}>
      <div onClick={() => props.onRetake()} className={styles.buttonDiv}>
        <RegenerateIcon />
        <p className={styles.text}>Retake</p>
      </div>
      <div className={styles.shareDivParent}>
        <div className={styles.shareDiv}>
          <PeriodicQuizInstagramIcon
            onClick={() => {
              props.onShare("instagram");
            }}
          />
          <PeriodicQuizSnapchatIcon
            onClick={() => {
              props.onShare("snapchat");
            }}
          />
        </div>
        <p className={styles.shareToText}>Share to</p>
      </div>
    </div>
  );
}

export function DimensionResultOutroInterractionBar(
  props: DimensionOutroProps
) {
  return (
    <div className={styles.mainDimensionOutro}>
      <SaveIcon />
      <p className={styles.savedToText}>Saved to your Results tab.</p>
      <button
        onClick={() => props.onContinue()}
        className={styles.continueButton}
      >
        Continue
      </button>
    </div>
  );
}

export function HappySadFaceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_62044_3379)">
        <path
          d="M7.73417 8.53135C8.01636 8.53135 8.29762 8.54729 8.57791 8.57822C8.54698 8.2979 8.53104 8.01667 8.53104 7.73448C8.53104 3.46896 11.9998 0 16.2655 0C20.531 0 24 3.46872 24 7.73448C24 12 20.5313 15.469 16.2655 15.469C15.9833 15.469 15.7021 15.453 15.4218 15.4221C15.4527 15.7024 15.4686 15.9836 15.4686 16.2658C15.4686 20.5314 11.9999 24.0003 7.73417 24.0003C3.46865 24.0003 -0.000310898 20.5316 -0.000310898 16.2658C-0.000310898 12.0003 3.46841 8.53135 7.73417 8.53135ZM22.5935 7.73448C22.5935 4.24512 19.7548 1.4064 16.2655 1.4064C12.7761 1.4064 9.93737 4.24512 9.93737 7.73448C9.93737 11.2238 12.7761 14.0626 16.2655 14.0626C19.7548 14.0626 22.5935 11.2238 22.5935 7.73448ZM7.73417 22.5938C11.2235 22.5938 14.0623 19.7551 14.0623 16.2658C14.0623 15.8804 14.0276 15.4961 13.9573 15.1173C11.5395 14.3551 9.64469 12.4605 8.88245 10.0425V10.0416C8.50371 9.9722 8.11932 9.93751 7.734 9.93751C4.24464 9.93751 1.40592 12.7762 1.40592 16.2656C1.40592 19.755 4.24481 22.5938 7.73417 22.5938Z"
          fill="#E6E1DF"
        />
        <path
          d="M14.0016 9.03024C15.4275 9.73899 17.1019 9.73899 18.5278 9.03024C18.6956 8.94587 18.8896 8.93087 19.0678 8.98899C19.245 9.04805 19.3931 9.17462 19.4765 9.34244C19.5599 9.51026 19.574 9.70431 19.514 9.8815C19.454 10.0596 19.3256 10.2059 19.1578 10.2884C17.3352 11.194 15.1939 11.194 13.3716 10.2884H13.3725C13.2038 10.2059 13.0753 10.0596 13.0153 9.8815C12.9553 9.70432 12.9694 9.51025 13.0528 9.34244C13.1363 9.17463 13.2844 9.04806 13.4616 8.98899C13.6397 8.93087 13.8338 8.94587 14.0016 9.03024Z"
          fill="#E6E1DF"
        />
        <path
          d="M18.2637 6.33374C18.0771 6.33374 17.898 6.25968 17.7668 6.12749C17.6346 5.99624 17.5605 5.81717 17.5605 5.63062V5.20313C17.5605 4.815 17.8755 4.5 18.2637 4.5C18.6518 4.5 18.9668 4.815 18.9668 5.20313V5.63251C18.9659 6.02064 18.6509 6.33374 18.2637 6.33374Z"
          fill="#E6E1DF"
        />
        <path
          d="M14.3965 6.33374C14.2099 6.33374 14.0309 6.25968 13.8996 6.12749C13.7674 5.99624 13.6934 5.81717 13.6934 5.63062V5.20313C13.6934 4.815 14.0084 4.5 14.3965 4.5C14.7846 4.5 15.0996 4.815 15.0996 5.20313V5.63251C15.0987 6.02064 14.7837 6.33374 14.3965 6.33374Z"
          fill="#E6E1DF"
        />
        <path
          d="M4.84086 17.8657C6.66337 16.9601 8.8047 16.9601 10.627 17.8657C10.7939 17.9492 10.9205 18.0954 10.9795 18.2726C11.0386 18.4498 11.0245 18.6429 10.942 18.8098C10.7677 19.1576 10.3448 19.2982 9.99703 19.1248H9.99797C8.57203 18.4161 6.89573 18.4161 5.47085 19.1248C5.30304 19.2101 5.10898 19.2251 4.93085 19.1661C4.75366 19.1079 4.60553 18.9804 4.5221 18.8126C4.43867 18.6457 4.4246 18.4507 4.4846 18.2735C4.5446 18.0963 4.67304 17.9501 4.84086 17.8667L4.84086 17.8657Z"
          fill="#E6E1DF"
        />
        <path
          d="M9.73241 15.1686C9.54585 15.1686 9.3668 15.0946 9.23554 14.9633C9.10336 14.8311 9.02929 14.6521 9.02929 14.4655V14.0361C9.02929 13.648 9.34429 13.333 9.73242 13.333C10.1205 13.333 10.4355 13.648 10.4355 14.0361V14.4655C10.4355 14.8546 10.1205 15.1686 9.73241 15.1686Z"
          fill="#E6E1DF"
        />
        <path
          d="M5.86523 15.1686C5.67867 15.1686 5.49961 15.0946 5.36835 14.9633C5.23617 14.8311 5.1621 14.6521 5.1621 14.4655V14.0361C5.1621 13.648 5.4771 13.333 5.86523 13.333C6.25336 13.333 6.56836 13.648 6.56836 14.0361V14.4655C6.56836 14.8546 6.25336 15.1686 5.86523 15.1686Z"
          fill="#E6E1DF"
        />
      </g>
      <defs>
        <clipPath id="clip0_62044_3379">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CopyIconInterractionBar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M18.169 0.274139H9.05023C8.3782 0.274139 7.76891 0.548281 7.32719 0.989997C6.88548 1.43171 6.61134 2.04186 6.61134 2.71304V4.51772H3.83208C3.16004 4.51772 2.55075 4.79186 2.10904 5.23358C1.66732 5.67529 1.39318 6.28544 1.39318 6.95662V19.287C1.39318 19.9581 1.66732 20.5683 2.10904 21.01C2.55075 21.4517 3.1609 21.7259 3.83208 21.7259H12.9509C13.6229 21.7259 14.2322 21.4517 14.6739 21.01C15.1156 20.5674 15.3898 19.9581 15.3898 19.287V17.4823H18.169C18.841 17.4823 19.4503 17.2081 19.8921 16.7664C20.3338 16.3247 20.6079 15.7146 20.6079 15.0434V2.71304C20.6079 2.041 20.3338 1.43171 19.8921 0.989997C19.4503 0.548281 18.8402 0.274139 18.169 0.274139ZM6.61131 5.9563V15.0443C6.61131 15.7155 6.88546 16.3256 7.32717 16.7673C7.76889 17.209 8.37904 17.4832 9.05021 17.4832H13.9512V19.2879C13.9512 19.562 13.8386 19.8129 13.6564 19.9951C13.4742 20.1764 13.2241 20.2899 12.9491 20.2899H3.83036C3.55622 20.2899 3.30529 20.1773 3.12311 19.9951C2.94092 19.8129 2.82835 19.5629 2.82835 19.2879V6.95752C2.82835 6.68338 2.94093 6.43245 3.12311 6.25026C3.30529 6.06893 3.55536 5.95551 3.83036 5.95551H6.60962L6.61131 5.9563ZM9.05111 1.71272H18.1699C18.444 1.71272 18.695 1.8253 18.8771 2.00748C19.0593 2.18966 19.1719 2.43973 19.1719 2.71473V15.0451C19.1719 15.3192 19.0593 15.5701 18.8771 15.7515C18.695 15.9337 18.4449 16.0462 18.1699 16.0462H9.05199C8.77785 16.0462 8.52692 15.9336 8.34474 15.7515C8.16341 15.5701 8.04998 15.3201 8.04998 15.0451V2.71473C8.04998 2.44059 8.16256 2.18966 8.34474 2.00748C8.52692 1.82529 8.77699 1.71272 9.05199 1.71272H9.05111Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}

export function SaveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
    >
      <g clipPath="url(#clip0_63992_27750)">
        <path
          d="M5.52872 22.4998H16.4713C18.0041 22.498 19.2462 21.2558 19.249 19.7221V3.27825C19.2462 1.7445 18.004 0.502408 16.4713 0.500488H10.9822C10.9222 0.500488 10.8613 0.506113 10.8022 0.518301H10.7844C10.7329 0.530488 10.6822 0.545488 10.6335 0.564239L10.5819 0.586739C10.5435 0.604552 10.506 0.625178 10.4713 0.648614L10.4272 0.674865L10.4263 0.675802C10.3794 0.708615 10.3344 0.746115 10.2932 0.786428L3.04254 8.04259C2.99848 8.08665 2.96004 8.13446 2.92629 8.18696L2.91504 8.2029L2.91598 8.20196C2.88692 8.24884 2.86067 8.29665 2.83817 8.34634C2.83348 8.35477 2.82973 8.36415 2.82691 8.37352C2.77817 8.48978 2.75285 8.61446 2.75098 8.74008V19.722C2.75379 21.2557 3.99596 22.4979 5.52872 22.4998ZM9.99969 3.91401V6.97113C9.99969 7.17738 9.91812 7.37519 9.77187 7.52145C9.62656 7.66676 9.42874 7.74926 9.22249 7.74926H6.16441L9.99969 3.91401ZM4.7516 9.74889H9.22257C10.7563 9.74795 11.9984 8.50482 12.0003 6.97113V2.50017H16.4713C16.9007 2.50017 17.2485 2.84798 17.2485 3.2783V19.7221C17.2485 19.9284 17.1669 20.1262 17.0207 20.2725C16.8754 20.4187 16.6775 20.5003 16.4713 20.5003H5.52872C5.09934 20.5003 4.75153 20.1525 4.75153 19.7221L4.7516 9.74889Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_63992_27750">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function PostIconWhite() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_66566_75)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7303 16.7279H4.77942V18.3791C4.77942 18.7905 4.44709 19.1176 4.04084 19.1176C3.8758 19.1176 3.72571 19.0676 3.60622 18.972L0.525793 16.6227V16.6182C0.432445 16.5465 0.348806 16.4584 0.272637 16.3576C-0.179171 15.7459 -0.0522104 14.8834 0.561639 14.4242V14.4197L3.60097 12.0942C3.7324 11.9987 3.87578 11.9486 4.04082 11.9486C4.44708 11.9486 4.7794 12.2787 4.7794 12.6872V14.3383H16.7302V9.10927L19.1176 7.26697V14.3382C19.1176 15.6622 18.0468 16.7279 16.7303 16.7279ZM18.5965 4.66436L15.5137 7.01812C15.3943 7.11669 15.2389 7.16897 15.0791 7.16897C14.6684 7.16897 14.3405 6.83665 14.3405 6.4304V4.77927H2.38971V10.0083L0 11.8506V4.7794C0 3.45759 1.06789 2.3897 2.38971 2.3897H14.3405V0.736334C14.3405 0.330085 14.6684 0 15.0791 0C15.2389 0 15.3898 0.0619831 15.5093 0.143382L18.5583 2.47554C19.1677 2.92734 19.3014 3.79211 18.8451 4.4068C18.7712 4.50687 18.6898 4.58818 18.5965 4.66436Z"
          fill="#FFFFF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9709 26.0001C22.4054 26.0001 26.0003 22.4052 26.0003 17.9706C26.0003 13.5361 22.4054 9.94116 17.9709 9.94116C13.5363 9.94116 9.94141 13.5361 9.94141 17.9706C9.94141 22.4052 13.5363 26.0001 17.9709 26.0001Z"
          fill="#928E8C"
        />
        <path
          d="M20.6254 14.5962C21.4555 14.5962 22.1268 15.2674 22.1268 16.0976C22.1268 16.9278 21.4556 17.599 20.6254 17.599C19.7974 17.599 19.124 16.9278 19.124 16.0976C19.124 15.2674 19.7973 14.5962 20.6254 14.5962Z"
          fill="#FFFFF2"
        />
        <path
          d="M23.2561 19.8337C23.2561 20.185 23.0281 20.4966 22.6915 20.6011C21.9973 20.8165 21.2737 20.9231 20.5481 20.9148C20.6025 20.737 20.6297 20.5509 20.6297 20.3648C20.6297 19.6309 20.3892 18.9179 19.9438 18.3323C20.6276 18.5414 21.3679 18.4264 21.9554 18.0208C22.292 18.1065 22.5931 18.2989 22.8106 18.5707C23.0991 18.9283 23.2561 19.3742 23.2561 19.8337Z"
          fill="#FFFFF2"
        />
        <path
          d="M18.5142 15.6956C18.5142 16.8044 17.6156 17.703 16.5069 17.703C15.3981 17.703 14.4995 16.8044 14.4995 15.6956C14.4995 14.5874 15.3981 13.6882 16.5069 13.6882C17.6156 13.6882 18.5142 14.5874 18.5142 15.6956Z"
          fill="#FFFFF2"
        />
        <path
          d="M19.2376 18.7881C19.5973 19.2356 19.7938 19.7918 19.7938 20.3669C19.7938 20.806 19.5094 21.1949 19.0892 21.3266C17.4081 21.8473 15.6076 21.8473 13.9265 21.3266C13.5062 21.1954 13.2197 20.806 13.2197 20.3669C13.2197 19.7918 13.4163 19.2356 13.7759 18.7881C14.0729 18.4201 14.4869 18.1671 14.9511 18.0751C15.8962 18.694 17.1174 18.694 18.0625 18.0751C18.5267 18.1676 18.9428 18.4206 19.2376 18.7881Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_66566_75">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function PostIconDark() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_66518_187)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0825 11.5809H3.80883V12.724C3.80883 13.0088 3.57876 13.2353 3.29751 13.2353C3.18325 13.2353 3.07934 13.2007 2.99662 13.1345L0.864011 11.508V11.5049C0.799385 11.4553 0.741481 11.3943 0.688749 11.3245C0.375959 10.901 0.463854 10.3039 0.888827 9.98598V9.98287L2.99298 8.37294C3.08397 8.30676 3.18323 8.27212 3.29749 8.27212C3.57874 8.27212 3.80881 8.50064 3.80881 8.78344V9.92653H12.0825V6.30642L13.7353 5.03098V9.92644C13.7353 10.8431 12.9939 11.5809 12.0825 11.5809ZM13.3745 3.22917L11.2403 4.8587C11.1576 4.92694 11.05 4.96313 10.9394 4.96313C10.655 4.96313 10.4281 4.73306 10.4281 4.45181V3.30872H2.15441V6.92883L0.5 8.20428V3.30882C0.5 2.39372 1.23931 1.65441 2.15441 1.65441H10.4281V0.509769C10.4281 0.22852 10.655 0 10.9394 0C11.05 0 11.1545 0.0429114 11.2372 0.0992645L13.3481 1.71383C13.77 2.02662 13.8625 2.62531 13.5466 3.05086C13.4954 3.12014 13.4391 3.17643 13.3745 3.22917Z"
          fill="#2D2927"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9412 18.0001C16.0112 18.0001 18.5 15.5113 18.5 12.4412C18.5 9.37114 16.0112 6.88235 12.9412 6.88235C9.87111 6.88235 7.38232 9.37114 7.38232 12.4412C7.38232 15.5113 9.87111 18.0001 12.9412 18.0001Z"
          fill="#928E8C"
        />
        <path
          d="M14.7792 10.1051C15.3539 10.1051 15.8186 10.5698 15.8186 11.1445C15.8186 11.7192 15.3539 12.1839 14.7792 12.1839C14.2059 12.1839 13.7397 11.7192 13.7397 11.1445C13.7397 10.5698 14.2059 10.1051 14.7792 10.1051Z"
          fill="#FFFFF2"
        />
        <path
          d="M16.6002 13.7311C16.6002 13.9743 16.4424 14.19 16.2093 14.2624C15.7287 14.4115 15.2278 14.4853 14.7255 14.4795C14.7631 14.3565 14.7819 14.2276 14.7819 14.0988C14.7819 13.5907 14.6155 13.0971 14.3071 12.6916C14.7805 12.8364 15.293 12.7568 15.6997 12.476C15.9328 12.5353 16.1412 12.6685 16.2918 12.8567C16.4916 13.1042 16.6002 13.413 16.6002 13.7311Z"
          fill="#FFFFF2"
        />
        <path
          d="M13.3175 10.8662C13.3175 11.6338 12.6954 12.2559 11.9278 12.2559C11.1602 12.2559 10.5381 11.6338 10.5381 10.8662C10.5381 10.099 11.1602 9.4765 11.9278 9.4765C12.6954 9.4765 13.3175 10.099 13.3175 10.8662Z"
          fill="#FFFFF2"
        />
        <path
          d="M13.8181 13.0072C14.0671 13.317 14.2031 13.7021 14.2031 14.1002C14.2031 14.4042 14.0063 14.6734 13.7153 14.7646C12.5515 15.1251 11.305 15.1251 10.1411 14.7646C9.85018 14.6738 9.65186 14.4042 9.65186 14.1002C9.65186 13.7021 9.78794 13.317 10.0369 13.0072C10.2425 12.7524 10.5291 12.5773 10.8505 12.5136C11.5048 12.9421 12.3503 12.9421 13.0045 12.5136C13.3259 12.5776 13.614 12.7528 13.8181 13.0072Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_66518_187">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
