export default function ConnectionsImage() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0408 17.4198C10.5709 17.3299 10.9259 16.8226 10.836 16.2925L10.6806 15.3952C10.5907 14.865 10.0835 14.51 9.55329 14.5999C9.02313 14.6898 8.66819 15.1971 8.75805 15.7272L8.91344 16.6246C8.9957 17.0999 9.40854 17.435 9.87319 17.435C9.92803 17.435 9.98288 17.4304 10.0408 17.4198L10.0408 17.4198Z"
        fill="#FFFFF2"
      />
      <path
        d="M15.7279 15.4499C15.8102 15.9253 16.223 16.2604 16.6876 16.2604C16.7425 16.2604 16.7973 16.2558 16.8552 16.2451C17.3854 16.1553 17.7403 15.648 17.6505 15.1178L17.4951 14.2205C17.4052 13.6903 16.8979 13.3354 16.3677 13.4253C15.8376 13.5151 15.4826 14.0224 15.5725 14.5526L15.7279 15.4499Z"
        fill="#FFFFF2"
      />
      <path
        d="M18.3841 19.1428C18.084 18.698 17.4762 18.5807 17.0313 18.8808C15.0858 20.1986 13.0338 20.5536 10.7609 19.9609C10.2429 19.8284 9.70817 20.1407 9.57105 20.6587C9.43394 21.1766 9.75082 21.7113 10.2688 21.8485C11.1341 22.0739 11.9842 22.1836 12.816 22.1836C14.6837 22.1836 16.4631 21.6184 18.1235 20.4911C18.5683 20.191 18.6856 19.5831 18.3855 19.1383L18.3841 19.1428Z"
        fill="#FFFFF2"
      />
      <path
        d="M30.2203 15.1847C27.7828 14.4793 25.2205 14.7642 22.9975 15.9876C22.9625 16.0074 22.9305 16.0302 22.9 16.05C22.8771 15.7301 22.8375 15.4071 22.7827 15.0872C22.35 12.5872 20.9729 10.4072 18.9025 8.94001C16.8322 7.47751 14.3122 6.90776 11.8123 7.34038C9.31233 7.77305 7.13231 9.15022 5.66513 11.2206C4.19806 13.2909 3.63288 15.8109 4.0655 18.3108C4.86075 22.9284 8.88551 26.1931 13.4177 26.1931C13.9524 26.1931 14.4948 26.1458 15.0356 26.0529C16.1279 25.8655 17.1608 25.4953 18.1007 24.9652C18.1937 26.3225 18.5837 27.6555 19.2586 28.885C20.4834 31.1077 22.4989 32.718 24.9335 33.425C25.811 33.6779 26.7006 33.7998 27.5705 33.7998C31.6885 33.7998 35.4953 31.0927 36.6958 26.9444C38.1507 21.9142 35.2454 16.6367 30.2182 15.182L30.2203 15.1847ZM34.8258 26.405C33.668 30.4025 29.4754 32.7121 25.4779 31.5526C21.4804 30.3948 19.1708 26.2022 20.3303 22.2047C20.8924 20.27 22.1706 18.6673 23.9378 17.6967C25.0682 17.0721 26.3128 16.7567 27.5681 16.7567C28.2735 16.7567 28.9834 16.8588 29.6781 17.0568C33.6756 18.2147 35.9852 22.4072 34.8257 26.4047L34.8258 26.405ZM5.98882 17.9775C5.64604 15.9925 6.09851 13.9921 7.26088 12.3424C8.42326 10.6927 10.1585 9.60021 12.1437 9.2574C12.5763 9.18275 13.009 9.14467 13.4416 9.14467C14.9864 9.14467 16.4916 9.62 17.7788 10.5295C19.4242 11.6919 20.521 13.4271 20.8638 15.4123C20.9842 16.1222 21.004 16.8352 20.9263 17.5344C19.7761 18.6618 18.9214 20.0694 18.4614 21.6599C18.3837 21.9325 18.3166 22.2052 18.2618 22.4795C17.2518 23.3265 16.0391 23.8993 14.7091 24.1294C10.6094 24.8393 6.69891 22.0743 5.99184 17.9748L5.98882 17.9775Z"
        fill="#FFFFF2"
      />
      <path
        d="M24.2185 23.4753C24.3084 23.5027 24.4014 23.5149 24.4912 23.5149C24.9117 23.5149 25.3017 23.2376 25.4266 22.8096L25.6795 21.9366C25.8273 21.4187 25.5317 20.8794 25.0123 20.727C24.4943 20.5792 23.955 20.8748 23.8026 21.3942L23.5497 22.2672C23.402 22.7851 23.6975 23.3244 24.217 23.4768L24.2185 23.4753Z"
        fill="#FFFFF2"
      />
      <path
        d="M31.6558 22.6528C31.1379 22.5051 30.5986 22.8006 30.4462 23.3201L30.1933 24.193C30.0455 24.711 30.3411 25.2503 30.8606 25.4026C30.9504 25.4301 31.0434 25.4423 31.1332 25.4423C31.5537 25.4423 31.9437 25.165 32.0686 24.7399L32.3215 23.867C32.4693 23.349 32.1737 22.8098 31.6543 22.6574L31.6558 22.6528Z"
        fill="#FFFFF2"
      />
      <path
        d="M29.8647 27.8476C27.54 28.1828 25.5396 27.6023 23.7495 26.0774C23.3397 25.727 22.7243 25.7773 22.3769 26.1871C22.0265 26.5969 22.0768 27.2123 22.4866 27.5597C24.2995 29.1075 26.3638 29.8875 28.6369 29.8875C29.129 29.8875 29.6317 29.8525 30.1467 29.7778C30.6814 29.7001 31.0516 29.2081 30.9739 28.6748C30.8962 28.1401 30.4041 27.7745 29.8709 27.8476H29.8647Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
