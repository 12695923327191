export default function CompatiblityTwoByTwoIcon(props: {
  inactive?: boolean;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={props.inactive ? { opacity: "0.4" } : {}}
    >
      <path
        d="M10.0667 17.9999C9.75358 17.9999 9.5 17.7463 9.5 17.4332V2.69997C9.5 2.38688 9.75358 2.1333 10.0667 2.1333C10.3798 2.1333 10.6333 2.38688 10.6333 2.69997V17.4332C10.6333 17.7463 10.3798 17.9999 10.0667 17.9999Z"
        fill="#FFFFF2"
      />
      <path
        d="M17.4327 10.6333H2.69948C2.38639 10.6333 2.13281 10.3798 2.13281 10.0667C2.13281 9.75358 2.38639 9.5 2.69948 9.5H17.4327C17.7458 9.5 17.9994 9.75358 17.9994 10.0667C17.9994 10.3798 17.7458 10.6333 17.4327 10.6333Z"
        fill="#FFFFF2"
      />
      <path
        d="M16 5.5C16 7.5 13 7.5 13 5.5C13 3.5 16 3.5 16 5.5Z"
        fill="#E0CC65"
      />
      <path
        d="M7 13.5C7 15.5 4 15.5 4 13.5C4 11.5 7 11.5 7 13.5Z"
        fill="#FFA0B9"
      />
    </svg>
  );
}
