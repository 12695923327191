export default function CompatiblitySpectrumsIcon(props: {
  inactive?: boolean;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={props.inactive ? { opacity: "0.4" } : {}}
    >
      <g clipPath="url(#clip0_59595_15339)">
        <path
          d="M15.2303 12.0281H12.0269C11.5847 12.0281 11.2266 11.67 11.2266 11.2278C11.2266 10.7856 11.5847 10.4268 12.0269 10.4268H15.2303C15.6725 10.4268 16.0314 10.7856 16.0314 11.2278C16.0314 11.67 15.6725 12.0281 15.2303 12.0281Z"
          fill="#FFFFF2"
        />
        <path
          d="M7.22283 12.0281H0.816682C0.374486 12.0281 0.015625 11.67 0.015625 11.2278C0.015625 10.7856 0.374486 10.4268 0.816682 10.4268H7.22283C7.66503 10.4268 8.02389 10.7856 8.02389 11.2278C8.02389 11.67 7.66503 12.0281 7.22283 12.0281Z"
          fill="#FFFFF2"
        />
        <path
          d="M15.2306 5.62187H8.82449C8.3823 5.62187 8.02344 5.26301 8.02344 4.82082C8.02344 4.37862 8.3823 4.02051 8.82449 4.02051H15.2306C15.6728 4.02051 16.0317 4.37862 16.0317 4.82082C16.0317 5.26301 15.6728 5.62187 15.2306 5.62187Z"
          fill="#FFFFF2"
        />
        <path
          d="M4.02014 5.62187H0.816682C0.374486 5.62187 0.015625 5.26301 0.015625 4.82082C0.015625 4.37862 0.374486 4.02051 0.816682 4.02051H4.02014C4.46234 4.02051 4.82045 4.37862 4.82045 4.82082C4.82045 5.26301 4.46234 5.62187 4.02014 5.62187Z"
          fill="#FFFFF2"
        />
        <path
          d="M6.42217 8.02426C5.57232 8.02426 4.75775 7.68642 4.15719 7.08582C3.55662 6.48522 3.21875 5.67065 3.21875 4.82084C3.21875 3.97173 3.55659 3.15641 4.15719 2.55585C4.75779 1.95529 5.57235 1.61816 6.42217 1.61816C7.27128 1.61816 8.08659 1.95525 8.68716 2.55585C9.28772 3.15645 9.62484 3.97177 9.62484 4.82084C9.62484 5.67069 9.28776 6.48526 8.68716 7.08582C8.08656 7.68638 7.27124 8.02426 6.42217 8.02426ZM6.42217 3.21945C5.77427 3.21945 5.19018 3.60983 4.94244 4.20818C4.69471 4.80654 4.83133 5.49572 5.28929 5.95367C5.74725 6.41163 6.43645 6.54828 7.03478 6.30052C7.63312 6.05277 8.02352 5.4687 8.02352 4.8208C8.02352 4.39663 7.8546 3.98897 7.5543 3.68867C7.254 3.38837 6.84634 3.21945 6.42217 3.21945Z"
          fill="#6AB4EA"
        />
        <path
          d="M9.62455 14.4305C8.77544 14.4305 7.96012 14.0934 7.35956 13.4928C6.759 12.8922 6.42188 12.0769 6.42188 11.2278C6.42188 10.378 6.75896 9.56341 7.35956 8.96285C7.96016 8.36229 8.77548 8.02441 9.62455 8.02441C10.4744 8.02441 11.289 8.36225 11.8895 8.96285C12.4901 9.56345 12.828 10.378 12.828 11.2278C12.828 12.0769 12.4901 12.8923 11.8895 13.4928C11.2889 14.0934 10.4744 14.4305 9.62455 14.4305ZM9.62455 9.6257C8.97665 9.6257 8.39333 10.0161 8.14482 10.6144C7.89707 11.2128 8.03446 11.902 8.49242 12.3599C8.95037 12.8179 9.63958 12.9553 10.2379 12.7075C10.8363 12.459 11.2266 11.8757 11.2266 11.2278C11.2266 10.8029 11.0577 10.3952 10.7574 10.0949C10.4571 9.79462 10.0495 9.6257 9.62455 9.6257Z"
          fill="#FFA0B9"
        />
      </g>
      <defs>
        <clipPath id="clip0_59595_15339">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
