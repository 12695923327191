/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import Link from "next/link";
import { db, firebaseStorage } from "@/_firebase/firebaseConfig";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";

import styles from "./MobileSearchPage.module.css";

import { useDebounce } from "@/helpers/useDebounce";
import useScroll from "@/helpers/useScroll";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import GhostBox from "@/components/shared/GhostBox/GhostBox";
import MagnifyingGlassImage from "@/components/shared/GhostBox/MagnifyingGlassImage";
import SadFace from "@/components/shared/GhostBox/SadFace";
import { usePublicProfileContext } from "@/context/PublicProfileContext";
import archetypesJsonData from "../../../assets/traitInfo/archetypes.json";
import elementsJsonData from "../../../assets/traitInfo/elements.json";
import patternsJsonData from "../../../assets/traitInfo/patterns.json";
import { TraitElement } from "@/models/sharedModels";
import Element from "@/components/traits/element/Element/Element";
import Archetype from "@/components/traits/arcehtype/Archetypes/Archetype";
import Pattern from "@/components/traits/pattern/Pattern/Pattern";
import { useAuth } from "@/context/AuthContext";
import { getDefaultProfilePhoto } from "@/context/utils";
import SearchShareSummoner from "./SearchShareSummoner";
import { Mixpanel } from "@/helpers/mixpanel";
import FriendButton from "@/components/shared/AuthenticatedHeader/AuthSearchBar.tsx/FriendButton";
import { RootState } from "@/redux/store";
import { useModalContext } from "@/context/ModalContext";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";
import ArchetypeModal from "@/components/traits/TraitsModals/Archetype/ArchetypeModal";
import PatternModal from "@/components/traits/TraitsModals/Pattern/PatternModal";
import ProfileModalHandler from "@/components/authenticatedProfile/Abstracts/ProfileModal/ProfileModalHandler";

export default function MobileSearchPage() {
  const { setMobileSearchOpen } = usePublicProfileContext();
  const [search, setSearch] = useState<string>("");
  const [profileResults, setProfileResults] = useState<PublicProfile[] | []>(
    []
  );
  const [traitResults, setTraitResults] = useState<TraitElement[] | []>([]);
  const [imagURls, setImageURls] = useState<any[] | []>([]);
  const [searchCount, setSearchCount] = useState<number>(0);
  const [blockScroll, allowScroll] = useScroll();
  const { user } = useAuth();
  const colorMap = ProfileColorMap;
  const connectionListWithFriendDetails = useSelector(
    (state: RootState) => state.connections.publicProfiles
  );
  const debouncedValue = useDebounce<string>(search, 500);
  const { setModalComponent, setModalOpen } = useModalContext();

  useEffect(() => {
    const searchForProfiles = async () => {
      setSearchCount((current) => current + 1);
      const profileRef = collection(db, "publicProfiles");
      const firstSearch = query(
        profileRef,
        where("userNameLowerCase", "==", search.toLowerCase()),
        limit(1)
      );
      const q = query(
        profileRef,
        where("searchTerms", "array-contains", search.toLowerCase()),
        orderBy("hasPhoto", "desc"),
        limit(10)
      );
      const firstQuerySnapshot = await getDocs(firstSearch);

      const querySnapshot = await getDocs(q);
      let hitUsernameUID: string = "";
      let firstPromise = firstQuerySnapshot.docs.map((doc) => {
        hitUsernameUID = doc.data().ownerUUID;
        if (doc.data().ownerUUID !== user?.uid) {
          setProfileResults((current: any) => [...current, doc.data()]);
          return getDownloadURL(
            ref(
              firebaseStorage,
              `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${
                doc.data().ownerUUID
              }/cropped.jpg`
            )
          )
            .then((url) => {
              return url;
            })
            .catch(async (err) => {
              return await getDefaultProfilePhoto(doc.data().ownerUUID).then(
                (value) => {
                  return value;
                }
              );
            });
        }
      });
      let promises = querySnapshot.docs.map((doc) => {
        if (
          doc.data().ownerUUID !== user?.uid &&
          doc.data().ownerUUID !== hitUsernameUID
        ) {
          setProfileResults((current: any) => [...current, doc.data()]);
          return getDownloadURL(
            ref(
              firebaseStorage,
              `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${
                doc.data().ownerUUID
              }/cropped.jpg`
            )
          )
            .then((url) => {
              return url;
            })
            .catch(async (err) => {
              return await getDefaultProfilePhoto(doc.data().ownerUUID).then(
                (value) => {
                  return value;
                }
              );
            });
        }
      });
      setImageURls(await Promise.all([firstPromise, promises].flat()));
    };
    const searchForTraits = () => {
      elementsJsonData.forEach((el) => {
        if (el.isDiscoverable === true) {
          if (
            el.name.toLowerCase().includes(search.toLowerCase()) ||
            el.alias?.toLowerCase().includes(search.toLowerCase())
          ) {
            setTraitResults((current) => [
              ...current,
              el as unknown as TraitElement,
            ]);
          }
        }
      });
      archetypesJsonData.forEach((el) => {
        if (el.isDiscoverable === true && el.archetypeGroupSlug !== "spirit") {
          if (
            el.name.toLowerCase().includes(search.toLowerCase()) ||
            el.acronym?.toLowerCase().includes(search.toLowerCase())
          ) {
            setTraitResults((current) => [
              ...current,
              el as unknown as TraitElement,
            ]);
          }
        }
      });
      patternsJsonData.forEach((el) => {
        if (el.isDiscoverable === true) {
          if (el.name.toLowerCase().includes(search.toLowerCase())) {
            setTraitResults((current) => [
              ...current,
              el as unknown as TraitElement,
            ]);
          }
        }
      });
    };

    if (searchCount > 1000) {
      return;
    } else {
      if (search.length > 3) {
        setProfileResults([]);
        setImageURls([]);
        searchForProfiles();
      } else {
        setProfileResults([]);
        setImageURls([]);
      }
      setTraitResults([]);
      searchForTraits();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    Mixpanel?.track("Global search opened", {});
    blockScroll();
    return () => {
      allowScroll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapped = profileResults.map((person, index) => {
    if (user?.uid) {
      return (
        <div className={styles.personDiv} key={index}>
          <div
            onClick={(e) => {
              setModalComponent(
                <ProfileModalHandler
                  uid={person.ownerUUID}
                  elementName="profileModal"
                />
              );
            }}
            className={styles.personDiv}
          >
            <img
              style={
                person.primaryNature?.nature
                  ? {
                      border: `3px solid ${
                        colorMap[person.primaryNature?.nature]
                      }`,
                    }
                  : {
                      border: `3px solid #55504F`,
                    }
              }
              width={50}
              height={50}
              alt=""
              src={imagURls[index] ?? "/default-image.png"}
              className={styles.smallImage}
            />
            <div className={styles.bioAndUsername}>
              <h5>{person.name}</h5>
              <p>{`@${person.userName}`}</p>
            </div>
          </div>
          {window.location.href.includes("app") ? (
            <FriendButton onSearch={() => {}} person={person} />
          ) : null}
        </div>
      );
    }
    return (
      <div className={styles.personDiv} key={index}>
        <Link
          href={`/${person.userName}`}
          onClick={(e) => {
            setSearch("");
            setProfileResults([]);
            setImageURls([]);
            setModalOpen(false);
            setMobileSearchOpen(false);
          }}
          className={styles.personDiv}
        >
          <img
            style={
              person.primaryNature?.nature
                ? {
                    border: `3px solid ${
                      colorMap[person.primaryNature?.nature]
                    }`,
                  }
                : {
                    border: `3px solid #55504F`,
                  }
            }
            width={50}
            height={50}
            alt=""
            src={imagURls[index] ?? "/default-image.png"}
            className={styles.smallImage}
          />
          <div className={styles.bioAndUsername}>
            <h5>{person.name}</h5>
            <p>{`@${person.userName}`}</p>
          </div>
        </Link>
        {window.location.href.includes("app") ? (
          <FriendButton onSearch={() => {}} person={person} />
        ) : null}
      </div>
    );
  });

  const prefilledConnectionsMapped = connectionListWithFriendDetails?.map(
    (person, index) => {
      if (user?.uid) {
        return (
          <div className={styles.personDiv} key={index}>
            <div
              onClick={(e) => {
                setModalComponent(
                  <ProfileModalHandler
                    uid={person.ownerUUID}
                    elementName="profileModal"
                  />
                );
              }}
              className={styles.personDiv}
            >
              <img
                style={
                  person.primaryNature?.nature
                    ? {
                        border: `3px solid ${
                          colorMap[person.primaryNature?.nature]
                        }`,
                      }
                    : {
                        border: `3px solid #55504F`,
                      }
                }
                width={50}
                height={50}
                alt=""
                src={person.imageUrl}
                className={styles.smallImage}
              />
              <div className={styles.bioAndUsername}>
                <h5>{person.name}</h5>
                <p>{`@${person.userName}`}</p>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className={styles.personDiv} key={index}>
          <Link
            href={`/${person.userName}`}
            onClick={(e) => {
              setSearch("");
              setProfileResults([]);
              setImageURls([]);
              setModalOpen(false);
              setMobileSearchOpen(false);
            }}
            className={styles.personDiv}
          >
            <img
              style={
                person.primaryNature?.nature
                  ? {
                      border: `3px solid ${
                        colorMap[person.primaryNature?.nature]
                      }`,
                    }
                  : {
                      border: `3px solid #55504F`,
                    }
              }
              width={50}
              height={50}
              alt=""
              src={person.imageUrl}
              className={styles.smallImage}
            />
            <div className={styles.bioAndUsername}>
              <h5>{person.name}</h5>
              <p>{`@${person.userName}`}</p>
            </div>
          </Link>
        </div>
      );
    }
  );

  const traitsMapped = traitResults.map((el, index) => {
    if (el.traitContent.traitType === "element") {
      if (user?.uid) {
        return (
          <div
            onClick={() => setModalComponent(<ElementModal slug={el.slug} />)}
            style={{ cursor: "pointer" }}
            key={el.slug}
            className="elBodyFiveMArgin"
          >
            <Element slug={el.slug} />
          </div>
        );
      }
      return (
        <Link
          onClick={() => setMobileSearchOpen(false)}
          href={`/traits/elements/${el.slug}`}
          key={el.slug}
          className="elBodyFiveMArgin"
        >
          <Element slug={el.slug} />
        </Link>
      );
    } else if (el.traitContent.traitType === "archetype") {
      if (user?.uid) {
        return (
          <div
            onClick={() => setModalComponent(<ArchetypeModal slug={el.slug} />)}
            style={{ cursor: "pointer" }}
            key={el.slug}
            className="elBodyFiveMArgin"
          >
            <Archetype
              // @ts-ignore
              archetype={el}
            />
          </div>
        );
      }
      return (
        <Link
          onClick={() => setMobileSearchOpen(false)}
          href={`/traits/archetypes/${el.slug}`}
          key={el.slug}
          className="elBodyFiveMArgin"
        >
          <Archetype
            // @ts-ignore
            archetype={el}
          />
        </Link>
      );
    } else {
      if (user?.uid) {
        return (
          <div
            onClick={() => setModalComponent(<PatternModal slug={el.slug} />)}
            style={{ cursor: "pointer" }}
            key={el.slug}
            className="elBodyFiveMArgin"
          >
            <Pattern patternSlug={el.slug} />
          </div>
        );
      }
      return (
        <Link
          onClick={() => setMobileSearchOpen(false)}
          href={`/traits/patterns/${el.slug}`}
          key={el.slug}
          className="elBodyFiveMArgin"
        >
          <Pattern patternSlug={el.slug} />
        </Link>
      );
    }
  });

  return (
    <>
      <div className={styles.main}>
        <div className={styles.top}>
          <input
            id="mobileSearchInput"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            className={styles.input}
            placeholder="Search users and traits"
            autoFocus
          />
          <p
            onClick={(e) => {
              setModalOpen(false);
              setMobileSearchOpen(false);
            }}
          >
            Cancel
          </p>
          <div className={styles.searchIconDiv}>
            <svg
              width="28"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6064 11.3561L18.0365 16.9996L18.0365 16.9998C18.477 17.4576 18.477 18.1993 18.0365 18.6571C17.8163 18.886 17.5271 19 17.2388 19C16.9497 19 16.6613 18.886 16.4411 18.6571L11.0109 13.0128C9.87852 13.8576 8.49134 14.3562 6.99122 14.3562C3.17633 14.3562 0.0839844 11.1422 0.0839844 7.17768C0.0839844 3.21295 3.17633 -0.000832019 6.99183 1.61578e-07C10.8067 1.61578e-07 13.8991 3.21402 13.8991 7.17854C13.8991 8.73749 13.4193 10.1792 12.6064 11.3561ZM6.99169 2.34277C4.4271 2.34277 2.33984 4.51117 2.33984 7.17733C2.33984 9.8435 4.4271 12.0119 6.99169 12.0119C9.55709 12.0119 11.6435 9.8435 11.6435 7.17733C11.6435 4.51202 9.55709 2.34277 6.99169 2.34277Z"
                fill="#2D2927"
              ></path>
            </svg>
          </div>
        </div>

        <div className={styles.resultDiv}>
          <h1 className={styles.people}>Traits</h1>
          {traitResults.length > 0 && (
            <div className={styles.traitResultDiv}>{traitsMapped}</div>
          )}
          {traitResults.length === 0 && (
            <GhostBox
              onlyMessage
              message="No traits found"
              image={<SadFace />}
            />
          )}
          <h1 className={styles.people} style={{ marginTop: "20px" }}>
            People
          </h1>
          {search.length === 0 && <div>{prefilledConnectionsMapped}</div>}
          {profileResults.length > 0 && <div>{mapped}</div>}
          {profileResults.length === 0 && search.length > 3 && (
            <GhostBox
              onlyMessage
              message="No users found"
              image={<SadFace />}
            />
          )}
          {search.length === 0 && <SearchShareSummoner />}
          {search.length < 4 && (
            <GhostBox
              image={<MagnifyingGlassImage />}
              onlyMessage={true}
              message="You must type at least 4 characters"
            />
          )}
        </div>
      </div>
    </>
  );
}
